import { useState } from 'react';

export const usePagination = (initialQuery: any) => {
  const [query, setQuery] = useState(initialQuery);

  const nextPage = () => {
    const newOffset = query.offset + query.limit;
    setQuery({ ...query, offset: newOffset });
  };

  const prevPage = () => {
    const newOffset = Math.max(query.offset - query.limit, 0);
    setQuery({ ...query, offset: newOffset });
  };

  const updateQuery = (updatedQuery: any) => {
    setQuery({ ...query, ...updatedQuery });
  };

  const changeQuery = (newQuery: any) => {
    setQuery({ limit: query?.limit, offset: query?.offset, ...newQuery });
  };

  const currentPage = Math.floor(query.offset / query.limit) + 1;

  return [query, nextPage, prevPage, currentPage, updateQuery, changeQuery];
};
