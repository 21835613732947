import { getFxJournalsFromOrganization } from '../../../../services/hedgehog/hedgehog.api';
import { parseReportBlobFileToWorkbook } from '../../../../services/excel/exceljs-service';
import { useQuery } from 'react-query';

const GRAND_TOTAL_TEXT = 'Grand Total';
const GRAND_TOTAL_CELL_LETTER = 'P';

export const useGetProfitOrLoss = (id: number) => {
  const fetchProfitOrLossData = async (): Promise<number[]> => {
    const journalsFiles = await getFxJournalsFromOrganization(id);
    const grandTotalValues: number[] = [];

    for (const journalFile of journalsFiles) {
      const EXPECTED_COLUMNS = 18;
      const workbook = await parseReportBlobFileToWorkbook(
        journalFile.data,
        journalFile.name,
      );

      if (workbook) {
        const selectedWorksSheet =
          workbook.getWorksheet(1)?.columns.length === EXPECTED_COLUMNS
            ? workbook.getWorksheet(1)
            : workbook.getWorksheet(2);

        selectedWorksSheet?.eachRow(
          { includeEmpty: true },
          (row, rowNumber) => {
            row.eachCell({ includeEmpty: true }, (cell) => {
              if (cell.value === GRAND_TOTAL_TEXT) {
                const grandTotalCellValue = selectedWorksSheet
                  .getRow(rowNumber)
                  .getCell(GRAND_TOTAL_CELL_LETTER).value;
                grandTotalValues.push(Number(grandTotalCellValue));
              }
            });
          },
        );
      }
    }

    return grandTotalValues;
  };

  return useQuery(['profitOrLossData', id], {
    queryFn: () => fetchProfitOrLossData(),
    enabled: !!id,
  });
};
