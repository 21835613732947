import { QueryKey, UseQueryOptions, useQuery } from 'react-query';
import { ApiQueryKeys } from '../services/hedgehog/api-query-keys.enum';
import { IIrsHedgeDocumentationForm } from '../services/hedgehog/interfaces/IHedgeDocumentationForm';
import { getCompleteOrIncompleteHedgeDocumentationForm } from '../services/hedgehog/hedgehog.api';
import { Toast } from '../services/toastify/Toastify';
import { HedgeDocumentationFormOption } from '../services/hedgehog/enum/HedgeDocumentationFormOption.enum';

type QueryOptions = Partial<
  UseQueryOptions<
    IIrsHedgeDocumentationForm[],
    unknown,
    IIrsHedgeDocumentationForm[],
    QueryKey
  >
>;

export const useIncompleteHedgeDocumentationQuery = (
  queryOptions?: QueryOptions,
  shouldSendNotification = true,
) => {
  const refetchTime = 1000 * 60 * 30;

  const defaultQueryOptions: Partial<
    UseQueryOptions<
      IIrsHedgeDocumentationForm[],
      unknown,
      IIrsHedgeDocumentationForm[],
      QueryKey
    >
  > = {
    refetchInterval: refetchTime,
    refetchOnWindowFocus: false,
    onSuccess: (fetchedData) => {
      if (fetchedData && fetchedData.length > 0 && shouldSendNotification) {
        Toast.custom({
          text: 'There are incomplete hedge documentation form!',
          duration: 3000,
          close: true,
          stopOnFocus: true,
          style: {
            background: 'linear-gradient(to right, #ef5350, #e57373)',
          },
        });
      }
    },
  };

  const query = useQuery<IIrsHedgeDocumentationForm[]>(
    [ApiQueryKeys.hedge_documentation_forms_incomplete],
    () =>
      getCompleteOrIncompleteHedgeDocumentationForm(
        HedgeDocumentationFormOption.Incomplete,
      ),
    { ...defaultQueryOptions, ...queryOptions },
  );

  return {
    ...query,
  };
};
