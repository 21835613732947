import { CellProperties } from 'handsontable/settings';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT, NUMERIC_FORMAT_PATTERN } from '../common/utils';
import { CellValue } from 'exceljs';

export const hedgeEffectivenessColumnsConfig = {};

export const mergedCells = Object.values({});

export const irsHedgeEffectivenessMergueCells = (sheet: CellValue[][]) => {
  return [
    // info:
    {
      row: 0,
      col: 10,
      rowspan: 2,
      colspan: 2,
    },
    {
      row: 0,
      col: 0,
      rowspan: 1,
      colspan: 10,
    },

    //title
    {
      row: 1,
      col: 2,
      rowspan: 1,
      colspan: 8,
    },
    //  entityName
    {
      row: 1,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    // actualIRS
    {
      row: 5,
      col: 1,
      rowspan: 1,
      colspan: 3,
    },
    // hypothetical
    {
      row: 5,
      col: 4,
      rowspan: 1,
      colspan: 3,
    },
    // hypen space
    {
      row: sheet.length - 4,
      col: 0,
      rowspan: 1,
      colspan: 12,
    },
    // blank space
    {
      row: sheet.length - 3,
      col: 0,
      rowspan: 1,
      colspan: 12,
    },
    //qualitativeAssessmentText
    {
      row: sheet.length - 2,
      col: 0,
      rowspan: 1,
      colspan: 12,
    },
    //footer
    {
      row: sheet.length - 1,
      col: 0,
      rowspan: 1,
      colspan: 12,
    },
  ];
};

const handleFooter = (value: string) => {
  if (
    typeof value === 'string' &&
    value.includes(
      'A qualitative assessment is made at each reporting period-end to confirm an economic relationship exists',
    )
  ) {
    return false;
  }

  return true;
};

export const customRenderer = (
  TD: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: string,
  cellProperties: CellProperties,
) => {
  const isDateCell =
    (col === 1 && row === 3) ||
    (col === 7 && row === 2) ||
    (col === 0 &&
      row > 6 &&
      moment(value, 'DD-MM-YY', true).isValid() &&
      handleFooter(value));
  const isNumericCell = col >= 1 && row > 6;

  if (isDateCell) {
    TD.textContent = moment.utc(new Date(value)).format(DEFAULT_DATE_FORMAT);
  }

  if (isNumericCell) {
    cellProperties.type = 'numeric';
    cellProperties.numericFormat = {
      pattern: NUMERIC_FORMAT_PATTERN,
    };
  }

  if ((col === 8 || col === 9) && row > 6 && handleFooter(value)) {
    cellProperties.type = 'text';
    cellProperties.className = 'htRight';
    cellProperties.wordWrap = true;
  }

  // - Line
  if (col === 0 && row === 10) {
    cellProperties.type = 'text';
    cellProperties.className = 'htLeft';
    cellProperties.wordWrap = true;
  }

  if (col === 0 && row === 11) {
    cellProperties.type = 'text';
    cellProperties.className = 'htLeft';
    cellProperties.wordWrap = true;
  }

  if ((col === 5 && row === 5) || (col === 3 && row === 5)) {
    cellProperties.className += ' header';
  }

  if (
    (col === 11 && row === 2) ||
    (col === 11 && row === 3) ||
    (col === 11 && row === 4)
  ) {
    cellProperties.className += ' htRight';
  }

  return TD;
};
