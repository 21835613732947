import { MemberDashboard } from './MembersDashboard';
import { useUserDashboardContext } from '../../../../hooks/useDashboardContext';

export const UserDashboardProfile = () => {
  const { currentOrganization } = useUserDashboardContext();

  return (
    <div className="flex flex-col gap-4">
      <div className="relative z-0 overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
        <div className="flex flex-wrap w-full p-4 gap-2 bg-white">
          <div className="text-lg font-semibold text-gray-900 w-full">
            <h3 className="text-2xl font-bold text-center">
              {`${currentOrganization.name} Profile`}
            </h3>
          </div>
        </div>
      </div>
      {currentOrganization && (
        <MemberDashboard organizationId={currentOrganization.id} />
      )}
    </div>
  );
};
