import HotTable from '@handsontable/react';
import React, { useEffect, useState } from 'react';
import { useLoadWorkbook } from '../../../hooks/useLoadWorkbook';
import { useLoadBasicSheet } from '../../../hooks/useLoadBasicSheet';
import {
  fxHedgeEffectivenessReportColumnConfig,
  fxHedgeEffectivenessReportCustomRenderer,
  fxHedgeEffectivenessReportMergeCells,
} from './fxHedgeEffectivenessConfig';
import { getCellProperties, insertEmptyRowAfter } from '../common/utils';
import { FxHedgeEffectivenessReportSheetNames } from './ViewFxHedgeEffectivenessReportTable';

interface IProps {
  report: Blob;
}

export const FxHedgeEffectivenessActualsTable = ({ report }: IProps) => {
  const { workbook } = useLoadWorkbook(
    report,
    'Error loading FX Hedge Effectiveness Actuals Report',
  );

  const { sheet: originalSheet } = useLoadBasicSheet(
    workbook,
    FxHedgeEffectivenessReportSheetNames.ACTUAL,
  );

  const headerPatterns = [
    /^The Hedging Instruments$/i,
    /^Reference$/i,
    /^Comment$/i,
    /^Deal Number$/i,
    /^Deal Date$/i,
    /^Base CCY$/i,
    /^Buy CCY$/i,
    /^Buy Amount$/i,
    /^Contract Rate$/i,
    /^Sell CCY$/i,
    /^Sell Amount$/i,
    /^Maturity Date$/i,
    /^C' party$/i,
    /^Spot Rate\s?at Inception$/i,
    /^MtM Value$/i,
    /^DF$/i,
    /^Market Forward Rate$/i,
    /^Spot Rate\s?at Valuation$/i,
  ];

  const [sheet, setSheet] = useState<any[][]>([]);

  useEffect(() => {
    if (originalSheet) {
      const formatedSheet = formatSheet(originalSheet);
      setSheet(
        insertEmptyRowAfter(
          formatedSheet,
          'Represents (gains) or losses in the CFHR of:',
        ),
      );
    }
  }, [originalSheet]);

  const formatSheet = (sheet: any[][]) => {
    const newSheet = sheet.map((row) => [...row]);
    newSheet[newSheet.length - 3] = [null];
    return newSheet;
  };

  return (
    <div className="flex justify-center">
      {sheet?.length > 0 && (
        <HotTable
          readOnly={true}
          data={sheet}
          rowHeaders={false}
          colHeaders={false}
          width="1780px"
          height="auto"
          licenseKey="non-commercial-and-evaluation"
          rowHeights={35}
          manualColumnResize={true}
          manualRowResize={true}
          wordWrap={false}
          allowEmpty={false}
          cells={(col, row) =>
            getCellProperties(sheet, headerPatterns, col, row)
          }
          columns={fxHedgeEffectivenessReportColumnConfig(
            FxHedgeEffectivenessReportSheetNames.ACTUAL,
          )}
          mergeCells={fxHedgeEffectivenessReportMergeCells(
            FxHedgeEffectivenessReportSheetNames.ACTUAL,
            sheet,
          )}
          afterRenderer={fxHedgeEffectivenessReportCustomRenderer}
        />
      )}
    </div>
  );
};
