import * as Yup from 'yup';
import { ExposureType } from '../../../services/hedgehog/enum/ExposureType.enum';

export const organizationFormValidationSchema = Yup.object().shape({
  'organization-name': Yup.string().required('Name is required'),
  'exposure-type': Yup.string().oneOf(Object.values(ExposureType)),
  'organization-description': Yup.string().optional(),
  'manager-email': Yup.string().required('Email is required'),
  'manager-name': Yup.string().required('Name is required'),
  'manager-last-name': Yup.string().required('Lastname is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must include at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must include at least one uppercase letter')
    .matches(/\d/, 'Password must include at least one digit')
    .matches(
      /[$&+,:;=?@#|'<>.^*()%!-]/,
      'Password must include at least one special character',
    ),
  'repeat-password': Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Repeat Password is required'),
});
