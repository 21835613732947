export const defaultColors: {
  [key: string]: { opaque: string; transparent: string };
} = {
  cyan500: {
    opaque: 'rgba(89, 255, 255, 1)',
    transparent: 'rgba(89, 255, 255, 0.2)',
  },
  blue400: {
    opaque: 'rgba(0, 153, 255, 1)',
    transparent: 'rgba(0, 153, 255, 0.2)',
  },
  purple500: {
    opaque: 'rgba(177, 105, 255, 1)',
    transparent: 'rgba(177, 105, 255, 0.2)',
  },
  pink500: {
    opaque: 'rgba(255, 0, 255, 1)',
    transparent: 'rgba(255, 0, 255, 0.2)',
  },
  black: {
    opaque: 'rgba(0, 0, 0, 1)',
    transparent: 'rgba(34, 34, 34, 0.2)',
  },
  orange500: {
    opaque: 'rgba(255, 153, 0, 1)',
    transparent: 'rgba(255, 153, 0, 0.2)',
  },
  yellow500: {
    opaque: 'rgba(255, 255, 0, 1)',
    transparent: 'rgba(255, 255, 0, 0.2)',
  },
  green500: {
    opaque: 'rgba(0, 255, 0, 1)',
    transparent: 'rgba(0, 255, 0, 0.2)',
  },
  red500: {
    opaque: 'rgba(255, 0, 0, 1)',
    transparent: 'rgba(255, 0, 0, 0.2)',
  },
  teal500: {
    opaque: 'rgba(0, 128, 128, 1)',
    transparent: 'rgba(0, 128, 128, 0.2)',
  },
  navy500: {
    opaque: 'rgba(0, 0, 128, 1)',
    transparent: 'rgba(0, 0, 128, 0.2)',
  },
  olive500: {
    opaque: 'rgba(128, 128, 0, 1)',
    transparent: 'rgba(128, 128, 0, 0.2)',
  },
  maroon500: {
    opaque: 'rgba(128, 0, 0, 1)',
    transparent: 'rgba(128, 0, 0, 0.2)',
  },
  lime500: {
    opaque: 'rgba(0, 255, 128, 1)',
    transparent: 'rgba(0, 255, 128, 0.2)',
  },
  amber500: {
    opaque: 'rgba(255, 191, 0, 1)',
    transparent: 'rgba(255, 191, 0, 0.2)',
  },
  brown500: {
    opaque: 'rgba(165, 42, 42, 1)',
    transparent: 'rgba(165, 42, 42, 0.2)',
  },
  gold500: {
    opaque: 'rgba(255, 215, 0, 1)',
    transparent: 'rgba(255, 215, 0, 0.2)',
  },
  silver500: {
    opaque: 'rgba(192, 192, 192, 1)',
    transparent: 'rgba(192, 192, 192, 0.2)',
  },
  coral500: {
    opaque: 'rgba(255, 127, 80, 1)',
    transparent: 'rgba(255, 127, 80, 0.2)',
  },
  indigo500: {
    opaque: 'rgba(75, 0, 130, 1)',
    transparent: 'rgba(75, 0, 130, 0.2)',
  },
};
