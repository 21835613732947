import { IReportingPeriods } from '../../../services/hedgehog/interfaces/IReportingPeriods';
import { UNEXPECTED_ERROR_MESSAGE } from '../../../error/errorMessages.constants';
import { SimpleAlert } from '../../alerts/SimpleAlert';
import { FolderCard } from '../../cards/FolderCard';
import { SimpleLoading } from '../../loading/SimpleLoading';
import { AdjustmentsLineIcon } from '../../icons/AdjustmentsLineIcon';
import { useQuery } from 'react-query';
import { ApiQueryKeys } from '../../../services/hedgehog/api-query-keys.enum';
import { getReportingPeriodsByOrganization } from '../../../services/hedgehog/hedgehog.api';
import { useModal } from '../../../hooks/useModal';
import { ReportingPeriodsFilterModal } from '../../modal/reporting-periods-filter/ReportingPeriodsFilterModal';
import { useEffect, useState } from 'react';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { LocalStorageKey } from '../../../services/hedgehog/local-storage-key.enums';
import { MonthNames } from '../../../services/hedgehog/month-names.enum';
import {
  ReportingPeriodsActiveOption,
  ReportingPeriodsReviewedOption,
} from './enum/reporting-periods.enum';
import { useUser } from '../../../hooks/useUser';
import { UserRole } from '../../../services/hedgehog/enum/UserRole.enum';
import { IReportingPeriodsQuery } from './interface/IReportingPeriodsQuery';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

interface IReportingPeriodsProps {
  organizationId: number;
  route: string;
}

export const DEFAULT_REPORTING_PERIODS_QUERY: IReportingPeriodsQuery = {
  year: moment().year().toString(),
  fromMonth: MonthNames.January,
  toMonth: MonthNames.December,
  isFilterActive: ReportingPeriodsActiveOption.No,
  reviewStatus: ReportingPeriodsReviewedOption.All,
};

export const ReportingPeriods = ({
  organizationId,
  route,
}: IReportingPeriodsProps) => {
  const { user } = useUser();

  const [query, setQuery] = useLocalStorage<IReportingPeriodsQuery>(
    `${LocalStorageKey.reportingPeriodsQueryParams} ${
      user?.role === UserRole.Admin ? UserRole.Admin : UserRole.User
    }`,
    DEFAULT_REPORTING_PERIODS_QUERY,
  );

  const {
    data: reportingPeriods,
    isLoading,
    refetch,
    error,
    remove,
  } = useQuery<IReportingPeriods[]>(
    [
      ApiQueryKeys.organization_reporting_periods_by_id,
      organizationId,
      query.fromMonth,
      query.toMonth,
      query.year,
    ],
    () => getReportingPeriodsByOrganization(Number(organizationId), query),
  );

  const { openModal, closeModal } = useModal();
  const [loadingNewData, setLoadingNewData] = useState(false);

  const handleReportingPeriodsConfig = () => {
    openModal(
      'filter-report-options',
      <ReportingPeriodsFilterModal
        close={() => closeModal('filter-report-options')}
        initialValues={query}
        onSubmit={({
          fromMonth,
          toMonth,
          year,
          isFilterActive,
          reviewStatus,
        }: IReportingPeriodsQuery) => {
          setQuery({
            year,
            fromMonth,
            toMonth,
            isFilterActive,
            reviewStatus,
          });
        }}
      />,
    );
  };

  useEffect(() => {
    setLoadingNewData(true);
    remove();
    refetch().then(() => {
      setLoadingNewData(false);
    });
  }, [query, refetch, remove]);

  const handleCardRoute = () => {
    if (user?.role !== UserRole.Admin)
      return ReportingPeriodsReviewedOption.Reviewed;

    if (query.isFilterActive === ReportingPeriodsActiveOption.Yes)
      return query.reviewStatus;

    return ReportingPeriodsReviewedOption.All;
  };
  return (
    <div className="flex flex-col w-full gap-2 ">
      <div className="flex w-full gap-2 items-center">
        <h3 className="text-base font-bold text-left w-full text-primary-dark-500 ">
          Reporting Periods
          {query.isFilterActive === ReportingPeriodsActiveOption.Yes &&
            ` - ${query.year}${
              query.reviewStatus === ReportingPeriodsReviewedOption.All
                ? ''
                : ` - ${query.reviewStatus}`
            }`}
        </h3>
        <div>
          <button
            className="hover:bg-gray-300 rounded-md p-2 transition ease-in duration-150 text-gray-500 hover:text-gray-700"
            onClick={handleReportingPeriodsConfig}
            data-cy="reporting-periods-filter-btn"
          >
            <AdjustmentsLineIcon />
          </button>
        </div>
      </div>

      {(isLoading || loadingNewData) && (
        <SimpleLoading
          loadingColor="text-primary-cyan-500"
          loadingSize="h-10 w-10"
          externalStyles="flex flex-wrap items-center justify-center p-2"
        />
      )}

      {!loadingNewData && error !== undefined && error !== null && (
        <>
          {error instanceof Error ? (
            <SimpleAlert alertMessage={error.message} alertType="danger" />
          ) : (
            <SimpleAlert
              alertMessage={UNEXPECTED_ERROR_MESSAGE}
              alertType="danger"
            />
          )}
        </>
      )}

      {!error && !loadingNewData && !reportingPeriods?.length && (
        <SimpleAlert alertMessage="No reports found" alertType="danger" />
      )}

      {!error && !loadingNewData && !!reportingPeriods?.length && (
        <div className="flex gap-4 h-fit flex-row overflow-auto py-2">
          {reportingPeriods.map(
            ({ month, reports, year }) =>
              reports.length > 0 && (
                <FolderCard
                  month={month}
                  year={year}
                  reports={reports}
                  route={route}
                  option={handleCardRoute()}
                  key={uuidv4()}
                />
              ),
          )}
        </div>
      )}
    </div>
  );
};
