import { IIRSTrade } from '../../../services/hedgehog/interfaces/IIRSTrade';
import moment from 'moment';

export const convertData = (trades: IIRSTrade[], labels: string[]) => {
  const dataByMonth = labels
    .map((month, index) => calculateValuesByMonth(trades, month))
    .filter(({ cleanFairValue }) => cleanFairValue !== 0);

  return dataByMonth;
};

const calculateValuesByMonth = (
  trades: IIRSTrade[],
  month: string,
): {
  cleanFairValue: number;
  pnlDrCr: number;
  pnlVolatilityWithoutHedgeAccounting: number;
  pnlActualMovement: number;
  monthName: string;
} => {
  let cleanFairValue = 0;
  let pnlDrCr = 0;
  let pnlVolatilityWithoutHedgeAccounting = 0;
  let pnlActualMovement = 0;

  trades.forEach((trade) => {
    trade.valuationEntries.forEach((entry) => {
      if (moment(entry.reportingDate).format('MMMM') === month) {
        cleanFairValue += entry.cleanFairValue;
        pnlDrCr += entry.profitAndLossesDrCr;
        pnlVolatilityWithoutHedgeAccounting = entry.periodicChange;
        pnlActualMovement = entry.profitAndLossesDrCrChange;
      }
    });
  });

  return {
    cleanFairValue,
    pnlDrCr,
    pnlVolatilityWithoutHedgeAccounting,
    pnlActualMovement,
    monthName: month,
  };
};
