import * as Yup from 'yup';
import { ReportingPeriodsReviewedOption } from '../../common/reporting-periods/enum/reporting-periods.enum';

export const reportingPeriodsFilterValidationSchema = Yup.object().shape({
  isFilterActive: Yup.string().required('Is Filter Active is required'),
  year: Yup.string().required('Year is required'),
  fromMonth: Yup.string().required('From month is required'),
  toMonth: Yup.string().required('To month is required'),
  reviewStatus: Yup.mixed<ReportingPeriodsReviewedOption>()
    .oneOf(Object.values(ReportingPeriodsReviewedOption))
    .required('Review Status is required'),
});
