import { useState, useEffect } from 'react';

import HotTable from '@handsontable/react';
import { useLoadWorkbook } from '../../../hooks/useLoadWorkbook';
import {
  customRenderer,
  irsCurrentNotCurrentCellsConfiguration,
} from './currentNonCurrentColumnConfig';
import { useLoadBasicSheet } from '../../../hooks/useLoadBasicSheet';
import {
  getCellProperties,
  insertEmptyRowAfter,
  swapSheetValues,
} from '../common/utils';

interface ICurrentNotCurrentTableProps {
  report: Blob;
}

export enum CurrentNotCurrentReportSheetName {
  IRSCurrentNotCurrentReport = 'IRS Current Non Current Report',
}

const headers = [
  /^Maturity (\n)?Date$/,
  /^Currency$/,
  /^Notional (\n)?Amount$/,
  /^Fixed (\n)?Rate$/,
  /^Swap (\n)?Type$/,
  /^Floating (\n)?Rate$/,
  /^Frequency$/,
  /^Valuation$/,
  /^Counterparty$/,
  /^Reference$/,
  /^Trade Reference$/,
  /^Current Asset$/,
  /^Non-current Asset$/,
  /^Current Liability$/,
  /^Non-current Liability$/,
  /^Current Net$/,
  /^Non-current Net$/,
];

export const CurrentNonCurrentTable = ({
  report,
}: ICurrentNotCurrentTableProps) => {
  const { workbook } = useLoadWorkbook(
    report,
    'Error loading IRS Current Non Current Report',
  );

  const [selectedSheet] = useState<CurrentNotCurrentReportSheetName>(
    CurrentNotCurrentReportSheetName.IRSCurrentNotCurrentReport,
  );

  const { sheet: originalSheet } = useLoadBasicSheet(workbook, selectedSheet);
  const [sheet, setSheet] = useState<any[][]>([]);

  useEffect(() => {
    if (!originalSheet) return;

    const updatedSheet = insertEmptyRowAfter(originalSheet, 'Total');
    if (updatedSheet[0].length === 12) {
      swapSheetValues(updatedSheet, [0, 9], [0, 10]);
      swapSheetValues(updatedSheet, [1, 2], [1, 5]);
    } else {
      swapSheetValues(updatedSheet, [0, 13], [0, 14]);
    }
    setSheet(updatedSheet);
  }, [originalSheet]);

  return (
    <div className="flex justify-center">
      {sheet.length > 0 && (
        <HotTable
          readOnly={true}
          data={sheet}
          rowHeaders={false}
          colHeaders={false}
          width={sheet[0].length === 12 ? '1400px' : '1780px'}
          height="auto"
          licenseKey="non-commercial-and-evaluation"
          rowHeights={40}
          colWidths={(column) => (column === 0 ? 200 : 105)}
          manualColumnResize={true}
          manualRowResize={true}
          wordWrap={true}
          autoWrapRow={true}
          autoWrapCol={true}
          allowEmpty={true}
          fixedRowsTop={3}
          mergeCells={irsCurrentNotCurrentCellsConfiguration(sheet)}
          afterRenderer={(...args) => customRenderer(...args, sheet)}
          cells={(row, col) => getCellProperties(sheet, headers, row, col)}
        />
      )}
    </div>
  );
};
