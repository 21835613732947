import * as Joi from 'joi';
import { irsMtmValuationOutputHeaders } from './mtmValuationReportOutputHeaders';
import { REFERENCE_VALIDATION_REGEX } from '../../helpers/validationRegex';

export const hedgehogMtmValuationTotalHeaderSchema: Record<string, Joi.Schema> =
  {
    [irsMtmValuationOutputHeaders.accruedInterest]: Joi.number().required(),
    [irsMtmValuationOutputHeaders.cleanFairValue]: Joi.number().required(),
    [irsMtmValuationOutputHeaders.totalFairValue]: Joi.number().required(),
    [irsMtmValuationOutputHeaders.cva]: Joi.number().required(),
    [irsMtmValuationOutputHeaders.dva]: Joi.number().required(),
    [irsMtmValuationOutputHeaders.riskFreeValuation]: Joi.number().required(),
  };

export const hedgehogMtmValuationHeaderSchema: Record<string, Joi.Schema> = {
  [irsMtmValuationOutputHeaders.tradeReferenceNumber]: Joi.alternatives()
    .try(
      Joi.string()
        .regex(REFERENCE_VALIDATION_REGEX)
        .message(
          'Trade reference can be a number, string and has special characters',
        ),
      Joi.number(),
    )
    .required(),
  [irsMtmValuationOutputHeaders.legalEntity]: Joi.string().required(),
  [irsMtmValuationOutputHeaders.tradeDate]: Joi.date().required(),
  [irsMtmValuationOutputHeaders.effectiveDate]: Joi.date().required(),
  [irsMtmValuationOutputHeaders.maturityDate]: Joi.date().required(),
  [irsMtmValuationOutputHeaders.fixedRate]: Joi.alternatives()
    .try(Joi.string(), Joi.number())
    .required(),
  [irsMtmValuationOutputHeaders.currency]: Joi.string()
    .regex(/^[A-Z]{3}$/)
    .required(),
  [irsMtmValuationOutputHeaders.notional]: Joi.number().required(),
  [irsMtmValuationOutputHeaders.swapIndex]: Joi.string().allow(null),
  [irsMtmValuationOutputHeaders.floatingRate]: Joi.alternatives()
    .try(Joi.string(), Joi.number())
    .required(),
  [irsMtmValuationOutputHeaders.nextResetDate]: Joi.date().required(),
  [irsMtmValuationOutputHeaders.lastResetDate]: Joi.date().required(),
  [irsMtmValuationOutputHeaders.counterparty]: Joi.string().required(),
  [irsMtmValuationOutputHeaders.valuationCurrency]: Joi.string()
    .regex(/^[A-Z]{3}$/)
    .required(),
  [irsMtmValuationOutputHeaders.intrinsicValue]: Joi.number().allow(null),
  [irsMtmValuationOutputHeaders.timeValue]: Joi.number().allow(null),
  [irsMtmValuationOutputHeaders.accruedInterest]: Joi.number().required(),
  [irsMtmValuationOutputHeaders.cleanFairValue]: Joi.number().required(),
  [irsMtmValuationOutputHeaders.totalFairValue]: Joi.number().required(),
  [irsMtmValuationOutputHeaders.cva]: Joi.number().allow(null),
  [irsMtmValuationOutputHeaders.dva]: Joi.number().allow(null),
  [irsMtmValuationOutputHeaders.riskFreeValuation]: Joi.number().allow(null),
};
