export const statusOptions = [
  {
    text: 'Active',
    value: true,
  },
  {
    text: 'Archived',
    value: false,
  },
];
