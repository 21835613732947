import { useQueryClient, useQuery } from 'react-query';
import { ApiQueryKeys } from '../services/hedgehog/api-query-keys.enum';
import { getOrganizationById } from '../services/hedgehog/hedgehog.api';
import { IOrganization } from '../services/hedgehog/interfaces/IOrganization';

export const useOrganization = (id: number) => {
  const queryClient = useQueryClient();

  const {
    data: organizationInfo,
    isLoading,
    ...extraOptions
  } = useQuery<IOrganization>(
    [ApiQueryKeys.organization_by_id, id],
    () => getOrganizationById(id),
    {
      refetchOnWindowFocus: true,
      initialData:
        queryClient.getQueryData<IOrganization>(
          ApiQueryKeys.organization_by_id,
        ) ?? undefined,
    },
  );

  const setOrganizationInfo = (organization: IOrganization | undefined) => {
    queryClient.setQueryData<IOrganization | undefined>(
      ApiQueryKeys.organization_by_id,
      organization,
    );
  };

  return {
    organization: organizationInfo,
    isLoading,
    setOrganizationInfo,
    ...extraOptions,
  };
};
