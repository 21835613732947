import { IOrganization } from './IOrganization';
import { IUser } from './IUser';

export interface IPeople {
  id: string;
  user: IUser;
  organization: IOrganization;
  position: Position;
}

export enum Position {
  manager = 'manager',
  member = 'member',
}
