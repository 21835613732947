import React, { useState, useCallback, useMemo } from 'react';

type ModalOptions = {
  closeOnOutClick?: boolean;
  className?: string;
};

type ModalService = {
  openModal: (id: string, content: JSX.Element, options?: ModalOptions) => void;
  closeModal: (id: string) => void;
};

const ModalContext = React.createContext({} as ModalService);

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modals, setModals] = useState<{
    [id: string]: JSX.Element;
  }>({});

  const openModal = useCallback((id: string, modal: JSX.Element) => {
    setModals((prevModals) => ({
      ...prevModals,
      [id]: modal,
    }));
  }, []);

  const closeModal = useCallback((id: string) => {
    setModals((prevModals) => {
      const updatedModals = { ...prevModals };
      delete updatedModals[id];
      return updatedModals;
    });
  }, []);

  const ModalProviderValue = useMemo(
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal],
  );

  return (
    <ModalContext.Provider value={ModalProviderValue}>
      {children}
      {Object.entries(modals).map(([id, modal]) => (
        <React.Fragment key={id}>{modal}</React.Fragment>
      ))}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
