import { IOutputReport } from '../../../services/hedgehog/interfaces/IOutputReport';

type Props = { report: IOutputReport };

export const ReportItem: React.FC<Props> = ({ report }) => {
  return (
    <li
      data-cy={`report-item-${report.id}`}
      className="text-sm"
      key={report.id}
    >
      {report.name}
    </li>
  );
};
