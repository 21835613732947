import { ColumnSettings } from 'handsontable/settings';
import { FxMtmJournalReportSheetNames } from './ViewFxMtmJournalReportTable';
import {
  NUMERIC_FORMAT_PATTERN,
  parseDateToDefaultFormatWithRenderer,
} from '../common/utils';
import { CellValue } from 'exceljs';

export const fxMtmJournalWorkingColumnConfig: ColumnSettings[] = [
  {
    width: 100,
    readOnly: true,
    readOnlyCellClassName: 'text-black',
    renderer: parseDateToDefaultFormatWithRenderer,
  },
  {
    width: 100,
    readOnly: true,
    readOnlyCellClassName: 'text-black',
    renderer: parseDateToDefaultFormatWithRenderer,
  },
  { width: 60, readOnly: true, readOnlyCellClassName: 'text-black' },
  {
    width: 100,
    type: 'numeric',
    numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
    readOnly: true,
    readOnlyCellClassName: 'text-black',
  },
  { width: 60, readOnly: true, readOnlyCellClassName: 'text-black' },
  {
    width: 100,
    type: 'numeric',
    numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
    readOnly: true,
    readOnlyCellClassName: 'text-black',
  },
  { width: 75, readOnly: true, readOnlyCellClassName: 'text-black' },
  {
    width: 100,
    type: 'numeric',
    readOnly: true,
    readOnlyCellClassName: 'text-black',
  },
  {
    width: 100,
    type: 'numeric',
    numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
    readOnly: true,
    readOnlyCellClassName: 'text-black',
  },
  { width: 90, readOnly: true, readOnlyCellClassName: 'text-black' },
  { width: 90, readOnly: true, readOnlyCellClassName: 'text-black' },
  { width: 75, readOnly: true, readOnlyCellClassName: 'text-black' },
  {
    width: 150,
    readOnly: true,
    readOnlyCellClassName: 'text-black',
    wordWrap: true,
  },
  { width: 100, readOnly: true, readOnlyCellClassName: 'text-black' },
  { width: 25, readOnly: true, readOnlyCellClassName: 'text-black' },
  {
    width: 125,
    type: 'numeric',
    numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
    readOnly: true,
    readOnlyCellClassName: 'text-black',
  },
  {
    width: 125,
    type: 'numeric',
    numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
    readOnly: true,
    readOnlyCellClassName: 'text-black',
  },
  {
    width: 125,
    type: 'numeric',
    numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
    readOnly: true,
    readOnlyCellClassName: 'text-black',
  },
];

export const fxMtmJournalColumnConfig: ColumnSettings[] = [
  {
    width: 200,
    readOnly: true,
    readOnlyCellClassName: 'text-black',
    wordWrap: true,
  },
  { width: 300, readOnly: true, readOnlyCellClassName: 'text-black' },
  { width: 200, readOnly: true, readOnlyCellClassName: 'text-black' },
  { width: 300, readOnly: true, readOnlyCellClassName: 'text-black' },
  {
    width: 200,
    type: 'numeric',
    numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
    readOnly: true,
    readOnlyCellClassName: 'text-black',
    wordWrap: true,
  },
  {
    width: 200,
    type: 'numeric',
    numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
    readOnly: true,
    readOnlyCellClassName: 'text-black',
    wordWrap: true,
  },
];

export const fxMtmJournalWorkingMergeCells = (sheet: CellValue[][]) => {
  const mergeCells = [
    { row: 1, col: 0, rowspan: 1, colspan: 2 },
    { row: 1, col: 2, rowspan: 1, colspan: 14 },
    { row: 1, col: 16, rowspan: 1, colspan: 2 },
    { row: 0, col: 0, rowspan: 1, colspan: 15 },
    { row: 0, col: 15, rowspan: 1, colspan: 3 },
    { row: 4, col: 14, rowspan: sheet.length - 7, colspan: 1 },
    { row: sheet.length - 2, col: 0, rowspan: 2, colspan: sheet[2].length },
  ];

  sheet.forEach((row, rowIndex) => {
    row.forEach((cellValue) => {
      if (cellValue === 'Grand Total') {
        const newRowIndex = rowIndex + 1;
        mergeCells.push({
          row: newRowIndex,
          col: 0,
          rowspan: 1,
          colspan: sheet[0].length,
        });
      }
    });
  });

  const hedgingHeaderPattern = /Hedging/;
  let isFirstOccurrence = true;
  sheet.forEach((row, rowIndex) => {
    const cellValue = row[0];
    if (typeof cellValue === 'string' && hedgingHeaderPattern.test(cellValue)) {
      mergeCells.push({
        row: rowIndex,
        col: 0,
        rowspan: 1,
        colspan: 14,
      });

      if (!isFirstOccurrence) {
        mergeCells.push({
          row: rowIndex,
          col: 15,
          rowspan: 2,
          colspan: 3,
        });
      } else {
        isFirstOccurrence = false;
      }
    }
  });

  return mergeCells;
};

export const fxMtmJournalMergeCells = (sheet: CellValue[][]) => {
  const mergeCells = [
    { row: 1, col: 1, rowspan: 1, colspan: 4 },
    { row: 0, col: 0, rowspan: 1, colspan: 4 },
    { row: 0, col: 4, rowspan: 1, colspan: 2 },
    { row: 2, col: 2, rowspan: 1, colspan: 2 },
    { row: sheet.length - 2, col: 0, rowspan: 2, colspan: sheet[0].length },
  ];

  sheet.forEach((row, rowIndex) => {
    row.forEach((cellValue) => {
      if (cellValue === '-') {
        const newRowIndex = rowIndex + 1;
        mergeCells.push({
          row: newRowIndex,
          col: 0,
          rowspan: 1,
          colspan: sheet[0].length,
        });
      }
    });
  });
  return mergeCells;
};

export const fxMtmJournalReportColumnConfig = (
  sheetName: FxMtmJournalReportSheetNames,
) => {
  return sheetName === FxMtmJournalReportSheetNames.FX_MTM_JOURNAL
    ? fxMtmJournalColumnConfig
    : fxMtmJournalWorkingColumnConfig;
};

export const fxMtmJournalReportMergeCells = (
  sheetName: FxMtmJournalReportSheetNames,
  sheet: CellValue[][],
) => {
  return sheetName === FxMtmJournalReportSheetNames.FX_MTM_JOURNAL
    ? fxMtmJournalMergeCells(sheet)
    : fxMtmJournalWorkingMergeCells(sheet);
};
