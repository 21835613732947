import { Outlet } from 'react-router-dom';
import { Footer } from './footer/Footer';
import { ScrollToAnchor } from './navbar/ScrollToAnchor';
import { Navbar } from './navbar/Navbar';
import { useUser } from '../hooks/useUser';
import { SimpleLoading } from './loading/SimpleLoading';

export const PublicLayout = () => {
  const { isLoading } = useUser();

  if (isLoading) {
    return (
      <div className="h-screen flex flex-row justify-center items-center">
        <SimpleLoading
          loadingColor="text-primary-cyan-500"
          loadingSize="h-20 w-20"
        />
      </div>
    );
  }

  return (
    <>
      <ScrollToAnchor />
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};
