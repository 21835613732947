import { IIRSMTMValuationEntry } from './IIRSMTMValuationEntry';
import { IOrganization } from './IOrganization';

export interface IIRSTrade {
  id: number;
  reference: string;
  maturityDate: Date;
  counterParty: string;
  dealDate: Date;
  currency: string;
  valuationEntries: IIRSMTMValuationEntry[];
  creationMethod: IRSTradeCreationMethod;
  updatedAt: string;
  createdAt: string;
  organization: IOrganization;
}

export interface IIRSTradeAndCount {
  trades: IIRSTrade[];
  count: number;
}

export enum IRSTradeCreationMethod {
  STANDARD = 'STANDARD',
  MIGRATION = 'MIGRATION',
}
