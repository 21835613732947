import moment from 'moment';
import { IIRSMTMValuationEntry } from '../services/hedgehog/interfaces/IIRSMTMValuationEntry';

export const calculateGradientOffset = (
  graphData: { cleanFairValue: number }[],
): number => {
  const dataMax = Math.max(...graphData.map((entry) => entry.cleanFairValue));
  const dataMin = Math.min(...graphData.map((entry) => entry.cleanFairValue));

  if (dataMax <= 0) {
    return 0;
  }
  if (dataMin >= 0) {
    return 1;
  }

  return dataMax / (dataMax - dataMin);
};

export const calculateDataValues = (entries: IIRSMTMValuationEntry[]) => {
  const map = new Map<string, number>();
  entries.forEach((entry) => {
    let amount = map.get(moment(entry.reportingDate).format('MMMM YYYY')) ?? 0;
    amount += entry.cleanFairValue;
    map.set(moment(entry.reportingDate).format('MMMM YYYY'), amount);
  });
  return Array.from(map).map(([key, value]) => ({
    month: key,
    cleanFairValue: value,
  }));
};
