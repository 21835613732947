import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { usePagination } from './usePagination';

export const usePaginatedQuery = <T, U>(
  queryKeys: Array<string | number>,
  initialQuery: U,
  fetchData: (query: U) => Promise<T>,
  queryOptions?: UseQueryOptions<T, any, T>,
) => {
  const [query, nextPage, prevPage, currentPage, updateQuery, changeQuery] =
    usePagination(initialQuery);

  const queryResult: UseQueryResult<T> = useQuery<T>(
    [...queryKeys, query],
    () => fetchData(query),
    queryOptions,
  );

  return {
    ...queryResult,
    nextPage,
    prevPage,
    currentPage,
    updateQuery,
    changeQuery,
    offset: query.offset,
    query,
  };
};
