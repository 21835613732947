import { useEffect } from 'react';
import { Axios, axiosHasAuthToken } from './Axios';
import { refreshUserToken } from '../cognito/cognito';
import { logout } from '../hedgehog/user.api';
import { router } from '../../router';
import { useUser } from '../../hooks/useUser';
import { AxiosError } from 'axios';

export const AxiosInterceptor = () => {
  const { setUserInfo, refetchUser } = useUser();

  const logoutUser = async () => {
    await logout();
    setUserInfo(undefined);
    router.navigate('/');
  };

  useEffect(() => {
    let refreshTokenPromise: Promise<string | undefined> | null = null;

    const refreshTokens = async (): Promise<string | undefined> => {
      try {
        const newSession = await refreshUserToken();
        const newToken = newSession.getIdToken().getJwtToken();
        Axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        return newToken;
      } catch (error) {
        await logoutUser();
        throw error;
      } finally {
        refreshTokenPromise = null;
      }
    };

    const requestInterceptor = Axios.interceptors.request.use(
      async (config) => {
        if (!axiosHasAuthToken()) return config;

        if (!refreshTokenPromise) {
          refreshTokenPromise = refreshTokens();
        }

        const newToken = await refreshTokenPromise;
        config.headers = config.headers ?? {};
        config.headers.Authorization = `Bearer ${newToken}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    const responseInterceptor = Axios.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if (error?.response?.status === 401) {
          if (!axiosHasAuthToken()) {
            await logoutUser();
            return Promise.reject(error);
          }
        }

        if (error?.response?.status === 403) {
          refetchUser();
        }

        return Promise.reject(error);
      },
    );

    return () => {
      Axios.interceptors.request.eject(requestInterceptor);
      Axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return null;
};
