type FilterItem<T> = {
  name: string;
  value: T;
  checked: boolean;
};

type Props<T> = {
  title: string;
  handleFilter: (value: T) => void;
  handleAllFilter?: (value: T[]) => void;
  filterList: FilterItem<T>[];
};

export const FilterCategory = <T,>({
  title,
  handleFilter,
  handleAllFilter,
  filterList,
}: Props<T>) => {
  const values = filterList.map((item) => item.value);

  return (
    <div className="flex flex-col xl:block">
      <div className="w-full flex flex-row-reverse xl:flex-row gap-1 xl:gap-0 justify-end xl:justify-between items-center">
        <h2 className="text-gray-900 font-bold text-left">{title}</h2>
        {handleAllFilter && (
          <input
            className="accent-secondary-purple-500"
            data-cy={`filter-${title}`}
            type="checkbox"
            value={`all-${title}`}
            checked={filterList.every((item) => item.checked)}
            onChange={() => handleAllFilter(values)}
          />
        )}
      </div>
      <ul className="w-full">
        {filterList.map((filter) => (
          <li
            key={filter.name}
            className="text-left whitespace-nowrap md:text-xs text-[0.65rem] flex flex-row items-center gap-1 my-1"
          >
            <input
              className="accent-secondary-purple-500"
              data-cy={`filter-${filter.name}`}
              name={filter.name}
              type="checkbox"
              checked={filter.checked}
              onChange={() => handleFilter(filter.value)}
            />
            <label htmlFor={filter.name}>{filter.name}</label>
          </li>
        ))}
      </ul>
    </div>
  );
};
