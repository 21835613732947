import { useEffect, useState } from 'react';
import { refreshSession } from '../services/hedgehog/user.api';
import { useQueryClient } from 'react-query';
import { SimpleLoading } from './loading/SimpleLoading';

interface ISessionRefresherProps {
  children: React.ReactNode;
}

export const SessionRefresher = ({ children }: ISessionRefresherProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const queryClient = useQueryClient();

  useEffect(() => {
    refreshSession()
      .then(() => {
        setIsLoading(false);
      })
      .catch(console.error);
  }, [queryClient]);

  if (isLoading) {
    return (
      <div
        data-cy="session-refresher-loading"
        className="h-screen flex flex-row justify-center items-center"
      >
        <SimpleLoading
          loadingColor="text-primary-cyan-500"
          loadingSize="h-20 w-20"
        />
      </div>
    );
  }

  return <>{children}</>;
};
