import { useQueryClient } from 'react-query';
import { useConfirm } from '../../../../hooks/useConfirm';
import { useModal } from '../../../../hooks/useModal';
import { usePaginatedQuery } from '../../../../hooks/usePaginatedQuery';
import { ApiQueryKeys } from '../../../../services/hedgehog/api-query-keys.enum';
import { UserRole } from '../../../../services/hedgehog/enum/UserRole.enum';
import {
  getUsers,
  updateUser,
} from '../../../../services/hedgehog/hedgehog.api';
import { IUser } from '../../../../services/hedgehog/interfaces/IUser';
import { Toast } from '../../../../services/toastify/Toastify';
import { AddMemberOptionsModal } from '../../../../components/modal/add-member-options-modal/AddMemberOptionsModal';
import { AddExistingAdminModal } from './AddExistingAdminModal';
import { AddAdminModal } from './AddAdminModal';

export const useAdminsPanel = () => {
  const { openModal, closeModal } = useModal();
  const { confirmAction } = useConfirm();
  const queryClient = useQueryClient();

  const { data, isLoading, currentPage, nextPage, prevPage } =
    usePaginatedQuery(
      [ApiQueryKeys.admins],
      { limit: 10, offset: 0, role: 'admin' },
      async (queries) => {
        const { users: admins, count } = await getUsers(queries);
        return {
          admins: admins.filter((admin) => admin.isSuperAdmin === false),
          count,
        };
      },
    );

  const handleDeleteAdmin = (user: IUser) => {
    const action = async () => {
      await updateUser(user.id, { role: UserRole.User });
      Toast.success('User removed as admin successfully');
      queryClient.refetchQueries(ApiQueryKeys.admins);
    };
    confirmAction(
      action,
      `The user will be removed as an admin. Are you sure you want to remove it: ${user.name} ${user.lastName}?`,
      'Remove user as admin',
    );
  };

  const handleClickAddAdminOptionsModal = () => {
    openModal(
      'add-admin-options',
      <AddMemberOptionsModal
        handleAddMemberModal={handleAddAdminModal}
        handleAddExistingMemberModal={handleAddExistingAdminModal}
        close={() => closeModal('add-admin-options')}
      />,
    );
  };

  const handleAddExistingAdminModal = () => {
    openModal(
      'add-existing-admin',
      <AddExistingAdminModal
        close={() => {
          closeModal('add-existing-admin');
          closeModal('add-admin-options');
        }}
        onSubmit={() => queryClient.refetchQueries(ApiQueryKeys.admins)}
      />,
    );
  };

  const handleAddAdminModal = () => {
    openModal(
      'add-admin',
      <AddAdminModal
        close={() => {
          closeModal('add-admin');
          closeModal('add-admin-options');
        }}
        onSubmit={() => queryClient.refetchQueries(ApiQueryKeys.admins)}
      />,
    );
  };

  return {
    admins: data?.admins,
    count: data?.count,
    isLoading,
    currentPage,
    nextPage,
    prevPage,
    handleDeleteAdmin,
    handleClickAddAdminOptionsModal,
  };
};
