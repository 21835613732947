export const fxAnalysisReportSheetMock = [
  [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    'Hedgehog Software',
    'Hedgehog Software',
  ],
  [],
  [
    null,
    'Bigger',
    'Bigger',
    'FX MTM Journal Working - 31 Aug 2023',
    'FX MTM Journal Working - 31 Aug 2023',
    'FX MTM Journal Working - 31 Aug 2023',
    'FX MTM Journal Working - 31 Aug 2023',
    'FX MTM Journal Working - 31 Aug 2023',
    'FX MTM Journal Working - 31 Aug 2023',
    'FX MTM Journal Working - 31 Aug 2023',
    'FX MTM Journal Working - 31 Aug 2023',
    'FX MTM Journal Working - 31 Aug 2023',
    'FX MTM Journal Working - 31 Aug 2023',
    null,
    null,
  ],
  [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    null,
    'AUD/USD Hedging - 2 Months',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    'AUD',
    'AUD',
    'AUD',
  ],
  [
    'Deal Date',
    'Maturity Date',
    'Buy Ccy',
    'Buy Amount',
    'Sell Ccy',
    'Sell Amount',
    'Deal Type',
    'Exchange Rate',
    'Fair value',
    'Counterparty',
    'Deal Number',
    'Spot Rateat Inception',
    'Reference',
    'Comment',
    'Profit or Loss - Dr/(Cr)',
    'OCI - Dr/(Cr)',
    'Inventory - Dr/(Cr)',
  ],
  [
    null,
    '2022-06-14T00:00:00.000Z',
    '2023-09-29T00:00:00.000Z',
    'AUD',
    288142.92,
    'USD',
    200000,
    'Forward',
    0.6941,
    -19895.32,
    'NAB',
    620118,
    null,
    '123',
    null,
    null,
    19895.32,
  ],
  [null, '-'],
  [
    null,
    'Grand Total',
    null,
    null,
    null,
    null,
    null,
    200,
    null,
    null,
    null,
    null,
    null,
    null,
    300,
    400,
    0,
  ],
  [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    null,
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
  ],
  [
    null,
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
    'The valuation date of this report is 31 Aug 23. ',
  ],
];
