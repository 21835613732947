import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '../../Button';
import { FxHedgeEffectivenessActualsTable } from './FxHedgeEffectivenessActualsTable';
import { FxHedgeEffectivenessHypotheticalTable } from './FxHedgeEffectivenessHypotheticalTable';
import { FxHedgeEffectivenessJournalTable } from './FxHedgeEffectivenessJournalTable';
import { FxHedgeEffectivenessTestTable } from './FxHedgeEffectivenessTestTable';

interface IFxHedgeEffectivenessReportProps {
  report: Blob;
}

export enum FxHedgeEffectivenessReportSheetNames {
  ACTUAL = 'Actuals',
  HYPO = 'Hypothetical',
  TEST = 'Test',
  JOURNAL = 'Journal',
}

export const ViewFxHedgeEffectivenessReportTable = ({
  report,
}: IFxHedgeEffectivenessReportProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSheet =
    searchParams.get('sheet') === 'journal'
      ? FxHedgeEffectivenessReportSheetNames.JOURNAL
      : searchParams.get('sheet') === 'hypothetical'
      ? FxHedgeEffectivenessReportSheetNames.HYPO
      : searchParams.get('sheet') === 'test'
      ? FxHedgeEffectivenessReportSheetNames.TEST
      : FxHedgeEffectivenessReportSheetNames.ACTUAL;

  const [selectedSheet, setSelectedSheet] =
    useState<FxHedgeEffectivenessReportSheetNames>(initialSheet);

  useEffect(() => {
    const sheetParamName = selectedSheet.toLowerCase();
    const showBackButton = window.history.length > 1;
    setSearchParams(
      { sheet: sheetParamName },
      { state: { showBackButton }, replace: true },
    );
  }, [selectedSheet, setSearchParams]);

  const handleButtonClick = (
    sheetName: FxHedgeEffectivenessReportSheetNames,
  ) => {
    setSelectedSheet(sheetName);
  };

  const tableComponents = {
    [FxHedgeEffectivenessReportSheetNames.ACTUAL]:
      FxHedgeEffectivenessActualsTable,
    [FxHedgeEffectivenessReportSheetNames.HYPO]:
      FxHedgeEffectivenessHypotheticalTable,
    [FxHedgeEffectivenessReportSheetNames.TEST]: FxHedgeEffectivenessTestTable,
    [FxHedgeEffectivenessReportSheetNames.JOURNAL]:
      FxHedgeEffectivenessJournalTable,
  };

  const SelectedTableComponent = tableComponents[selectedSheet];

  return (
    <>
      <div>
        <div className="flex justify-center gap-2 p-2 text-lg font-semibold text-left text-gray-900">
          {Object.values(FxHedgeEffectivenessReportSheetNames).map(
            (sheetName) => (
              <Button
                key={uuidv4()}
                onClick={() => handleButtonClick(sheetName)}
                data-cy={`${sheetName}-button`}
                disabled={selectedSheet === sheetName}
                variant={selectedSheet === sheetName ? 'solid' : 'outline'}
              >
                {sheetName}
              </Button>
            ),
          )}
        </div>
      </div>

      <SelectedTableComponent report={report} />
    </>
  );
};
