import * as yup from 'yup';
import { IStaticField } from '../../OutputReportTypes';
import { IrsMtmJournalOptionSelect } from './enum/IrsMtmJournalOptions.enum';

export const IrsMtmJournalStaticData: {
  fields: IStaticField[];
  validationSchema: any;
} = {
  fields: [
    {
      label: 'Name',
      name: 'name',
      type: 'string',
      defaultValue: 'IRS MTM Journal',
    },
    {
      label: 'Select Creation Option',
      name: 'aggregatedOrIndividualsJournals',
      type: 'select',
      options: Object.values(IrsMtmJournalOptionSelect),
      defaultValue: IrsMtmJournalOptionSelect.IndividualsJournals,
      comment:
        'NOTE: You can either create a Aggregated Journal with the combined totals of individual trade references or generate Individual Journals for each trade reference.',
    },
  ],

  validationSchema: yup.object().shape({
    name: yup.string().required('Name is required'),
  }),
};
