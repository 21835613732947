import * as yup from 'yup';
import { IStaticField } from '../../OutputReportTypes';

export const IRSCurrentNonCurrentStaticData: {
  fields: IStaticField[];
  validationSchema: any;
  instantGenerated?: boolean;
} = {
  instantGenerated: true,
  fields: [
    {
      label: 'Name',
      name: 'name',
      type: 'string',
      defaultValue: 'IRS Current Non Current Report',
    },
  ],
  validationSchema: yup.object().shape({
    name: yup.string().required('Name is required'),
  }),
};
