import { useNavigate } from 'react-router-dom';
import { ReactNode, useEffect } from 'react';
import { useUser } from '../../hooks/useUser';
import { SimpleLoading } from '../loading/SimpleLoading';

export const AuthGuard = ({ children }: { children: ReactNode }) => {
  const { isLoading, user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.id && !isLoading) {
      navigate('/');
    }
  }, [isLoading, user, navigate]);

  if (isLoading) {
    return (
      <div className="h-screen flex flex-row justify-center items-center">
        <SimpleLoading
          loadingColor="text-primary-cyan-500"
          loadingSize="h-20 w-20"
        />
      </div>
    );
  }

  return <>{children}</>;
};
