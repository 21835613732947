import HotTable from '@handsontable/react';
import { useEffect, useState } from 'react';
import { useLoadWorkbook } from '../../../hooks/useLoadWorkbook';
import { useLoadBasicSheet } from '../../../hooks/useLoadBasicSheet';
import {
  getCellProperties,
  insertEmptyRowAfter,
  parseRichText,
} from '../common/utils';
import { fxMtmJournalWorkingHeaders } from './FxMtmJournalHeaders';
import { FxMtmJournalReportSheetNames } from './ViewFxMtmJournalReportTable';
import {
  fxMtmJournalWorkingColumnConfig,
  fxMtmJournalWorkingMergeCells,
} from './fxMtmJournalConfig';

export const FxMtmJournalWorkingReportTable = ({
  report,
}: {
  report: Blob;
}) => {
  const { workbook } = useLoadWorkbook(
    report,
    'Error loading FX MTM Journal Report',
  );
  const { sheet: originalSheet } = useLoadBasicSheet(
    workbook,
    FxMtmJournalReportSheetNames.FX_MTM_JOURNAL_WORKING,
  );
  const [sheet, setSheet] = useState<any[][]>([]);

  useEffect(() => {
    if (!originalSheet) return;
    const parsedSheet = parseRichText(originalSheet);
    const formattedSheet = formatWorkingSheet(parsedSheet);
    const cleanedSheet = formattedSheet.filter(
      (row) => !row.every((cell) => cell === ' '),
    );
    setSheet(cleanedSheet);
  }, [originalSheet]);

  const formatWorkingSheet = (sheet: any[][]) => {
    const newSheet = sheet.map((row) => [...row]);
    const footer = newSheet[newSheet.length - 1];
    newSheet[newSheet.length - 2] = [...footer];
    newSheet[0].length = 18;
    newSheet[0].fill(newSheet[0][13], 13, 18);
    newSheet[0].fill(null, 12, 15);
    return insertEmptyRowAfter(newSheet, 'Grand Total');
  };

  const getMtmJournalCellProperties = (
    sheet: any[][],
    headerPatterns: RegExp[],
    row: number,
    col: number,
  ) => {
    const cellProperties = getCellProperties(sheet, headerPatterns, row, col);

    if (row === 2 || row === 3) {
      cellProperties.className += ' header';
    }

    return cellProperties;
  };

  return (
    <div className="flex justify-center">
      {sheet.length > 0 && (
        <HotTable
          readOnly
          key="fx-mtm-journal-working-report"
          data={sheet}
          rowHeaders={false}
          colHeaders={false}
          width={1700}
          height="auto"
          licenseKey="non-commercial-and-evaluation"
          rowHeights={35}
          manualColumnResize
          manualRowResize
          wordWrap
          allowEmpty={false}
          columns={fxMtmJournalWorkingColumnConfig}
          mergeCells={fxMtmJournalWorkingMergeCells(sheet)}
          cells={(row, col) =>
            getMtmJournalCellProperties(
              sheet,
              fxMtmJournalWorkingHeaders,
              row,
              col,
            )
          }
        />
      )}
    </div>
  );
};
