import { useEffect, useState } from 'react';
import * as ExcelJS from 'exceljs';

export const useLoadBasicSheet = (
  workbook: ExcelJS.Workbook | undefined,
  selectedSheet: string,
) => {
  const [sheet, setSheet] = useState<ExcelJS.CellValue[][]>();

  useEffect(() => {
    if (workbook && selectedSheet) {
      const selectedWorksheet = workbook.getWorksheet(selectedSheet);
      const worksheet: ExcelJS.CellValue[][] = [];

      selectedWorksheet?.eachRow({ includeEmpty: true }, (row) => {
        const rowData: ExcelJS.CellValue[] = [];
        row.eachCell({ includeEmpty: true }, (cell) => {
          rowData.push(cell.value);
        });
        worksheet.push(rowData);
      });

      const filteredData: ExcelJS.CellValue[][] = worksheet.filter(
        (row: ExcelJS.CellValue[]) =>
          row.some((cellValue: ExcelJS.CellValue) => cellValue !== null),
      );

      setSheet(filteredData);
    }
  }, [workbook, selectedSheet]);

  return {
    sheet,
  };
};
