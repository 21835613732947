import { useQuery } from 'react-query';
import { getReportFileById } from '../../../../services/hedgehog/hedgehog.api';
import { IReportByMonth } from '../../../../services/hedgehog/interfaces/IReportByMonth';
import { Toast } from '../../../../services/toastify/Toastify';

export const useFetchMtmValuationReportFile = (
  mtmValuationReports: IReportByMonth | undefined,
) => {
  return useQuery('file', {
    queryFn: () => {
      if (mtmValuationReports && mtmValuationReports.allReports[0].id) {
        return getReportFileById(
          'output-report',
          mtmValuationReports.allReports[0].id,
        );
      }
    },
    onError: (error: any) => {
      Toast.error(error.message);
    },
    enabled: !!mtmValuationReports && mtmValuationReports.allReports.length > 0,
  });
};
