import { ChartData } from 'chart.js';
import { IFxTrade } from '../../../services/hedgehog/interfaces/IFXTrade';
import { defaultColors } from '../default-colors';

export type PieChartData = ChartData<'bar', number[], string>;

interface TradeData {
  value: number;
  count: number;
  originalPairs: Map<string, number>;
}

const normalizePair = (pair: string): string => {
  const [base, quote] = pair.split('/');
  return base < quote ? `${base}/${quote}` : `${quote}/${base}`;
};

const aggregateTrades = (trades: IFxTrade[]) => {
  const labels = new Map<string, TradeData>();

  trades.forEach((trade) => {
    const normalizedPair = normalizePair(trade.currencyPairHedging);
    const entry = labels.get(normalizedPair) ?? {
      value: 0,
      count: 0,
      originalPairs: new Map(),
    };

    let totalCleanFairValue = 0;
    trade.maturedDealsEntry.forEach((entry) => {
      totalCleanFairValue += entry.gainAndLoss;
    });

    entry.value += totalCleanFairValue;
    entry.count += 1;

    const originalPairCount =
      entry.originalPairs.get(trade.currencyPairHedging) ?? 0;
    entry.originalPairs.set(trade.currencyPairHedging, originalPairCount + 1);

    labels.set(normalizedPair, entry);
  });

  return labels;
};

const sortLabels = (labels: Map<string, TradeData>) => {
  return Array.from(labels.entries()).sort((a, b) => a[1].count - b[1].count);
};

const createDatasets = (sortedLabels: [string, TradeData][]) => {
  const datasets: PieChartData['datasets'] = [];

  sortedLabels.forEach(([key, entry], index) => {
    const combinedLabelParts = Array.from(entry.originalPairs.entries()).map(
      ([originalPair, count]) =>
        `${originalPair} (${count} - ${count === 1 ? 'Trade' : 'Trades'})`,
    );
    const combinedLabel = combinedLabelParts.join(' + ');

    datasets.push({
      label: combinedLabel,
      data: sortedLabels.map(([k, e]) => (k === key ? entry.value : 0)),
      borderWidth: 1,
      backgroundColor:
        Object.values(defaultColors)[
          index % Object.values(defaultColors).length
        ].transparent,
      borderColor:
        Object.values(defaultColors)[
          index % Object.values(defaultColors).length
        ].opaque,
    });
  });

  return datasets;
};

export const convertTradesToChart = (trades: IFxTrade[]): PieChartData => {
  const aggregatedLabels = aggregateTrades(trades);
  const sortedLabels = sortLabels(aggregatedLabels);
  const datasets = createDatasets(sortedLabels);

  return {
    labels: datasets.map((d) => d.label!.split(' ')[0]),
    datasets,
  };
};
