import { CellValue } from 'exceljs';
import { CellProperties } from 'handsontable/settings';

export const getHedgeDocumentationCellProperties = (
  sheet: CellValue[][],
  headerPatterns: RegExp[],
  row: number,
  col: number,
) => {
  const cellValue = sheet[row][col] ? String(sheet[row][col]) : '';
  const cellProperties: Partial<CellProperties> = {};
  const isCheckmark = cellValue === 'x';
  const isTitleRow = [0, 1].includes(row) && col === 0;
  const isSubtitleRow = [2].includes(row) && col === 0;

  cellProperties.className +=
    ' default-text-color default-hedge-documentation-cell border';

  if (headerPatterns.some((pattern) => pattern.test(cellValue))) {
    cellProperties.className += ' hedge-documentation-header';
  }

  if (isTitleRow) {
    cellProperties.className += ' title';
  }

  if (isSubtitleRow) {
    cellProperties.className += ' subtitle no-outline';
  }

  if (isCheckmark) {
    cellProperties.className += ' checkmark';
  }

  return cellProperties;
};
