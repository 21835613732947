import { Formik, Form } from 'formik';
import { Button } from '../../Button';
import { Modal } from '../Modal';
import {
  fxAndIrsValidationSchema,
  fxWithGeneralValidationSchema,
  irsWithGeneralValidationSchema,
} from './profile.config.validation.schema';
import { IOrganizationProfile } from '../../../services/hedgehog/interfaces/IOrganizationProfile';
import { useState } from 'react';
import { IProfileConfigSegment } from './interface/IProfileSegment';
import {
  fxOnlySegmentProfile,
  irsOnlySegmentProfile,
  segmentConfigProfileForBoth,
} from './profileStaticData';
import { v4 as uuidv4 } from 'uuid';
import { FormFieldSpecific } from '../../form-field/FormFieldSpecific';
import { ExposureType } from '../../../services/hedgehog/enum/ExposureType.enum';
import { EMPTY_EXPOSURE_CURRENCY } from '../../../services/hedgehog/types/reports/fx-mtm-journal-report/fx-mtm-journal-options';

const initialValues: IOrganizationProfile = {
  hedgeDocumentationFormStaticData: {
    natureOfHedgedRisk: undefined,
    reviewedBy: undefined,
    preparedBy: undefined,
  },
  currency: 'AUD',
  fxCurrencyRules: [],
  legalEntity: '',
};

export const ProfileConfigModal = ({
  close,
  onSubmit,
  exposureType,
  defaultValues = initialValues,
}: {
  close: () => void;
  onSubmit: (values: IOrganizationProfile) => any;
  exposureType: ExposureType;
  defaultValues?: IOrganizationProfile;
}) => {
  const [selectedSegment, setSelectedSegment] = useState<IProfileConfigSegment>(
    segmentConfigProfileForBoth[0],
  );

  const handleSubmit = async (values: typeof initialValues) => {
    onSubmit(values);
  };

  const handleInitialValues = (defaultValues: IOrganizationProfile) => {
    defaultValues.fxCurrencyRules.forEach((rule) => {
      if (
        rule.exposureCurrency !== rule.baseCurrency &&
        rule.exposureCurrency !== rule.quoteCurrency
      ) {
        rule.exposureCurrency = EMPTY_EXPOSURE_CURRENCY;
      }
    });
    return { ...defaultValues };
  };

  const handleSpecificFormByExposureType = (
    exposureType: ExposureType,
  ): IProfileConfigSegment[] => {
    const formByExposureType = {
      [ExposureType.FX_ONLY]: fxOnlySegmentProfile,
      [ExposureType.IRS_ONLY]: irsOnlySegmentProfile,
      [ExposureType.BOTH]: segmentConfigProfileForBoth,
    };

    return formByExposureType[exposureType] || segmentConfigProfileForBoth;
  };

  const handleSpecificValidationSchemaByExposureType = (
    exposureType: ExposureType,
  ) => {
    const validationSchemaByExposureType = {
      [ExposureType.FX_ONLY]: fxWithGeneralValidationSchema,
      [ExposureType.IRS_ONLY]: irsWithGeneralValidationSchema,
      [ExposureType.BOTH]: fxAndIrsValidationSchema,
    };

    return (
      validationSchemaByExposureType[exposureType] || fxAndIrsValidationSchema
    );
  };

  return (
    <Modal close={close}>
      <div className="max-h-full overflow-y-auto py-6">
        <div className="flex flex-col justify-center items-center relative z-0 overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
          <Formik
            initialValues={handleInitialValues(defaultValues)}
            validationSchema={handleSpecificValidationSchemaByExposureType(
              exposureType,
            )}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            <Form className="flex flex-col justify-center items-center md:w-full lg:w-[50rem] xl:w-[62rem] h-full p-4 gap-2 ">
              <div>
                <h2 className="font-bold text-gray-900 text-center text-lg">
                  Profile Static Data
                </h2>
              </div>
              <div className="flex flex-col  w-full gap-4">
                <div className="flex flex-col md:flex-row gap-2 w-full justify-center">
                  {handleSpecificFormByExposureType(exposureType).map(
                    (segment) => (
                      <Button
                        type="button"
                        color={selectedSegment === segment ? 'cyan' : 'gray'}
                        key={uuidv4()}
                        onClick={() => setSelectedSegment(segment)}
                        variant={
                          selectedSegment === segment ? 'solid' : 'outline'
                        }
                        data-cy={`segment-${segment.name}`}
                      >
                        {segment.name}
                      </Button>
                    ),
                  )}
                </div>
                <div className="flex flex-row w-full">
                  <div className="w-full">
                    {selectedSegment && (
                      <>
                        <h3 className="font-semibold text-base text-gray-800  mb-2">
                          {selectedSegment.title}
                        </h3>
                        <FormFieldSpecific
                          segment={selectedSegment}
                          key={uuidv4()}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center justify-end gap-4 w-full">
                <Button
                  type="button"
                  variant="underline"
                  color="black"
                  onClick={close}
                >
                  Cancel
                </Button>
                <Button
                  data-cy="save-profile-button"
                  variant="solid"
                  color="cyan"
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </Modal>
  );
};
