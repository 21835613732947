import {
  validateHeadersBySchema,
  validateTotalHeadersBySchema,
} from '../../helpers/headerValidator';
import { InputReportType } from '../../inputReportType';
import { ProcessableInputReport } from '../../ProcessableInputReport';
import {
  IRSCNCHeadersWithTotal,
  IrsCurrentNonCurrentColHeaders,
  IrsCurrentNonCurrentRowHeaders,
} from '../irsCurrentNonCurrent/irsCurrentNonCurrentHeaders';
import {
  hedgehogCurrentNonCurrentHeadersSchema,
  hedgehogCurrentNonCurrentTotalHeadersSchema,
} from './hedgehogCurrentNonCurrent.schema';

export class HedgehogCurrentNonCurrentValidator {
  colHeaders: IrsCurrentNonCurrentColHeaders[] = Object.values(
    IrsCurrentNonCurrentColHeaders,
  );
  totalColHeaders: string[] = IRSCNCHeadersWithTotal;

  constructor(private processableInput: ProcessableInputReport) {}

  validate() {
    if (
      this.processableInput.sheetName !==
      InputReportType.HEDGEHOG_IRS_CURRENT_NON_CURRENT_REPORT
    ) {
      throw new Error(
        `Input report type ${InputReportType.HEDGEHOG_IRS_CURRENT_NON_CURRENT_REPORT} is wrong type for this output`,
      );
    }
    const totalRowValue = this.processableInput.findCellValue(
      IrsCurrentNonCurrentRowHeaders.Total,
    );

    if (!totalRowValue) {
      throw new Error(
        `Row "${IrsCurrentNonCurrentRowHeaders.Total}" is missing in ${this.processableInput.sheetName}`,
      );
    }

    validateHeadersBySchema(
      this.colHeaders,
      hedgehogCurrentNonCurrentHeadersSchema,
      this.processableInput,
      totalRowValue.row,
    );

    validateTotalHeadersBySchema(
      this.totalColHeaders,
      hedgehogCurrentNonCurrentTotalHeadersSchema,
      this.processableInput,
      totalRowValue.row,
    );
  }
}
