import { irsMtmValuationOutputHeaders } from './mtmValuationReportOutputHeaders';

export const hedgehogMtmValuationTotalHeader = [
  irsMtmValuationOutputHeaders.accruedInterest,
  irsMtmValuationOutputHeaders.cleanFairValue,
  irsMtmValuationOutputHeaders.totalFairValue,
  irsMtmValuationOutputHeaders.cva,
  irsMtmValuationOutputHeaders.dva,
  irsMtmValuationOutputHeaders.riskFreeValuation,
];
