import { CellProperties, ColumnSettings } from 'handsontable/settings';
import { DetailedSettings } from 'handsontable/plugins/mergeCells';
import { FxHedgeDocumentationFormTemplateOptions } from '../../../services/hedgehog/types/reports/fx-mtm-journal-report/fx-mtm-journal-options';
export const fxHedgeDocumentationFormColumnConfig: ColumnSettings[] = [
  { width: 400 },
  { width: 400 },
  { width: 300 },
  { width: 150 },
  { width: 150 },
];

interface IMergeTypes {
  totalRowContent: DetailedSettings;
  hedgeType: DetailedSettings;
  rightContent: DetailedSettings;
}
const mergeTypes: IMergeTypes = {
  totalRowContent: {
    row: 0,
    col: 0,
    rowspan: 1,
    colspan: 5,
  },
  hedgeType: {
    row: 0,
    col: 0,
    rowspan: 1,
    colspan: 3,
  },
  rightContent: {
    row: 0,
    col: 1,
    rowspan: 1,
    colspan: 4,
  },
};

type MergeType = keyof typeof mergeTypes;

const getHedgeDocumentationMergeConfig = (
  mergeType: MergeType,
  row: number,
) => {
  const mergeOption: DetailedSettings = mergeTypes[mergeType];

  return { ...mergeOption, row };
};

export const fxHedgeDocumentationFormMergeCells = (type: string) => {
  let hedgeType: number[] = [];

  let totalRowContent: number[] = [];
  let rightContent: number[] = [];

  if (type === FxHedgeDocumentationFormTemplateOptions.STANDARD_TEMPLATE) {
    const headerRowWithPolicy = [
      2, 3, 4, 5, 6, 7, 8, 9, 14, 15, 16, 27, 37, 38, 42, 51, 54, 59, 61, 63,
      65, 67, 69,
    ];
    const detailsHedgingInstrument = [17, 18, 19, 20, 21, 22, 23, 24, 25, 26];
    const detailsHedgedItem = [28, 29, 30, 31, 32, 33, 34, 35, 36];
    const assessmentHedgeEffectiveness = [39, 40, 41];
    const matchedTermsInception = [
      43, 44, 45, 46, 47, 48, 49, 50, 52, 53, 55, 56, 57, 58,
    ];
    const subHeaders = [60, 62, 64, 66, 68, 70];
    const finalHeaders = [71, 72, 73];

    totalRowContent = [...headerRowWithPolicy, ...subHeaders];
    rightContent = [
      ...detailsHedgingInstrument,
      ...detailsHedgedItem,
      ...assessmentHedgeEffectiveness,
      ...matchedTermsInception,
      ...finalHeaders,
    ];
    hedgeType = [10, 11, 12, 13];
  } else if (
    type === FxHedgeDocumentationFormTemplateOptions.SYNTHETIC_LEGS_TEMPLATE
  ) {
    const headerRowWithPolicy = [3, 5, 7, 9, 15];
    const detailsHedgingInstrument = [17, 18, 19, 20, 21, 22, 23, 24, 25, 26];
    const headers = [
      2, 4, 6, 8, 14, 16, 27, 39, 40, 43, 55, 57, 59, 61, 63, 65,
    ];
    const detailsHedgedItem = [
      28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 41, 42,
    ];
    const assessmentHedgeEffectiveness = [44, 45, 46];

    const subHeaders = [56, 58, 60, 62, 64, 66];
    const finalHeaders = [67, 68, 69];

    totalRowContent = [...headerRowWithPolicy, ...subHeaders, ...headers];
    rightContent = [
      ...detailsHedgingInstrument,
      ...detailsHedgedItem,
      ...assessmentHedgeEffectiveness,
      ...finalHeaders,
    ];
    hedgeType = [10, 11, 12, 13];
  }

  const mergeSettings: DetailedSettings[] = [];

  totalRowContent.forEach((row: number) => {
    const mergeSetting = getHedgeDocumentationMergeConfig(
      'totalRowContent',
      row,
    );
    mergeSettings.push(mergeSetting);
  });

  rightContent.forEach((row: number) => {
    const mergeSetting = getHedgeDocumentationMergeConfig('rightContent', row);
    mergeSettings.push(mergeSetting);
  });

  hedgeType.forEach((row: number) => {
    const mergeSetting = getHedgeDocumentationMergeConfig('hedgeType', row);
    mergeSettings.push(mergeSetting);
  });

  mergeSettings.push({
    row: 0,
    col: 0,
    rowspan: 1,
    colspan: 5,
  });

  mergeSettings.push({
    row: 1,
    col: 0,
    rowspan: 1,
    colspan: 5,
  });

  return mergeSettings;
};

export const customRender = (
  TD: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: string,
  cellProperties: CellProperties,
) => {
  if ((row === 26 || row === 35) && col === 1) {
    TD.textContent = Number(TD.textContent) * 100 + '%';
  }
  return TD;
};
