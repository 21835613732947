import { CellProperties } from 'handsontable/settings';
import moment from 'moment';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_PERCENTAGE_FORMAT,
  NUMERIC_FORMAT_PATTERN,
} from '../common/utils';
import { CellValue } from 'handsontable/common';

export const irsCurrentNotCurrentCellsConfiguration = (
  sheet: CellValue[][],
) => {
  const mergeCellsConfiguration = [
    {
      row: 0,
      col: 0,
      rowspan: 1,
      colspan: sheet[0].length - 3,
    },
    {
      row: 0,
      col: sheet[0].length - 3,
      rowspan: 1,
      colspan: 3,
    },
    {
      row: 1,
      col: 2,
      rowspan: 1,
      colspan: sheet[0].length - 3,
    },
    {
      row: 1,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    { row: sheet.length - 2, col: 0, rowspan: 2, colspan: sheet[0].length },
    { row: sheet.length - 3, col: 0, rowspan: 1, colspan: sheet[0].length },
  ];

  return mergeCellsConfiguration;
};

let totalRow: number | undefined = undefined;

export const customRenderer = (
  TD: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: string,
  cellProperties: CellProperties,
  sheet: any[][],
) => {
  if (row <= 2) return TD;
  if (value === 'Total') {
    totalRow = row;
  }

  const dateCol = sheet[0].length === 12 ? 8 : 0;
  const numericCols = sheet[0].length === 12 ? [3, 8] : [2, 7, 10];
  const percentageCols = sheet[0].length === 12 ? [6, 7] : [3, 5];

  const isDateCell = col === dateCol && (!totalRow || row < totalRow);
  const isNumericCell = numericCols.includes(col);
  const isPercentageCell = percentageCols.includes(col);

  if (isDateCell) {
    TD.textContent = moment(new Date(value)).format(DEFAULT_DATE_FORMAT);
  }

  if (isNumericCell) {
    cellProperties.type = 'numeric';
    cellProperties.numericFormat = {
      pattern: NUMERIC_FORMAT_PATTERN,
    };
  }

  if (isPercentageCell) {
    cellProperties.type = 'numeric';
    cellProperties.numericFormat = {
      pattern: DEFAULT_PERCENTAGE_FORMAT,
    };
  }

  return TD;
};
