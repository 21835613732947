import { useQuery } from 'react-query';
import { getOrganizationLogoById } from '../services/hedgehog/hedgehog.api';
import { ApiQueryKeys } from '../services/hedgehog/api-query-keys.enum';
import { SimpleLoading } from './loading/SimpleLoading';

const logoSizes = {
  small:
    'min-w-6 max-w-12 min-h-6 max-h-12 shadow-primary-dark-500 border border-gray-200',
  medium:
    'min-w-12 max-w-24 min-h-12 max-h-24 shadow-primary-dark-500 border border-gray-200',
  big: 'min-w-24 max-w-48 min-h-24 max-h-48 shadow-primary-dark-500 border border-gray-200',
  veryBig: 'w-48 h-48 shadow-primary-dark-500 border border-gray-200',
  none: '',
};
type LogoSize = keyof typeof logoSizes;

interface IOrganiationProps {
  logoSize: LogoSize;
  organizationId?: number;
  logoStyles?: string;
  src?: string;
}

export const OrganizationLogo = ({
  organizationId,
  logoStyles = 'p-2',
  logoSize,
  src,
}: IOrganiationProps) => {
  const { data: logo, isLoading } = useQuery(
    [ApiQueryKeys.organization_logo_by_id, organizationId],
    () => {
      if (!organizationId) return;
      return getOrganizationLogoById(organizationId);
    },
  );

  if (isLoading) {
    return (
      <SimpleLoading
        loadingColor="text-primary-cyan-500"
        loadingSize="h-10 w-10"
        externalStyles="flex flex-wrap items-center justify-center p-2"
      />
    );
  }

  return (
    <img
      src={src ?? logo}
      alt="Organization Logo"
      className={`${logoStyles} ${logoSizes[logoSize]}`}
    />
  );
};
