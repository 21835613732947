import { InputReportType } from '../../inputReportType';
import { ProcessableInputReport } from '../../ProcessableInputReport';
import { Cell } from '../../ProcessableReport';
import {
  fxMtmValuationHeaderMapping,
  FxMtmValuationReportInputsRowHeader,
  FxMtmValuationReportOutputColHeaders,
} from './fxForwardsHeldHeaders';
import { FxMtmValuationReportRequiredInputsType } from './fxForwardsHeldInputs';
import {
  fxMtmValuationHeadersSchema,
  fxMtmValuationTotalSchema,
} from './fxMtmValuationHeaders.schema';

export class FxForwardsHeldReportValidator {
  colHeaders: FxMtmValuationReportOutputColHeaders[] = Object.values(
    FxMtmValuationReportOutputColHeaders,
  );
  rowHeaders: FxMtmValuationReportInputsRowHeader[] = Object.values(
    FxMtmValuationReportInputsRowHeader,
  );

  constructor(private processableInput: ProcessableInputReport) {}

  validate() {
    if (
      this.processableInput.sheetName !==
      InputReportType.HB_FX_FORWARDS_HELD_REPORT
    ) {
      throw new Error(
        `Input report type ${FxMtmValuationReportRequiredInputsType.HB_FX_FORWARDS_HELD_REPORT} is wrong type for this output`,
      );
    }

    const eachDealDate = this.processableInput.findCellValues(
      fxMtmValuationHeaderMapping(
        FxMtmValuationReportOutputColHeaders.DealDate,
      ),
    );

    const eachTotalRow = this.processableInput.findCellValues(
      fxMtmValuationHeaderMapping(FxMtmValuationReportInputsRowHeader.TOTAL),
    );

    if (eachDealDate.length === 0) {
      throw new Error(
        `Missing Deal Date Header ${this.processableInput.sheetName}`,
      );
    }

    if (eachTotalRow.length === 0) {
      throw new Error(
        `Missing Total Row in ${this.processableInput.sheetName}`,
      );
    }

    if (eachDealDate.length !== eachTotalRow.length) {
      throw new Error(
        `There must be a Total row for each Deal Date row in ${this.processableInput.sheetName}`,
      );
    }

    const valuationHeader = this.processableInput.findCellValue(
      fxMtmValuationHeaderMapping(
        FxMtmValuationReportOutputColHeaders.FairValue,
      ),
    );

    if (!valuationHeader) {
      throw new Error(
        `Missing Fair Value Header ${this.processableInput.sheetName}`,
      );
    }

    eachDealDate.forEach((dealDate, index) => {
      const totalRow = eachTotalRow[index];

      this.colHeaders.forEach((header) => {
        const schema = fxMtmValuationHeadersSchema[header];
        const cellValue = this.processableInput.findCellValue(
          fxMtmValuationHeaderMapping(header),
        );

        if (!cellValue) {
          throw new Error(
            `Column "${header}" is missing in ${this.processableInput.sheetName}`,
          );
        }

        this.processableInput.getRangeValues(
          { col: cellValue.col, row: dealDate.row + 1 },
          {
            col: cellValue.col,
            row: totalRow.row - 1,
          },
          {
            condition: (cell: Cell) => {
              const validationResult = schema.validate(cell.value);
              if (validationResult.error) {
                throw new Error(
                  `Validation error: An incorrect value was found under the header "${header}" and the value is "${cell.value}": ${validationResult.error.message}`,
                );
              }
              return true;
            },
          },
        );
      });
    });

    eachTotalRow.forEach((totalRow) => {
      const schema =
        fxMtmValuationTotalSchema[
          FxMtmValuationReportOutputColHeaders.FairValue
        ];

      const cellValue = this.processableInput.getCellValue({
        col: valuationHeader.col,
        row: totalRow.row,
      });

      if (!cellValue) {
        throw new Error(
          `No Total entry found under the Valuation column header in ${this.processableInput.sheetName}`,
        );
      }

      const validationResult = schema.validate(cellValue);

      if (validationResult.error) {
        throw new Error(
          `Validation error: An incorrect value was found in the Total row under the header "Valuation" and the value is "${cellValue}": ${validationResult.error.message}`,
        );
      }
    });

    this.validateReportingDateInFooter();
    this.validateValuationCurrencyInFooter();
  }

  private validateReportingDateInFooter() {
    const targetLineRegex = /FX Forwards Held Report/;

    const matchCell = this.processableInput.findCellValueByCondition(
      (cell: Cell) => {
        if (typeof cell.value === 'string') {
          return targetLineRegex.test(cell.value);
        }
        return false;
      },
    );

    if (!matchCell) {
      throw new Error(
        'Failed to locate the expected footer line in the FX Forwards Held Report. Ensure that the footer includes a line similar to the following example: "Hedgehog - FX Forwards Held Report(30 Nov 2023)"',
      );
    }

    const regex = /\((\d{1,2} [A-Za-z]+ \d{2,4})\)/;
    const match = matchCell.value.match(regex);

    if (!match) {
      throw new Error(
        'Invalid reporting date format in footer from FX Forwards Held Report',
      );
    }
  }

  private validateValuationCurrencyInFooter() {
    const targetLinePartialRegex =
      /Report run as at .* for .* valued in \w+ using a mid market spread basis/;

    const matchCell = this.processableInput.findCellValueByCondition(
      (cell: Cell) => {
        if (typeof cell.value === 'string') {
          return targetLinePartialRegex.test(cell.value);
        }
        return false;
      },
    );

    if (!matchCell) {
      throw new Error(
        'Failed to locate the expected currency line in the FX Forwards Held Report. Ensure that the report includes a line similar to the following example: "Report run as at 30 Nov 2023 for Hedgehog valued in AUD using a mid market spread basis."',
      );
    }

    const currencyRegex = /valued in (\w+)/;
    const match = matchCell.value.match(currencyRegex);

    if (!match) {
      throw new Error(
        'Invalid or missing currency format in the valuation report.',
      );
    }
  }
}
