import { Button } from '../../../../components/Button';
import { SimpleLoading } from '../../../../components/loading/SimpleLoading';
import { PaginationButtons } from '../dashboard-home/PaginationButtons';
import { useAdminsPanel } from './useAdminsPanel';

const columnNames = ['full name', 'email', 'actions'];

export const AdminsPanel = () => {
  const {
    admins,
    count,
    isLoading,
    nextPage,
    prevPage,
    currentPage,
    handleClickAddAdminOptionsModal,
    handleDeleteAdmin,
  } = useAdminsPanel();

  const isReadyToShowData = !isLoading && admins;
  const hasAdmins = isReadyToShowData && admins?.length > 0;

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap align-center justify-between w-full py-4 mb-2  bg-white text-gray-900">
        <div className="flex-1">
          <Button
            data-cy="add-admin"
            onClick={handleClickAddAdminOptionsModal}
            color="cyan"
          >
            Add new administrator
          </Button>
        </div>
        <h3 className="hidden md:block flex-1 text-2xl font-bold text-center">
          Administrators
        </h3>
        <div className="hidden md:block flex-1"></div>
      </div>
      <div className="relative z-0 overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
        <table
          data-cy="admin-table"
          className="w-full text-sm text-left text-gray-500"
        >
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr className="bg-white border-b hover:bg-gray-50">
              {columnNames.map((key) => (
                <th key={key} className="whitespace-nowrap px-6 py-4 space-x-2">
                  {key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan={columnNames.length}>
                  <SimpleLoading
                    loadingColor="text-primary-cyan-500"
                    loadingSize="h-10 w-10"
                    externalStyles="flex flex-wrap items-center justify-center p-2"
                  />
                </td>
              </tr>
            )}
            {isReadyToShowData &&
              (hasAdmins ? (
                admins.map((admin) => (
                  <tr
                    key={admin.id}
                    className="bg-white border-b hover:bg-gray-50"
                  >
                    <td className="px-6 py-4 space-x-2">{`${admin.name} ${admin.lastName}`}</td>
                    <td className="px-6 py-4 space-x-2">{admin.email}</td>
                    <td className="px-4 py-4">
                      <div className="flex gap-2 flex-wrap">
                        <button
                          className="font-medium whitespace-nowrap text-red-500 hover:underline"
                          data-cy={`delete-admin-${admin.id}`}
                          onClick={() => handleDeleteAdmin(admin)}
                        >
                          Remove admin
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={columnNames.length} className="text-center py-2">
                    No administrators found.
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <PaginationButtons
        isLoading={isLoading}
        currentPage={currentPage}
        amountOfItems={count || 0}
        nextPage={nextPage}
        prevPage={prevPage}
      />
    </div>
  );
};
