import moment from 'moment';
import { useState } from 'react';
import { IIRSTrade } from '../../../services/hedgehog/interfaces/IIRSTrade';
import {
  calculateDataValues,
  calculateGradientOffset,
} from '../../../utils/irsTrades';
import { IRSTradeGraphRow } from '../../IRSTradeGraphRow';

type Props = {
  irsTrades: IIRSTrade[];
  onRowClick: (irsTrade: IIRSTrade) => void;
};

const columnNames: string[] = [
  'reference',
  'Counter Party',
  'Deal Date',
  'Maturity Date',
  'FV 6 Months graph',
];

export const IRSTradesTable = ({ irsTrades, onRowClick }: Props) => {
  const [selectedTrade, setSelectedTrade] = useState<IIRSTrade>(irsTrades[0]);

  const handleSelect = (irsTrade: IIRSTrade) => {
    setSelectedTrade(irsTrade);
    onRowClick(irsTrade);
  };

  return (
    <table className="w-full text-sm text-left text-gray-500 rounded-lg">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
        <tr className="bg-white border-b hover:bg-gray-50 rounded-lg">
          {columnNames.map((key) => (
            <th key={key} className="px-4 py-4 space-x-2">
              {key}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {irsTrades.map((trade) => (
          <tr
            key={`${trade.id}-${trade.reference}`}
            data-cy={`irs-trade-row-${trade.reference}`}
            className={`bg-white border-b hover:bg-primary-cyan-100 ease-in transition duration-100 cursor-pointer ${
              trade.id === selectedTrade?.id ? 'bg-gray-100' : ''
            }`}
            onClick={() => handleSelect(trade)}
          >
            <td className="px-4 py-4 space-x-2">{trade.reference}</td>

            <td className="px-4 py-4 space-x-2">{trade.counterParty}</td>

            <td className="px-4 py-4 space-x-2">
              {moment(trade.dealDate).format('DD MMM YY')}
            </td>
            <td className="px-4 py-4 space-x-2">
              {moment(trade.maturityDate).format('DD MMM YY')}
            </td>
            <td className="px-4 min-w-[250px] py-4">
              <IRSTradeGraphRow
                calculateDataValues={calculateDataValues}
                calculateGradientOffset={calculateGradientOffset}
                trade={trade}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
