import React from 'react';
import { LeftArrow } from '../../../../components/icons/LeftArrow';
import { RightArrow } from '../../../../components/icons/RightArrow';

type Props = {
  isLoading: boolean;
  currentPage: number;
  amountOfItems: number;
  limit?: number;
  nextPage: () => void;
  prevPage: () => void;
};

const LOGIN_SYMBOL = '...';

export const PaginationButtons: React.FC<Props> = ({
  isLoading,
  currentPage,
  amountOfItems,
  limit = 10,
  nextPage,
  prevPage,
}) => {
  const NUMBER_OF_PAGES = Math.ceil(amountOfItems / limit);
  const previousPageAvailable = currentPage > 1;
  const nextPageAvailable = currentPage < NUMBER_OF_PAGES;

  return (
    <div className="flex justify-center mt-1">
      <div className="flex content-center justify-between w-36 h-7 border border-gray-300 ">
        <button
          className="px-2 border border-r-gray-300 enabled:hover:bg-gray-200"
          data-cy="prev-page-button"
          onClick={prevPage}
          disabled={!previousPageAvailable || isLoading}
        >
          <LeftArrow fill="black" className="align-middle" />
        </button>
        <span className="text-sm" data-cy="pages-number">
          {currentPage} of {isLoading ? LOGIN_SYMBOL : NUMBER_OF_PAGES}
        </span>
        <button
          className="px-2 border border-l-gray-300 enabled:hover:bg-gray-200"
          data-cy="next-page-button"
          onClick={nextPage}
          disabled={!nextPageAvailable || isLoading}
        >
          <RightArrow fill="black" />
        </button>
      </div>
    </div>
  );
};
