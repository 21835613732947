import { FxHedgeEffectivenessReportStaticData } from './reports/fx-hedge-effectiveness-report/fx-hedge-effectiveness-report-static-data';
import { fxHedgeEffectivenessReportInputs } from './reports/fx-hedge-effectiveness-report/fx-hedge-effectiveness-report.inputs';
import { FxMaturedDealsReportStaticData } from './reports/fx-matured-deals-report/fx-matured-deals-report-static-data';
import { FxMaturedDealsReportInputs } from './reports/fx-matured-deals-report/fx-matured-deals-report.inputs';
import { FxMtmValuationStaticData } from './reports/fx-mtm-valuation-report/fx-mtm-valuation-report-static-data';
import { FxMtmValuationInputs } from './reports/fx-mtm-valuation-report/fx-mtm-valuation-report.inputs';
import { HedgeDocumentationFormStaticData } from './reports/hedge-documentation-form/hedge-documentation-form-static-data';
import { HedgeDocumentationFormInputs } from './reports/hedge-documentation-form/hedge-documentation-form.inputs';
import { IRSCurrentNonCurrentStaticData } from './reports/irs-current-non-current-report/irs-current-non-current-static-data';
import { IRSCurrentNonCurrentInput } from './reports/irs-current-non-current-report/irs-current-non-current.inputs';
import { IrsMtmJournalStaticData } from './reports/irs-mtm-journal-report/irs-mtm-journal-static-data';
import { IrsMtmJournalInputs } from './reports/irs-mtm-journal-report/irs-mtm-journal.inputs';
import { MtmValuationStaticData } from './reports/mtm-valuation-report/mtm-valuation-static-data';
import { MtmValuationInputs } from './reports/mtm-valuation-report/mtm-valuation.inputs';
import { RawReportsInputs } from './reports/raw-report/raw-report.inputs';

export const enum OutputReportTypes {
  RAW_REPORT = 'Raw Report',
  IRS_MTM_VALUATION_REPORT = 'IRS MTM Valuation Report',
  IRS_CURRENT_NON_CURRENT_REPORT = 'IRS Current/Non-Current Report',
  IRS_HEDGE_DOCUMENTATION_FORM = 'IRS Hedge Documentation Form',
  IRS_HEDGE_EFFECTIVENESS_REPORT = 'IRS Hedge Effectiveness Report',
  IRS_MTM_JOURNAL = 'IRS MTM Journal',
  FX_MTM_VALUATION = 'FX MTM Valuation Report',
  FX_MATURED_DEALS_REPORT = 'FX Matured Deals Report',
  FX_HEDGE_EFFECTIVENESS_REPORT = 'FX Hedge Effectiveness Report',
  FX_MTM_JOURNAL_REPORT = 'FX MTM Journal Report',
  FX_HEDGE_DOCUMENTATION_FORM = 'FX Hedge Documentation Form',
}

export type IRSReportTypes = Extract<
  OutputReportTypes,
  | OutputReportTypes.IRS_MTM_VALUATION_REPORT
  | OutputReportTypes.IRS_CURRENT_NON_CURRENT_REPORT
  | OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM
  | OutputReportTypes.IRS_HEDGE_EFFECTIVENESS_REPORT
  | OutputReportTypes.IRS_MTM_JOURNAL
>;

export type FXReportTypes = Extract<
  OutputReportTypes,
  | OutputReportTypes.FX_MTM_VALUATION
  | OutputReportTypes.FX_MATURED_DEALS_REPORT
  | OutputReportTypes.FX_HEDGE_EFFECTIVENESS_REPORT
  | OutputReportTypes.FX_MTM_JOURNAL_REPORT
  | OutputReportTypes.FX_HEDGE_DOCUMENTATION_FORM
>;

export type GeneratedOutputReportTypes = Exclude<
  OutputReportTypes,
  | OutputReportTypes.IRS_HEDGE_EFFECTIVENESS_REPORT
  | OutputReportTypes.FX_HEDGE_DOCUMENTATION_FORM
  | OutputReportTypes.FX_MTM_JOURNAL_REPORT
>;

export const OutputReportRoutes: {
  [key in GeneratedOutputReportTypes]: string;
} = {
  [OutputReportTypes.IRS_CURRENT_NON_CURRENT_REPORT]:
    'irs-current-non-current-report',
  [OutputReportTypes.IRS_MTM_VALUATION_REPORT]: 'irs-mtm-valuation-report',
  [OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM]: 'hedge-documentation-form',
  [OutputReportTypes.IRS_MTM_JOURNAL]: 'irs-mtm-journal-report',
  [OutputReportTypes.RAW_REPORT]: 'output-report',
  [OutputReportTypes.FX_MTM_VALUATION]: 'fx-mtm-valuation-report',
  [OutputReportTypes.FX_MATURED_DEALS_REPORT]: 'fx-matured-deals-report',
  [OutputReportTypes.FX_HEDGE_EFFECTIVENESS_REPORT]:
    'fx-hedge-effectiveness-report',
};

export interface IStaticField {
  label: string;
  name: string;
  type: 'date' | 'number' | 'string' | 'select' | 'textarea' | 'radio';
  options?: string[]; // Only required if type is 'select'
  rows?: number; // Only required if type is 'textarea'
  defaultValue?: string | number;
  comment?: string;
  link?: { text: string; url: string };
}

export interface IStaticSegment {
  display: boolean;
  sections: IStaticSection[];
  buttonOpenName: string;
  buttonCloseName: string;
}

export interface IStaticSection {
  fields: IStaticField[];
  name: string;
}

export interface IReportInputs {
  type: string;
  optional?: boolean;
}
export const InputReportsByOutputType: {
  [key in GeneratedOutputReportTypes]: IReportInputs[];
} = {
  [OutputReportTypes.RAW_REPORT]: RawReportsInputs,
  [OutputReportTypes.IRS_MTM_VALUATION_REPORT]: MtmValuationInputs,
  [OutputReportTypes.IRS_CURRENT_NON_CURRENT_REPORT]: IRSCurrentNonCurrentInput,
  [OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM]:
    HedgeDocumentationFormInputs,
  [OutputReportTypes.IRS_MTM_JOURNAL]: IrsMtmJournalInputs,
  [OutputReportTypes.FX_MTM_VALUATION]: FxMtmValuationInputs,
  [OutputReportTypes.FX_MATURED_DEALS_REPORT]: FxMaturedDealsReportInputs,
  [OutputReportTypes.FX_HEDGE_EFFECTIVENESS_REPORT]:
    fxHedgeEffectivenessReportInputs,
};

export const StaticDataByOutputType: {
  [key in GeneratedOutputReportTypes]: {
    fields: IStaticField[];
    segment?: IStaticSegment;
    instantGenerated?: boolean;
    validationSchema: any;
  };
} = {
  [OutputReportTypes.IRS_CURRENT_NON_CURRENT_REPORT]:
    IRSCurrentNonCurrentStaticData,
  [OutputReportTypes.IRS_MTM_VALUATION_REPORT]: MtmValuationStaticData,
  [OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM]:
    HedgeDocumentationFormStaticData,
  [OutputReportTypes.IRS_MTM_JOURNAL]: IrsMtmJournalStaticData,
  [OutputReportTypes.FX_MTM_VALUATION]: FxMtmValuationStaticData,
  [OutputReportTypes.RAW_REPORT]: {
    fields: [],
    validationSchema: {},
  },
  [OutputReportTypes.FX_MATURED_DEALS_REPORT]: FxMaturedDealsReportStaticData,
  [OutputReportTypes.FX_HEDGE_EFFECTIVENESS_REPORT]:
    FxHedgeEffectivenessReportStaticData,
};

export const ProfileStaticDataByOutputType: {
  [key in GeneratedOutputReportTypes]: string | null;
} = {
  [OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM]:
    'hedgeDocumentationFormStaticData',
  [OutputReportTypes.IRS_CURRENT_NON_CURRENT_REPORT]: null,
  [OutputReportTypes.IRS_MTM_VALUATION_REPORT]: null,
  [OutputReportTypes.FX_MTM_VALUATION]: null,
  [OutputReportTypes.IRS_MTM_JOURNAL]: null,
  [OutputReportTypes.RAW_REPORT]: null,
  [OutputReportTypes.FX_MATURED_DEALS_REPORT]: null,
  [OutputReportTypes.FX_HEDGE_EFFECTIVENESS_REPORT]: null,
};
