import * as Yup from 'yup';

const hedgeTypeValidationSchema = {
  cashFlowHedge: Yup.string()
    .oneOf(['Yes', 'No'], 'Must be either "Yes" or "No"')
    .required('Cash Flow Hedge is required'),
  fairValueHedge: Yup.string()
    .oneOf(['Yes', 'No'], 'Must be either "Yes" or "No"')
    .required('Fair Value Hedge is required'),
  netInvestmentHedge: Yup.string()
    .required('Net Investment Hedge is required')
    .oneOf(['Yes', 'No'], 'Must be either "Yes" or "No"'),
};

export const fxHedgeDocumentationFormGeneralInfoValidationSchema = {
  natureOfHedgedRisk: Yup.string()
    .trim()
    .min(1, 'Nature of Hedged Risk must be at least 1 characters')
    .required('Nature of Hedged Risk is required.'),
  policyDeclaration: Yup.string()
    .trim()
    .min(1, 'Policy Declaration must be at least 1 characters')
    .required('Policy Declaration is required'),
  riskManagementObjective: Yup.string()
    .trim()
    .min(1, 'Risk Management Objective must be at least 1 characters')
    .required('Risk Management Objective is required'),
  strategy: Yup.string()
    .trim()
    .min(1, 'Strategy must be at least 1 characters')
    .required('Strategy is required'),
  natureOfHedgingInstrument: Yup.string()
    .trim()
    .min(1, 'Nature of Hedging Instrument must be at least 1 characters')
    .required('Nature of Hedging Instrument is required'),
  amountOfPrincipalHedgingInstrumentPercentaje: Yup.number()
    .positive('Amount designated as a hedged item must be positive')
    .max(100, 'Amount designated as a hedged item must be at most 100')
    .required('Amount designated as a hedged item is required'),
  natureOfHedgedItem: Yup.string()
    .trim()
    .min(1, 'Nature of hedged item must be at least 1 characters')
    .required('Nature of hedged item is required'),
  impactToProfitOrLoss: Yup.string()
    .trim()
    .min(1, 'Impact To Profit Or Loss must be at least 1 characters')
    .required('Impact To Profit Or Loss is required'),
  contractualPartiesOfHedgingItem: Yup.string()
    .trim()
    .min(1, 'Contractual Parties of Hedging Item must be at least 1 characters')
    .required('Contractual Parties of Hedging Item is required'),
  amountDesignatedAsHedgedItemPercentage: Yup.number()
    .positive('Amount designated as a hedged item must be positive')
    .max(100, 'Amount designated as a hedged item must be at most 100')
    .required('Amount designated as a hedged item is required'),
  preparedBy: Yup.string()
    .matches(
      /^[A-Za-z\s]+ - [A-Za-z\s]+ - [A-Za-z\s]+$/,
      'Please provide a valid format: "Name - Position - Company"',
    )
    .required('Prepared By is required'),
  reviewedBy: Yup.string()
    .matches(
      /^[A-Za-z\s]+ - [A-Za-z\s]+ - [A-Za-z\s]+$/,
      'Please provide a valid format: "Name - Position - Company"',
    )
    .required('Prepared By is required'),
};

const fxHedgeDocumentationFormSyntheticInfoValidationSchema = {
  toAchieveHedgeEffectivenessExplanationSyntheticTemplate: Yup.string()
    .trim()
    .min(
      1,
      'To Achieve Hedge Effectiveness Explanation must be at least 1 characters',
    )
    .required('To Achieve Hedge Effectiveness Explanation is required'),
  underlyingRiskHedgedItemI: Yup.string()
    .trim()
    .min(1, 'Underlying Risk - Hedged Item I must be at least 1 characters')
    .required('Underlying Risk - Hedged Item I is required'),
  underlyingRiskHedgedItemII: Yup.string()
    .trim()
    .min(1, 'Underlying Risk - Hedged Item II must be at least 1 characters')
    .required('Underlying Risk - Hedged Item II is required'),
};

export const fxHedgeDocFormStandardTemplateValidationSchema =
  Yup.object().shape({
    ...fxHedgeDocumentationFormGeneralInfoValidationSchema,
    ...hedgeTypeValidationSchema,
  });

export const fxHedgeDocFormSyntheticTemplateValidationSchema =
  Yup.object().shape({
    ...fxHedgeDocumentationFormGeneralInfoValidationSchema,
    ...fxHedgeDocumentationFormSyntheticInfoValidationSchema,
    ...hedgeTypeValidationSchema,
  });
