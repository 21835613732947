import { useLocation, useNavigate } from 'react-router-dom';

import { useUser } from '../../hooks/useUser';
import { logout } from '../../services/hedgehog/user.api';
import { Button } from '../../components/Button';
import { IOrganization } from '../../services/hedgehog/interfaces/IOrganization';
import { OrganizationLogo } from '../../components/OrganizationLogo';

interface IAdminHeaderProps {
  adminOrganization?: IOrganization;
}

export const DashboardHeader = ({ adminOrganization }: IAdminHeaderProps) => {
  const { pathname } = useLocation();
  const isOrganizationViewLink = pathname.includes('organization');
  const { setUserInfo, user } = useUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    setUserInfo(undefined);
    navigate('/');
  };

  return (
    <header
      className={`sticky top-0 z-[90] bg-white md:flex w-full shadow-sm border-b ${
        isOrganizationViewLink ? 'hidden' : ''
      }`}
    >
      <div className="flex flex-grow items-center justify-end py-4 px-6 shadow-2 md:px-10 2xl:px-14">
        {!isOrganizationViewLink &&
          user?.role === 'admin' &&
          user.isSuperAdmin && (
            <div className="mr-auto md:gap-4 flex">
              <Button
                color="white"
                className="hover:text-black text-xs md:text-sm px-2 md:px-2"
                data-cy="manage-organizations-button"
                onClick={() => navigate('home')}
              >
                Manage Organizations
              </Button>
              <Button
                color="white"
                className="hover:text-black text-xs md:text-sm px-0"
                data-cy="manage-admins-button"
                onClick={() => navigate('manage-admins')}
              >
                Manage Administrators
              </Button>
            </div>
          )}
        <div className="flex items-center gap-4">
          {isOrganizationViewLink ? (
            <OrganizationLogo
              logoSize="none"
              logoStyles="min-w-6 max-w-12 min-h-6 max-h-12 shadow-primary-dark-500"
              organizationId={adminOrganization?.id}
            />
          ) : (
            <Button
              variant="solid"
              color="cyan"
              className="hover:text-black ml-2 whitespace-nowrap text-xs md:text-sm"
              data-cy="logout-button"
              onClick={handleLogout}
            >
              Log out
            </Button>
          )}
        </div>
      </div>
    </header>
  );
};
