import { UNEXPECTED_ERROR_MESSAGE } from '../../../error/errorMessages.constants';
import { IReportByMonth } from '../../../services/hedgehog/interfaces/IReportByMonth';
import { useEffect, useState } from 'react';
import { Button } from '../../Button';
import { SimpleAlert } from '../../alerts/SimpleAlert';
import { SimpleLoading } from '../../loading/SimpleLoading';
import { HedgeDocumentationMonthlyTable } from './HedgeDocumentationMonthlyTable';
import { ReportMonthlyTable } from './ReportMonthlyTable';
import { PaginationButtons } from '../../../pages/dashboard/admin/dashboard-home/PaginationButtons';
import { FilterSection } from '../filter-section/FilterSection';
import { useMonthlyReportPagination } from './useMonthlyReportPagination';
import {
  ADMIN_DASHBOARD_ROUTE,
  USER_DASHBOARD_ROUTE,
} from '../../navbar/Navbar';
import { useFilters } from '../../../hooks/useFilters';
import { useLocation } from 'react-router-dom';
import { EmailIcon } from '../../icons/EmailIcon';
import { useModal } from '../../../hooks/useModal';
import { NotifyReportsToEmailModal } from '../../modal/notify-reports-to-email-modal/NotifyReportsToEmailModal';

const routesTypes = {
  user: {
    name: 'User Home',
    route: USER_DASHBOARD_ROUTE,
  },
  admin: {
    name: 'Admin Home',
    route: ADMIN_DASHBOARD_ROUTE,
  },
};
type RouteType = keyof typeof routesTypes;

interface IMonthlyReportProps {
  reports?: IReportByMonth;
  month: string;
  year: string;
  isLoading: boolean;
  error: unknown;
  routeType: RouteType;
  status?: string;
}

export const MonthlyReport = ({
  reports,
  month,
  year,
  error,
  isLoading,
  routeType,
  status,
}: IMonthlyReportProps) => {
  const location = useLocation();
  const { filters } = useFilters();
  const [showDocFormsTable, setShowDocFormsTable] = useState(false);
  const itemsPerPage = 10;

  const { openModal, closeModal } = useModal();

  const {
    paginatedIrsForms,
    paginatedFxForms,
    paginatedReports,
    setCurrentPage,
    currentPage,
    totalItems,
    nextPage,
    prevPage,
  } = useMonthlyReportPagination({
    reports,
    showDocFormsTable,
    itemsPerPage,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [location.pathname, filters, setCurrentPage]);

  const validMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const handleClickReportsToNotify = (organizationId: number) => {
    openModal(
      'reports-to-notify',
      <NotifyReportsToEmailModal
        close={() => closeModal('reports-to-notify')}
        organizationId={organizationId}
      />,
    );
  };

  if (year && isNaN(Number(year))) {
    return (
      <SimpleAlert
        alertMessage="Invalid year"
        alertType="danger"
        alertRoute={routesTypes[routeType]}
      />
    );
  }

  if (month && !validMonths.includes(month)) {
    return (
      <SimpleAlert
        alertMessage="Invalid month"
        alertType="danger"
        alertRoute={routesTypes[routeType]}
      />
    );
  }

  return (
    <>
      <div className="relative z-0 overflow-x-auto bg-white shadow-md rounded-lg">
        {error !== undefined && error !== null && (
          <>
            {error instanceof Error ? (
              <SimpleAlert alertMessage={error.message} alertType="danger" />
            ) : (
              <SimpleAlert
                alertMessage={UNEXPECTED_ERROR_MESSAGE}
                alertType="danger"
              />
            )}
          </>
        )}
      </div>
      {
        <div className="flex flex-col xl:flex-row-reverse gap-4 w-full">
          <FilterSection />
          <div className="flex flex-col w-full">
            <div className="w-full overflow-y-auto h-fit bg-white border border-gray-200 shadow-md rounded-lg mb-1">
              <div className="flex flex-col md:flex-row md:min-w-[430px] justify-between items-center w-full p-4">
                <h4 className="text-gray-900 font-bold text-left">
                  {!showDocFormsTable ? 'Reports' : 'Documentations'}
                  {` - ${month} ${year}`}
                </h4>
                <div className="flex justify-between items-center md:flex-col">
                  <Button
                    variant="solid"
                    color="purple"
                    data-cy="toggle-table-button"
                    className="flex justify-center items-center self-stretch h-9 flex-grow-0 flex-shrink-0 relative gap-2.5 rounded mt-1 mr-1 md:mr-0"
                    onClick={() => {
                      setShowDocFormsTable(!showDocFormsTable);
                      setCurrentPage(1);
                    }}
                  >
                    {showDocFormsTable ? 'Reports' : 'Hedge Documentation'}
                  </Button>
                  {routeType === 'admin' && reports && (
                    <Button
                      type="button"
                      variant="solid"
                      color="cyan"
                      className="flex justify-center items-center self-stretch h-9 flex-grow-0 flex-shrink-0 relative gap-2.5 rounded mt-1"
                      data-cy="notify-clients-button"
                      onClick={() =>
                        handleClickReportsToNotify(reports.organization.id)
                      }
                    >
                      <EmailIcon />
                      <span className="flex-grow-0 flex-shrink-0 text-base font-semibold text-left text-primary-dark-500">
                        Notify Clients
                      </span>
                    </Button>
                  )}
                </div>
              </div>
              {isLoading && (
                <SimpleLoading
                  loadingColor="text-primary-cyan-500"
                  loadingSize="h-10 w-10"
                  externalStyles="flex flex-wrap items-center justify-center p-2"
                />
              )}
              {!showDocFormsTable && reports && paginatedReports.length > 0 && (
                <ReportMonthlyTable
                  organization={reports.organization}
                  reports={paginatedReports}
                  filters={{
                    month,
                    year,
                    status,
                    customFilters: filters,
                    organizationId: reports.organization.id,
                  }}
                />
              )}
              {showDocFormsTable &&
                reports &&
                (paginatedIrsForms.length > 0 ||
                  paginatedFxForms.length > 0) && (
                  <HedgeDocumentationMonthlyTable
                    organization={reports.organization}
                    irsHedgeDocumentationForms={paginatedIrsForms}
                    fxHedgeDocumentationForms={paginatedFxForms}
                  />
                )}
            </div>
            <PaginationButtons
              amountOfItems={totalItems}
              currentPage={currentPage}
              isLoading={isLoading}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </div>
        </div>
      }
    </>
  );
};
