import { useQuery } from 'react-query';
import { Button } from '../../Button';
import { InputFile } from '../../input-file/InputFile';
import { Modal } from '../Modal';
import { ApiQueryKeys } from '../../../services/hedgehog/api-query-keys.enum';
import {
  uploadInputReport,
  getOrganizations,
  uploadRawReport,
  getOrganizationInputReports,
} from '../../../services/hedgehog/hedgehog.api';
import { useCallback, useEffect, useState } from 'react';
import {
  FXReportTypes,
  GeneratedOutputReportTypes,
  IRSReportTypes,
  InputReportsByOutputType,
  OutputReportTypes,
  ProfileStaticDataByOutputType,
  StaticDataByOutputType,
} from '../../../services/hedgehog/types/OutputReportTypes';
import { Toast } from '../../../services/toastify/Toastify';
import { InputSelect } from '../../InputSelect';
import { IInputReport } from '../../../services/hedgehog/interfaces/IInputReport';
import { useModal } from '../../../hooks/useModal';
import { useIncompleteHedgeDocumentationQuery } from '../../../hooks/useIncompleteHedgeDocumentationQuery';
import { StaticDataModal } from './StaticDataModal';
import useLoading from '../../../hooks/useLoading';
import { SimpleLoading } from '../../loading/SimpleLoading';
import { useOrganization } from '../../../hooks/useOrganization';
import { ExposureType } from '../../../services/hedgehog/enum/ExposureType.enum';
import moment from 'moment';
import { UNEXPECTED_ERROR_MESSAGE } from '../../../error/errorMessages.constants';
import { validateInput } from '../../../services/validation/validateInput';
import { InputReportType } from '../../../services/validation/inputReportType';
import { RawReportTypes } from './RawReportTypes.enum';
import { isRawHedgeDocumentation } from './utils';

type Props = {
  close: () => void;
  onSubmit?: () => void;
  organizationId: number;
};

export const AddReportModal = ({ close, onSubmit, organizationId }: Props) => {
  const [formData, setFormData] = useState<{
    organizationId: number;
    hedgeDocumentationFormId: number | undefined;
    outputReportType: GeneratedOutputReportTypes | '';
    reportingDate: string;
    name: string;
    rawReportType: RawReportTypes;
    reference: string;
  }>({
    organizationId: organizationId,
    outputReportType: '',
    hedgeDocumentationFormId: undefined,
    reportingDate: '',
    name: '',
    rawReportType: RawReportTypes.OUTPUT_REPORT,
    reference: '',
  });
  const [rawReportFile, setRawReportFile] = useState<File | null>(null);
  const [uploadingRawReport, waitForRawReportUpload] = useLoading();
  const [reportFiles, setReportFiles] = useState<{
    [key: string]: IInputReport;
  }>({});

  const { data, isLoading: isLoadingOrganizations } = useQuery(
    ApiQueryKeys.organizations,
    () => getOrganizations({ is_active: true }),
  );

  const { openModal, closeModal } = useModal();

  const {
    data: hedgeDocumentationForms,
    refetch: refetchHedgeDocumentationQuery,
  } = useIncompleteHedgeDocumentationQuery(
    {
      enabled: true,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
    false,
  );

  const { organization } = useOrganization(formData.organizationId);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUploadRawReport = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!rawReportFile) return;
    const outputReportData = {
      organizationId: formData.organizationId,
      name: formData.name.trim(),
      reportingDate: new Date(formData.reportingDate),
      report: rawReportFile,
      rawReportType: formData.rawReportType,
      reference: formData.reference,
    };

    try {
      await waitForRawReportUpload(uploadRawReport(outputReportData));
      Toast.success('Raw report uploaded successfully');
      onSubmit?.();
      close();
    } catch (error: unknown) {
      if (error instanceof Error) Toast.error(error.message);
      else Toast.error(UNEXPECTED_ERROR_MESSAGE);
    }
  };

  const handleStaticDataForm = (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.organizationId)
      return Toast.error('No organization selected');

    if (
      formData.outputReportType ===
        OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM &&
      !formData.hedgeDocumentationFormId
    ) {
      return Toast.error('No incomplete hedge documentation form selected');
    }

    if (!formData.outputReportType)
      return Toast.error('No output type selected');

    const outputReportData = {
      organizationId: formData.organizationId,
      outputReportType: formData.outputReportType,
      reports: Object.values(reportFiles),
      hedgeDocumentationFormId: formData.hedgeDocumentationFormId,
    };
    if (!organization) return Toast.error('The organization is not loaded');

    openModal(
      'static-data-form',
      <StaticDataModal
        fields={StaticDataByOutputType[formData.outputReportType].fields}
        segment={StaticDataByOutputType[formData.outputReportType].segment}
        validationSchema={
          StaticDataByOutputType[formData.outputReportType].validationSchema
        }
        organizationProfileStaticData={{
          organization,
          organizationProfileByOutputType:
            ProfileStaticDataByOutputType[formData.outputReportType],
        }}
        formData={outputReportData}
        instantGenerated={
          StaticDataByOutputType[formData.outputReportType].instantGenerated
        }
        onSubmit={() => {
          onSubmit?.();
          close();
        }}
        close={handleCloseStaticDataModal}
      />,
    );
  };

  const handleCloseStaticDataModal = useCallback(async () => {
    closeModal('static-data-form');
    await refetchHedgeDocumentationQuery();
  }, [refetchHedgeDocumentationQuery, closeModal]);

  const handleChangeTextInputRawReport = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [allFieldsAreFilled, setAllFieldsAreFilled] = useState(false);
  const checkAllFieldsAreFilled = useCallback(() => {
    if (!formData.organizationId || !formData.outputReportType) return false;

    const inputReports = InputReportsByOutputType[formData.outputReportType];

    const requiredFieldsFilled = inputReports.every((report) => {
      if (!report.optional) {
        return report.type in reportFiles;
      }
      return true;
    });

    return requiredFieldsFilled;
  }, [formData.organizationId, formData.outputReportType, reportFiles]);

  useEffect(() => {
    setAllFieldsAreFilled(checkAllFieldsAreFilled());
  }, [checkAllFieldsAreFilled, reportFiles]);

  useEffect(() => {
    setRawReportFile(null);
    setReportFiles({});
  }, [formData.outputReportType]);

  const allFieldsAreFilledRawReport = () =>
    !formData.organizationId ||
    !formData.name.trim() ||
    formData.reportingDate === '' ||
    !rawReportFile;

  const displayInputReportOption = (item: IInputReport) => {
    return (
      <option key={item.id} value={JSON.stringify(item)}>{`${
        item.inputType
      } - ${moment(new Date(item.reportingDate)).format('DD-MMM-YY')}`}</option>
    );
  };
  const handleInputReportSelection = (
    reportName: string,
    report: IInputReport | undefined,
  ) => {
    if (!report) {
      const reportFilesAux = { ...reportFiles };
      delete reportFilesAux[reportName];
      setReportFiles(reportFilesAux);
      return;
    }
    setReportFiles({
      ...reportFiles,
      [reportName]: report,
    });
  };
  const handleUploadInputFile = async (file: File, reportName: string) => {
    await validateInput(file, reportName as InputReportType);
    return await uploadInputReport({
      inputType: reportName,
      report: file,
      organizationId,
    });
  };

  const handleFetchOptionItems = (reportName: string) => {
    return getOrganizationInputReports(organizationId, reportName, {
      limit: 20,
      offset: 0,
    });
  };

  const handleReportTypeByExposureType = (
    exposureType?: ExposureType | undefined,
  ) => {
    const generalValidTypes = [OutputReportTypes.RAW_REPORT];
    const irsOnlyValidTypes: IRSReportTypes[] = [
      OutputReportTypes.IRS_CURRENT_NON_CURRENT_REPORT,
      OutputReportTypes.IRS_MTM_VALUATION_REPORT,
      OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM,
      OutputReportTypes.IRS_MTM_JOURNAL,
    ];
    const fxOnlyValidTypes: FXReportTypes[] = [
      OutputReportTypes.FX_MTM_VALUATION,
      OutputReportTypes.FX_MATURED_DEALS_REPORT,
      OutputReportTypes.FX_HEDGE_EFFECTIVENESS_REPORT,
    ];

    const message = 'Update exposure type first';
    switch (exposureType) {
      case ExposureType.IRS_ONLY:
        return [...generalValidTypes, ...irsOnlyValidTypes];
      case ExposureType.FX_ONLY:
        return [...generalValidTypes, ...fxOnlyValidTypes];
      case ExposureType.BOTH:
        return [
          ...generalValidTypes,
          ...irsOnlyValidTypes,
          ...fxOnlyValidTypes,
        ];
      default:
        return [message];
    }
  };

  const getButtonText = (): string => {
    if (!formData.outputReportType) return 'Upload Report';

    return StaticDataByOutputType[formData.outputReportType].instantGenerated
      ? 'Upload Report'
      : 'Load Static Data';
  };

  return (
    <>
      <Modal>
        <div className=" flex flex-col justify-center items-center relative w-11/12 sm:w-96  z-0 overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
          {isLoadingOrganizations && (
            <SimpleLoading
              loadingColor="text-primary-cyan-500"
              loadingSize="h-20 w-20"
              externalStyles="flex flex-wrap items-center justify-center p-6"
            />
          )}

          {data?.organizations && (
            <form className="flex flex-col justify-center items-center w-full p-4 gap-2 bg-white">
              <InputSelect
                data-cy="organization-select"
                label={'Select an organization'}
                id={'organizationId'}
                name={'organizationId'}
                disabled={
                  !!organizationId || Object.values(reportFiles).length !== 0
                }
                type={'text'}
                defaultValue={`${formData.organizationId}`}
                handleChange={handleChange}
              >
                <option value="">Choose a organization</option>
                {data.organizations?.map((organization) => (
                  <option key={organization.id} value={organization.id}>
                    {organization.name}
                  </option>
                ))}
              </InputSelect>
              <InputSelect
                data-cy="output-type-select"
                label={'Select an output type'}
                id={'outputReportType'}
                name={'outputReportType'}
                type={'text'}
                handleChange={handleChange}
                defaultValue={formData.outputReportType}
              >
                <option value="">Choose an output type</option>
                {handleReportTypeByExposureType(organization?.exposureType).map(
                  (outputType) => (
                    <option key={outputType} value={outputType}>
                      {outputType}
                    </option>
                  ),
                )}
              </InputSelect>

              {formData.outputReportType &&
                formData.outputReportType ===
                  OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM && (
                  <div className="w-full my-2">
                    <InputSelect
                      data-cy="type-select-hedge-documentation-form"
                      label="Select the incomplete IRS Hedge Documentation Form"
                      id="hedgeDocumentationFormId"
                      name="hedgeDocumentationFormId"
                      type={'text'}
                      handleChange={handleChange}
                      defaultValue={formData.outputReportType}
                    >
                      <option value={undefined}>
                        Please select an option.
                      </option>
                      {hedgeDocumentationForms
                        ?.filter(
                          (form) =>
                            form.organization.id === formData.organizationId,
                        )
                        .map(({ id, reference, organization }) => (
                          <option key={reference} value={id}>
                            {`Id: ${id} | Reference: ${reference} | Organization: ${organization.name}`}
                          </option>
                        ))}
                    </InputSelect>
                  </div>
                )}

              {formData.outputReportType &&
                formData.outputReportType === OutputReportTypes.RAW_REPORT && (
                  <>
                    <InputSelect
                      id="rawReportType"
                      data-cy="raw-report-type-select"
                      type="text"
                      name="rawReportType"
                      label="Raw Report Type"
                      handleChange={handleChange}
                      defaultValue={RawReportTypes.OUTPUT_REPORT}
                    >
                      <option value={RawReportTypes.OUTPUT_REPORT}>
                        Output report
                      </option>
                      {organization?.exposureType === ExposureType.FX_ONLY && (
                        <>
                          <option value={RawReportTypes.FX_HEDGE_DOCUMENTATION}>
                            FX Hedge documentation
                          </option>
                        </>
                      )}

                      {organization?.exposureType === ExposureType.IRS_ONLY && (
                        <>
                          <option
                            value={RawReportTypes.IRS_HEDGE_DOCUMENTATION}
                          >
                            IRS Hedge documentation
                          </option>
                        </>
                      )}

                      {organization?.exposureType === ExposureType.BOTH && (
                        <>
                          <option value={RawReportTypes.FX_HEDGE_DOCUMENTATION}>
                            FX Hedge documentation
                          </option>
                          <option
                            value={RawReportTypes.IRS_HEDGE_DOCUMENTATION}
                          >
                            IRS Hedge documentation
                          </option>
                        </>
                      )}
                    </InputSelect>
                    {isRawHedgeDocumentation(formData.rawReportType) && (
                      <>
                        <label className="block text-sm font-medium text-gray-900 w-full">
                          Reference
                        </label>
                        <input
                          data-cy="reference"
                          className="block w-full p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
                          type="text"
                          id="reference"
                          name="reference"
                          required
                          onChange={handleChangeTextInputRawReport}
                        ></input>
                      </>
                    )}
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-900 w-full "
                    >
                      Name
                    </label>
                    <input
                      data-cy="report-name"
                      type="text"
                      name="name"
                      id="name"
                      onChange={handleChangeTextInputRawReport}
                      className="block mb-2 w-full p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    />
                    <label
                      htmlFor="reportingDate"
                      className="block text-sm font-medium text-gray-900 w-full"
                    >
                      Report Date
                    </label>
                    <input
                      data-cy="reporting-date"
                      type="date"
                      name="reportingDate"
                      id="report-date"
                      onChange={handleChangeTextInputRawReport}
                      className="block w-full p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    />
                  </>
                )}

              {formData.outputReportType &&
                InputReportsByOutputType[formData.outputReportType].map(
                  ({ type: reportName, optional }) => (
                    <div
                      key={reportName}
                      className="w-full my-2 flex flex-row gap-2"
                    >
                      {formData.outputReportType !==
                        OutputReportTypes.RAW_REPORT && (
                        <InputFile
                          name={reportName}
                          label={`${reportName}${
                            optional ? ' (Optional)' : ''
                          }`}
                          aria-describedby={reportName}
                          id={reportName}
                          handleLoad={(file: File) =>
                            handleUploadInputFile(file, reportName)
                          }
                          fetchItems={() => handleFetchOptionItems(reportName)}
                          displayOptions={displayInputReportOption}
                          onSelectItem={(report) =>
                            handleInputReportSelection(reportName, report)
                          }
                        />
                      )}
                      {formData.outputReportType ===
                        OutputReportTypes.RAW_REPORT && (
                        <InputFile
                          name={reportName}
                          label={reportName}
                          aria-describedby={reportName}
                          id={reportName}
                          handleLoad={async (file: File) => file}
                          onSelectItem={(file) =>
                            file && setRawReportFile(file)
                          }
                        />
                      )}
                    </div>
                  ),
                )}
              <div className="flex flex-row items-center justify-end gap-4 w-full my-4">
                <Button
                  type="button"
                  variant="underline"
                  color="black"
                  onClick={close}
                >
                  Cancel
                </Button>{' '}
                {formData.outputReportType &&
                formData.outputReportType === OutputReportTypes.RAW_REPORT ? (
                  <Button
                    color={allFieldsAreFilledRawReport() ? 'cyan' : 'gray'}
                    data-cy="submit-upload-report-button"
                    onClick={handleUploadRawReport}
                    disabled={allFieldsAreFilledRawReport()}
                    variant={
                      allFieldsAreFilledRawReport() ? 'outline' : 'solid'
                    }
                  >
                    Upload Report
                  </Button>
                ) : (
                  <Button
                    color={allFieldsAreFilled ? 'cyan' : 'gray'}
                    data-cy="load-static-data-button"
                    onClick={handleStaticDataForm}
                    disabled={!allFieldsAreFilled}
                    variant={allFieldsAreFilled ? 'solid' : 'outline'}
                  >
                    {getButtonText()}
                  </Button>
                )}
              </div>
            </form>
          )}
        </div>
      </Modal>

      {uploadingRawReport && (
        <Modal className="z-[250]">
          <div className="max-h-full overflow-y-auto">
            <div className="flex flex-wrap justify-center items-center relative w-full overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
              <SimpleLoading
                loadingColor="text-primary-cyan-500"
                loadingSize="h-20 w-20"
                externalStyles="flex flex-wrap items-center justify-center p-6"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
