import { FormField } from '../form-field/FormField';

import { useQueryClient } from 'react-query';
import { login } from '../../services/hedgehog/user.api';
import { Form, Formik } from 'formik';
import { ComponentProps } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Toast } from '../../services/toastify/Toastify';
import { loginValidationSchema } from './loginForm.schema';
import { UNEXPECTED_ERROR_MESSAGE } from '../../error/errorMessages.constants';
import useLoading from '../../hooks/useLoading';
import { ApiQueryKeys } from '../../services/hedgehog/api-query-keys.enum';
import { ADMIN_DASHBOARD_ROUTE, USER_DASHBOARD_ROUTE } from '../navbar/Navbar';
import { IUser } from '../../services/hedgehog/interfaces/IUser';
import { SimpleLoading } from '../loading/SimpleLoading';
import { Button } from '../Button';

const formFields: ComponentProps<typeof FormField>[] = [
  {
    label: 'Email',
    id: 'email',
    name: 'email',
    type: 'email',
    placeholder: 'Enter your email',
  },
  {
    label: 'Password',
    id: 'password',
    name: 'password',
    type: 'password',
    placeholder: 'Enter your password',
  },
];

export const LoginForm = () => {
  const [isLoading, execute] = useLoading();
  const [isUserLoading, executeUser] = useLoading<IUser>();
  const initialValues = {
    email: '',
    password: '',
  };
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const handleSubmit = async (values: typeof initialValues) => {
    try {
      const userData = await executeUser(login(values.email, values.password));
      queryClient.setQueryData(ApiQueryKeys.userData, userData);
      navigate(
        userData.role === 'admin'
          ? ADMIN_DASHBOARD_ROUTE
          : USER_DASHBOARD_ROUTE,
      );
      Toast.success('Logged in successfully');
    } catch (e: unknown) {
      if (e instanceof Error) Toast.error(e.message);
      else Toast.error(UNEXPECTED_ERROR_MESSAGE);
      console.log(e);
    }
  };

  if (isLoading || isUserLoading) {
    return (
      <SimpleLoading
        loadingColor="text-primary-cyan-500"
        loadingSize="h-10 w-10"
        externalStyles="flex items-center justify-center p-2"
      />
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginValidationSchema}
      onSubmit={(values) => execute(handleSubmit(values))}
    >
      <Form>
        {formFields.map((field) => (
          <FormField key={field.id} {...field} />
        ))}
        <div className="flex items-center justify-between">
          <Link
            data-cy="forgot-password-link"
            className="text-blue-500 text-sm"
            to="/password-recovery"
          >
            Forgot password
          </Link>

          <Button
            data-cy="login-form-button"
            color="cyan"
            type="submit"
            className="hover:bg-cyan-600 text-white"
          >
            Log In
          </Button>
        </div>
      </Form>
    </Formik>
  );
};
