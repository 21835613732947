import { useParams } from 'react-router-dom';
import { MemberDashboard } from '../../user/profile/MembersDashboard';
import { useOrganization } from '../../../../hooks/useOrganization';
import { SimpleLoading } from '../../../../components/loading/SimpleLoading';
import { Button } from '../../../../components/Button';
import { UpdateOrganizationModal } from '../../../../components/modal/update-organization-modal/UpdateOrganizationModal';
import { IOrganizationUpdate } from '../../../../components/modal/update-organization-modal/interface/IOrganizationUpdate';
import { IOrganizationProfile } from '../../../../services/hedgehog/interfaces/IOrganizationProfile';
import {
  updateOrganization,
  updateOrganizationProfile,
} from '../../../../services/hedgehog/hedgehog.api';
import { useModal } from '../../../../hooks/useModal';
import { Toast } from '../../../../services/toastify/Toastify';
import { UNEXPECTED_ERROR_MESSAGE } from '../../../../error/errorMessages.constants';
import useLoading from '../../../../hooks/useLoading';

export const OrganizationDashboardProfile = () => {
  const ORGANIZATION_UPDATE_MODAL_ID = 'organization-update';
  const { id } = useParams();

  const organizationId = Number(id);

  const { organization, refetch } = useOrganization(organizationId);
  const [isLoading, waitFor] = useLoading();

  const { openModal, closeModal } = useModal();
  const handleUpdateOrganization = () => {
    if (!organization) return;

    const updateOrganizationInfo = async (
      organizationUpdate: IOrganizationUpdate,
      organizationProfile: IOrganizationProfile,
    ) => {
      try {
        await updateOrganization(organizationId, organizationUpdate);

        await updateOrganizationProfile(organizationId, organizationProfile);

        refetch();
        Toast.success('Organization updated successfully');
        closeModal(ORGANIZATION_UPDATE_MODAL_ID);
      } catch (error) {
        if (error instanceof Error) Toast.error(error.message);
        else Toast.error(UNEXPECTED_ERROR_MESSAGE);
      }
    };

    openModal(
      ORGANIZATION_UPDATE_MODAL_ID,
      <UpdateOrganizationModal
        close={() => closeModal(ORGANIZATION_UPDATE_MODAL_ID)}
        onSubmit={(organizationUpdate, organizationProfile) =>
          waitFor(
            updateOrganizationInfo(organizationUpdate, organizationProfile),
          )
        }
        organization={organization}
      />,
    );
  };
  return (
    <div className="flex flex-col gap-2">
      {isLoading && (
        <SimpleLoading
          loadingColor="text-primary-cyan-500"
          loadingSize="h-10 w-10"
          externalStyles="flex flex-wrap items-center justify-center p-2"
        />
      )}
      {organization && (
        <>
          <div className="flex flex-col">
            <h3 className="text-2xl font-bold text-center">
              {`${organization.name} Profile`}
            </h3>
            <div className="flex w-full justify-end">
              <Button
                color="cyan"
                variant="solid"
                className="self-end"
                data-cy="update-organization-button"
                onClick={handleUpdateOrganization}
              >
                Update organization
              </Button>
            </div>
          </div>
          {organization && <MemberDashboard organizationId={organization.id} />}
        </>
      )}
    </div>
  );
};
