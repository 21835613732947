export enum IrsCurrentNonCurrentColHeaders {
  MaturityDate = 'Maturity Date',
  Currency = 'Currency',
  NotionalAmount = 'Notional Amount',
  FixedRate = 'Fixed Rate',
  SwapType = 'Swap Type',
  FloatingRate = 'Floating Rate',
  Frequency = 'Frequency',
  Valuation = 'Valuation',
  Counterparty = 'Counterparty',
  Reference = 'Reference',
  CurrentAsset = 'Current Asset',
  NonCurrentAsset = 'Non-current Asset',
  CurrentLiability = 'Current Liability',
  NonCurrentLiability = 'Non-current Liability',
  CurrentNet = 'Current Net',
  NonCurrentNet = 'Non-current Net',
}

export const IRSCNCHeadersWithTotal = [
  IrsCurrentNonCurrentColHeaders.NotionalAmount,
  IrsCurrentNonCurrentColHeaders.CurrentAsset,
  IrsCurrentNonCurrentColHeaders.NonCurrentAsset,
  IrsCurrentNonCurrentColHeaders.CurrentLiability,
  IrsCurrentNonCurrentColHeaders.NonCurrentLiability,
  IrsCurrentNonCurrentColHeaders.CurrentNet,
  IrsCurrentNonCurrentColHeaders.NonCurrentNet,
];

export enum IrsCurrentNonCurrentRowHeaders {
  Total = 'Total',
}

export const headerMapping = (
  header: IrsCurrentNonCurrentColHeaders | IrsCurrentNonCurrentRowHeaders,
) => {
  if (header === IrsCurrentNonCurrentColHeaders.Currency) {
    return 'Ccy';
  }
  return header;
};
