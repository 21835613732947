import React from 'react';
import { AuthGuard } from './AuthGuard';
import { useUser } from '../../hooks/useUser';
import { Page } from '../Page';

export const AdminAuthGuard = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUser();

  const isAdmin = user?.role === 'admin';

  return (
    <AuthGuard>
      {isAdmin ? (
        children
      ) : (
        <Page>
          <h1 data-cy="unauthorized-page">Unauthorized</h1>
        </Page>
      )}
    </AuthGuard>
  );
};

export default AdminAuthGuard;
