import { useQueryClient } from 'react-query';
import { ApiQueryKeys } from '../../../services/hedgehog/api-query-keys.enum';
import {
  deleteReport,
  reviewReport,
} from '../../../services/hedgehog/hedgehog.api';
import { Toast } from '../../../services/toastify/Toastify';
import { handleDownloadFile } from '../../../utils/handleDownloadFile';
import { useConfirm } from '../../../hooks/useConfirm';
import { useModal } from '../../../hooks/useModal';
import { OutputReportTypes } from '../../../services/hedgehog/types/OutputReportTypes';
import {
  OutputGetFileByIdRoutes,
  OutputGetByIdRoutes,
} from '../../../services/hedgehog/types/OutputReportRoutes';
import {
  IReport,
  IReportByMonth,
} from '../../../services/hedgehog/interfaces/IReportByMonth';
import { IReportingPeriods } from '../../../services/hedgehog/interfaces/IReportingPeriods';
import { IReportingPeriodsQuery } from '../reporting-periods/interface/IReportingPeriodsQuery';
import { ViewInputsModal } from '../../modal/view-inputs-modal/ViewInputsModal';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { UserRole } from '../../../services/hedgehog/enum/UserRole.enum';
import { LocalStorageKey } from '../../../services/hedgehog/local-storage-key.enums';
import { DEFAULT_REPORTING_PERIODS_QUERY } from '../reporting-periods/ReportingPeriods';

interface UseReportActionsParams {
  filters: {
    month: string;
    year: string;
    status: string | undefined;
    customFilters: Set<OutputReportTypes>;
    organizationId: number;
  };
  userRole: string;
  organizationName: string;
}

export const useReportActions = ({
  filters,
  userRole,
  organizationName,
}: UseReportActionsParams) => {
  const queryClient = useQueryClient();
  const { confirmAction } = useConfirm();
  const { openModal, closeModal } = useModal();
  const [reportingPeriodsQuery] = useLocalStorage<IReportingPeriodsQuery>(
    `${LocalStorageKey.reportingPeriodsQueryParams} ${
      userRole === UserRole.Admin ? UserRole.Admin : UserRole.User
    }`,
    DEFAULT_REPORTING_PERIODS_QUERY,
  );

  const handleDownloadFileByType = (type: string, id: number, name: string) => {
    switch (type) {
      case OutputReportTypes.IRS_MTM_JOURNAL:
        handleDownloadFile(
          name,
          organizationName,
          id,
          OutputGetFileByIdRoutes['IRS MTM Journal'],
        );
        break;

      case OutputReportTypes.IRS_HEDGE_EFFECTIVENESS_REPORT:
        handleDownloadFile(
          name,
          organizationName,
          id,
          OutputGetFileByIdRoutes['IRS Hedge Effectiveness Report'],
        );
        break;

      default:
        handleDownloadFile(
          name,
          organizationName,
          id,
          OutputGetFileByIdRoutes['Output Report'],
        );
        break;
    }
  };

  const handleViewReport = (type: string) => {
    switch (type) {
      case OutputReportTypes.IRS_MTM_JOURNAL:
        return OutputGetByIdRoutes['IRS MTM Journal'];
      case OutputReportTypes.IRS_HEDGE_EFFECTIVENESS_REPORT:
        return OutputGetByIdRoutes['IRS Hedge Effectiveness Report'];
      default:
        return OutputGetByIdRoutes['Output Report'];
    }
  };

  const handleDeleteReport = async (report: IReport) => {
    const allReportsKey = [
      ApiQueryKeys.organization_all_reports_by_month_and_year,
      filters.month,
      filters.year,
      filters.organizationId,
      filters.status,
      [...filters.customFilters],
    ];
    const reportingPeriodsKey = [
      ApiQueryKeys.organization_reporting_periods_by_id,
      filters.organizationId,
      reportingPeriodsQuery.fromMonth,
      reportingPeriodsQuery.toMonth,
      reportingPeriodsQuery.year,
    ];

    const action = async () => {
      try {
        await deleteReport(report);
        Toast.success('Report deleted successfully');

        const reports = queryClient.getQueryData<IReportByMonth>(allReportsKey);
        if (reports) {
          queryClient.setQueryData(allReportsKey, {
            ...reports,
            allReports: reports.allReports.filter(
              (oldReport) => oldReport.id !== report.id,
            ),
          });
        }

        const reportingPeriods =
          queryClient.getQueryData<IReportingPeriods[]>(reportingPeriodsKey);
        if (reportingPeriods) {
          const updatedReportingPeriods = reportingPeriods.map((period) => ({
            ...period,
            reports: period.reports.filter(
              (oldReport) => oldReport.id !== report.id,
            ),
          }));

          queryClient.setQueryData(
            reportingPeriodsKey,
            updatedReportingPeriods,
          );
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          Toast.error(e.message);
        }
      }
    };

    let message = `Delete ${report.name}?`;

    if (report.type === OutputReportTypes.IRS_MTM_VALUATION_REPORT) {
      message =
        'All hedge documentation associated with this report will be deleted';
    }

    confirmAction(action, message);
  };

  const handleReview = async (report: IReport) => {
    const action = async () => {
      try {
        await reviewReport(report);
        Toast.success('Report reviewed successfully');
        queryClient.refetchQueries(
          ApiQueryKeys.organization_all_reports_by_month_and_year,
        );
      } catch (err: unknown) {
        if (err instanceof Error) {
          Toast.error(err.message);
        }
      }
    };
    confirmAction(
      action,
      `Set ${report.name} as reviewed? This action will send an email with the report file to the organization members`,
    );
  };

  const handleViewInputsModal = (outputId: number) => {
    openModal(
      'view-inputs-modal',
      <ViewInputsModal
        outputdId={outputId}
        close={() => closeModal('view-inputs-modal')}
        organizationName={organizationName}
      />,
    );
  };

  return {
    handleDownloadFileByType,
    handleViewReport,
    handleDeleteReport,
    handleReview,
    handleViewInputsModal,
  };
};
