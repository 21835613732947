import { useQuery } from 'react-query';
import { FXBarChart } from '../../../../components/charts/FXBarChart';
import { FXPieChart } from '../../../../components/charts/FXPieChart';
import { IRSComposedGraph } from '../../../../components/charts/IRSComposedGraph';
import { IRSPieChart } from '../../../../components/charts/IRSPieChart';
import { useUserDashboardContext } from '../../../../hooks/useDashboardContext';
import { ExposureType } from '../../../../services/hedgehog/enum/ExposureType.enum';
import { getAllReportByOrganization } from '../../../../services/hedgehog/hedgehog.api';
import { IReportByMonth } from '../../../../services/hedgehog/interfaces/IReportByMonth';
import { ApiQueryKeys } from '../../../../services/hedgehog/api-query-keys.enum';
import { useOrganization } from '../../../../hooks/useOrganization';
import { GridCountCards } from '../../../../components/cards/GridCountCard';

export const UserDashboardHome = () => {
  const { currentOrganization } = useUserDashboardContext();
  const { organization } = useOrganization(currentOrganization?.id);
  const { data: reports, isLoading: isLoadingReports } =
    useQuery<IReportByMonth>(
      [ApiQueryKeys.organization_all_reports, currentOrganization.id],
      () => getAllReportByOrganization(currentOrganization.id),
    );

  return (
    <>
      <div className="flex pb-2 md:pb-4">
        <p className="text-base font-bold text-left text-primary-dark-500">
          {`${currentOrganization.name}`}
        </p>
        <span className="px-1"> - </span>
        <p className="text-base text-left text-zinc-500">Overview</p>
      </div>
      <div className="pb-4 md:pb-6">
        {organization && (
          <GridCountCards
            isLoadingReports={isLoadingReports}
            reports={reports}
            organization={organization}
          />
        )}
      </div>
      <div className="grid gap-4 md:gap-6 grid-cols-1 xl:grid-cols-12 h-full">
        {currentOrganization?.exposureType === ExposureType.IRS_ONLY && (
          <>
            <div className="col-span-12 xl:col-span-8 w-full h-full rounded-[10px] bg-white border border-zinc-200 p-6">
              <p className="text-base font-bold text-left text-primary-dark-500">
                Total FV - IRS Trades
              </p>
              <IRSComposedGraph organizationId={currentOrganization.id} />
            </div>

            <div className="col-span-12 xl:col-span-4 w-full h-full rounded-[10px] bg-white border border-zinc-200 p-6">
              <IRSPieChart organizationId={currentOrganization.id} />
            </div>
          </>
        )}
        {currentOrganization?.exposureType === ExposureType.FX_ONLY && (
          <>
            <div className="col-span-12 xl:col-span-8 w-full h-full rounded-[10px] bg-white border border-zinc-200 p-6">
              <FXBarChart organizationId={currentOrganization.id} />
            </div>

            <div className="col-span-12 xl:col-span-4 w-full h-full rounded-[10px] bg-white border border-zinc-200 p-6">
              <FXPieChart organizationId={currentOrganization.id} />
            </div>
          </>
        )}

        {currentOrganization?.exposureType === ExposureType.BOTH && (
          <>
            <div className="col-span-12 xl:col-span-8 w-full h-full rounded-[10px] bg-white border border-zinc-200 p-6">
              <p className="text-base font-bold text-left text-primary-dark-500">
                Total FV - IRS Trades
              </p>
              <IRSComposedGraph organizationId={currentOrganization.id} />
            </div>

            <div className="col-span-12 xl:col-span-4 w-full h-full rounded-[10px] bg-white border border-zinc-200 p-6">
              <IRSPieChart organizationId={currentOrganization.id} />
            </div>

            <div className="col-span-12 xl:col-span-8 w-full h-full rounded-[10px] bg-white border border-zinc-200 p-6">
              <FXBarChart organizationId={currentOrganization.id} />
            </div>

            <div className="col-span-12 xl:col-span-4 w-full h-full rounded-[10px] bg-white border border-zinc-200 p-6">
              <FXPieChart organizationId={currentOrganization.id} />
            </div>
          </>
        )}
      </div>
    </>
  );
};
