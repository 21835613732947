import {
  ResponsiveContainer,
  ComposedChart,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Area,
  CartesianGrid,
  XAxis,
} from 'recharts';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ApiQueryKeys } from '../../services/hedgehog/api-query-keys.enum';
import { getOrganizationIrsTrades } from '../../services/hedgehog/hedgehog.api';
import { SimpleLoading } from '../loading/SimpleLoading';
import moment from 'moment';
import { defaultColors } from './default-colors';
import { convertData, formatTick } from './utils';

export const IRSComposedGraph = ({
  className = 'w-full h-80',
  organizationId,
}: {
  className?: string;
  organizationId: number;
}) => {
  const [dateRange, setDateRange] = useState<number>(5);
  const [xAxisLabels, setXAxisLabels] = useState<string[]>([]);
  const [query, setQuery] = useState<{ from: Date; to: Date }>(() => ({
    from: moment(new Date())
      .startOf('month')
      .subtract(dateRange, 'month')
      .toDate(),
    to: new Date(),
  }));
  const { data, isLoading, isError } = useQuery(
    [ApiQueryKeys.irs_line_chart_data, query],
    () =>
      getOrganizationIrsTrades(organizationId, {
        entriesFrom: query.from,
        entriesTo: query.to,
      }),
  );
  const [chartData, setChartData] = useState<Record<string, any>[] | null>(
    null,
  );

  useEffect(() => {
    if (data && !isLoading && !isError) {
      setChartData(convertData(data.trades, xAxisLabels));
    }
  }, [data, isLoading, isError, xAxisLabels]);

  useEffect(() => {
    const labels: string[] = [];
    let currentDate = moment(query.to).endOf('month');

    for (let i = 0; i <= dateRange; i++) {
      labels.push(currentDate.format('MMMM'));
      currentDate = currentDate.subtract(1, 'month');
    }

    setXAxisLabels([...labels].reverse());
  }, [dateRange, query]);

  const handleDateRangeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedRange = parseInt(event.target.value);
    setDateRange(selectedRange);
    setQuery({
      from: moment(new Date())
        .startOf('month')
        .subtract(selectedRange, 'month')
        .toDate(),
      to: new Date(),
    });
  };

  if (isLoading || !chartData) {
    return (
      <div className=" flex flex-wrap gap-2 items-center justify-center">
        <SimpleLoading
          loadingColor="text-primary-cyan-500"
          loadingSize="h-10 w-10"
          externalStyles="flex flex-wrap items-center justify-center p-2 w-80 h-80"
        />
      </div>
    );
  }

  if (isError) {
    return <div>Error loading data.</div>;
  }

  return (
    <div className={className}>
      <div className="flex flex-wrap items-center justify-center w-full">
        <div className="w-full flex flex-row gap-2 items-center justify-end">
          <label className="font-medium" htmlFor="date-range">
            Select Date Range:
          </label>
          <select
            data-cy="irs-line-chart-date-range"
            id="date-range"
            value={dateRange}
            onChange={handleDateRangeChange}
            className="cursor-pointer"
          >
            <option value={5}>6 Months</option>
            <option value={6}>7 Months</option>
            <option value={7}>8 Months</option>
            <option value={8}>9 Months</option>
            <option value={9}>10 Months</option>
            <option value={10}>11 Months</option>
            <option value={11}>12 Months</option>
          </select>
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={chartData}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={defaultColors.cyan500.opaque}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={defaultColors.cyan500.opaque}
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={defaultColors.purple500.opaque}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={defaultColors.purple500.opaque}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <YAxis
            yAxisId="left"
            tick={{ fontSize: 10, fill: defaultColors.blue400.opaque }}
            tickFormatter={formatTick}
            label={{
              value: 'AUD',
              position: 'insideLeft',
              fontSize: 12,
              angle: -90,
            }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            tick={{ fontSize: 10 }}
            tickFormatter={formatTick}
            label={{
              value: 'Movements',
              position: 'insideRight',
              fontSize: 12,
              angle: -90,
            }}
          />
          <XAxis dataKey="monthName" tick={{ fontSize: 10 }} />
          <Tooltip formatter={(value) => `${value.toLocaleString()}`} />
          <Legend
            payload={[
              {
                value: 'FV of Portfolio on B/S',
                type: 'rect',
                color: defaultColors.blue400.opaque,
              },
              {
                value: 'P&L Movements if FVTPL',
                type: 'line',
                color: defaultColors.cyan500.opaque,
              },
              {
                value: 'P&L Movements of CFH',
                type: 'line',
                color: defaultColors.purple500.opaque,
              },
            ]}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Area
            yAxisId={'right'}
            dataKey="pnlVolatilityWithoutHedgeAccounting"
            name="P&L Movements if FVTPL"
            stroke={defaultColors.cyan500.opaque}
            fillOpacity={1}
            fill="url(#colorUv)"
          />
          <Area
            yAxisId={'right'}
            type="monotone"
            dataKey="pnlActualMovement"
            name="P&L Movements of CFH"
            fill="url(#colorPv)"
            stroke={defaultColors.purple500.opaque}
          />
          <Bar
            yAxisId={'left'}
            dataKey="cleanFairValue"
            name="FV of Portfolio on B/S"
            fill={defaultColors.blue400.opaque}
            barSize={16}
            stroke={defaultColors.blue400.opaque}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
