import useLoading from '../../../hooks/useLoading';
import { Button } from '../../Button';
import { Modal } from '../Modal';
import { SimpleLoadingModal } from '../SimpleLoadingModal';

type Props = {
  text?: string;
  title?: string;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
};

const ConfirmModal = ({
  title = 'Confirm',
  text = 'Are you sure?',
  onConfirm,
  onCancel,
}: Props) => {
  const [loading, waitFor] = useLoading();
  const handleConfirm = async () => {
    await onConfirm();
    onCancel();
  };

  return (
    <>
      <Modal>
        <div className="flex flex-col justify-center items-center min-[320px]:w-80 md:w-96 text-wrap relative overflow-y-auto z-10 border p-4 bg-white border-gray-200 shadow-md rounded-lg">
          <div className="w-full my-2">
            <p className="font-medium text-center text-xl text-gray-900 my-4">
              {title}
            </p>
            <p className="text-base text-center  text-gray-900 my-4">{text}</p>
          </div>
          <div className="w-full flex flex-row justify-between mb-2">
            <Button
              data-cy="confirm-modal-confirm-button"
              variant={loading ? 'outline' : 'solid'}
              disabled={loading}
              onClick={() => waitFor(handleConfirm())}
            >
              Confirm
            </Button>
            <Button
              data-cy="confirm-modal-cancel-button"
              disabled={loading}
              variant="outline"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      {loading && <SimpleLoadingModal />}
    </>
  );
};

export default ConfirmModal;
