import { Button } from '../../../../components/Button';
import { SimpleLoading } from '../../../../components/loading/SimpleLoading';
import { AddOrganizationModal } from '../../../../components/modal/add-organization-modal/AddOrganizationModal';
import { useModal } from '../../../../hooks/useModal';
import { usePaginatedQuery } from '../../../../hooks/usePaginatedQuery';
import { ApiQueryKeys } from '../../../../services/hedgehog/api-query-keys.enum';
import { getOrganizations } from '../../../../services/hedgehog/hedgehog.api';
import { ClientsTable } from './ClientsTable';
import { FilterOrganizations } from './FilterOrganizations';
import { useArchiveOrganization } from './hooks/useArchiveOrganization';
import { PaginationButtons } from './PaginationButtons';

export const AdminHomeDashboard = () => {
  const { openModal, closeModal } = useModal();
  const {
    handleArchiveOrganization,
    handleUnarchiveOrganization,
    handleDeleteOrganization,
  } = useArchiveOrganization();
  const INITAL_QUERY = { limit: 10, offset: 0 };

  const {
    data,
    isLoading,
    refetch: refetchOrganizations,
    nextPage,
    prevPage,
    currentPage,
    changeQuery,
    query,
  } = usePaginatedQuery(
    [ApiQueryKeys.organizations],
    { ...INITAL_QUERY, is_active: true },
    getOrganizations,
  );

  const handleAddOrganizationModal = () => {
    openModal(
      'add-organization',
      <AddOrganizationModal
        close={() => closeModal('add-organization')}
        onSubmit={refetchOrganizations}
      />,
    );
  };

  return (
    <>
      <div className="flex relative flex-wrap h-full w-full py-4 mb-2">
        <div className="flex flex-wrap w-[100%]">
          <div className="flex flex-1 items-start justify-start">
            <Button
              variant="solid"
              color="cyan"
              data-cy="add-organization-button"
              onClick={handleAddOrganizationModal}
            >
              Add new organization
            </Button>
          </div>
          <h3 className="flex-grow text-2xl font-bold text-center hidden lg:block">
            Active Clients
          </h3>
          <FilterOrganizations
            onQueryChange={(newQuery) =>
              changeQuery({ ...INITAL_QUERY, ...newQuery })
            }
          />
        </div>
      </div>
      <div className="flex flex-col h-full w-full">
        <div className="relative z-0 overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
          {isLoading && (
            <SimpleLoading
              loadingColor="text-primary-cyan-500"
              loadingSize="h-10 w-10"
              externalStyles="flex flex-wrap items-center justify-center p-2"
            />
          )}
          {data?.organizations && (
            <ClientsTable
              organizations={data.organizations}
              showUnarchiveActions={query.is_active}
              handleArchiveOrganization={handleArchiveOrganization}
              handleUnarchiveOrganization={handleUnarchiveOrganization}
              handleDeleteOrganization={handleDeleteOrganization}
            />
          )}
        </div>
        <PaginationButtons
          isLoading={isLoading}
          currentPage={currentPage}
          amountOfItems={data?.count || 0}
          nextPage={nextPage}
          prevPage={prevPage}
        />
      </div>
    </>
  );
};
