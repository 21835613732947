import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { signup } from '../../services/hedgehog/user.api';
import { FormField } from '../form-field/FormField';
import { ComponentProps } from 'react';
import { Toast } from '../../services/toastify/Toastify';
import { signupValidationSchema } from './signupForm.schema';
import { UNEXPECTED_ERROR_MESSAGE } from '../../error/errorMessages.constants';
import useLoading from '../../hooks/useLoading';
import { Button } from '../Button';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  repeatPassword: '',
};
const formFields: ComponentProps<typeof FormField>[] = [
  {
    label: 'First Name',
    id: 'firstName',
    name: 'firstName',
    type: 'text',
    placeholder: 'Enter your first name',
  },
  {
    label: 'Last Name',
    id: 'lastName',
    name: 'lastName',
    type: 'text',
    placeholder: 'Enter your last name',
  },
  {
    label: 'Email',
    id: 'email',
    name: 'email',
    type: 'email',
    placeholder: 'Enter your email',
  },
  {
    label: 'Password',
    id: 'password',
    name: 'password',
    type: 'password',
    placeholder: 'Enter your password',
  },
  {
    label: 'Repeat Password',
    id: 'repeatPassword',
    name: 'repeatPassword',
    type: 'password',
    placeholder: 'Repeat your password',
  },
];

export const SignupForm = () => {
  const [isLoading, execute] = useLoading();
  const navigate = useNavigate();

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      await signup(
        values.firstName,
        values.lastName,
        values.email,
        values.password,
      );
      Toast.success('User created successfully');
      navigate('/');
    } catch (e: unknown) {
      if (e instanceof Error) Toast.error(e.message);
      else Toast.error(UNEXPECTED_ERROR_MESSAGE);
      console.log(e);
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={signupValidationSchema}
      onSubmit={(values) => execute(handleSubmit(values))}
    >
      <Form>
        {formFields.map((field) => (
          <FormField key={field.id} {...field} />
        ))}
        <div className="flex items-center justify-between">
          <Button
            data-cy="signup-form-button"
            type="submit"
            className="bg-blue-500 hover:bg-blue-700"
          >
            Sign up
          </Button>
        </div>
      </Form>
    </Formik>
  );
};
