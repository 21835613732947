export enum ReportingPeriodsReviewedOption {
  All = 'All',
  Reviewed = 'Reviewed',
  Unreviewed = 'Unreviewed',
}

export enum ReportingPeriodsActiveOption {
  Yes = 'Yes',
  No = 'No',
}
