import { useEffect, useState } from 'react';
import HotTable from '@handsontable/react';
import { useLoadWorkbook } from '../../../hooks/useLoadWorkbook';
import {
  customRenderer,
  irsHedgeEffectivenessMergueCells,
} from './hedgeEffectivenessSheetConfig';
import { useLoadBasicSheet } from '../../../hooks/useLoadBasicSheet';
import { getCellProperties, insertEmptyRowAfter } from '../common/utils';
import { CellValue } from 'handsontable/common';

interface IHedgeEffectivenessViewTableProps {
  report: Blob;
}

export enum HedgeEffectivenessReportSheetName {
  HedgeEffectivenessReportSheetName = 'Hedge Effectiveness',
}

const headers = [
  /^Curve Date$/,
  /^Clean Fair Value$/,
  /^Periodic Chg$/,
  /^Cuml Chg$/,
  /^Clean Fair Value$/,
  /^Periodic Chg$/,
  /^Cuml Chg$/,
  /^Lesser of Cuml Chg$/,
  /^Ratio$/,
  /^Highly Effective$/,
  /^PnL Dr\/\(Cr\)$/,
  /^OCI Dr\/\(Cr\)$/,
];

export const ViewTHedgeEffectivenessReportTable = ({
  report,
}: IHedgeEffectivenessViewTableProps) => {
  const { workbook } = useLoadWorkbook(
    report,
    'Error loading Hedge Effectiveness Report',
  );

  const [selectedSheet] = useState<HedgeEffectivenessReportSheetName>(
    HedgeEffectivenessReportSheetName.HedgeEffectivenessReportSheetName,
  );

  const { sheet: originalSheet } = useLoadBasicSheet(workbook, selectedSheet);
  const [sheet, setSheet] = useState<CellValue[][]>([]);

  useEffect(() => {
    if (!originalSheet) return;

    const updatedSheet = insertEmptyRowAfter(originalSheet, '-');
    updatedSheet.splice(11, 1);
    updatedSheet.splice(12, 1);

    setSheet(updatedSheet);
  }, [originalSheet]);

  return (
    <div className="w-full h-full">
      {sheet.length > 0 && (
        <HotTable
          readOnly={true}
          key={'hedge effectiveness report'}
          data={sheet}
          rowHeaders={false}
          colHeaders={false}
          width="auto"
          height="auto"
          licenseKey="non-commercial-and-evaluation"
          rowHeights={40}
          colWidths={(column) => (column === 0 ? 200 : 150)}
          manualColumnResize={true}
          manualRowResize={true}
          wordWrap={true}
          allowEmpty={true}
          fixedRowsTop={6}
          mergeCells={irsHedgeEffectivenessMergueCells(sheet)}
          afterRenderer={customRenderer}
          cells={(row, col) => getCellProperties(sheet, headers, row, col)}
        />
      )}
    </div>
  );
};
