import { Link } from 'react-router-dom';

interface INavLinkProps {
  link: string[][];
}

export const NavLinks = ({ link }: INavLinkProps) => {
  return (
    <>
      {link.map(([label, href]) => (
        <Link
          key={label}
          to={href}
          className="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-md text-gray-700 transition-colors hover:text-gray-900 hover:bg-gray-100 ease-in duration-200"
        >
          <span className="relative z-10">{label}</span>
        </Link>
      ))}
    </>
  );
};
