import {
  confirmNewPassword,
  passwordRecovery,
} from '../../services/cognito/cognito';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmailForm } from '../email-form/EmailForm';
import { ConfirmationForm } from './confirmation-form/ConfirmationForm';
import { Toast } from '../../services/toastify/Toastify';
import { UNEXPECTED_ERROR_MESSAGE } from '../../error/errorMessages.constants';
import useLoading from '../../hooks/useLoading';

export const PasswordRecoveryForm = () => {
  const [isLoading, execute] = useLoading();

  const [email, setEmail] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const navigate = useNavigate();

  const handleSendCode = async (values: { email: string }) => {
    try {
      passwordRecovery(values.email);
      setCodeSent(true);
      setEmail(values.email);
      Toast.success('Code sent to your email !!!');
    } catch (e: unknown) {
      if (e instanceof Error) Toast.error(e.message);
      else Toast.error(UNEXPECTED_ERROR_MESSAGE);
      console.log(e);
    }
  };

  const handleConfirm = async (values: {
    code: string;
    'new-password': string;
  }) => {
    try {
      await confirmNewPassword(values['new-password'], values.code, email);
      Toast.success('Successfully confirmed new password');
      navigate('/');
    } catch (e: unknown) {
      if (e instanceof Error) Toast.error(e.message);
      else Toast.error(UNEXPECTED_ERROR_MESSAGE);
      console.log(e);
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (codeSent) {
    return (
      <ConfirmationForm
        onSubmit={(values) => execute(handleConfirm(values))}
      ></ConfirmationForm>
    );
  }
  return (
    <EmailForm
      buttonMessage="Send code"
      dataCy="send-code-form-button"
      onSubmit={(values) => execute(handleSendCode(values))}
    ></EmailForm>
  );
};
