import { CellValue } from 'hyperformula';
import { Schema } from 'joi';

import { FxMaturedDealsReportHeadersSchema } from './fxMaturedDeals.schema';
import { Cell } from '../../ProcessableReport';
import {
  fxMaturedDealsReportHeaderMapping,
  FxMaturedDealsReportHedgingHeader,
  FxMaturedDealsReportInputsRowHeader,
  FxMaturedDealsReportOutputColHeaders,
  FxMaturedDealsReportTotalColHeaders,
} from './fxMaturedDealsHeaders';
import { ProcessableInputReport } from '../../ProcessableInputReport';
import { FxMaturedDealsReportRequiredInputsType } from './fxMaturedDealsInputs';
import { InputReportType } from '../../inputReportType';

export class FxMaturedDealsReportValidator {
  colHeaders: FxMaturedDealsReportOutputColHeaders[] = Object.values(
    FxMaturedDealsReportOutputColHeaders,
  );

  colWithTotalHeaders: FxMaturedDealsReportTotalColHeaders[] = Object.values(
    FxMaturedDealsReportTotalColHeaders,
  );
  rowHeaders: FxMaturedDealsReportInputsRowHeader[] = Object.values(
    FxMaturedDealsReportInputsRowHeader,
  );

  constructor(private processableInput: ProcessableInputReport) {}

  validate() {
    if (
      this.processableInput.sheetName !==
      InputReportType.HB_FX_MATURED_DEALS_REPORT
    ) {
      throw new Error(
        `Input report type ${FxMaturedDealsReportRequiredInputsType.HB_FX_MATURED_DEALS_REPORT} is wrong type for this output`,
      );
    }
    const eachDealDate = this.processableInput.findCellValues(
      FxMaturedDealsReportOutputColHeaders.DealDate,
    );

    const eachTotalRow = this.processableInput.findCellValues(
      FxMaturedDealsReportInputsRowHeader.TOTAL,
    );

    const eachHedgingPosition = this.processableInput.findCellValues(
      (cellValue: CellValue) => {
        return (
          typeof cellValue === 'string' &&
          cellValue.includes(FxMaturedDealsReportHedgingHeader.HEDGING)
        );
      },
    );

    this.checkCondition(
      eachDealDate.length === 0,
      `Missing Deal Date Header ${this.processableInput.sheetName}`,
    );
    this.checkCondition(
      eachTotalRow.length === 0,
      `Missing Total Row in ${this.processableInput.sheetName}`,
    );
    this.checkCondition(
      eachHedgingPosition.length === 0,
      `No hedging headers found in ${this.processableInput.sheetName}. Please ensure that each Deal Date has a corresponding hedging position following the format "AUD/NZD Hedging".`,
    );
    this.checkCondition(
      eachDealDate.length !== eachTotalRow.length,
      `There must be a Total row for each Deal Date row in ${this.processableInput.sheetName}`,
    );
    this.checkCondition(
      eachDealDate.length !== eachHedgingPosition.length,
      `There must be a hedging position corresponding to each deal date header in ${this.processableInput.sheetName}. Each hedging position should follow the format "AUD/NZD Hedging".`,
    );

    this.validateValuesUnderHeader(
      eachDealDate,
      eachTotalRow,
      eachHedgingPosition,
    );
    this.validateValuesInTotalRow(
      eachDealDate,
      eachTotalRow,
      eachHedgingPosition,
    );

    this.validateReportingDateInFooter();
    this.validateReportRunInFooter();
  }

  private validateValuesUnderHeader(
    eachDealDate: Cell[],
    eachTotalRow: Cell[],
    eachHedgingPosition: Cell[],
  ) {
    eachHedgingPosition.forEach((hedgingPosition, index) => {
      const dealDate = eachDealDate[index];
      const totalRow = eachTotalRow[index];

      const [firstCurrency, secondCurrency] =
        this.validateHedgingCurrency(hedgingPosition);

      this.colHeaders.forEach((header) => {
        const schema = FxMaturedDealsReportHeadersSchema[header];

        let headerPosition: Cell | null =
          this.processableInput.findCellValueByCondition((cell) => {
            return (
              typeof cell.value === 'string' && cell.value.includes(header)
            );
          });

        if (header === FxMaturedDealsReportOutputColHeaders.FirstVolume) {
          headerPosition = this.validateVolumeHeader(header, firstCurrency);
        }

        if (header === FxMaturedDealsReportOutputColHeaders.SecondVolume) {
          headerPosition = this.validateVolumeHeader(header, secondCurrency);
        }

        const realHeader = fxMaturedDealsReportHeaderMapping(header);

        if (!headerPosition) {
          throw new Error(
            `Missing ${realHeader} in ${this.processableInput.sheetName}`,
          );
        }

        this.validateHeaderAndContent(
          realHeader,
          headerPosition,
          dealDate,
          totalRow,
          schema,
        );
      });
    });
  }
  private validateHedgingCurrency(hedgingPosition: Cell): string[] {
    return hedgingPosition.value.match(/[A-Z]{3}/g) || [];
  }

  private validateVolumeHeader(
    header:
      | FxMaturedDealsReportOutputColHeaders
      | FxMaturedDealsReportTotalColHeaders,
    currency: string,
  ) {
    const completeHeader = `${fxMaturedDealsReportHeaderMapping(
      header,
    )} (${currency})`;

    const headerPosition = this.processableInput.findCellValueByCondition(
      (cell) => {
        return (
          typeof cell.value === 'string' && cell.value.includes(completeHeader)
        );
      },
    );

    if (!headerPosition) {
      throw new Error(
        `Missing ${completeHeader} in ${this.processableInput.sheetName}`,
      );
    }

    this.checkMissingColumn(headerPosition, completeHeader);

    return headerPosition;
  }
  private validateHeaderAndContent(
    header: string,
    headerPosition: Cell,
    dealDate: Cell,
    totalRow: Cell,
    schema: Schema,
  ) {
    this.checkMissingColumn(headerPosition, header);

    this.processableInput.getRangeValues(
      { col: headerPosition.col, row: dealDate.row + 1 },
      {
        col: headerPosition.col,
        row: totalRow.row - 1,
      },
      {
        condition: (cell: Cell) => {
          const validationResult = schema.validate(cell.value);
          if (validationResult.error) {
            throw new Error(
              `Validation error: An incorrect value was found under the header "${header}" and the value is "${cell.value}": ${validationResult.error.message}`,
            );
          }
          return true;
        },
      },
    );
  }

  private validateValuesInTotalRow(
    eachDealDate: Cell[],
    eachTotalRow: Cell[],
    eachHedgingPosition: Cell[],
  ) {
    eachDealDate.forEach((_, index) => {
      const hedgingPosition = eachHedgingPosition[index];
      const totalRow = eachTotalRow[index];

      const [firstCurrency, secondCurrency] =
        this.validateHedgingCurrency(hedgingPosition);

      this.colWithTotalHeaders.forEach((header) => {
        let headerPosition = this.processableInput.findCellValueByCondition(
          (cell) => {
            return (
              typeof cell.value === 'string' &&
              cell.value.includes(fxMaturedDealsReportHeaderMapping(header))
            );
          },
        );

        if (header === FxMaturedDealsReportTotalColHeaders.FirstVolume) {
          headerPosition = this.validateVolumeHeader(header, firstCurrency);
        }

        if (header === FxMaturedDealsReportTotalColHeaders.SecondVolume) {
          headerPosition = this.validateVolumeHeader(header, secondCurrency);
        }

        const totalValue = this.processableInput.getCellValue({
          col: headerPosition?.col,
          row: totalRow.row,
        });

        const totalSchema = FxMaturedDealsReportHeadersSchema[header];

        const realHeader = fxMaturedDealsReportHeaderMapping(header);

        const validationResult = totalSchema.validate(totalValue);

        if (validationResult.error) {
          throw new Error(
            `Validation error: An incorrect value was found in the Total row under the header "${realHeader}" and the value is "${totalValue}": ${validationResult.error.message}`,
          );
        }
      });
    });
  }

  private validateReportingDateInFooter() {
    const targetLineRegex = /FX Matured Deals Report/;

    const matchCells = this.processableInput.findCellValues(
      (cellValue: string) => {
        return targetLineRegex.test(cellValue);
      },
    );

    if (matchCells.length === 0) {
      throw new Error(
        'Failed to locate the expected footer line in the FX Matured Deals Report',
      );
    }

    const matchCell = matchCells[matchCells.length - 1];
    const regex = /\((\d{1,2} [A-Za-z]+ \d{2,4})\)/;
    const match = matchCell.value.match(regex);

    if (!match) {
      throw new Error(
        'Invalid reporting date format in footer from FX Matured Deals Report',
      );
    }
  }

  private checkMissingColumn(headerPosition: Cell, header: string) {
    if (!headerPosition) {
      throw new Error(
        `Column "${header}" is missing in ${this.processableInput.sheetName}`,
      );
    }
  }

  private checkCondition(condition: boolean, errorMessage: string) {
    if (condition) {
      throw new Error(errorMessage);
    }
  }

  private validateReportRunInFooter() {
    const targetLinePartialRegex = /Report run from .* to .* for \w+/;

    const matchCell = this.processableInput.findCellValueByCondition(
      (cell: Cell) => {
        if (typeof cell.value === 'string') {
          return targetLinePartialRegex.test(cell.value);
        }
        return false;
      },
    );

    if (!matchCell) {
      throw new Error(
        'Failed to locate the expected currency line in the FX MTM Valuation Report. Ensure that the report includes a line similar to the following example: "Report run as at 30 Nov 2023 for Hedgehog valued in AUD using a mid market spread basis."',
      );
    }
  }
}
