import { Outlet } from 'react-router-dom';
import { DashboardHeader } from '../DashboardHeader';
import { AdminAuthGuard } from '../../../components/guards/AdminAuthGuard';
import { useIncompleteHedgeDocumentationQuery } from '../../../hooks/useIncompleteHedgeDocumentationQuery';

export const AdminLayout = () => {
  useIncompleteHedgeDocumentationQuery({
    refetchOnWindowFocus: false,
  });

  return (
    <AdminAuthGuard>
      <div className="h-screen">
        <div className="flex h-screen overflow-hidden">
          <div
            className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
            data-cy="admin-layout"
          >
            <DashboardHeader />
            <main>
              <div className="mx-auto p-4 md:p-6">
                <Outlet />
              </div>
            </main>
          </div>
        </div>
      </div>
    </AdminAuthGuard>
  );
};
