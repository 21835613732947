export enum ApiQueryKeys {
  userData = 'user-data',
  organizations = 'organizations',
  input_reports = 'input_reports',
  output_reports = 'output_reports',
  hedge_documentation_forms = 'hedge_documentation_forms',
  hedge_documentation_forms_complete = 'hedge_documentation_forms_complete',
  hedge_documentation_forms_incomplete = 'hedge_documentation_forms_incomplete',
  irs_mtm_journal = 'irs_mtm_journal',
  users = 'users',
  admins = 'admins',
  organization_input_reports = 'organization_input_reports',
  organization_outpout_reports = 'organization_outpout_reports',
  organization_members = 'organization_members',
  organization_by_id = 'organization_by_id',
  organization_logo_by_id = 'organization_logo_by_id',
  organization_hedge_documentation_forms = 'organization_hedge_documentation_forms',
  organization_irs_mtm_journal = 'organization_irs_mtm_journal',
  output_report_by_id = 'output_report_by_id',
  output_report_file_by_id = 'output_report_file_by_id',
  hedge_effectiveness_reports = 'hedge_effectiveness_reports',
  organization_hedge_effectiveness_reports = 'organization_hedge_effectiveness_reports',
  organization_reporting_periods_by_id = 'organization_reporting_periods_by_id',
  organization_all_reports_by_month_and_year = 'organization_all_reports_by_month_and_year',
  irs_pie_chart_data = 'irs_pie_chart_data',
  irs_line_chart_data = 'irs_line_chart_data',
  fx_pie_mtm_valuation_chart_data = 'fx_pie_mtm_valuation_chart_data',
  fx_matured_deals_chart_data = 'fx_matured_deals_chart_data',
  organization_all_reports = 'organization_all_reports',
  irs_trades_table = 'irs_trades_table',
  irs_trade_graph = 'irs_trade_graph',
  inputs = 'inputs',
}
