import { forwardRef } from 'react';
import { HedgehogLogo } from '../icons/HedgehogLogo';
import { UserIcon } from '../icons/UserIcon';

export const MobileScreen = ({
  children,
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className={`flex flex-col ${className}`} {...props}>
      <div className="flex justify-between px-4 pt-4">
        <HedgehogLogo
          svgIconStyles="text-white h-8 w-32 flex-none fill-current"
          logoTitleStyles="text-secondary-white fill-current"
          logoIconStyles="text-primary-cyan-500 fill-current"
        />
        <UserIcon className="h-8 w-6 flex-none" />
      </div>
      {children}
    </div>
  );
};

MobileScreen.Header = forwardRef<
  React.ElementRef<'div'>,
  { children: React.ReactNode; className?: string }
>(function AppScreenHeader({ children, className }, ref) {
  return (
    <div ref={ref} className={`mt-6 px-4 text-white ${className}`}>
      {children}
    </div>
  );
});

MobileScreen.Title = forwardRef<
  React.ElementRef<'h3'>,
  { children: React.ReactNode } & React.HTMLProps<HTMLHeadingElement>
>(function AppScreenTitle({ children, ...props }, ref) {
  return (
    <h3 ref={ref} className="text-2xl text-white" {...props}>
      {children}
    </h3>
  );
});

MobileScreen.Subtitle = forwardRef<
  React.ElementRef<'span'>,
  { children: React.ReactNode } & React.HTMLProps<HTMLSpanElement>
>(function AppScreenSubtitle({ children, ...props }, ref) {
  return (
    <span ref={ref} className="text-sm text-gray-500" {...props}>
      {children}
    </span>
  );
});
MobileScreen.Body = forwardRef<
  React.ElementRef<'div'>,
  { className?: string; children: React.ReactNode }
>(function AppScreenBody({ children, className }, ref) {
  return (
    <div
      ref={ref}
      className={`mt-6 flex-auto rounded-t-2xl bg-white ${className}`}
    >
      {children}
    </div>
  );
});
