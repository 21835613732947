import * as Joi from 'joi';

import {
  CURRENCY_VALIDATION_REGEX,
  REFERENCE_VALIDATION_REGEX,
} from '../../helpers/validationRegex';
import { FxDetailedInstrumentReportColHeaders } from './fx-hedge-effectiveness-report.enums';

export const fxHedgeEffectivenessInputsReportHeadersSchema: Record<
  FxDetailedInstrumentReportColHeaders,
  Joi.Schema
> = {
  [FxDetailedInstrumentReportColHeaders.Reference]: Joi.alternatives()
    .try(
      Joi.string()
        .regex(REFERENCE_VALIDATION_REGEX)
        .message(
          'Trade reference can be a number, string and has special characters',
        ),
      Joi.number(),
    )
    .required(),
  [FxDetailedInstrumentReportColHeaders.Comment]: Joi.alternatives()
    .try(Joi.string(), Joi.number())
    .allow(null),
  [FxDetailedInstrumentReportColHeaders.DealNumber]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Deal Number must be a valid number and cannot be null or empty',
      'any.required': 'Deal Number must be provided',
    }),
  [FxDetailedInstrumentReportColHeaders.DealDate]: Joi.date()
    .required()
    .messages({
      'date.base':
        'Deal Date must be a date with format DD/MM/YYYY, DD MMM YY or DD-MM-YYYY, etc.',
      'any.required': 'Deal Date must be provided',
    }),
  [FxDetailedInstrumentReportColHeaders.BaseCurrency]: Joi.string()
    .regex(CURRENCY_VALIDATION_REGEX)
    .required()
    .messages({
      'string.base': 'Base Currency must be a string, not null, and not empty',
      'string.pattern.base':
        'Base Currency must be a three-letter uppercase string',
      'any.required': 'Base Currency must be provided',
    }),
  [FxDetailedInstrumentReportColHeaders.BuyCurrency]: Joi.string()
    .regex(CURRENCY_VALIDATION_REGEX)
    .required()
    .messages({
      'string.base': 'Buy Currency must be a string, not null, and not empty',
      'string.pattern.base':
        'Buy Currency must be a three-letter uppercase string',
      'any.required': 'Buy Currency must be provided',
    }),
  [FxDetailedInstrumentReportColHeaders.BuyAmount]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Buy Amount must be a valid number and cannot be null or empty',
      'any.required': 'Buy Amount must be provided',
    }),
  [FxDetailedInstrumentReportColHeaders.ContractRate]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Contract Rate must be a valid number and cannot be null or empty',
      'any.required': 'Contract Rate must be provided',
    }),
  [FxDetailedInstrumentReportColHeaders.SellCurrency]: Joi.string()
    .regex(CURRENCY_VALIDATION_REGEX)
    .required()
    .messages({
      'string.base': 'Sell Currency must be a string, not null, and not empty',
      'string.pattern.base':
        'Sell Currency must be a three-letter uppercase string',
      'any.required': 'Sell Currency must be provided',
    }),
  [FxDetailedInstrumentReportColHeaders.SellAmount]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Sell Amount must be a valid number and cannot be null or empty',
      'any.required': 'Sell Amount must be provided',
    }),
  [FxDetailedInstrumentReportColHeaders.MaturityDate]: Joi.date()
    .required()
    .messages({
      'date.base':
        'Maturity Date must be a date with format DD/MM/YYYY, DD MMM YY or DD-MM-YYYY, etc',
      'any.required': 'Maturity Date must be provided',
    }),
  [FxDetailedInstrumentReportColHeaders.Counterparty]: Joi.string()
    .required()
    .messages({
      'string.base': 'Counterparty must be a string, not null, and not empty',
      'any.required': 'Counterparty must be provided',
    }),
  [FxDetailedInstrumentReportColHeaders.SpotRateatInception]: Joi.alternatives()
    .try(Joi.string(), Joi.number())
    .allow(null),
  [FxDetailedInstrumentReportColHeaders.Value]: Joi.number()
    .required()
    .messages({
      'number.base': 'Value must be a valid number and cannot be null or empty',
      'any.required': 'Value must be provided',
    }),
  [FxDetailedInstrumentReportColHeaders.DiscountFactor]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Discount Factor must be a valid number and cannot be null or empty',
      'any.required': 'Discount Factor must be provided',
    }),
  [FxDetailedInstrumentReportColHeaders.MarketForwardRate]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Market Forward Rate must be a valid number and cannot be null or empty',
      'any.required': 'Market Forward Rate must be provided',
    }),
  [FxDetailedInstrumentReportColHeaders.SpotRateatValuation]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Spot Rateat Valuation must be a valid number and cannot be null or empty',
      'any.required': 'Spot Rateat Valuation must be provided',
    }),
};
