import { useContext } from 'react';
import { UserDashboardContext } from '../context/UserDashboardProvider';

export const useUserDashboardContext = () => {
  const context = useContext(UserDashboardContext);
  if (!context) {
    throw new Error(
      'useUserDashbardContext must be used within an UserDashboardProvider',
    );
  }
  return context;
};
