interface IHedgehogLogoProps {
  svgIconStyles: string;
  logoTitleStyles: string;
  logoIconStyles: string;
}

export const HedgehogLogo = ({
  svgIconStyles,
  logoTitleStyles,
  logoIconStyles,
}: IHedgehogLogoProps) => {
  return (
    <svg
      id="svg"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      className={svgIconStyles}
      viewBox="0 0 539.47 76.8"
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path
            className={logoTitleStyles}
            d="M63.76,3.2V59.2h-12.8v-22.8H12.8v22.8H0V3.2H12.8V25.2H50.96V3.2h12.8Z"
          />
          <path
            className={logoTitleStyles}
            d="M99.08,51.52c7.6,0,12.4-2,16.96-5.68l6.64,7.28c-6.16,5.44-14.08,7.84-24.24,7.84-18.16,0-28.64-9.12-28.64-23.76s10.4-23.84,27.76-23.84c16,0,26.72,8.16,26.72,23.76,0,1.44-.08,2.16-.32,2.88h-42.24c1.12,7.92,7.12,11.52,17.36,11.52Zm-16.8-19.44l30.16-.16c-1.92-6.32-6.96-9.12-14.8-9.12s-13.6,3.04-15.36,9.28Z"
          />
          <path
            className={logoTitleStyles}
            d="M169.8,22.32V0h12.4V59.2h-12.4v-7.12c-3.6,4.88-10.16,8.8-19.2,8.8-14,0-23.76-9.28-23.76-23.68s9.76-23.68,23.76-23.68c9.04,0,15.6,3.92,19.2,8.8Zm-.08,21.04v-12.32c-3.84-5.04-9.52-7.2-16-7.2-9.6,0-15.04,4.72-15.04,13.36s5.44,13.36,15.04,13.36c6.48,0,12.16-2.16,16-7.2Z"
          />
          <path
            className={logoTitleStyles}
            d="M243.48,52.48c0,18.08-14.16,24.32-27.76,24.32-8,0-17.92-2.08-23.2-5.36l3.68-9.04c4.08,2.64,10.48,4.56,18.16,4.56,10.96,0,16.72-4.08,16.72-12.4v-3.76c-4.08,5.6-11.52,8.72-19.36,8.72-14.4,0-23.6-8.8-23.6-22.96s9.2-23.04,23.6-23.04c8.32,0,15.28,3.12,19.36,8.8v-7.2h12.4V52.48Zm-12.32-10.72v-10.4c-4.16-5.84-9.44-8.16-16-8.16-9.68,0-15.04,4.96-15.04,13.36s5.36,13.36,15.04,13.36c6.56,0,11.84-2.48,16-8.16Z"
          />
          <path
            className={logoTitleStyles}
            d="M278.68,51.52c7.6,0,12.4-2,16.96-5.68l6.64,7.28c-6.16,5.44-14.08,7.84-24.24,7.84-18.16,0-28.64-9.12-28.64-23.76s10.4-23.84,27.76-23.84c16,0,26.72,8.16,26.72,23.76,0,1.44-.08,2.16-.32,2.88h-42.24c1.12,7.92,7.12,11.52,17.36,11.52Zm-16.8-19.44l30.16-.16c-1.92-6.32-6.96-9.12-14.8-9.12s-13.6,3.04-15.36,9.28Z"
          />
          <path
            className={logoTitleStyles}
            d="M360.92,33.68v25.52h-12.4v-24.72c0-7.2-3.84-11.12-11.2-11.12-6.56,0-12,3.04-15.6,9.36v26.48h-12.4V0h12.4V22.88c3.6-5.84,10.72-9.36,19.2-9.36,13.2,0,20,8.4,20,20.16Z"
          />
          <path
            className={logoTitleStyles}
            d="M366.28,37.2c0-14.72,10.72-23.76,28.16-23.76s28.16,9.04,28.16,23.76-10.8,23.76-28.16,23.76-28.16-9.12-28.16-23.76Zm44.4,0c0-9.2-6.88-13.68-16.24-13.68s-16.16,4.48-16.16,13.68,6.88,13.68,16.16,13.68,16.24-4.56,16.24-13.68Z"
          />
          <path
            className={logoTitleStyles}
            d="M481,52.48c0,18.08-14.16,24.32-27.76,24.32-8,0-17.92-2.08-23.2-5.36l3.68-9.04c4.08,2.64,10.48,4.56,18.16,4.56,10.96,0,16.72-4.08,16.72-12.4v-3.76c-4.08,5.6-11.52,8.72-19.36,8.72-14.4,0-23.6-8.8-23.6-22.96s9.2-23.04,23.6-23.04c8.32,0,15.28,3.12,19.36,8.8v-7.2h12.4V52.48Zm-12.32-10.72v-10.4c-4.16-5.84-9.44-8.16-16-8.16-9.68,0-15.04,4.96-15.04,13.36s5.36,13.36,15.04,13.36c6.56,0,11.84-2.48,16-8.16Z"
          />
          <polygon
            className={logoIconStyles}
            points="495 59.2 495 14.73 539.47 14.73 495 59.2"
          />
        </g>
      </g>
    </svg>
  );
};
