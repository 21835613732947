import { IStaticField } from '../../OutputReportTypes';
import * as yup from 'yup';

export const FxMaturedDealsReportStaticData: {
  fields: IStaticField[];
  validationSchema: any;
  instantGenerated?: boolean;
} = {
  fields: [
    {
      label: 'Name',
      name: 'name',
      type: 'string',
      defaultValue: 'FX Matured Deals Report',
    },
  ],
  instantGenerated: true,
  validationSchema: yup.object().shape({
    name: yup.string().required('Name is required'),
  }),
};
