import { ApiQueryKeys } from '../../../../../services/hedgehog/api-query-keys.enum';
import {
  archiveOrganization,
  deleteOrganization,
  unarchiveOrganization,
} from '../../../../../services/hedgehog/hedgehog.api';
import { useMakeAction } from './useMakeAction';

const CONFIRM_ACTION_ARCHIVE_ORGANIZATION_MESSAGE =
  'Are you sure you want to archive this organization?';
const CONFIRM_ACTION_UNARCHIVE_ORGANIZATION_MESSAGE =
  'Are you sure you want to unarchive this organization?';
const CONFIRM_ACTION_DELETE_ORGANIZATION_MESSAGE =
  'Are you sure you want to delete this organization?';

export const useArchiveOrganization = () => {
  const { makeAction } = useMakeAction();

  const handleArchiveOrganization = (organizationId: number) => {
    makeAction({
      id: organizationId,
      queryKey: ApiQueryKeys.organizations,
      confirmationMessage: CONFIRM_ACTION_ARCHIVE_ORGANIZATION_MESSAGE,
      fn: archiveOrganization,
    });
  };

  const handleUnarchiveOrganization = (organizationId: number) => {
    makeAction({
      id: organizationId,
      queryKey: ApiQueryKeys.organizations,
      confirmationMessage: CONFIRM_ACTION_UNARCHIVE_ORGANIZATION_MESSAGE,
      fn: unarchiveOrganization,
    });
  };

  const handleDeleteOrganization = (organizationId: number) => {
    makeAction({
      id: organizationId,
      queryKey: ApiQueryKeys.organizations,
      confirmationMessage: CONFIRM_ACTION_DELETE_ORGANIZATION_MESSAGE,
      fn: deleteOrganization,
    });
  };

  return {
    handleArchiveOrganization,
    handleUnarchiveOrganization,
    handleDeleteOrganization,
  };
};
