import { createContext, useMemo, useState } from 'react';
import { OutputReportTypes } from '../services/hedgehog/types/OutputReportTypes';

interface IFilterContext {
  filters: Set<OutputReportTypes>;
  updateFilters: (newFilters: Set<OutputReportTypes>) => void;
}

export const FilterContext = createContext({} as IFilterContext);

export const FilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [filters, setFilters] = useState<Set<OutputReportTypes>>(new Set());

  const updateFilters = (newFilters: Set<OutputReportTypes>) => {
    setFilters(new Set(newFilters));
  };

  const contextValue = useMemo(() => ({ filters, updateFilters }), [filters]);

  return (
    <FilterContext.Provider value={contextValue}>
      {children}
    </FilterContext.Provider>
  );
};
