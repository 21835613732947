import { ColumnSettings } from 'handsontable/settings';
import { NUMERIC_FORMAT_PATTERN } from '../common/utils';
import { handleGlAccountMergeCell } from './utils/handleGlAccountMergeCell';
import { CellValue } from 'handsontable/common';

export const irsMtmJournalCellsConfig = (sheet: CellValue[][]) => {
  return [
    { row: 0, col: 0, rowspan: 1, colspan: 5 },
    {
      row: 1,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 1,
      col: 2,
      rowspan: 1,
      colspan: 3,
    },
    {
      row: 0,
      col: 5,
      rowspan: 2,
      colspan: 2,
    },
    {
      row: 2,
      col: 2,
      rowspan: 1,
      colspan: 3,
    },
    {
      row: 6,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 10,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 13,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 18,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 21,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    handleGlAccountMergeCell(2),
    handleGlAccountMergeCell(4),
    handleGlAccountMergeCell(5),
    handleGlAccountMergeCell(7),
    handleGlAccountMergeCell(8),
    handleGlAccountMergeCell(9),
    handleGlAccountMergeCell(11),
    handleGlAccountMergeCell(12),
    handleGlAccountMergeCell(14),
    handleGlAccountMergeCell(15),
    handleGlAccountMergeCell(16),
    handleGlAccountMergeCell(17),
    handleGlAccountMergeCell(19),
    handleGlAccountMergeCell(20),
    {
      row: sheet?.length - 2,
      col: 0,
      rowspan: 2,
      colspan: 7,
    },
    {
      row: sheet?.length - 3,
      col: 0,
      rowspan: 1,
      colspan: 7,
    },
  ];
};

export const irsMtmJournalColumnConfig: ColumnSettings[] = [
  { width: 200 },
  { width: 200 },
  { width: 200 },
  { width: 260 },
  { width: 260 },
  {
    width: 150,
    type: 'numeric',
    numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
  },
  {
    width: 150,
    type: 'numeric',
    numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
  },
];
