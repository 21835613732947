export enum MigrationReportHeaders {
  AnalysisPeriod = 'Analysis Period',
  ReportingCcy = 'Reporting Ccy',
  TestType = 'Test Type',
  FairValueChanges = 'Fair Value Changes',
  DesignationDate = 'Designation Date',
  BetaRange = 'Beta Range',
  Tenor = 'Tenor',
  RiskClass = 'Risk Class',
  AnalysisFrequency = 'Analysis Frequency',
  HedgeType = 'Hedge Type',
  ReportingDate = 'Reporting Date',
  CleanFairValue = 'Clean Fair Value',
  PeriodicChg = 'Periodic Chg',
  CumlChg = 'Cuml Chg',
  LesserOfCumlChg = 'Lesser of Cuml Chg',
  Ratio = 'Ratio',
  HighlyEffective = 'Highly Effective',
  PnLDrCr = 'PnL Dr/(Cr)',
  OCIDrCr = 'OCI Dr/(Cr)',
  TradeReference = 'Trade Reference',
  LastReportDate = 'Last Report Date',
  LegalEntity = 'Legal Entity',
  Counterparty = 'Counter Party',
}

export enum MigrationReportOptionalHeaders {
  Reference = 'Reference',
  Efctv = 'Efctv',
  CurveDate = 'Curve Date',
}
