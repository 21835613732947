import { ProcessableInputReport } from '../../ProcessableInputReport';
import { irsPositionSchemas } from './irsPositionSchemas';
import { validateEitherHeadersBySchema } from '../../helpers/headerValidator';
import { irsMtmValuationReportHeaders } from '../cvaReport/irsMtmValuationReportInputHeaders';
import { Cell } from '../../ProcessableReport';
import { validateActualAndHypoReferences } from '../../helpers/actualAndHypoValidation';

export class IrsPositionReportValidator {
  constructor(private processableInput: ProcessableInputReport) {}

  validate() {
    const { irsPositionReportInputsHeaders: headers } =
      irsMtmValuationReportHeaders;

    this.validateLegalEntityName();
    const totalRowValue = this.processableInput.findCellValue('TOTAL');

    if (!totalRowValue) {
      throw new Error(
        `Row "TOTAL" is missing in ${this.processableInput.sheetName}`,
      );
    }

    validateEitherHeadersBySchema(
      headers,
      irsPositionSchemas,
      this.processableInput,
      totalRowValue.row,
    );

    validateActualAndHypoReferences(this.processableInput);
    this.validateReportPeriodInFooter();
    this.validateValuationCurrencyInFooter();
  }

  private validateLegalEntityName() {
    const legalEntityAddress = {
      col: 0,
      row: 0,
    };
    const legalEntityCellValue =
      this.processableInput.getCellValue(legalEntityAddress);
    const expectedType = 'string';

    if (
      legalEntityCellValue === null ||
      legalEntityCellValue.toString().trim() === ''
    ) {
      throw new Error('Legal entity not found at position A1');
    }

    if (typeof legalEntityCellValue !== expectedType) {
      throw new Error(
        `The legal entity must be of type string and located at position A1, but the value '${legalEntityCellValue}' was found in this location.`,
      );
    }
  }

  private validateReportPeriodInFooter() {
    const targetRegex = /Interest Rate Swaps Position Report/;

    const matchCell = this.getCellValueByCondition((cellValue: Cell) => {
      return typeof cellValue === 'string' && targetRegex.test(cellValue);
    });

    if (!matchCell) {
      throw new Error(
        'Failed to locate the expected footer line in the IRS Position Report. Ensure that the footer includes a line similar to the following example: "Hedgehog - Interest Rate Swaps Position Report(28 Apr 2023)"',
      );
    }

    const regex = /\((\d{1,2} [A-Za-z]+ \d{2,4})\)/;
    const match = matchCell.value.match(regex);

    if (!match) {
      throw new Error(
        'Invalid reporting date format in footer from IRS Position Report',
      );
    }
  }

  private validateValuationCurrencyInFooter() {
    const targetRegex = /using a mid market spread basis/;

    const matchCell = this.getCellValueByCondition((cellValue: Cell) => {
      return typeof cellValue === 'string' && targetRegex.test(cellValue);
    });

    if (!matchCell) {
      throw new Error(
        'Failed to locate the expected footer line in the IRS Position Report. Ensure that the footer includes a line similar to the following example: "Report run as at 30 Nov 2023 for Hedgehog valued in AUD using a mid market spread basis."',
      );
    }

    const regex = /valued in (\w+) using a mid/;
    const match = matchCell.value.match(regex);

    if (!match) {
      throw new Error(
        'Invalid Valuation Currency format in footer from IRS Position Report',
      );
    }
  }

  private getCellValueByCondition(
    valueOrCondition: (cell: any) => boolean,
  ): any | null {
    const dimensions = this.processableInput.getSheetDimensions();
    for (let row = 0; row < dimensions.height; row++) {
      for (let col = 0; col < dimensions.width; col++) {
        const cellValue = this.processableInput.getCellValue({
          row,
          col,
        });
        if (typeof valueOrCondition === 'function') {
          if (valueOrCondition(cellValue)) {
            return { col, row, value: cellValue };
          }
        }
      }
    }

    return null;
  }
}
