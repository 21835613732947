import { Outlet, useParams } from 'react-router-dom';
import { ReportingPeriods } from '../../../../components/common/reporting-periods/ReportingPeriods';

export const OrganizationReportingPeriods = () => {
  const { id } = useParams();

  const organizationId = Number(id);

  const route = `organization/${organizationId}`;

  return (
    <div className="flex flex-col gap-4">
      <div className="relative flex flex-col z-0 overflow-x-auto gap-4">
        <ReportingPeriods organizationId={organizationId} route={route} />
        <Outlet />
      </div>
    </div>
  );
};
