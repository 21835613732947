import * as Joi from 'joi';
import { FxMtmValuationReportOutputColHeaders } from './fxForwardsHeldHeaders';
import {
  CURRENCY_VALIDATION_REGEX,
  REFERENCE_VALIDATION_REGEX,
} from '../../helpers/validationRegex';

export const fxMtmValuationHeadersSchema: Record<string, Joi.Schema> = {
  [FxMtmValuationReportOutputColHeaders.DealDate]: Joi.date()
    .required()
    .messages({
      'date.base':
        'Deal Date must be a date with format DD/MM/YYYY, DD MMM YY or DD-MM-YYYY, etc.',
      'any.required': 'Deal Date must be provided',
    }),
  [FxMtmValuationReportOutputColHeaders.MaturityDate]: Joi.date()
    .required()
    .messages({
      'date.base':
        'Maturity Date must be a date with format DD/MM/YYYY, DD MMM YY or DD-MM-YYYY, etc',
      'any.required': 'Maturity Date must be provided',
    }),
  [FxMtmValuationReportOutputColHeaders.BuyCcy]: Joi.string()
    .regex(CURRENCY_VALIDATION_REGEX)
    .required()
    .messages({
      'string.base': 'Buy Currency must be a string, not null, and not empty',
      'string.pattern.base':
        'Buy Currency must be a three-letter uppercase string',
      'any.required': 'Buy Currency must be provided',
    }),
  [FxMtmValuationReportOutputColHeaders.BuyAmount]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Buy Amount must be a valid number and cannot be null or empty',
      'any.required': 'Buy Amount must be provided',
    }),
  [FxMtmValuationReportOutputColHeaders.SellCcy]: Joi.string()
    .regex(CURRENCY_VALIDATION_REGEX)
    .required()
    .messages({
      'string.base': 'Sell Currency must be a string and not null',
      'string.pattern.base':
        'Sell Currency must be a three-letter uppercase string',
      'any.required': 'Sell Currency must be provided',
    }),
  [FxMtmValuationReportOutputColHeaders.SellAmount]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Sell Amount must be a valid number and cannot be null or empty',
      'any.required': 'Sell Amount must be provided',
    }),
  [FxMtmValuationReportOutputColHeaders.DealType]: Joi.string()
    .required()
    .messages({
      'string.base': 'Deal Type must be a string, not null, and not empty',
      'any.required': 'Deal Type must be provided',
    }),
  [FxMtmValuationReportOutputColHeaders.ExchangeRate]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Exchange Rate must be a valid number and cannot be null or empty',
      'any.required': 'Exchange Rate must be provided',
    }),
  [FxMtmValuationReportOutputColHeaders.FairValue]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Valuation must be a valid number and cannot be null or empty',
      'any.required': 'Valuation must be provided',
    }),
  [FxMtmValuationReportOutputColHeaders.Counterparty]: Joi.string()
    .required()
    .messages({
      'string.base': 'Counterparty must be a string, not null, and not empty',
      'any.required': 'Counterparty must be provided',
    }),
  [FxMtmValuationReportOutputColHeaders.DealNumber]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Deal Number must be a valid number and cannot be null or empty',
      'any.required': 'Deal Number must be provided',
    }),
  [FxMtmValuationReportOutputColHeaders.SpotRateatInception]: Joi.alternatives()
    .try(Joi.string(), Joi.number())
    .allow(null),
  [FxMtmValuationReportOutputColHeaders.Reference]: Joi.alternatives()
    .try(
      Joi.string()
        .regex(REFERENCE_VALIDATION_REGEX)
        .message(
          'Trade reference can be a number, string and has special characters',
        ),
      Joi.number(),
    )
    .required(),
  [FxMtmValuationReportOutputColHeaders.Comment]: Joi.alternatives()
    .try(Joi.string(), Joi.number())
    .allow(null),
};

export const fxMtmValuationTotalSchema: Record<string, Joi.Schema> = {
  [FxMtmValuationReportOutputColHeaders.FairValue]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Total Valuation must be a valid number and cannot be null or empty',
      'any.required': 'Total Valuation must be provided',
    }),
};
