import { useQuery } from 'react-query';
import { getReportByMonthAndYear } from '../../../../services/hedgehog/hedgehog.api';
import { OutputReportTypes } from '../../../../services/hedgehog/types/OutputReportTypes';
import { ApiQueryKeys } from '../../../../services/hedgehog/api-query-keys.enum';
import { IReportByMonth } from '../../../../services/hedgehog/interfaces/IReportByMonth';
import { IFxTrade } from '../../../../services/hedgehog/interfaces/IFXTrade';
import { formatDate } from '../../../tables/common/utils';
import { Toast } from '../../../../services/toastify/Toastify';

function getLatestReportingDateMonthYear(trades: IFxTrade[]): {
  year: string;
  month: string;
} {
  const allEntries = trades.flatMap((trade) => trade.mtmValuationEntry);
  if (allEntries.length === 0) {
    throw new Error('No trade valuation entries found');
  }
  const latestReportingDate = allEntries.reduce((latest, entry) => {
    return new Date(entry.reportingDate) > new Date(latest)
      ? entry.reportingDate
      : latest;
  }, allEntries[0].reportingDate);

  const date = formatDate(latestReportingDate);
  return {
    year: date.split(' ')[2],
    month: date.split(' ')[1],
  };
}

export const useFetchMtmValuationReports = (
  organizationId: number,
  trades: IFxTrade[] | undefined,
) => {
  return useQuery<IReportByMonth>(
    [
      ApiQueryKeys.organization_all_reports_by_month_and_year,
      trades,
      organizationId,
    ],
    {
      queryFn: () => {
        if (!trades || trades.length === 0) {
          throw new Error('Trades data is not available');
        }
        const { month, year } = getLatestReportingDateMonthYear(trades);
        return getReportByMonthAndYear(organizationId, {
          month,
          year,
          types: [OutputReportTypes.FX_MTM_VALUATION],
        });
      },
      onError: (error: any) => {
        Toast.error(error.message);
      },
      enabled: !!trades && trades.length > 0,
    },
  );
};
