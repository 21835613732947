export const LeftArrow = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={13}
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path d="M768 903.232 717.568 960 256 512 717.568 64 768 120.768 364.928 512z" />
  </svg>
);
