import { InputHTMLAttributes, MouseEvent, useEffect, useState } from 'react';
import { Toast } from '../../services/toastify/Toastify';
import { UNEXPECTED_ERROR_MESSAGE } from '../../error/errorMessages.constants';
import useLoading from '../../hooks/useLoading';

type Props<T> = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  label?: string;
  fetchItems?: () => Promise<T[]>;
  handleLoad: (file: File) => Promise<T>;
  displayOptions?: (item: T) => JSX.Element;
  onSelectItem?: (item: T | undefined) => void;
};
const fileButtonStyle = `
file:inline-flex file:h-full file:justify-center file:rounded-lg file:py-1 file:cursor-pointer file:px-2 file:text-sm file:font-semibold file:outline-2 file:outline-offset-2 file:transition-colors file:bg-primary-cyan-500 file:border-none  file:text-black file:hover:bg-primary-cyan-600 
file:before:transition-colors
`;
const buttonStyle = `
inline-flex text-xs justify-center items-center h-10 rounded-lg  cursor-pointer px-2 font-semibold outline-2 outline-offset-2 transition-colors
bg-primary-cyan-500 text-black hover:bg-primary-cyan-600 active:bg-gray-600 active:text-black/80 before:transition-colors
`;

const inputClassName = `
  block w-full h-10 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none
`;
export const InputFile = <T,>({
  label,
  className,
  fetchItems,
  displayOptions,
  handleLoad,
  onSelectItem,
  ...props
}: Props<T>) => {
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const [items, setItems] = useState<T[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [loadingFile, waitLoad] = useLoading<T>();
  const [loadingOptions, waitOptions] = useLoading<T[]>();
  const toggleSelect = (e: MouseEvent) => {
    e.preventDefault();
    setShowSelect(!showSelect);
    setError(false);
    onSelectItem?.(undefined);
  };

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = Array.from(e.target.files)[0];
    try {
      const uploadedItem = await waitLoad(handleLoad(file));
      Toast.success(`File: ${file.name} loaded successfully`);
      onSelectItem?.(uploadedItem);
      setError(false);
    } catch (err) {
      if (err instanceof Error) Toast.error(err.message);
      else Toast.error(UNEXPECTED_ERROR_MESSAGE);
      setError(true);
      onSelectItem?.(undefined);
    }
  };

  useEffect(() => {
    if (!fetchItems) return;
    const auxFetch = async () => {
      const options: T[] = await waitOptions(fetchItems());
      setItems(options);
    };
    auxFetch();
  }, [showSelect, fetchItems, waitOptions]);
  return (
    <div className="w-full">
      {label && (
        <label
          className="block mb-2 text-sm  font-medium text-gray-900 "
          htmlFor={props.id}
        >
          {label}
        </label>
      )}
      <div className="flex flex-row w-full gap-2">
        {!showSelect && (
          <input
            className={`${className} ${error && 'border-red-500'} ${
              loadingFile ? 'border-yellow-500 pointer-events-none' : ''
            } ${inputClassName} ${fileButtonStyle} `}
            type="file"
            disabled={loadingFile}
            onChange={uploadFile}
            {...props}
          />
        )}
        {showSelect && displayOptions && onSelectItem && (
          <select
            onChange={(e) => {
              e.currentTarget.value === ''
                ? onSelectItem(undefined)
                : onSelectItem(JSON.parse(e.currentTarget.value) as T);
            }}
            defaultValue={''}
            disabled={loadingOptions}
            className={`${className} ${inputClassName} ${
              loadingOptions ? 'border-yellow-500 pointer-events-none' : ''
            } ${fileButtonStyle}`}
          >
            <option value={''}>Select</option>
            {items?.map(displayOptions)}
          </select>
        )}
        {fetchItems && (
          <button
            disabled={loadingFile}
            onClick={toggleSelect}
            className={`w-28 ${buttonStyle}`}
          >
            {showSelect ? 'File upload' : 'Choose from system'}
          </button>
        )}
      </div>
    </div>
  );
};
