import { validateActualAndHypoReferences } from '../../helpers/actualAndHypoValidation';
import { validateEitherHeadersBySchema } from '../../helpers/headerValidator';
import { ProcessableInputReport } from '../../ProcessableInputReport';
import { irsMtmValuationReportHeaders } from '../cvaReport/irsMtmValuationReportInputHeaders';
import { detailedInstrumentSchemas } from './detailedInstrumentSchemas';

export class DetailedInstrumentReportValidator {
  constructor(private processableInput: ProcessableInputReport) {}

  validate() {
    const { detailedInstrumentReportInputsHeaders: headers } =
      irsMtmValuationReportHeaders;

    this.validateLegalEntityName();

    const sheetDimentions = this.processableInput.getSheetDimensions();

    validateEitherHeadersBySchema(
      headers,
      detailedInstrumentSchemas,
      this.processableInput,
      sheetDimentions.height - 1,
    );
    validateActualAndHypoReferences(this.processableInput);
  }

  private validateLegalEntityName() {
    const legalEntityAddress = {
      col: 0,
      row: 0,
    };
    const legalEntityCellValue =
      this.processableInput.getCellValue(legalEntityAddress);
    const expectedType = 'string';

    if (
      legalEntityCellValue === null ||
      legalEntityCellValue.toString().trim() === ''
    ) {
      throw new Error('Legal entity not found at position A1');
    }

    if (typeof legalEntityCellValue !== expectedType) {
      throw new Error(
        `The legal entity must be of type string and located at position A1, but the value '${legalEntityCellValue}' was found in this location.`,
      );
    }
  }
}
