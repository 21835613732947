export enum FxDetailedInstrumentReportColHeaders {
  Reference = 'Reference',
  Comment = 'Comment',
  DealNumber = 'DealNumber',
  DealDate = 'DealDate',
  BaseCurrency = 'BaseCurrency',
  BuyCurrency = 'BuyCurrency',
  BuyAmount = 'BuyAmount',
  ContractRate = 'ContractRate',
  SellCurrency = 'SellCurrency',
  SellAmount = 'SellAmount',
  MaturityDate = 'MaturityDate',
  Counterparty = 'Counterparty',
  SpotRateatInception = 'Spot Rateat Inception',
  Value = 'Value',
  DiscountFactor = 'DiscountFactor',
  MarketForwardRate = 'Market ForwardRate',
  SpotRateatValuation = 'Spot Rateat Valuation',
}

export enum FxHedgeEffectivenessReportRequiredInputsType {
  HB_FX_DETAILED_INSTRUMENT_REPORT = 'HB - FX Actual Detailed Instrument',
}

export interface IFxHedgeEffectivenessReportInputsByPipeline {
  v1: {
    inputs: FxHedgeEffectivenessReportRequiredInputsType[];
  };
}

export const FxHedgeEffectivenessReportInputsByPipeline = {
  v1: {
    inputs: [
      FxHedgeEffectivenessReportRequiredInputsType.HB_FX_DETAILED_INSTRUMENT_REPORT,
    ],
  },
};
