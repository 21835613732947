import { useQuery } from 'react-query';
import { getReportsToNotify } from '../../../../services/hedgehog/hedgehog.api';
import { Toast } from '../../../../services/toastify/Toastify';

export const useFetchReportsToNotify = (organizationId: number) => {
  return useQuery('notify-reports', {
    queryFn: () => {
      return getReportsToNotify(organizationId);
    },
    onError: (error: any) => {
      Toast.error(error.message);
    },
  });
};
