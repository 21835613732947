import React from 'react';

type BasicCountCardProps = {
  count: number;
  type: string;
  cardClassName?: string;
  countClassName?: string;
  typeClassName?: string;
};

export const BasicCountCard = ({
  count,
  type,
  cardClassName,
  countClassName,
  typeClassName,
}: BasicCountCardProps) => {
  return (
    <div
      className={`flex flex-col items-center justify-center ${cardClassName}`}
      data-cy={`basic-count-card-${type}`}
    >
      <dt
        className={`mb-1 text-2xl font-extrabold ${countClassName}`}
        data-cy={`basic-count-card-${type}-count`}
      >
        {count}+
      </dt>
      <dd
        className={`text-gray-500 text-sm text-center mb-auto px-1 ${typeClassName}`}
        data-cy={`basic-count-card-type-${type}`}
      >
        {type}
      </dd>
    </div>
  );
};
