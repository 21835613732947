import { HyperFormula, RawCellContent, SimpleCellAddress } from 'hyperformula';
import { customParseDate } from './helpers/customParseDate';

export class HyperFormulaService {
  workbook: HyperFormula;
  static workbook: HyperFormula;

  constructor() {
    this.workbook = HyperFormula.buildEmpty({
      licenseKey: 'gpl-v3',
      parseDateTime: customParseDate,
      dateFormats: [
        'DD MMM YY',
        'DD MMM YYYY',
        'D/M/YY',
        'D/MM/YY',
        'MM/DD/YY',
        'DD/MM/YY',
        'DD/MM/YYYY',
        'D-MMM-YY',
        'D/MM/YY',
        'M/DD/YY',
      ],
      decimalSeparator: '.',
      thousandSeparator: ',',
      functionArgSeparator: ';',
    });
    HyperFormulaService.workbook = this.workbook;
  }

  protected setCellContents(
    address: SimpleCellAddress,
    sheetInfo: RawCellContent | RawCellContent[][],
  ) {
    this.workbook.setCellContents(address, sheetInfo);
  }
}
