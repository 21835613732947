import * as Joi from 'joi';
import { FxMaturedDealsReportOutputColHeaders } from './fxMaturedDealsHeaders';
import { REFERENCE_VALIDATION_REGEX } from '../../helpers/validationRegex';

export const FxMaturedDealsReportHeadersSchema: Record<string, Joi.Schema> = {
  [FxMaturedDealsReportOutputColHeaders.DealDate]: Joi.date()
    .required()
    .messages({
      'date.base':
        'Deal Date must be a date with format DD/MM/YYYY, DD MMM YY or DD-MM-YYYY, etc.',
      'any.required': 'Deal Date must be provided',
    }),
  [FxMaturedDealsReportOutputColHeaders.MaturityDate]: Joi.date()
    .required()
    .messages({
      'date.base':
        'Maturity Date must be a date with format DD/MM/YYYY, DD MMM YY or DD-MM-YYYY, etc',
      'any.required': 'Maturity Date must be provided',
    }),
  [FxMaturedDealsReportOutputColHeaders.DealType]: Joi.string()
    .required()
    .messages({
      'string.base': 'Deal Type must be a string, not null, and not empty',
      'any.required': 'Deal Type must be provided',
    }),
  [FxMaturedDealsReportOutputColHeaders.Direction]: Joi.string()
    .required()
    .messages({
      'string.base': 'Direction must be a string, not null, and not empty',
      'any.required': 'Direction must be provided',
    }),
  [FxMaturedDealsReportOutputColHeaders.FirstVolume]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Volume must be a valid number and cannot be null or empty',
      'any.required': 'Volume must be provided',
    }),
  [FxMaturedDealsReportOutputColHeaders.SecondVolume]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Volume must be a valid number and cannot be null or empty',
      'any.required': 'Volume must be provided',
    }),
  [FxMaturedDealsReportOutputColHeaders.ExchangeRate]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Exchange Rate must be a valid number and cannot be null or empty',
      'any.required': 'Exchange Rate must be provided',
    }),
  [FxMaturedDealsReportOutputColHeaders.Counterparty]: Joi.string()
    .required()
    .messages({
      'string.base': 'Counterparty must be a string, not null, and not empty',
      'any.required': 'Counterparty must be provided',
    }),
  [FxMaturedDealsReportOutputColHeaders.SpotRateAtMaturity]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Spot Rate at Maturity must be a valid number and cannot be null or empty',
      'any.required': 'Spot Rate at Maturity must be provided',
    }),
  [FxMaturedDealsReportOutputColHeaders.gainAndLoss]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Gain/Loss must be a valid number and cannot be null or empty',
      'any.required': 'Gain/Loss must be provided',
    }),
  [FxMaturedDealsReportOutputColHeaders.DealNumber]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Deal Number must be a valid number and cannot be null or empty',
      'any.required': 'Deal Number must be provided',
    }),

  [FxMaturedDealsReportOutputColHeaders.Reference]: Joi.alternatives()
    .try(
      Joi.string()
        .regex(REFERENCE_VALIDATION_REGEX)
        .message(
          'Trade reference can be a number, string and has special characters',
        ),
      Joi.number(),
    )
    .required(),
  [FxMaturedDealsReportOutputColHeaders.Comment]: Joi.alternatives()
    .try(Joi.string(), Joi.number())
    .allow(null),
};

export const fxMaturedDealsReportTotalSchema: Record<string, Joi.Schema> = {
  [FxMaturedDealsReportOutputColHeaders.FirstVolume]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Total Volume must be a valid number and cannot be null or empty',
      'any.required': 'Total Volume must be provided',
    }),
  [FxMaturedDealsReportOutputColHeaders.ExchangeRate]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Total Exchange Rate must be a valid number and cannot be null or empty',
      'any.required': 'Total Exchange Rate must be provided',
    }),
  [FxMaturedDealsReportOutputColHeaders.gainAndLoss]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Total Gain/Loss must be a valid number and cannot be null or empty',
      'any.required': 'Total Gain/Loss must be provided',
    }),
};
