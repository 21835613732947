import { useQueryClient } from 'react-query';
import { useConfirm } from '../../../../hooks/useConfirm';
import { useUser } from '../../../../hooks/useUser';
import { UserRole } from '../../../../services/hedgehog/enum/UserRole.enum';
import { IPeople } from '../../../../services/hedgehog/interfaces/IPeople';
import { IUser } from '../../../../services/hedgehog/interfaces/IUser';
import { deleteOrganizationMember } from '../../../../services/hedgehog/hedgehog.api';
import { ApiQueryKeys } from '../../../../services/hedgehog/api-query-keys.enum';
import { Toast } from '../../../../services/toastify/Toastify';

type Props = {
  members: IPeople[];
  organizationId: number;
};

const columnNames: string[] = [
  'id',
  'full name',
  'email',
  'position',
  'actions',
];
export const MembersTable = ({ members: people, organizationId }: Props) => {
  const { user } = useUser();
  const { confirmAction } = useConfirm();
  const queryClient = useQueryClient();

  const handleDeleteUser = (user: IUser) => {
    const action = async () => {
      await deleteOrganizationMember(user.id, organizationId);
      Toast.success('User removed successfully');
      queryClient.refetchQueries(ApiQueryKeys.organization_members);
    };
    confirmAction(
      action,
      `The user will be removed from the organization. Are you sure you want to delete it: ${user.name} ${user.lastName}?`,
      'Delete user',
    );
  };

  return (
    <table className="w-full text-sm text-left text-gray-500">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
        <tr className="bg-white border-b hover:bg-gray-50">
          {columnNames.map((key) => (
            <th key={key} className="px-6 py-4 space-x-2">
              {key}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {people.map((member) => (
          <tr key={member.id} className="bg-white border-b hover:bg-gray-50">
            <td className="px-6 py-4 space-x-2">{member.user.id}</td>
            <td className="px-6 py-4 space-x-2">{`${member.user.name} ${member.user.lastName}`}</td>
            <td className="px-6 py-4 space-x-2">{member.user.email}</td>
            <td className="px-6 py-4 space-x-2">{member.position}</td>
            <td className="px-4 py-4">
              <div className="flex gap-2 flex-wrap">
                {user?.role === UserRole.Admin ? (
                  <button
                    className="font-medium text-red-500 hover:underline"
                    data-cy={`delete-${member.user.name}-${member.user.id}`}
                    onClick={() => handleDeleteUser(member.user)}
                  >
                    Delete
                  </button>
                ) : (
                  'N/A'
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
