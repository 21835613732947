import { Form, Formik } from 'formik';
import { ComponentProps } from 'react';
import { UNEXPECTED_ERROR_MESSAGE } from '../../../../error/errorMessages.constants';
import useLoading from '../../../../hooks/useLoading';
import { signup } from '../../../../services/hedgehog/user.api';
import { Toast } from '../../../../services/toastify/Toastify';
import { Modal } from '../../../../components/modal/Modal';
import { FormField } from '../../../../components/form-field/FormField';
import { Button } from '../../../../components/Button';
import { SimpleLoadingModal } from '../../../../components/modal/SimpleLoadingModal';
import { updateUser } from '../../../../services/hedgehog/hedgehog.api';
import { UserRole } from '../../../../services/hedgehog/enum/UserRole.enum';
import { addNewUserAsAdminValidationSchema } from './validations/addNewUserAsAdminValidation';

const initialValues = {
  email: '',
  name: '',
  'last-name': '',
  role: UserRole.Admin,
  password: '',
  'password-repeat': '',
};

const formFields: ComponentProps<typeof FormField>[] = [
  {
    label: 'Email',
    id: 'email',
    name: 'email',
    type: 'email',
    placeholder: 'Email',
  },
  {
    label: 'Name',
    id: 'name',
    name: 'name',
    type: 'text',
    placeholder: 'Name',
  },
  {
    label: 'Last name',
    id: 'last-name',
    name: 'last-name',
    type: 'text',
    placeholder: 'Last name',
  },
  {
    label: 'Password',
    id: 'password',
    name: 'password',
    type: 'password',
    placeholder: 'Password',
  },
  {
    label: 'Repeat Password',
    id: 'repeat-password',
    name: 'repeat-password',
    type: 'password',
    placeholder: 'Repeat Password',
  },
];

type AddAdminModalProps = {
  close: () => void;
  onSubmit?: () => void;
};

export const AddAdminModal = ({ close, onSubmit }: AddAdminModalProps) => {
  const [isAddingMember, waitFor] = useLoading();

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      const member = await signup(
        values['name'],
        values['last-name'],
        values['email'],
        values.password,
      );
      await updateUser(member.id, { role: UserRole.Admin });
      Toast.success('Admin added successfully');
      onSubmit?.();
      close();
    } catch (e: unknown) {
      if (e instanceof Error) Toast.error(e.message);
      else Toast.error(UNEXPECTED_ERROR_MESSAGE);
    }
  };

  return (
    <>
      <Modal>
        <div className="max-h-full w-full sm:w-auto overflow-y-auto items-center py-2">
          <div className="flex flex-col relative w-full sm:w-96 justify-center items-center bg-white border border-gray-200 shadow-md rounded-lg">
            <Formik
              initialValues={initialValues}
              validationSchema={addNewUserAsAdminValidationSchema}
              onSubmit={(values) => waitFor(handleSubmit(values))}
            >
              <Form className="flex flex-col justify-center rounded-lg items-center w-full p-4 gap-2 bg-white">
                <div>
                  <h3 className="text-center font-semibold text-xl my-2 text-black">
                    Add a new user as administrator
                  </h3>
                </div>

                {formFields.map((field) => (
                  <FormField key={field.id} {...field} />
                ))}

                <div className="flex flex-row items-center gap-4 justify-end w-full">
                  <button
                    type="button"
                    className="text-sm font-semibold underline underline-offset-4"
                    onClick={close}
                  >
                    Close
                  </button>
                  <Button
                    data-cy="add-admin-button"
                    variant="solid"
                    color="cyan"
                    type="submit"
                  >
                    Create
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </Modal>
      {isAddingMember && <SimpleLoadingModal />}
    </>
  );
};
