import { IReportsToNotify } from '../../../../services/hedgehog/interfaces/IReportsToNotify';

export const theReportsAreEmpty = (reports?: IReportsToNotify) => {
  if (!reports) {
    return true;
  }

  const allReportsCount =
    reports.irsMtmJournals.length +
    reports.outputs.length +
    reports.hedgeEffectivenessReports.length +
    reports.hedgeDocumentationForms.length +
    reports.fxHedgeDocumentationForms.length;

  return allReportsCount <= 0;
};
