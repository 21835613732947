import { orderAlphabeticallyCurrencies } from '../../../../../utils/orderAlphabeticallyCurrencies';
import { FxReportCurrencyRulesOptions } from '../../../interfaces/IFxMtmJournalStaticData';

export const fxReportCurrencyRulesStaticData = 'fxCurrencyRules';

export const EMPTY_EXPOSURE_CURRENCY = '-';

export enum FxMtmJournalReportBaseCurrency {
  AUD = 'AUD',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  EUR = 'EUR',
  GBP = 'GBP',
  JPY = 'JPY',
  NZD = 'NZD',
  USD = 'USD',
  ZAR = 'ZAR',
  AED = 'AED',
  CNY = 'CNY',
  CZK = 'CZK',
  DKK = 'DKK',
  GHS = 'GHS',
  HKD = 'HKD',
  HUF = 'HUF',
  IDR = 'IDR',
  INR = 'INR',
  KES = 'KES',
  KRW = 'KRW',
  MXN = 'MXN',
  MYR = 'MYR',
  NOK = 'NOK',
  PHP = 'PHP',
  PNL = 'PNL',
  QAR = 'QAR',
  RON = 'RON',
  RUB = 'RUB',
  SEK = 'SEK',
  SGD = 'SGD',
  THB = 'THB',
  TRY = 'TRY',
  UGX = 'UGX',
  TWD = 'TWD',
}

export enum FxMtmJournalReportQuoteCurrency {
  AUD = 'AUD',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  EUR = 'EUR',
  GBP = 'GBP',
  JPY = 'JPY',
  NZD = 'NZD',
  USD = 'USD',
  ZAR = 'ZAR',
  AED = 'AED',
  CNY = 'CNY',
  CZK = 'CZK',
  DKK = 'DKK',
  GHS = 'GHS',
  HKD = 'HKD',
  HUF = 'HUF',
  IDR = 'IDR',
  INR = 'INR',
  KES = 'KES',
  KRW = 'KRW',
  MXN = 'MXN',
  MYR = 'MYR',
  NOK = 'NOK',
  PHP = 'PHP',
  PNL = 'PNL',
  QAR = 'QAR',
  RON = 'RON',
  RUB = 'RUB',
  SEK = 'SEK',
  SGD = 'SGD',
  THB = 'THB',
  TRY = 'TRY',
  UGX = 'UGX',
  TWD = 'TWD',
}

export enum FxMtmJournalReportMonth {
  SameMonth = 'Same Month',
  Month1 = '1 Month',
  Month2 = '2 Months',
  Month3 = '3 Months',
  Month4 = '4 Months',
  Month5 = '5 Months',
  Month6 = '6 Months',
  Month7 = '7 Months',
  Month8 = '8 Months',
  Month9 = '9 Months',
  Month10 = '10 Months',
  Month11 = '11 Months',
  Month12 = '12 Months',
}

export enum FxMtmJournalReportRule {
  PNL = 'PNL',
  OCI = 'OCI',
  Inventory = 'Inventory',
}

export enum FxHedgeDocumentationFormTemplateOptions {
  No = 'No',
  STANDARD_TEMPLATE = 'Standard Template',
  SYNTHETIC_LEGS_TEMPLATE = 'Synthetic Legs Template',
}

const baseCurrencyValues = Object.values(FxMtmJournalReportBaseCurrency);
const quoteCurrencyValues = Object.values(FxMtmJournalReportQuoteCurrency);

export const fxReportCurrencyRules: FxReportCurrencyRulesOptions = {
  baseCurrency: orderAlphabeticallyCurrencies(baseCurrencyValues),
  quoteCurrency: orderAlphabeticallyCurrencies(quoteCurrencyValues),
  exposureCurrency: [FxMtmJournalReportBaseCurrency.AUD],
  month: Object.values(FxMtmJournalReportMonth),
  rule: Object.values(FxMtmJournalReportRule),
  fxHedgeDocumentationFormTemplateOption: Object.values(
    FxHedgeDocumentationFormTemplateOptions,
  ),
};
