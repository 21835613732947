export enum FxMaturedDealsReportOutputColHeaders {
  DealDate = 'Deal Date',
  MaturityDate = 'Maturity Date',
  DealType = 'Deal Type',
  Direction = 'Direction',
  FirstVolume = 'First Volume',
  SecondVolume = 'Second Volume',
  ExchangeRate = 'Exchange Rate',
  Counterparty = 'Counterparty',
  SpotRateAtMaturity = 'Spot Rate at Maturity',
  gainAndLoss = 'Gain/Loss',
  DealNumber = 'Deal Number',
  Reference = 'Reference',
  Comment = 'Comment',
}

export enum FxMaturedDealsReportTotalColHeaders {
  FirstVolume = 'First Volume',
  SecondVolume = 'Second Volume',
  ExchangeRate = 'Exchange Rate',
  GainAndLoss = 'Gain/Loss',
}

export enum FxMaturedDealsReportHedgingHeader {
  HEDGING = 'Hedging',
}

export enum FxMaturedDealsReportInputsRowHeader {
  TOTAL = 'TOTAL',
}

export const fxMaturedDealsReportHeaderMapping = (
  header:
    | FxMaturedDealsReportOutputColHeaders
    | FxMaturedDealsReportTotalColHeaders,
) => {
  if (
    header === FxMaturedDealsReportOutputColHeaders.FirstVolume ||
    header === FxMaturedDealsReportOutputColHeaders.SecondVolume
  ) {
    return 'Volume';
  }
  return header;
};
