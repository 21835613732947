import { UserTable } from './UserTable';
import { usePaginatedQuery } from '../../../../hooks/usePaginatedQuery';
import { ApiQueryKeys } from '../../../../services/hedgehog/api-query-keys.enum';
import { getUsers } from '../../../../services/hedgehog/hedgehog.api';
import { SimpleLoading } from '../../../../components/loading/SimpleLoading';
import { UserAndCount } from '../../../../services/hedgehog/interfaces/IUser';
import { IPagination } from '../../../../services/hedgehog/interfaces/IPagination';

export const UserDashboard = () => {
  const { data, isLoading } = usePaginatedQuery<UserAndCount, IPagination>(
    [ApiQueryKeys.users],
    { limit: 20, offset: 0 },
    getUsers,
  );

  return (
    <div className="flex flex-col ">
      <div className="relative z-0 overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
        <div className="flex flex-wrap w-full justify-center p-4 gap-2 bg-white">
          <div className="text-lg font-semibold text-gray-900">
            <h3 className="text-2xl font-bold text-center">Users</h3>
          </div>
        </div>
        {isLoading && (
          <SimpleLoading
            loadingColor="text-primary-cyan-500"
            loadingSize="h-10 w-10"
            externalStyles="flex items-center justify-center p-2"
          />
        )}
        {data?.users && <UserTable users={data.users} />}
      </div>
    </div>
  );
};
