import moment from 'moment';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { IRSTradeGraph } from '../../../../components/charts/IRSTradeGraph';
import { IRSTradesTable } from '../../../../components/common/trade-tables/IRSTradesTable';
import { SimpleLoading } from '../../../../components/loading/SimpleLoading';
import { ApiQueryKeys } from '../../../../services/hedgehog/api-query-keys.enum';
import {
  getIrsTradeById,
  getOrganizationIrsTrades,
} from '../../../../services/hedgehog/hedgehog.api';
import {
  IIRSTrade,
  IIRSTradeAndCount,
} from '../../../../services/hedgehog/interfaces/IIRSTrade';
import { useUserDashboardContext } from '../../../../hooks/useDashboardContext';
import { Toast } from '../../../../services/toastify/Toastify';

export const UserOrganizationTrades = () => {
  const { currentOrganization } = useUserDashboardContext();

  const [selectedTrade, setSelectedTrade] = useState<IIRSTrade | undefined>(
    undefined,
  );

  const { data: tradesResponse, isLoading: isLoadingTrades } =
    useQuery<IIRSTradeAndCount>(
      [ApiQueryKeys.irs_trades_table, currentOrganization.id],
      () =>
        getOrganizationIrsTrades(currentOrganization.id, {
          entriesFrom: moment(new Date())
            .startOf('month')
            .subtract(6, 'month')
            .toDate(),
          entriesTo: new Date(),
        }),
    );

  const handleSelectTrade = async (irsTrade: IIRSTrade) => {
    const trade = await getIrsTradeById(currentOrganization.id, irsTrade.id);
    setSelectedTrade(trade);
  };
  useEffect(() => {
    if (tradesResponse && tradesResponse.trades.length > 0) {
      getIrsTradeById(currentOrganization.id, tradesResponse.trades[0].id)
        .then((trade) => {
          setSelectedTrade(trade);
        })
        .catch((err) => Toast.error(err.message));
    }
  }, [currentOrganization.id, tradesResponse]);

  return (
    <div className="w-full h-fit grid lg:grid-cols-12 grid-cols-1 gap-6 ">
      <div className="col-span-6 whitespace-nowrap w-full min-h-[354px]  border bg-white border-gray-200 shadow-md rounded-lg pt-2 overflow-auto">
        {isLoadingTrades && (
          <SimpleLoading loadingColor=" w-full h-full text-primary-cyan-500" />
        )}
        {tradesResponse && (
          <IRSTradesTable
            irsTrades={tradesResponse.trades}
            onRowClick={handleSelectTrade}
          />
        )}
      </div>
      <div className="col-span-6 min-h-[360px] overflow-auto w-full p-4 border bg-white border-gray-200 shadow-md rounded-lg">
        {selectedTrade && (
          <IRSTradeGraph
            className="min-w-[400px] h-80 max-h-fit"
            trade={selectedTrade}
          ></IRSTradeGraph>
        )}
      </div>
      <div className="col-span-6 w-full h-80  border bg-white border-gray-200 shadow-md rounded-lg"></div>
      <div className="col-span-6 w-full h-80  border bg-white border-gray-200 shadow-md rounded-lg"></div>
    </div>
  );
};
