import HotTable from '@handsontable/react';

import { useLoadWorkbook } from '../../../hooks/useLoadWorkbook';
import { useLoadBasicSheet } from '../../../hooks/useLoadBasicSheet';
import {
  irsMtmJournalCellsConfig,
  irsMtmJournalColumnConfig,
} from './irsMtmJournalTableConfig';
import { getIrsJournalCellProperties } from './utils/getIrsJournalCellProperties';
import React, { useEffect, useState } from 'react';
import { insertEmptyRowAfter } from '../common/utils';

interface IIrsMtmJournalTableProps {
  report: Blob;
}

export const irsMtmJournalSheetName = 'IRS MTM Journal';

const headers = [
  /^GL Account$/,
  /^GL Account$/,
  /^Classification$/,
  /^Entry Descrption$/,
  /^Entry Description$/,
  /^Reference$/,
  /DR$/,
  /CR$/,
];

export const ViewIrsMtmJournalTable = ({
  report,
}: IIrsMtmJournalTableProps) => {
  const { workbook } = useLoadWorkbook(report, 'Error loading IRS MTM Journal');
  const { sheet: originalSheet } = useLoadBasicSheet(
    workbook,
    irsMtmJournalSheetName,
  );
  const [sheet, setSheet] = useState<any[][]>([]);

  useEffect(() => {
    if (!originalSheet) return;

    const updatedSheet = insertEmptyRowAfter(originalSheet, '-');
    setSheet(updatedSheet);
  }, [originalSheet]);

  return (
    <div className="flex items-center justify-center">
      {sheet.length > 0 && (
        <HotTable
          readOnly={true}
          data={sheet}
          columns={irsMtmJournalColumnConfig}
          rowHeaders={false}
          colHeaders={false}
          width={1450}
          height="auto"
          licenseKey="non-commercial-and-evaluation"
          rowHeights={40}
          manualColumnResize={true}
          mergeCells={irsMtmJournalCellsConfig(sheet)}
          allowEmpty={true}
          cells={(row, col) =>
            getIrsJournalCellProperties(sheet, headers, row, col)
          }
        />
      )}
    </div>
  );
};
