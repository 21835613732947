import { useState, useCallback } from 'react';

const useLoading = <T>(): [boolean, (promise: Promise<T>) => Promise<T>] => {
  const [isLoading, setIsLoading] = useState(false);

  const execute = useCallback(async (promise: Promise<T>): Promise<T> => {
    setIsLoading(true);

    try {
      const result = await promise;
      return result;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return [isLoading, execute];
};

export default useLoading;
