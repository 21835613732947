import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NavLinks } from './NavLinks';
import { useState } from 'react';
import { ChevronUpIcon } from '../icons/ChevronUpIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { Button } from '../Button';
import { HedgehogLogo } from '../icons/HedgehogLogo';
import { navLink } from './navLink.constant';
import { logout } from '../../services/hedgehog/user.api';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { useUser } from '../../hooks/useUser';
import { NavMobileMenu } from './NavMobileMenu';

export const ADMIN_DASHBOARD_ROUTE = '/admin/home';
export const USER_DASHBOARD_ROUTE = '/user/home';

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user, setUserInfo } = useUser();
  const { menuRef, buttonRef } = useOutsideClick(() => {
    setIsMenuOpen(false);
  });

  const { pathname } = useLocation();

  const navLinks =
    !pathname.includes('/user') && !pathname.includes('/organization')
      ? navLink
      : undefined;

  const handleLogout = () => {
    logout();
    setUserInfo(undefined);
    navigate('/');
  };

  return (
    <header id="header">
      <nav className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative z-[90] flex justify-between py-8 ">
        <div className="relative z-20 flex items-center gap-16 w-52">
          <Link to="/" aria-label="Home">
            <HedgehogLogo
              svgIconStyles="text-black w-56 p-2"
              logoTitleStyles="text-primary-dark fill-current"
              logoIconStyles="text-primary-cyan-500 fill-current"
            />
          </Link>
          <div className="hidden lg:flex lg:gap-10">
            <NavLinks link={navLink} />
          </div>
        </div>
        <div className="flex items-center gap-6">
          <div className="lg:hidden flex flex-row items-center gap-2 h-full">
            <button
              className="relative z-20 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 ui-not-focus-visible:outline-none"
              aria-label="Toggle site navigation"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              data-cy="mobile-menu-button"
              ref={buttonRef}
            >
              {isMenuOpen ? (
                <ChevronUpIcon
                  className="h-6 w-6"
                  data-cy="navbar-close-icon"
                />
              ) : (
                <MenuIcon className="h-6 w-6" data-cy="navbar-open-icon" />
              )}
            </button>

            {isMenuOpen && (
              <NavMobileMenu
                link={navLinks}
                menuRef={menuRef}
                userRole={user?.role}
                onClickOutside={() => setIsMenuOpen(false)}
              />
            )}
          </div>
          {user && (
            <Button
              data-cy="navbar-logout-button"
              to={'/'}
              variant="outline"
              className="hidden lg:block"
              onClick={handleLogout}
            >
              Log out
            </Button>
          )}
          {!user && (
            <Button
              data-cy="navbar-login-button"
              to={'/'}
              variant="solid"
              color="cyan"
              className="hidden lg:block hover:bg-primary-cyan-600 text-white"
            >
              Log In
            </Button>
          )}
        </div>
      </nav>
    </header>
  );
};
