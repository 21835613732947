import { Outlet, useLocation, useParams } from 'react-router-dom';
import AdminAuthGuard from '../../../components/guards/AdminAuthGuard';
import { DashboardHeader } from '../DashboardHeader';
import { SimpleAlert } from '../../../components/alerts/SimpleAlert';
import {
  ADMIN_DASHBOARD_ROUTE,
  Navbar,
} from '../../../components/navbar/Navbar';
import { useOrganization } from '../../../hooks/useOrganization';
import { SimpleLoading } from '../../../components/loading/SimpleLoading';
import { AdminSidebar } from '../../../components/sidebar/AdminSidebar';

export const OrganizationProfileViewLayout = () => {
  const { id } = useParams();

  const organizationId = Number(id);
  const { pathname } = useLocation();
  const isOrganizationView = pathname.includes('organization');

  const { organization, isLoading } = useOrganization(organizationId);

  if (isNaN(organizationId)) {
    const alertRoute = {
      name: 'Admin Home',
      route: ADMIN_DASHBOARD_ROUTE,
    };
    return (
      <SimpleAlert
        alertMessage="Page not found"
        alertType="danger"
        alertRoute={alertRoute}
      />
    );
  }

  if (isLoading) {
    return (
      <div className="h-screen flex flex-row justify-center items-center">
        <SimpleLoading
          loadingColor="text-primary-cyan-500"
          loadingSize="h-20 w-20"
        />
      </div>
    );
  }

  return (
    <AdminAuthGuard>
      <div className="h-screen">
        <div className="flex h-screen overflow-hidden">
          {isOrganizationView && <AdminSidebar />}
          <div
            className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-gray-100"
            data-cy="user-dashboard-layout"
          >
            <div className="block md:hidden">
              <Navbar />
            </div>
            <DashboardHeader adminOrganization={organization} />
            <main>
              <div className="mx-auto p-4 md:p-6">
                <Outlet />
              </div>
            </main>
          </div>
        </div>
      </div>
    </AdminAuthGuard>
  );
};
