import { DetailedSettings } from 'handsontable/plugins/mergeCells';
import * as ExcelJS from 'exceljs';
import { CellProperties } from 'handsontable/settings';
import { isCellWithFormula } from './utils/isCellWithFormula';

interface IMergeTypes {
  totalRowContent: DetailedSettings;
  hedgeType: DetailedSettings;
  rightContent: DetailedSettings;
}

const mergeTypes: IMergeTypes = {
  totalRowContent: {
    row: 0,
    col: 0,
    rowspan: 1,
    colspan: 5,
  },
  hedgeType: {
    row: 0,
    col: 0,
    rowspan: 1,
    colspan: 3,
  },
  rightContent: {
    row: 0,
    col: 0,
    rowspan: 1,
    colspan: 4,
  },
};

type MergeType = keyof typeof mergeTypes;

const getHedgeDocumentationMergeConfig = (
  mergeType: MergeType,
  row: number,
) => {
  const mergeOption: DetailedSettings = mergeTypes[mergeType];

  return { ...mergeOption, row };
};

export const handleMergeCellsConfig = (sheet: ExcelJS.CellValue[][]) => {
  const sheetLength = sheet.length;

  const lengthWithoutPolicy = 63;
  const lengthWithPolicy = 65;
  let headerRowWithPolicy: number[] = [];
  let hedgeType: number[] = [];
  let detailsHedgingInstrument: number[] = [];
  let detailsHedgedItem: number[] = [];
  let assessmentHedgeEffectiveness: number[] = [];
  let matchedTermsInception: number[] = [];
  let subHeaders: number[] = [];
  let finalHeaders: number[] = [];

  if (sheetLength === lengthWithoutPolicy) {
    headerRowWithPolicy = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 13, 14, 15, 26, 35, 36, 41, 51,
    ];
    hedgeType = [9, 10, 11, 12];
    detailsHedgingInstrument = [16, 17, 18, 19, 20, 21, 22, 23, 24, 25];
    detailsHedgedItem = [27, 28, 29, 30, 31, 32, 33, 34];
    assessmentHedgeEffectiveness = [37, 38, 39, 40];
    matchedTermsInception = [42, 43, 44, 45, 46, 47];
    subHeaders = [48, 49, 50, 52, 53, 54, 55, 56, 57, 58, 59];
    finalHeaders = [60, 61, 62];
  } else if (sheetLength === lengthWithPolicy) {
    headerRowWithPolicy = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    hedgeType = [11, 12, 13, 14];
    detailsHedgingInstrument = [18, 19, 20, 21, 22, 23, 24, 25, 26, 27];
    detailsHedgedItem = [29, 30, 31, 32, 33, 34, 35, 36];
    assessmentHedgeEffectiveness = [39, 40, 41, 42];
    matchedTermsInception = [44, 45, 46, 47, 49];
    subHeaders = [
      15, 16, 17, 28, 37, 38, 43, 48, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
      60, 61,
    ];
    finalHeaders = [62, 63, 64];
  }

  const mergeSettings: DetailedSettings[] = [];

  headerRowWithPolicy.forEach((row: number) => {
    const mergeSetting = getHedgeDocumentationMergeConfig(
      'totalRowContent',
      row,
    );
    mergeSettings.push(mergeSetting);
  });

  hedgeType.forEach((row: number) => {
    const mergeSetting = getHedgeDocumentationMergeConfig('hedgeType', row);
    mergeSettings.push(mergeSetting);
  });

  detailsHedgingInstrument.forEach((row: number) => {
    const mergeSetting = getHedgeDocumentationMergeConfig('rightContent', row);
    mergeSettings.push(mergeSetting);
  });

  detailsHedgedItem.forEach((row: number) => {
    const mergeSetting = getHedgeDocumentationMergeConfig('rightContent', row);
    mergeSettings.push(mergeSetting);
  });

  assessmentHedgeEffectiveness.forEach((row: number) => {
    const mergeSetting = getHedgeDocumentationMergeConfig('rightContent', row);
    mergeSettings.push(mergeSetting);
  });

  matchedTermsInception.forEach((row: number) => {
    const mergeSetting = getHedgeDocumentationMergeConfig('rightContent', row);
    mergeSettings.push(mergeSetting);
  });

  finalHeaders.forEach((row: number) => {
    const mergeSetting = getHedgeDocumentationMergeConfig('rightContent', row);
    mergeSettings.push(mergeSetting);
  });

  subHeaders.forEach((row: number) => {
    const mergeSetting = getHedgeDocumentationMergeConfig(
      'totalRowContent',
      row,
    );
    mergeSettings.push(mergeSetting);
  });

  return mergeSettings;
};

export const customRender = (
  TD: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: string,
  cellProperties: CellProperties,
) => {
  if ((row === 23 || row === 25) && col === 4 && isCellWithFormula(value)) {
    const formula = value.formula;
    const match = RegExp(/(\d+.\d+)/).exec(formula);

    if (match) {
      const percentage = parseFloat(match[1]);
      const baseText = 'Fixed Rate of';
      const formattedRate = (percentage * 100).toFixed(3) + '%';
      TD.textContent = `${baseText} ${formattedRate}`;
    } else {
      TD.textContent = 'Cannot be calculated';
    }
  }
  return TD;
};
