import { Formik, Form } from 'formik';
import { FormField } from '../form-field/FormField';
import { emailValidationSchema } from './emailForm.schema';
import { Button } from '../Button';

const initialValues = {
  email: '',
};

const emailFormField = {
  label: 'Email',
  id: 'email',
  name: 'email',
  type: 'email',
  placeholder: 'Enter your email',
};

export const EmailForm = ({
  buttonMessage,
  dataCy,
  onSubmit,
}: {
  buttonMessage: string;
  dataCy: string;
  onSubmit: (values: typeof initialValues) => any;
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={emailValidationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      <Form>
        <FormField {...emailFormField} />
        <Button
          data-cy={dataCy}
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 font-medium"
        >
          {buttonMessage}
        </Button>
      </Form>
    </Formik>
  );
};
