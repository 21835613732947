import * as ExcelJS from 'exceljs';
import { MtmValuationReportSheetName } from '../../components/tables/mtm-valuation-table/enum/MtmValuationReportSheetName';
import { FX_MATURED_DEALS_REPORT_SHEET_NAME } from '../../components/tables/fx-matured-deals-report/ViewFxMaturedDealsReportTable';
import { maturedDealsSheetMock } from './mocks/matured-deals-mock';
import { FX_MTM_VALUATION_SHEET_NAME } from '../../components/tables/fx-mtm-valuation/ViewFxMtmValuationReportTable';
import { fxMtmValuationSheetMock } from './mocks/fx-mtm-valuation-mock';
import { FxMtmJournalReportSheetNames } from '../../components/tables/fx-mtm-journal/ViewFxMtmJournalReportTable';
import {
  fxMtmJournalSheetMock,
  fxMtmJournalWorkingSheetMock,
} from './mocks/fx-mtm-journal-mock';
import {
  fxHedgeDocumentationStandardSheetMock,
  fxHedgeDocumentationSyntheticSheetMock,
} from './mocks/fx-hedge-documentation-mock';
import {
  FX_HEDGE_DOCUMENTATION_STANDARD_SHEET_NAME,
  FX_HEDGE_DOCUMENTATION_SYNTHETIC_SHEET_NAME,
} from '../../components/tables/fx-hedge-documentation-form/ViewFxHedgeDocumentationForm';
import { fxHedgeEffectivenessActualsSheetMock } from './mocks/fx-hedge-effectiveness-actuals-mock';
import { FxHedgeEffectivenessReportSheetNames } from '../../components/tables/fx-hedge-effectiveness/ViewFxHedgeEffectivenessReportTable';
import { fxHedgeEffectivenessTestSheetMock } from './mocks/fx-hedge-effectiveness-test-mock';
import { fxHedgeEffectivenessJournalSheetMock } from './mocks/fx-hedge-effectiveness-journal-mock';
import { FX_ANALYSIS_REPORT_NAME } from '../../components/modal/fx-analysis-report-modal/FxAnalysisReportModal';
import { fxAnalysisReportSheetMock } from './mocks/fx-analysis-report-mock';

export const parseReportBlobFileToWorkbook = async (
  report: Blob,
  reportName?: string,
) => {
  try {
    if (typeof Cypress !== 'undefined' && Cypress.env('testing')) {
      return createExcelWorkbookMock();
    }

    const workbook = new ExcelJS.Workbook();
    const buffer = await report.arrayBuffer();
    await workbook.xlsx.load(buffer);
    const oldSheetName = workbook.worksheets[0].name;
    const worksheet = workbook.getWorksheet(oldSheetName);
    if (worksheet && reportName) {
      worksheet.name = reportName;
    }
    return workbook;
  } catch (error) {
    if (error instanceof Error) throw new Error(error.message);
  }
};

const createExcelWorkbookMock = () => {
  const workbook = new ExcelJS.Workbook();

  const actualWorksheetName = Cypress.env('worksheetName');

  const actualWorksheet = workbook.addWorksheet(actualWorksheetName);

  const worksheetMap: Record<string, any[][]> = {
    [FX_MATURED_DEALS_REPORT_SHEET_NAME]: maturedDealsSheetMock,
    [FX_MTM_VALUATION_SHEET_NAME]: fxMtmValuationSheetMock,
    [FxMtmJournalReportSheetNames.FX_MTM_JOURNAL_WORKING]:
      fxMtmJournalWorkingSheetMock,
    [FxMtmJournalReportSheetNames.FX_MTM_JOURNAL]: fxMtmJournalSheetMock,
    [FX_HEDGE_DOCUMENTATION_STANDARD_SHEET_NAME]:
      fxHedgeDocumentationStandardSheetMock,
    [FX_HEDGE_DOCUMENTATION_SYNTHETIC_SHEET_NAME]:
      fxHedgeDocumentationSyntheticSheetMock,
    [FxHedgeEffectivenessReportSheetNames.ACTUAL]:
      fxHedgeEffectivenessActualsSheetMock,
    [FxHedgeEffectivenessReportSheetNames.TEST]:
      fxHedgeEffectivenessTestSheetMock,
    [FxHedgeEffectivenessReportSheetNames.JOURNAL]:
      fxHedgeEffectivenessJournalSheetMock,
    [FX_ANALYSIS_REPORT_NAME]: fxAnalysisReportSheetMock,
  };

  if (worksheetMap[actualWorksheetName]) {
    worksheetMap[actualWorksheetName].forEach((row) => {
      actualWorksheet.addRow(row);
    });
    return workbook;
  }

  const hypoWorksheet = workbook.addWorksheet(
    MtmValuationReportSheetName.HypoMTMValuationReport,
  );

  const headers = [
    'Trade Reference Number',
    'Legal Entity',
    'Trade Date',
    'Fixed Rate',
  ];
  const organizationName = ['Scalemote'];
  const tradeDate = ['22-04-2022'];

  actualWorksheet.addRow(organizationName);
  actualWorksheet.addRow(tradeDate);
  actualWorksheet.addRow(headers);
  actualWorksheet.addRow(['123456', 'Scalemote', '22-04-2022', '0.1']);

  hypoWorksheet.addRow(organizationName);
  hypoWorksheet.addRow(tradeDate);
  hypoWorksheet.addRow(headers);
  hypoWorksheet.addRow(['123456 H', 'Hedgehog', '22-04-2022', '0.1']);

  return workbook;
};
