import { useEffect, useState } from 'react';

import HotTable from '@handsontable/react';
import { Button } from '../../Button';
import {
  customRenderer,
  irsMtmValuationCellsConfig,
} from './mtmValuationColumnConfig';
import { MtmValuationReportSheetName } from './enum/MtmValuationReportSheetName';
import { useLoadWorkbook } from '../../../hooks/useLoadWorkbook';
import { useLoadBasicSheet } from '../../../hooks/useLoadBasicSheet';
import { v4 as uuidv4 } from 'uuid';
import { getCellProperties, insertEmptyRowAfter } from '../common/utils';

interface IMtmValuationTableProps {
  report: Blob;
}

const headers = [
  /^Trade Reference Number$/,
  /^Trade Reference$/,
  /^Legal Entity$/,
  /^Trade (\n)?Date$/,
  /^Effective (\n)?Date$/,
  /^Maturity (\n)?Date$/,
  /^Fixed (\n)?Rate$/,
  /^Currency$/,
  /^Notional$/,
  /^Notional \nAmount$/,
  /^Swap (\n)?Index$/,
  /^Floating (\n)?Rate$/,
  /^Next Reset Date$/,
  /^Last Reset Date$/,
  /^Counterparty$/,
  /^Valuation (\n)?Currency$/,
  /^Intrinsic Value$/,
  /^Time Value$/,
  /^Accrued (\n)?Interest$/,
  /^Clean (\n)?Fair Value$/,
  /^Total (\n)?Fair Value$/,
  /^CVA$/,
  /^DVA$/,
  /^Risk Free (\n)?Valuation$/,
];

export const MtmValuationTable = ({ report }: IMtmValuationTableProps) => {
  const [selectedSheet, setSelectedSheet] =
    useState<MtmValuationReportSheetName>(
      MtmValuationReportSheetName.ActualMTMValuationReport,
    );

  const { workbook } = useLoadWorkbook(
    report,
    'Error loading IRS MTM Valuation Report',
  );

  const { sheet: originalSheet } = useLoadBasicSheet(workbook, selectedSheet);
  const [sheet, setSheet] = useState<any[][]>([]);

  useEffect(() => {
    if (originalSheet) {
      const updatedSheet = insertEmptyRowAfter(originalSheet, 'Total');
      setSheet(updatedSheet);
    }
  }, [originalSheet]);

  const handleButtonClick = (sheetName: MtmValuationReportSheetName) => {
    setSelectedSheet(sheetName);
  };

  return (
    <>
      <div>
        <div className="flex justify-center gap-2 p-2 text-lg font-semibold text-left text-gray-900">
          {Object.values(MtmValuationReportSheetName).map((sheetName) => (
            <Button
              key={uuidv4()}
              onClick={() => handleButtonClick(sheetName)}
              data-cy={`${sheetName}-button`}
              disabled={selectedSheet === sheetName}
              variant={selectedSheet === sheetName ? 'solid' : 'outline'}
            >
              {sheetName}
            </Button>
          ))}
        </div>
      </div>
      <div className="w-full h-full">
        {sheet.length > 0 && (
          <HotTable
            data={sheet}
            afterRenderer={(...args) => customRenderer(...args, sheet)}
            rowHeaders={false}
            colHeaders={false}
            width="auto"
            height="auto"
            licenseKey="non-commercial-and-evaluation"
            rowHeights={40}
            colWidths={(col) => (col === 0 || col === 1 ? 210 : 150)}
            manualColumnResize={true}
            fixedRowsTop={3}
            mergeCells={irsMtmValuationCellsConfig(sheet)}
            wordWrap={true}
            allowEmpty={true}
            cells={(row, col) => getCellProperties(sheet, headers, row, col)}
          />
        )}
      </div>
    </>
  );
};
