import { Formik, Form } from 'formik';
import { FormField } from '../../form-field/FormField';
import { confirmationValidationSchema } from './confirmationForm.schema';
import { Button } from '../../Button';

const initialValues = {
  'new-password': '',
  'confirm-new-password': '',
  code: '',
};

const confirmationFormFields = {
  newPassword: {
    label: 'New password',
    id: 'new-password',
    name: 'new-password',
    type: 'password',
    placeholder: 'Enter your new password',
  },
  confirmNewPassword: {
    label: 'Confirm new password',
    id: 'confirm-new-password',
    name: 'confirm-new-password',
    type: 'password',
    placeholder: 'Confirm your new password',
  },
  code: {
    label: 'Code',
    id: 'code',
    name: 'code',
    type: 'text',
    placeholder: 'Enter your code',
  },
};

export const ConfirmationForm = ({
  onSubmit,
}: {
  onSubmit: (values: typeof initialValues) => any;
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={confirmationValidationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      <Form>
        <FormField {...confirmationFormFields.newPassword} />
        <FormField {...confirmationFormFields.confirmNewPassword} />
        <FormField {...confirmationFormFields.code} />

        <Button
          data-cy="confirmation-form-button"
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 font-medium"
        >
          Confirm
        </Button>
      </Form>
    </Formik>
  );
};
