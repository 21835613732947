import * as yup from 'yup';
import { IStaticField, IStaticSegment } from '../../OutputReportTypes';

const generateCustomValidation = (fieldName: string) => {
  return function (value: string | undefined, context: yup.TestContext) {
    const regex = /^No\.\s.+/;

    if (!value) {
      throw context.createError({
        message: `${fieldName} is required`,
      });
    }

    if (value === 'Yes' || regex.test(value)) {
      return true;
    }

    if (value === 'No') {
      throw context.createError({
        message:
          'Explanation is required when choosing "No". Please provide a valid explanation starting with "No. + Explanation". Example: "No. It is unnecessary right now".',
      });
    }
    throw context.createError({
      message:
        'Please enter only "Yes" and if you enter "No", provide a valid explanation following the period. Example: "No. It is unnecessary right now".',
    });
  };
};

const defaultValuesIRSTemplate = {
  natureOfHedgedRisk: 'Cash flow interest rate risk',
  policyDeclaration:
    'In accoradance with the Treasury Risk Management Policy, interest rate exposure is the risk that funding costs will vary as a direct consequence of changes in the BBSY reference rate. The aim is to minimise the negative effects which can occur from fluctuations in the BBSY interest rates.',
  riskManagementObjective: '',
  riskManagementStrategy: '',
  cashFlowHedge: 'Yes',
  fairValueHedge: 'No',
  netInvestmenthHedge: 'No',
  amountOfPrincipalHedgingInstrumentPercentaje: 100,
  natureOfHedgedItem: 'Financing Facility',
  amountDesignatedAsHedgedItemPercentage: 100,
  fairValueAtInception: 'Nil',
  canHedgeEffectivenessBeReliablyMeasured: 'Yes',

  expectationOfHedgeEffectiveness: 'Highly Effective',
  typeOfTest: 'Qualitative & Quantitative Testing',

  typeOfTestExplanation:
    // eslint-disable-next-line quotes
    "In order to assess the hedge effectiveness we can support the explanation that the economic relationship exists by performing a quantitative assessment comparing the cumulative changes in fair value of the hedged items' attributable to the hedged risks from inception of the hedging relationship to the cumulative changes in fair value of the hedging instrument for the same period.",
  notionalPrincipleAmount: 'Yes',
  currency: 'Yes',
  inceptionDate: 'Yes',
  maturityDate: 'Yes',
  principleAndCouponPaymentsDates: 'Yes',
  underlyingRiskMatch: 'Yes',
  existenceOfAnEconomicRelationship:
    'An economic relationship exists. The changes in the fair value of the hedging instrument and the hedged item are both based on the same underlying risks. Therefore it is expected that the hedging instruments and the hedged items will move systematically in the opposite direction and offset each other.',
  effectOfChangesInCreditRisk:
    // eslint-disable-next-line quotes
    "Credit risk arises from the credit rating of the Company and the counterparty to the hedging instrument(s). The Company monitors the counterparty's credit risk for any significant changes. The credit risk associated with Company and the counterparty is considered minimal and will be re-assessed in cases where there is a significant change in any party’s circumstances. The credit risk of the counterparty is not expected to change significantly over the life of the contract.",
  hedgeRatioApplied:
    'The hedge ratio is 1:1, i.e. the same quantity of hedged item and hedging instrument is designated for hedge accounting purposes as it is for risk management purposes.',
  howIneffectivenessWillBeQuantified:
    'Cumulative ineffectiveness within the relationship will be measured by calculating the difference between cumulative changes in the fair value of the hedged item (hypothetical derivative) attributable to the hedged risks from inception of the hedging relationship, and cumulative changes in the fair value of the hedging instrument (to the extent it has been designated) for the same period. The amount of ineffectiveness to record in the profit and loss for the current reporting period is equal to the change in the total ineffectiveness balance from the previous reporting date, calculated as above.',
  expectedIneffectiveness:
    'Potential sources of ineffectiveness include: \n- Changes in the timing of the payments on the hedged item. \n- Changes in the credit risk of the entity or the counterparty. \n- Credit and execution costs of the bank (costs of hedging). ',
  testingFrequency:
    'All the hedge effectiveness requirements listed above are assessed at inception of the hedging relationship, and on an ongoing basis. The ongoing assessment is performed at a minimum on each reporting date or upon a significant change in circumstances affecting hedge effectiveness requirement, whichever comes first.',
  natureOfHedgingInstrument: 'Interest Rate Swap',
  preparedBy: 'Kevin Mitchell - Partner - Rochford Capital Pty Ltd',
  reviewedBy: 'Darryl Hughes - GM Corporate Finance - Adbri Ltd',
};

export const HedgeDocumentationFormStaticData: {
  fields: IStaticField[];
  segment: IStaticSegment;
  validationSchema: any;
} = {
  fields: [
    {
      label: 'Name',
      name: 'name',
      type: 'string',
      defaultValue: 'IRS Hedge Documentation Form',
    },
    {
      label: 'Hedge Documentation prepared by',
      name: 'preparedBy',
      type: 'string',
    },
    {
      label: 'Hedge Documentation reviewed by',
      name: 'reviewedBy',
      type: 'string',
    },
  ],
  segment: {
    display: true,
    buttonOpenName: 'More Options',
    buttonCloseName: 'Less Options',
    sections: [
      {
        fields: [
          {
            label: 'Nature of risk hedged',
            name: 'natureOfHedgedRisk',
            type: 'string',
            defaultValue: defaultValuesIRSTemplate.natureOfHedgedRisk,
          },
          {
            label: 'Policy Declaration (Optional: No entry for optional)',
            name: 'policyDeclaration',
            type: 'textarea',
            rows: 2,
          },
          {
            label: 'Risk Management Objective',
            name: 'riskManagementObjective',
            type: 'textarea',
            rows: 2,
            defaultValue: defaultValuesIRSTemplate.riskManagementObjective,
          },
          {
            label: 'Risk Management Strategy',
            name: 'riskManagementStrategy',
            type: 'textarea',
            rows: 2,
            defaultValue: defaultValuesIRSTemplate.riskManagementStrategy,
          },
        ],
        name: 'Introduction',
      },
      {
        fields: [
          {
            label: 'Cash Flow Hedge',
            name: 'cashFlowHedge',
            type: 'radio',
            options: ['Yes', 'No'],
            defaultValue: defaultValuesIRSTemplate.cashFlowHedge,
          },
          {
            label: 'Fair Value Hedge',
            name: 'fairValueHedge',
            type: 'radio',
            options: ['Yes', 'No'],
            defaultValue: defaultValuesIRSTemplate.fairValueHedge,
          },
          {
            label: 'Net Investment Hedge',
            name: 'netInvestmenthHedge',
            type: 'radio',
            options: ['Yes', 'No'],
            defaultValue: defaultValuesIRSTemplate.netInvestmenthHedge,
          },
        ],
        name: 'Hedge Type',
      },
      {
        fields: [
          {
            label:
              'Amount of principal designated as hedging instrument (Percentage)',
            name: 'amountOfPrincipalHedgingInstrumentPercentaje',
            type: 'number',
            defaultValue:
              defaultValuesIRSTemplate.amountOfPrincipalHedgingInstrumentPercentaje,
          },
          {
            label: 'Nature of the hedged item',
            name: 'natureOfHedgedItem',
            type: 'string',
            defaultValue: defaultValuesIRSTemplate.natureOfHedgedItem,
          },
          {
            label: 'Amount designated as a hedged item (Percentage)',
            name: 'amountDesignatedAsHedgedItemPercentage',
            type: 'number',
            defaultValue:
              defaultValuesIRSTemplate.amountDesignatedAsHedgedItemPercentage,
          },
          {
            label: 'Fair value at inception',
            name: 'fairValueAtInception',
            type: 'string',
            defaultValue: defaultValuesIRSTemplate.fairValueAtInception,
          },
          {
            label: 'Nature of hedging instrument',
            name: 'natureOfHedgingInstrument',
            type: 'string',
            defaultValue: defaultValuesIRSTemplate.natureOfHedgingInstrument,
          },
        ],
        name: 'Details of the Hedging Instrument',
      },
      {
        name: 'Effectiveness testing at inception and ongoing',
        fields: [
          {
            label: 'Can hedge effectiveness be reliably measured',
            name: 'canHedgeEffectivenessBeReliablyMeasured',
            type: 'radio',
            options: ['Yes', 'No'],
            defaultValue:
              defaultValuesIRSTemplate.canHedgeEffectivenessBeReliablyMeasured,
          },
          {
            label: 'Expectation of hedge effectiveness',
            name: 'expectationOfHedgeEffectiveness',
            type: 'select',
            options: ['Effective', 'Highly Effective'],
            defaultValue:
              defaultValuesIRSTemplate.expectationOfHedgeEffectiveness,
          },
          {
            label: 'Type of test',
            name: 'typeOfTest',
            type: 'textarea',
            rows: 2,
            defaultValue: defaultValuesIRSTemplate.typeOfTest,
          },
          {
            label: 'Type of test Explanation',
            name: 'typeOfTestExplanation',
            type: 'textarea',
            rows: 3,
            defaultValue: defaultValuesIRSTemplate.typeOfTestExplanation,
          },
        ],
      },
      {
        name: 'Matched terms at inception Part 1',
        fields: [
          {
            label: 'Notional/Principle Amount',
            name: 'notionalPrincipleAmount',
            type: 'string',
            defaultValue: defaultValuesIRSTemplate.notionalPrincipleAmount,
          },
          {
            label: 'Currency',
            name: 'currency',
            type: 'string',
            defaultValue: defaultValuesIRSTemplate.currency,
          },
          {
            label: 'Inception Date',
            name: 'inceptionDate',
            type: 'string',
            defaultValue: defaultValuesIRSTemplate.inceptionDate,
          },
          {
            label: 'Maturity Date',
            name: 'maturityDate',
            type: 'string',
            defaultValue: defaultValuesIRSTemplate.maturityDate,
          },
        ],
      },
      {
        name: 'Matched terms at inception Part 2',
        fields: [
          {
            label: 'Principle and coupon payments dates',
            name: 'principleAndCouponPaymentsDates',
            type: 'string',
            defaultValue:
              defaultValuesIRSTemplate.principleAndCouponPaymentsDates,
          },
          {
            label: 'Underlying risk(s) match',
            name: 'underlyingRiskMatch',
            type: 'string',
            defaultValue: defaultValuesIRSTemplate.underlyingRiskMatch,
          },
        ],
      },
      {
        name: 'Final headings Part 1',
        fields: [
          {
            label: 'Existence of an economic relationship',
            name: 'existenceOfAnEconomicRelationship',
            type: 'textarea',
            rows: 3,
            defaultValue:
              defaultValuesIRSTemplate.existenceOfAnEconomicRelationship,
          },
          {
            label: 'Effect of changes in credit risk',
            name: 'effectOfChangesInCreditRisk',
            type: 'textarea',
            rows: 3,
            defaultValue: defaultValuesIRSTemplate.effectOfChangesInCreditRisk,
          },
          {
            label: 'Hedge ratio applied',
            name: 'hedgeRatioApplied',
            type: 'textarea',
            rows: 2,
            defaultValue: defaultValuesIRSTemplate.hedgeRatioApplied,
          },
        ],
      },
      {
        name: 'Final headings Part 2',
        fields: [
          {
            label: 'How Ineffectiveness will be quantified',
            name: 'howIneffectivenessWillBeQuantified',
            type: 'textarea',
            rows: 3,
            defaultValue:
              defaultValuesIRSTemplate.howIneffectivenessWillBeQuantified,
          },
          {
            label: 'Expected ineffectiveness',
            name: 'expectedIneffectiveness',
            type: 'textarea',
            rows: 2,
            defaultValue: defaultValuesIRSTemplate.expectedIneffectiveness,
          },
          {
            label: 'Testing Frequency',
            name: 'testingFrequency',
            type: 'textarea',
            rows: 2,
            defaultValue: defaultValuesIRSTemplate.testingFrequency,
          },
        ],
      },
    ],
  },

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .trim()
      .min(1, 'Name must be at least 1 characters')
      .required('Name is required'),
    natureOfHedgedRisk: yup
      .string()
      .trim()
      .min(1, 'Nature of risk hedged must be at least 1 characters')
      .required('Nature of risk hedged is required'),
    policyDeclaration: yup
      .string()
      .trim()
      .min(1, 'Policy Declaration must be at least 1 characters')
      .optional(),
    riskManagementObjective: yup
      .string()
      .trim()
      .min(1, 'Risk Management Objective must be at least 1 characters')
      .required('Risk Management Objective is required'),
    riskManagementStrategy: yup
      .string()
      .trim()
      .min(1, 'Risk Management Strategy must be at least 1 characters')
      .required('Risk Management Strategy is required'),
    cashFlowHedge: yup
      .string()
      .oneOf(['Yes', 'No'], 'Must be either "Yes" or "No"')
      .required('Cash Flow Hedge is required'),
    fairValueHedge: yup
      .string()
      .oneOf(['Yes', 'No'], 'Must be either "Yes" or "No"')
      .required('Fair Value Hedge is required'),
    netInvestmenthHedge: yup
      .string()
      .required('Net Investment Hedge is required')
      .oneOf(['Yes', 'No'], 'Must be either "Yes" or "No"'),
    amountOfPrincipalHedgingInstrumentPercentaje: yup
      .number()
      .positive(
        'Amount of principal designated as hedging instrument must be positive',
      )
      .max(
        100,
        'Amount of principal designated as hedging instrument must be at most 100',
      )
      .required(
        'Amount of principal designated as hedging instrument is required',
      ),
    natureOfHedgedItem: yup
      .string()
      .trim()
      .min(1, 'Nature of hedged item must be at least 1 characters')
      .required('Nature of hedged item is required'),
    amountDesignatedAsHedgedItemPercentage: yup
      .number()
      .positive('Amount designated as a hedged item must be positive')
      .max(100, 'Amount designated as a hedged item must be at most 100')
      .required('Amount designated as a hedged item is required'),
    fairValueAtInception: yup
      .string()
      .trim()
      .min(1, 'Fair value at inception must be at least 1 characters')
      .required('Fair value at inception is required'),
    canHedgeEffectivenessBeReliablyMeasured: yup
      .string()
      .oneOf(['Yes', 'No'], 'Must be either "Yes" or "No"')
      .required('Can hedge effectiveness be reliably measured is required'),
    expectationOfHedgeEffectiveness: yup
      .string()
      .oneOf(
        ['Effective', 'Highly Effective'],
        'Must be either "Effective" or "Highly Effective"',
      )
      .required('Expectation of hedge effectiveness is required'),
    typeOfTest: yup
      .string()
      .trim()
      .min(1, 'Type of test must be at least 1 characters')
      .required('Type of test is required'),
    typeOfTestExplanation: yup
      .string()
      .trim()
      .min(1, 'Type of test explanation must be at least 1 characters')
      .required('Type of test explanation is required'),
    notionalPrincipleAmount: yup
      .string()
      .test(
        'customValidation',
        'Invalid input',
        generateCustomValidation('Notional/Principle Amount'),
      ),
    currency: yup
      .string()
      .test(
        'customValidation',
        'Invalid input',
        generateCustomValidation('Currency'),
      ),
    inceptionDate: yup
      .string()
      .test(
        'customValidation',
        'Invalid Input',
        generateCustomValidation('Inception Date'),
      ),
    maturityDate: yup
      .string()
      .test(
        'customValidation',
        'Invalid Input',
        generateCustomValidation('Maturity Date'),
      ),
    principleAndCouponPaymentsDates: yup
      .string()
      .test(
        'customValidation',
        'Invalid Input',
        generateCustomValidation('Principle and coupon payments dates'),
      ),
    underlyingRiskMatch: yup
      .string()
      .test(
        'customValidation',
        'Invalid Input',
        generateCustomValidation('Underlying risk(s) match'),
      ),
    existenceOfAnEconomicRelationship: yup
      .string()
      .trim()
      .min(
        1,
        'Existence of an economic relationship must be at least 1 characters',
      )
      .required('Existence of an economic relationship is required'),
    effectOfChangesInCreditRisk: yup
      .string()
      .trim()
      .min(1, 'Effect of changes in credit risk must be at least 1 characters')
      .required('Effect of changes in credit risk is required'),
    hedgeRatioApplied: yup
      .string()
      .trim()
      .min(1, 'Hedge ratio applied must be at least 1 characters')
      .required('Hedge ratio applied is required'),
    howIneffectivenessWillBeQuantified: yup
      .string()
      .trim()
      .min(1, 'How ineffectiveness will be quantified is required')
      .required('How ineffectiveness will be quantified is required'),
    expectedIneffectiveness: yup
      .string()
      .trim()
      .min(1, 'Expected ineffectiveness is required')
      .required('Expected ineffectiveness is required'),
    testingFrequency: yup
      .string()
      .trim()
      .min(1, 'Testing Frequency is required')
      .required('Testing Frequency is required'),
    preparedBy: yup
      .string()
      .matches(
        /^[A-Za-z\s]+ - [A-Za-z\s]+ - [A-Za-z\s]+$/,
        'Please provide a valid format: "Name - Position - Company"',
      )
      .required('Hedge Documentation Prepared By is required'),
    reviewedBy: yup
      .string()
      .matches(
        /^[A-Za-z\s]+ - [A-Za-z\s]+ - [A-Za-z\s]+$/,
        'Please provide a valid format: "Name - Position - Company"',
      )
      .required('Hedge Documentation Prepared By is required'),
  }),
};
