import * as Yup from 'yup';
import {
  EMPTY_EXPOSURE_CURRENCY,
  FxHedgeDocumentationFormTemplateOptions,
  fxReportCurrencyRulesStaticData,
} from '../../../services/hedgehog/types/reports/fx-mtm-journal-report/fx-mtm-journal-options';

const generalIndividualValidationSchema = {
  legalEntity: Yup.string().required('Legal Entity is required.'),
};

const irsValidationSchema = {
  hedgeDocumentationFormStaticData: Yup.object().shape({
    natureOfHedgedRisk: Yup.string()
      .trim()
      .min(1, 'Nature of Hedged Risk must be at least 1 characters')
      .required('Nature of Hedged Risk is required.'),
    preparedBy: Yup.string()
      .matches(
        /^[A-Za-z\s]+ - [A-Za-z\s]+ - [A-Za-z\s]+$/,
        'Please provide a valid format: "Name - Position - Company"',
      )
      .required('Prepared By is required'),
    reviewedBy: Yup.string()
      .matches(
        /^[A-Za-z\s]+ - [A-Za-z\s]+ - [A-Za-z\s]+$/,
        'Please provide a valid format: "Name - Position - Company"',
      )
      .required('Prepared By is required'),
  }),
};

const fxCurrencyValidationSchema = {
  fxCurrencyRules: Yup.array()
    .of(
      Yup.object().shape({
        baseCurrency: Yup.string().required('Base Currency is required.'),
        quoteCurrency: Yup.string().required('Quote Currency is required.'),
        exposureCurrency: Yup.string()
          .required('Exposure Currency is required.')
          .test(
            'not-dash',
            'Exposure Currency cannot be "-"',
            (value) => value !== EMPTY_EXPOSURE_CURRENCY,
          ),
        month: Yup.string().required('Month is required.'),
        rule: Yup.string().required('Rule is required.'),
        fxHedgeDocumentationFormTemplateOption:
          Yup.mixed<FxHedgeDocumentationFormTemplateOptions>().required(
            'FX Hedge Documentation Form is required.',
          ),
        fxHedgeDocumentationFormTemplateInfo: Yup.object()
          .nullable()
          .notRequired(),
      }),
    )
    .test(
      'unique-currency-combination',
      'Currency combination must be unique.',
      function (currencyRules) {
        const currencyCombinations = new Set();

        if (!currencyRules) return;

        for (const rule of currencyRules) {
          const combination = `${rule.baseCurrency}/${rule.quoteCurrency}`;

          if (
            rule.fxHedgeDocumentationFormTemplateOption !==
              FxHedgeDocumentationFormTemplateOptions.No &&
            !rule.fxHedgeDocumentationFormTemplateInfo
          ) {
            return this.createError({
              path: `${fxReportCurrencyRulesStaticData}[0].baseCurrency`,
              message:
                'Ensure all rules have their template information completed',
            });
          }

          if (rule.baseCurrency === rule.quoteCurrency) {
            return this.createError({
              path: `${fxReportCurrencyRulesStaticData}[0].baseCurrency`,
              message: `Base currency cannot be equal to quote currency for combination "${combination}".`,
            });
          }
          if (currencyCombinations.has(combination)) {
            return this.createError({
              path: `${fxReportCurrencyRulesStaticData}[0].baseCurrency`,
              message: `Duplicate currency combination found for base currency "${rule.baseCurrency}" and quote currency "${rule.quoteCurrency}".`,
            });
          }
          currencyCombinations.add(combination);
        }
        return true;
      },
    )
    .min(1, 'At least one currency rule is required.'),
};

export const fxWithGeneralValidationSchema = Yup.object().shape({
  ...generalIndividualValidationSchema,
  ...fxCurrencyValidationSchema,
});

export const irsWithGeneralValidationSchema = Yup.object().shape({
  ...generalIndividualValidationSchema,
  ...irsValidationSchema,
});

export const fxAndIrsValidationSchema = Yup.object().shape({
  ...generalIndividualValidationSchema,
  ...fxCurrencyValidationSchema,
  ...irsValidationSchema,
});
