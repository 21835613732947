import React from 'react';

type ModalProps = {
  className?: string;
  closeOnOutClick?: boolean;
  close?: () => void;
  children: React.ReactNode;
};

export const Modal = ({
  close,
  closeOnOutClick = false,
  className = '',
  children,
}: ModalProps) => {
  const handleClickOutside = (e: React.MouseEvent<HTMLDialogElement>) => {
    if (closeOnOutClick && e.target === e.currentTarget) {
      close?.();
    }
  };
  return (
    <dialog
      className={`absolute z-[100] inset-0 w-screen h-screen flex flex-col justify-center items-center bg-black bg-opacity-10 ${className}`}
      open
      onClick={handleClickOutside}
    >
      {children}
    </dialog>
  );
};
