import { Formik, Form, FormikErrors } from 'formik';
import {
  GeneratedOutputReportTypes,
  IStaticField,
  IStaticSegment,
} from '../../../services/hedgehog/types/OutputReportTypes';
import { FormField } from '../../form-field/FormField';
import { Modal } from '../Modal';
import { Button } from '../../Button';
import useLoading from '../../../hooks/useLoading';
import { UNEXPECTED_ERROR_MESSAGE } from '../../../error/errorMessages.constants';
import { Toast } from '../../../services/toastify/Toastify';
import { IInputReport } from '../../../services/hedgehog/interfaces/IInputReport';
import { useEffect, useRef, useState } from 'react';
import { generateOutputReport } from '../../../services/hedgehog/hedgehog.api';
import { IOrganization } from '../../../services/hedgehog/interfaces/IOrganization';
import { IHedgeDocumentationFormStaticData } from '../../../services/hedgehog/interfaces/IHedgeDocumentationFormStaticData';
import { SimpleLoading } from '../../loading/SimpleLoading';
import { v4 as uuidv4 } from 'uuid';
import { useQueryClient } from 'react-query';
import { ApiQueryKeys } from '../../../services/hedgehog/api-query-keys.enum';

type Props = {
  onSubmit?: () => void;
  fields: IStaticField[];
  segment?: IStaticSegment;
  validationSchema: any;
  formData: {
    organizationId: number;
    hedgeDocumentationFormId?: number;
    outputReportType: GeneratedOutputReportTypes;
    reports: IInputReport[];
  };
  close: () => void;
  organizationProfileStaticData: {
    organization: IOrganization;
    organizationProfileByOutputType: string | null;
  };
  instantGenerated?: boolean;
};
export const StaticDataModal = ({
  onSubmit,
  fields,
  segment,
  validationSchema,
  formData,
  close,
  organizationProfileStaticData,
  instantGenerated,
}: Props) => {
  const queryClient = useQueryClient();
  const [isGeneratingReport, waitFor] = useLoading();
  const [currentSection, setCurrentSection] = useState<number | undefined>(
    undefined,
  );

  const [isSectionTemplateOpen, setIsSectionTemplateOpen] = useState(false);
  const instantGeneratedRef = useRef(instantGenerated);

  useEffect(() => {
    if (instantGeneratedRef.current) {
      waitFor(handleSubmit(initialValues()));
      instantGeneratedRef.current = false;
    }
  }, [isGeneratingReport, waitFor]);

  const handleSubmit = async (values: { [key: string]: any }) => {
    const staticValues: { [key: string]: any } = values;
    try {
      await generateOutputReport(
        formData.organizationId,
        formData.outputReportType,
        formData.reports,
        {
          ...staticValues,
          hedgeDocumentationFormId: formData.hedgeDocumentationFormId,
        },
      );
      Toast.success('Report generated successfully');
      queryClient.invalidateQueries(
        ApiQueryKeys.organization_reporting_periods_by_id,
      );
      queryClient.invalidateQueries(
        ApiQueryKeys.organization_all_reports_by_month_and_year,
      );

      onSubmit?.();
    } catch (e: unknown) {
      if (e instanceof Error) Toast.error(e.message);
      else Toast.error(UNEXPECTED_ERROR_MESSAGE);
      console.log(e);
    } finally {
      close();
    }
  };

  const initialValues = () => {
    const { organization, organizationProfileByOutputType } =
      organizationProfileStaticData;

    const organizationProfile = organization.organizationProfile;

    const initialFieldValues: { [key: string]: any } = {};
    fields.forEach((field) => {
      initialFieldValues[field.name] = field.defaultValue;

      if (
        organizationProfileByOutputType &&
        organizationProfile[organizationProfileByOutputType].hasOwnProperty(
          field.name,
        )
      ) {
        initialFieldValues[field.name] =
          organizationProfile[organizationProfileByOutputType][
            field.name as keyof IHedgeDocumentationFormStaticData
          ];
      }
    });

    const initialSectionValues: { [key: string]: any } = {};
    segment?.sections.forEach((section) => {
      section.fields.forEach((field) => {
        initialFieldValues[field.name] = field.defaultValue;

        if (
          organizationProfileByOutputType &&
          organizationProfile[organizationProfileByOutputType].hasOwnProperty(
            field.name,
          )
        ) {
          initialFieldValues[field.name] =
            organizationProfile[organizationProfileByOutputType][
              field.name as keyof IHedgeDocumentationFormStaticData
            ];
        }
      });
    });

    return { ...initialFieldValues, ...initialSectionValues };
  };

  const handleSectionChange = (
    errors: FormikErrors<{
      [x: string]: any;
    }>,
    index: number,
  ) => {
    if (Object.keys(errors).length === 0) {
      setCurrentSection(index);
    } else {
      Toast.error('Fix the errors to move to the next section');
    }
  };

  return (
    <>
      {isGeneratingReport && (
        <Modal className="z-[150]">
          <div className="max-h-full overflow-y-auto">
            <div className="flex flex-wrap justify-center items-center relative w-full overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
              <SimpleLoading
                loadingColor="text-primary-cyan-500"
                loadingSize="h-20 w-20"
                externalStyles="flex flex-wrap items-center justify-center p-6"
              />
            </div>
          </div>
        </Modal>
      )}
      <Modal className={`${instantGenerated ? 'hidden' : ''}`}>
        <div className="max-h-full overflow-y-auto">
          <div className="flex flex-col justify-center items-center relative overflow-y-auto z-10 border border-gray-200 shadow-md rounded-lg">
            <Formik
              initialValues={initialValues()}
              validationSchema={validationSchema}
              onSubmit={(values) => waitFor(handleSubmit(values))}
            >
              {({ errors }) => (
                <Form
                  className={`flex flex-col justify-center items-center relative  min-[320px]:w-80  ${
                    isSectionTemplateOpen ? 'md:w-[50rem]' : 'sm:w-[24rem]'
                  } p-4 gap-2 bg-white`}
                >
                  <div className="flex flex-col md:flex-row w-full">
                    <div
                      className={`${
                        isSectionTemplateOpen ? 'w-full md:w-2/5 p-4' : 'w-full'
                      }`}
                    >
                      <div className="w-full p-2">
                        <h2 className="text-lg font-semibold text-center">
                          {formData.outputReportType}
                        </h2>
                      </div>
                      {fields.map((field) => (
                        <div
                          className={field.comment ? 'mb-4' : ''}
                          key={field.name}
                        >
                          <FormField
                            id={field.name}
                            placeholder={''}
                            {...field}
                          />
                          {field.comment && (
                            <>
                              <span className="text-xs text-left text-gray-500">
                                {field.comment + ' '}
                              </span>
                              <a
                                target="_blank"
                                className="text-xs text-left text-blue-500"
                                href={field.link?.url.replace(
                                  ':organizationId',
                                  formData.organizationId.toString(),
                                )}
                                rel="noreferrer"
                              >
                                {field.link?.text}
                              </a>
                            </>
                          )}
                        </div>
                      ))}
                      {segment && segment.display && (
                        <Button
                          type="button"
                          data-cy="more-options-button"
                          onClick={() => {
                            setIsSectionTemplateOpen(!isSectionTemplateOpen);
                            setCurrentSection(0);
                          }}
                          variant={
                            isSectionTemplateOpen === true ? 'solid' : 'outline'
                          }
                        >
                          {isSectionTemplateOpen
                            ? segment.buttonCloseName
                            : segment.buttonOpenName}
                        </Button>
                      )}
                    </div>

                    {isSectionTemplateOpen && segment && (
                      <div className="flex flex-col border-l border-gray-50 w-full md:w-3/5">
                        <div>
                          {currentSection !== undefined && segment && (
                            <div className="w-full p-3">
                              <div className="w-full p-2">
                                <h2 className="text-lg font-semibold text-center">
                                  {segment.sections[currentSection].name}
                                </h2>
                              </div>
                              {segment.sections[currentSection].fields.map(
                                (field) => (
                                  <FormField
                                    id={field.name}
                                    placeholder={''}
                                    key={field.name}
                                    {...field}
                                  />
                                ),
                              )}
                            </div>
                          )}
                        </div>
                        <div className="flex flex-wrap justify-center gap-0.5 mt-auto p-3">
                          {segment &&
                            segment.sections.map((section, index) => (
                              <Button
                                type="button"
                                data-cy={`section-button-${index + 1}`}
                                onClick={() =>
                                  handleSectionChange(errors, index)
                                }
                                key={`section-${uuidv4()}`}
                                disabled={currentSection === index}
                                variant={
                                  currentSection === index ? 'solid' : 'outline'
                                }
                              >
                                {index + 1}
                              </Button>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-row items-center justify-between w-full my-4">
                    <Button
                      type="submit"
                      data-cy="generate-report-button"
                      disabled={isGeneratingReport}
                    >
                      Generate report
                    </Button>

                    <Button
                      type="button"
                      variant="outline"
                      onClick={close}
                      disabled={isGeneratingReport}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  );
};
