import { useLocation, useNavigate } from 'react-router-dom';

export const GoBackButton = ({
  buttonStyles = 'bg-gray-200 hover:bg-gray-300 rounded-md p-2',
  svgStyles = 'text-gray-700 hover:text-gray-900 transition ease-in duration-150',
}: {
  buttonStyles?: string;
  svgStyles?: string;
}) => {
  const navigate = useNavigate();

  const location = useLocation();

  const showBackButton = location.state?.showBackButton;

  return (
    <>
      {showBackButton && (
        <button
          onClick={() => navigate(-1)}
          className={`${buttonStyles} ${svgStyles} transition ease-in duration-150`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="m5.828 7l2.536 2.535L6.95 10.95L2 6l4.95-4.95l1.414 1.415L5.828 5H13a8 8 0 1 1 0 16H4v-2h9a6 6 0 0 0 0-12z"
            />
          </svg>
        </button>
      )}
    </>
  );
};
