import { Button } from '../Button';
import { useQuery } from 'react-query';
import { ApiQueryKeys } from '../../services/hedgehog/api-query-keys.enum';
import { getReportingPeriodsByOrganization } from '../../services/hedgehog/hedgehog.api';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
import { AddMemberModal } from '../modal/add-member-modal/AddMemberModal';
import { useUserDashboardContext } from '../../hooks/useDashboardContext';
import { v4 as uuidv4 } from 'uuid';
import { IReportingPeriods } from '../../services/hedgehog/interfaces/IReportingPeriods';
import { OverviewIcon } from '../icons/OverviewIcon';

import { logout } from '../../services/hedgehog/user.api';
import { useUser } from '../../hooks/useUser';
import { DocumentIcon } from '../icons/DocumentIcon';
import { MembersIcon } from '../icons/MembersIcon';
import { PlusIcon } from '../icons/PlusIcon';
import { ExitIcon } from '../icons/ExitIcon';
import { LivePricing } from '../icons/LivePricingIcon';
import { UserRole } from '../../services/hedgehog/enum/UserRole.enum';
import { AddExistingMemberModal } from '../modal/add-existing-member-modal/AddExistingMemberModal';
import { AddMemberOptionsModal } from '../modal/add-member-options-modal/AddMemberOptionsModal';
import { INavLink } from './AdminSidebar';

type Props = {
  onClickOutside?: () => void;
};

export const UserSideBarMenuOptions: React.FC<Props> = ({ onClickOutside }) => {
  const location = useLocation();

  const isCurrentRoute = (href: string) => {
    return location.pathname === href;
  };

  const { asPeople, currentOrganization, changeOrganization } =
    useUserDashboardContext();
  const { user, setUserInfo } = useUser();

  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();

  useQuery<IReportingPeriods[]>(
    [ApiQueryKeys.organization_reporting_periods_by_id, currentOrganization.id],
    () => getReportingPeriodsByOrganization(Number(currentOrganization.id)),
    { refetchOnWindowFocus: false },
  );

  const handleChangeOrganization = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    changeOrganization(+e.target.value);
    onClickOutside?.();
  };

  const closeModals = (keyModal: string) => {
    closeModal(keyModal);
    closeModal('add-member-options');
  };

  const handleAddMemberModal = () => {
    if (!currentOrganization) return;
    openModal(
      'add-member',
      <AddMemberModal
        close={() => closeModals('add-member')}
        onSubmit={() => navigate('/user/profile')}
        organization={currentOrganization}
      />,
    );
    onClickOutside?.();
  };

  const handleAddExistingMemberModal = () => {
    if (!currentOrganization) return;
    openModal(
      'add-existing-member',
      <AddExistingMemberModal
        close={() => closeModals('add-existing-member')}
        onSubmit={() => navigate('/user/profile')}
        organization={currentOrganization}
      />,
    );
    onClickOutside?.();
  };

  const handleClickAddMemberOptionsModal = () => {
    openModal(
      'add-member-options',
      <AddMemberOptionsModal
        handleAddMemberModal={handleAddMemberModal}
        handleAddExistingMemberModal={handleAddExistingMemberModal}
        close={() => closeModal('add-member-options')}
      />,
    );
    onClickOutside?.();
  };

  const headerLinks: INavLink[] = [
    {
      label: 'Overview',
      href: '/user/home',
      icon: <OverviewIcon />,
    },
    {
      label: 'Reporting Periods',
      href: '/user/reporting-periods',
      icon: <DocumentIcon />,
    },
    {
      label: 'Members',
      href: '/user/profile',
      icon: <MembersIcon />,
    },
    {
      label: 'Trades',
      href: '/user/trades',
      icon: <LivePricing />,
    },
  ];

  const handleLogout = () => {
    logout();
    setUserInfo(undefined);
    onClickOutside?.();
    navigate('/');
  };

  return (
    <>
      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-4">
        <p className="self-stretch flex-grow-0 flex-shrink-0 w-[258px] text-base font-semibold text-left text-slate-500">
          Menu
        </p>
        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-6 ">
          {headerLinks.map(({ href, label: name, icon, extra }) => (
            <Link
              className={`flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2 px-3 py-2 ${
                isCurrentRoute(href) ? 'bg-gray-200' : ''
              }`}
              to={href}
              key={uuidv4()}
              onClick={() => onClickOutside?.()}
            >
              {icon}
              <pre>{name} </pre>
              {extra && (
                <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-2 px-2.5 rounded bg-primary-dark-500">
                  <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-left text-white">
                    {extra}
                  </p>
                </div>
              )}
            </Link>
          ))}
        </div>
      </div>

      <div className="border-t w-full border-gray-500" />
      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-4">
        <p className="self-stretch flex-grow-0 flex-shrink-0 w-[258px] text-base font-semibold text-left text-slate-500">
          {currentOrganization.name} - Organization
        </p>
        {asPeople.length > 1 && (
          <li className="flex flex-col w-full py-2  divide-gray-200 text-left text-base tracking-tight text-gray-700 transition-colors">
            <label htmlFor="organizationSelect" className="font-medium mb-1">
              Organization:
            </label>
            <select
              id="organizationSelect"
              data-cy="organization-select"
              onChange={handleChangeOrganization}
              defaultValue={currentOrganization.id}
              className="text-md w-full text-slate-500"
            >
              {asPeople.map((person) => (
                <option
                  key={`${person.organization.name}`}
                  value={person.organization.id}
                >
                  {person.organization.name}
                </option>
              ))}
            </select>
          </li>
        )}
      </div>

      {user?.role === UserRole.Admin && (
        <Button
          type="button"
          variant="solid"
          color="cyan"
          className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 h-11 relative gap-2.5 px-3 py-2 rounded "
          onClick={handleClickAddMemberOptionsModal}
          data-cy="add-member-button"
        >
          <PlusIcon />
          <span className="flex-grow-0 flex-shrink-0 text-base font-semibold text-left text-primary-dark-500">
            Add New Member
          </span>
        </Button>
      )}

      <Button
        type="button"
        variant="outline"
        className="hidden md:flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1 px-2 py-1.5 rounded bg-[#f7f8f9] border-0"
        onClick={handleLogout}
        data-cy="dashboard-logout-button"
      >
        <ExitIcon />
        <span className="flex-grow-0 flex-shrink-0 text-base font-semibold text-left text-primary-dark-500">
          Logout
        </span>
      </Button>
    </>
  );
};
