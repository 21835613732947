import { useQuery, useQueryClient } from 'react-query';
import { getUserData } from '../services/hedgehog/user.api';
import { IUser } from '../services/hedgehog/interfaces/IUser';
import { ApiQueryKeys } from '../services/hedgehog/api-query-keys.enum';
import { axiosHasAuthToken } from '../services/axios/Axios';

export const useUser = () => {
  const queryClient = useQueryClient();
  const ONE_HOUR = 1000 * 60 * 60;

  const {
    data: userData,
    isLoading,
    refetch: refetchUser,
  } = useQuery<IUser>(ApiQueryKeys.userData, getUserData, {
    refetchOnWindowFocus: false,
    initialData:
      queryClient.getQueryData<IUser>(ApiQueryKeys.userData) ?? undefined,
    staleTime: ONE_HOUR,
    enabled: axiosHasAuthToken(),
  });

  const setUserInfo = (user: IUser | undefined) => {
    queryClient.setQueryData<IUser | undefined>(ApiQueryKeys.userData, user);
  };

  return { user: userData, isLoading, setUserInfo, refetchUser };
};
