import { SimpleLoading } from '../loading/SimpleLoading';
import { Modal } from './Modal';

export const SimpleLoadingModal = () => {
  return (
    <Modal className="z-[250]">
      <div className="max-h-full overflow-y-auto">
        <div className="flex flex-wrap justify-center items-center relative w-full overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
          <SimpleLoading
            loadingColor="text-primary-cyan-500"
            loadingSize="h-20 w-20"
            externalStyles="flex flex-wrap items-center justify-center p-6"
          />
        </div>
      </div>
    </Modal>
  );
};
