/* eslint-disable quotes */
/* eslint-disable no-sparse-arrays */
export const fxHedgeEffectivenessJournalSheetMock = [
  [, , null, , , , , , , 'Hedgehog Software', 'Hedgehog Software'],
  [],
  [
    ,
    'The Jon Org',
    'The Jon Org',
    'FX Effectiveness Journal - 30 Jun 24',
    'FX Effectiveness Journal - 30 Jun 24',
    'FX Effectiveness Journal - 30 Jun 24',
    'FX Effectiveness Journal - 30 Jun 24',
    'FX Effectiveness Journal - 30 Jun 24',
    'FX Effectiveness Journal - 30 Jun 24',
    ,
    null,
  ],
  [],
  [
    ,
    'GL Accounts',
    'GL Accounts',
    'Classification',
    'Classification',
    'Classification',
    'Entry Description',
    'Entry Description',
    'Reference',
    'DR',
    'CR',
  ],
  [
    ,
    'Cash Flow Hedge Reserve',
    'Cash Flow Hedge Reserve',
    'OCI/Equity',
    'OCI/Equity',
    'OCI/Equity',
    'Lower of test',
    'Lower of test',
    'Hedge ineffectiveness CNY/AUD Hedging - INR/AUD Hedging',
    0,
    690,
  ],
  [
    ,
    'Unrealised FX gains or losses',
    'Unrealised FX gains or losses',
    'Profit or Loss',
    'Profit or Loss',
    'Profit or Loss',
    'Lower of test',
    'Lower of test',
    'Hedge ineffectiveness CNY/AUD Hedging - INR/AUD Hedging',
    690,
    0,
  ],
  [, , null, , null, null, , null, , 690, 690],
  [],
  [],
  [, , null, null, null, null, null, null, null, null, null],
  [, , null, null, null, null, null, null, null, null, null],
  [, , null, null, null, null, null, null, null, null, null],
  [, , null, null, null, null, null, null, null, null, null],
  [, , null, null, null, null, null, null, null, null, null],
  [],
  [, , null, null, null, null, null, null, null, null, null],
  [],
  [
    ,
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
  ],
  [
    ,
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
  ],
];
