import { Button } from '../../Button';
import { Modal } from '../Modal';

type Props = {
  close: () => void;
  handleAddExistingMemberModal: () => void;
  handleAddMemberModal: () => void;
};
export const AddMemberOptionsModal = ({
  close,
  handleAddMemberModal,
  handleAddExistingMemberModal,
}: Props) => {
  return (
    <Modal>
      <div className="max-h-full w-full sm:w-auto overflow-y-auto items-center py-2">
        <div className="flex flex-col relative w-full sm:w-96 justify-center items-center bg-white border border-gray-200 shadow-md rounded-lg py-5">
          <h1 className="text-xl mb-2">Select an option.</h1>
          <div className="flex gap-1">
            <Button
              variant="solid"
              color="cyan"
              onClick={handleAddMemberModal}
              data-cy="add-new-member-button"
            >
              New Member
            </Button>
            <Button
              variant="solid"
              color="cyan"
              data-cy="add-existing-member-button"
              onClick={handleAddExistingMemberModal}
            >
              Existing Member
            </Button>
          </div>
          <button
            type="button"
            className="text-sm font-semibold underline underline-offset-4 mt-1"
            onClick={close}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};
