import { useQuery } from 'react-query';
import { useLogout } from '../../../hooks/useLogout';
import { useModal } from '../../../hooks/useModal';
import { AdminSidebarNavigationList } from '../AdminSidebarNavigationList';
import { IReportByMonth } from '../../../services/hedgehog/interfaces/IReportByMonth';
import { ApiQueryKeys } from '../../../services/hedgehog/api-query-keys.enum';
import { getAllReportByOrganization } from '../../../services/hedgehog/hedgehog.api';
import { AddMemberModal } from '../../modal/add-member-modal/AddMemberModal';
import { AddExistingMemberModal } from '../../modal/add-existing-member-modal/AddExistingMemberModal';
import { AddMemberOptionsModal } from '../../modal/add-member-options-modal/AddMemberOptionsModal';
import { AddReportModal } from '../../modal/add-report-modal/AddReportModal';
import { Button } from '../../Button';
import { PlusIcon } from '../../icons/PlusIcon';
import { ExitIcon } from '../../icons/ExitIcon';
import { GoBackIcon } from '../../icons/GoBackIcon';
import { useParams } from 'react-router-dom';
import { useOrganization } from '../../../hooks/useOrganization';
import { IOrganization } from '../../../services/hedgehog/interfaces/IOrganization';

type Props = {
  onClickOutside?: () => void;
};

export const AdminSideBarMenuOptions: React.FC<Props> = ({
  onClickOutside,
}) => {
  const { handleLogout } = useLogout();

  const { id } = useParams();
  const { organization } = useOrganization(Number(id));

  const adminOrganization = organization as IOrganization;

  const { openModal, closeModal } = useModal();
  const { refetch: refetchReports } = useQuery<IReportByMonth>(
    [ApiQueryKeys.organization_all_reports, adminOrganization?.id],
    () => getAllReportByOrganization(adminOrganization?.id),
    { refetchOnWindowFocus: true },
  );

  const closeModals = (keyModal: string) => {
    closeModal(keyModal);
    closeModal('add-member-options');
  };

  const handleAddMemberModal = () => {
    if (!adminOrganization) return;
    openModal(
      'add-member',
      <AddMemberModal
        close={() => closeModals('add-member')}
        organization={adminOrganization}
      />,
    );
  };

  const handleAddExistingMemberModal = () => {
    if (!adminOrganization) return;
    openModal(
      'add-existing-member',
      <AddExistingMemberModal
        close={() => closeModals('add-existing-member')}
        organization={adminOrganization}
      />,
    );
  };

  const handleClickAddMemberOptionsModal = () => {
    openModal(
      'add-member-options',
      <AddMemberOptionsModal
        handleAddMemberModal={handleAddMemberModal}
        handleAddExistingMemberModal={handleAddExistingMemberModal}
        close={() => closeModal('add-member-options')}
      />,
    );
  };

  const handleUploadReportModal = () => {
    openModal(
      'upload-new-report',
      <AddReportModal
        close={() => closeModal('upload-new-report')}
        onSubmit={() => refetchReports()}
        organizationId={adminOrganization.id}
      />,
    );
  };

  return (
    <>
      <Button
        type="button"
        variant="solid"
        color="cyan"
        className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 h-11 relative gap-2.5 px-3 py-2 rounded "
        onClick={handleUploadReportModal}
        data-cy="upload-new-report-button"
      >
        <PlusIcon />
        <span className="flex-grow-0 flex-shrink-0 text-base font-semibold text-left text-primary-dark-500">
          New Report
        </span>
      </Button>
      <AdminSidebarNavigationList
        adminOrganization={adminOrganization}
        onclickOutside={onClickOutside}
      />
      <div className="border-t w-full border-gray-500" />
      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-4">
        <p className="self-stretch flex-grow-0 flex-shrink-0 w-[258px] text-base font-semibold text-left text-slate-500">
          {adminOrganization?.name} - Organization
        </p>
      </div>
      <Button
        type="button"
        variant="solid"
        color="cyan"
        className="flex justify-center items-center self-stretch h-9 flex-grow-0 flex-shrink-0 relative gap-2.5 rounded"
        onClick={handleClickAddMemberOptionsModal}
        data-cy="add-member-button"
      >
        <PlusIcon />
        <span className="flex-grow-0 flex-shrink-0 text-base font-semibold text-left text-primary-dark-500">
          Add New Member
        </span>
      </Button>

      <Button
        type="button"
        variant="outline"
        className="hidden md:flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1 px-2 py-1.5 rounded bg-[#f7f8f9] border-0"
        onClick={handleLogout}
        data-cy="dashboard-logout-button"
      >
        <ExitIcon />
        <span className="flex-grow-0 flex-shrink-0 text-base font-semibold text-left text-primary-dark-500">
          Logout
        </span>
      </Button>
      <Button
        type="button"
        variant="outline"
        className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1 px-2 py-1.5 rounded bg-[#f7f8f9] border-0"
        to={'/admin/home'}
        data-cy="dashboard-logout-button"
      >
        <GoBackIcon className="text-lg" />
        <span className="flex-grow-0 flex-shrink-0 text-base font-semibold text-left text-primary-dark-500">
          Active Clients
        </span>
      </Button>
    </>
  );
};
