export const irsMtmValuationOutputHeaders = {
  tradeReferenceNumber: 'Trade Reference Number',
  legalEntity: 'Legal Entity',
  tradeDate: 'Trade Date',
  effectiveDate: 'Effective Date',
  maturityDate: 'Maturity Date',
  fixedRate: 'Fixed Rate',
  currency: 'Currency',
  notional: 'Notional',
  swapIndex: 'Swap Index',
  floatingRate: 'Floating Rate',
  nextResetDate: 'Next Reset Date',
  lastResetDate: 'Last Reset Date',
  counterparty: 'Counterparty',
  valuationCurrency: 'Valuation Currency',
  intrinsicValue: 'Intrinsic Value',
  timeValue: 'Time Value',
  accruedInterest: 'Accrued Interest',
  cleanFairValue: 'Clean Fair Value',
  totalFairValue: 'Total Fair Value',
  cva: 'CVA',
  dva: 'DVA',
  riskFreeValuation: 'Risk Free Valuation',
  total: 'Total',
};

export const irsMtmValuationReportOutputHeaders = [
  irsMtmValuationOutputHeaders.tradeReferenceNumber,
  irsMtmValuationOutputHeaders.legalEntity,
  irsMtmValuationOutputHeaders.tradeDate,
  irsMtmValuationOutputHeaders.effectiveDate,
  irsMtmValuationOutputHeaders.maturityDate,
  irsMtmValuationOutputHeaders.fixedRate,
  irsMtmValuationOutputHeaders.currency,
  irsMtmValuationOutputHeaders.notional,
  irsMtmValuationOutputHeaders.swapIndex,
  irsMtmValuationOutputHeaders.floatingRate,
  irsMtmValuationOutputHeaders.nextResetDate,
  irsMtmValuationOutputHeaders.lastResetDate,
  irsMtmValuationOutputHeaders.counterparty,
  irsMtmValuationOutputHeaders.valuationCurrency,
  irsMtmValuationOutputHeaders.intrinsicValue,
  irsMtmValuationOutputHeaders.timeValue,
  irsMtmValuationOutputHeaders.accruedInterest,
  irsMtmValuationOutputHeaders.cleanFairValue,
  irsMtmValuationOutputHeaders.totalFairValue,
  irsMtmValuationOutputHeaders.cva,
  irsMtmValuationOutputHeaders.dva,
  irsMtmValuationOutputHeaders.riskFreeValuation,
];
