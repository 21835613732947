import { IIrsHedgeDocumentationForm } from '../../../services/hedgehog/interfaces/IHedgeDocumentationForm';
import { handleDownloadFile } from '../../../utils/handleDownloadFile';
import { Link } from 'react-router-dom';
import { OutputGetFileByIdRoutes } from '../../../services/hedgehog/types/OutputReportRoutes';
import { useUser } from '../../../hooks/useUser';
import { ApiQueryKeys } from '../../../services/hedgehog/api-query-keys.enum';
import {
  deleteReport,
  reviewReport,
} from '../../../services/hedgehog/hedgehog.api';
import { Toast } from '../../../services/toastify/Toastify';
import { useQueryClient } from 'react-query';
import { useConfirm } from '../../../hooks/useConfirm';
import { UserRole } from '../../../services/hedgehog/enum/UserRole.enum';
import {
  IFxHedgeDocumentationForm,
  IFXHedgeDocumentationPipeline,
} from '../../../services/hedgehog/interfaces/IFxHedgeDocumentationForm';
import { OutputReportTypes } from '../../../services/hedgehog/types/OutputReportTypes';
import moment from 'moment';
import { IReport } from '../../../services/hedgehog/interfaces/IReportByMonth';
import { IOrganization } from '../../../services/hedgehog/interfaces/IOrganization';

type Props = {
  organization: IOrganization;
  irsHedgeDocumentationForms: IIrsHedgeDocumentationForm[];
  fxHedgeDocumentationForms: IFxHedgeDocumentationForm[];
};

const columnNames: string[] = [
  'name - reference',
  'is complete',
  'reviewed',
  'actions',
];

interface IHedgeDocumentationFormCombined {
  id: number;
  name: string;
  reference: string;
  pipeline: string | null;
  type: string;
  reportingDate: string;
  reviewDate: string;
  isComplete: boolean;
  template: string;
}

export const HedgeDocumentationMonthlyTable = ({
  irsHedgeDocumentationForms,
  fxHedgeDocumentationForms,
  organization,
}: Props) => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const { confirmAction } = useConfirm();
  const handleDeleteReport = async (
    report: IHedgeDocumentationFormCombined,
  ) => {
    const combinedForms = [
      ...fxHedgeDocumentationForms,
      ...irsHedgeDocumentationForms,
    ];

    const selectedReport = combinedForms.find(
      (hedgeDocumentationForm) =>
        hedgeDocumentationForm.reference === report.reference,
    );
    if (!selectedReport) return;

    const action = async () => {
      try {
        await deleteReport(selectedReport);
        Toast.success('Report deleted successfully');
        queryClient.invalidateQueries(
          ApiQueryKeys.organization_all_reports_by_month_and_year,
        );
        queryClient.invalidateQueries(
          ApiQueryKeys.organization_reporting_periods_by_id,
        );
      } catch (e: unknown) {
        if (e instanceof Error) {
          Toast.error(e.message);
        }
      }
    };
    confirmAction(action, `Delete ${report.name}?`);
  };

  const handleHedgeDocumentationForms = (
    fxHedgeDocumentationForms: IFxHedgeDocumentationForm[],
    irsHedgeDocumentationForms: IIrsHedgeDocumentationForm[],
  ): IHedgeDocumentationFormCombined[] => {
    const combinedForms = [
      ...fxHedgeDocumentationForms,
      ...irsHedgeDocumentationForms,
    ];

    const irsHedgeDocumentationDefaultTemplate = 'Default Template';
    return combinedForms.map((form, index) => {
      let isComplete = true;

      if (form.type === OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM) {
        const irsForm = form as IIrsHedgeDocumentationForm;
        isComplete = irsForm.isComplete;
      }

      const template =
        form.type === OutputReportTypes.FX_HEDGE_DOCUMENTATION_FORM
          ? fxHedgeDocumentationForms[index].template
          : irsHedgeDocumentationDefaultTemplate;

      return {
        ...form,
        id: form.id,
        name: form.name,
        reference: form.reference,
        type: form.type,
        pipeline: form.pipeline,
        isComplete,
        template,
      };
    });
  };

  const handleReview = async (report: IHedgeDocumentationFormCombined) => {
    const action = async () => {
      try {
        const { id, type } = report;
        await reviewReport({ id, type } as IReport);
        Toast.success('Report reviewed successfully');
        queryClient.refetchQueries(
          ApiQueryKeys.organization_all_reports_by_month_and_year,
        );
      } catch (err: unknown) {
        if (err instanceof Error) {
          Toast.error(err.message);
        }
      }
    };
    confirmAction(
      action,
      `Set ${report.name} as reviewed? This action will send an email with the report file to the organization members`,
    );
  };

  const reviewCondition = (
    report: IHedgeDocumentationFormCombined,
    reviewButton: JSX.Element,
  ) => {
    if (!report.isComplete) return 'Complete first';
    if (report.reviewDate)
      return moment(new Date(report.reviewDate)).format('DD-MMM-YY');
    return reviewButton;
  };

  return (
    <table className="w-full text-sm text-left text-gray-500">
      <thead className="text-xs text-gray-800 uppercase">
        <tr className="bg-white whitespace-nowrap border-b hover:bg-primary-cyan-100 ease-in transition duration-100">
          {columnNames.map((key) => (
            <th key={key} className="px-4 py-4 space-x-2">
              {key}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {handleHedgeDocumentationForms(
          fxHedgeDocumentationForms,
          irsHedgeDocumentationForms,
        ).map((hedgeDocumentationForm) => {
          if (
            user?.role === UserRole.User &&
            !hedgeDocumentationForm.reviewDate
          )
            return null;
          return (
            <tr
              key={`${hedgeDocumentationForm.id} - ${hedgeDocumentationForm.type}`}
              className={`${
                hedgeDocumentationForm.isComplete
                  ? 'bg-white hover:bg-primary-cyan-100 ease-in transition duration-100'
                  : 'bg-red-50 hover:bg-red-100'
              } border-b`}
              title={`${
                hedgeDocumentationForm.isComplete ? '' : 'Not'
              } Complete`}
            >
              <td className="px-3 py-4 min-w-[175px]">
                <div className="text-gray-700 capitalize ">
                  <span className="text-sm">{hedgeDocumentationForm.name}</span>
                </div>
                <div>
                  <span> {hedgeDocumentationForm.reference}</span>
                </div>
              </td>
              <td
                className={`px-3 py-4 space-x-2 ${
                  hedgeDocumentationForm.isComplete
                    ? ''
                    : 'text-red-500 font-bold'
                }`}
              >
                {hedgeDocumentationForm.isComplete
                  ? `Yes. With ${hedgeDocumentationForm.template}`
                  : 'No'}
              </td>
              <td className="px-4 min-w-[125px] py-4 space-x-2">
                {reviewCondition(
                  hedgeDocumentationForm,
                  <button
                    className="font-medium text-blue-600 hover:underline"
                    data-cy={`review-${hedgeDocumentationForm.type}-${hedgeDocumentationForm.id}`}
                    onClick={() => handleReview(hedgeDocumentationForm)}
                  >
                    Review
                  </button>,
                )}
              </td>
              <td className="px-3 py-4 ">
                {hedgeDocumentationForm.isComplete ? (
                  <div className="flex gap-2 flex-wrap">
                    {hedgeDocumentationForm?.pipeline !==
                      IFXHedgeDocumentationPipeline.RAW_REPORT_PIPELINE && (
                      <Link
                        to={
                          hedgeDocumentationForm.type ===
                          OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM
                            ? `/${user?.role}/view/hedge-documentation-form/${hedgeDocumentationForm.id}`
                            : `/${user?.role}/view/fx-hedge-documentation-form/${hedgeDocumentationForm.id}`
                        }
                        className="font-medium text-blue-600 hover:underline mr-1"
                        data-cy={`view-${hedgeDocumentationForm.type}-${hedgeDocumentationForm.id}`}
                        state={{ showBackButton: true }}
                      >
                        View
                      </Link>
                    )}

                    <button
                      onClick={() => {
                        handleDownloadFile(
                          hedgeDocumentationForm.name,
                          organization.name,
                          hedgeDocumentationForm.id,
                          OutputGetFileByIdRoutes[
                            hedgeDocumentationForm.type ===
                            OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM
                              ? OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM
                              : OutputReportTypes.FX_HEDGE_DOCUMENTATION_FORM
                          ],
                        );
                      }}
                      className="font-medium text-blue-600 hover:underline"
                      data-cy={`download-${hedgeDocumentationForm.type}-${hedgeDocumentationForm.id}`}
                    >
                      Download
                    </button>
                    {user?.role === UserRole.Admin && (
                      <button
                        onClick={() =>
                          handleDeleteReport(hedgeDocumentationForm)
                        }
                        className="font-medium text-red-500 hover:underline"
                        data-cy={`delete-${hedgeDocumentationForm.type}-${hedgeDocumentationForm.id}`}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="flex gap-2 flex-wrap">
                    {user?.role === UserRole.Admin ? (
                      <button
                        onClick={() =>
                          handleDeleteReport(hedgeDocumentationForm)
                        }
                        className="font-medium text-red-500 hover:underline"
                        data-cy={`delete-hedge-documentation-form-${hedgeDocumentationForm.id}`}
                      >
                        Delete
                      </button>
                    ) : (
                      'N/A'
                    )}
                  </div>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
