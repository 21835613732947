export const RightArrow = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={13}
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path d="M256 120.768 306.432 64 768 512 306.432 960 256 903.232 659.072 512z" />
  </svg>
);
