import { Form, Formik } from 'formik';
import { Button } from '../../Button';
import { Modal } from '../Modal';
import { v4 as uuidv4 } from 'uuid';
import { FormField } from '../../form-field/FormField';
import { IFxHedgeDocumentationFormTemplateInfo } from '../../../services/hedgehog/interfaces/IFxHedgeDocumentationFormTemplateInfo';
import {
  fxHedgeDocFormGeneralTemplate,
  fxHedgeDocFormSyntheticTemplate,
} from '../profile-config-modal/profileStaticData';
import { FxHedgeDocumentationFormTemplateOptions } from '../../../services/hedgehog/types/reports/fx-mtm-journal-report/fx-mtm-journal-options';
import {
  fxHedgeDocFormStandardTemplateValidationSchema,
  fxHedgeDocFormSyntheticTemplateValidationSchema,
} from './FxHedgeDocForm.validation.schema';

const initialValues: IFxHedgeDocumentationFormTemplateInfo = {
  natureOfHedgedRisk: '',
  policyDeclaration: '',
  riskManagementObjective: '',
  strategy: '',
  natureOfHedgingInstrument: '',
  amountOfPrincipalHedgingInstrumentPercentaje: 0,
  impactToProfitOrLoss: '',
  contractualPartiesOfHedgingItem: '',
  amountDesignatedAsHedgedItemPercentage: 0,
  cashFlowHedge: '',
  netInvestmentHedge: '',
  fairValueHedge: '',
  natureOfHedgedItem: '',
  toAchieveHedgeEffectivenessExplanationSyntheticTemplate: '',
  underlyingRiskHedgedItemI: '',
  underlyingRiskHedgedItemII: '',
  reviewedBy: '',
  preparedBy: '',
};

export const FxHedgeDocFormModal = ({
  close,
  onSubmit,
  templateOption,
  defaultValues = initialValues,
}: {
  close: () => void;
  onSubmit: (values: IFxHedgeDocumentationFormTemplateInfo) => any;
  templateOption: FxHedgeDocumentationFormTemplateOptions;
  defaultValues?: IFxHedgeDocumentationFormTemplateInfo;
}) => {
  const handleSubmit = async (
    values: IFxHedgeDocumentationFormTemplateInfo,
  ) => {
    onSubmit(values);
  };

  const handleSegmentOption = (
    templateOption: FxHedgeDocumentationFormTemplateOptions,
  ) => {
    if (
      templateOption ===
      FxHedgeDocumentationFormTemplateOptions.SYNTHETIC_LEGS_TEMPLATE
    ) {
      return fxHedgeDocFormSyntheticTemplate.fields;
    }

    return fxHedgeDocFormGeneralTemplate.fields;
  };

  const handleValidationSchema = (
    templateOption: FxHedgeDocumentationFormTemplateOptions,
  ) => {
    if (
      templateOption ===
      FxHedgeDocumentationFormTemplateOptions.SYNTHETIC_LEGS_TEMPLATE
    ) {
      return fxHedgeDocFormSyntheticTemplateValidationSchema;
    }

    return fxHedgeDocFormStandardTemplateValidationSchema;
  };

  return (
    <Modal close={close}>
      <div className="max-h-full overflow-y-auto py-6">
        <div className="flex flex-col justify-center items-center relative z-0 overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
          <Formik
            initialValues={defaultValues}
            validationSchema={handleValidationSchema(templateOption)}
            onSubmit={handleSubmit}
          >
            <Form className="flex flex-col justify-center items-center md:w-[54rem] h-full p-4 gap-2 ">
              <div>
                <h2 className="font-bold text-gray-900 text-center text-lg">
                  FX Hedge Documentation Form Template Info
                </h2>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-4">
                <div className="flex justify-center w-full flex-row">
                  <div className="w-full">
                    {handleSegmentOption(templateOption).map((field) => (
                      <div
                        className={field.type === 'radio' ? 'py-2' : ''}
                        key={uuidv4()}
                      >
                        <FormField
                          containerClassName={
                            field.type === 'radio' ? 'flex' : ''
                          }
                          labelClassName={
                            field.type === 'radio' ? 'w-[175px]' : ''
                          }
                          id={field.name}
                          placeholder={''}
                          {...field}
                          name={field.name}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between w-full">
                <Button data-cy="save-hedge-doc-form-button" type="submit">
                  Save
                </Button>

                <Button
                  data-cy="cancel-hedge-doc-form-button"
                  type="button"
                  variant="outline"
                  onClick={close}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </Modal>
  );
};
