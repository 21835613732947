import { useEffect, useState } from 'react';
import {
  ExposureType,
  ExposureTypeQuery,
} from '../../../../services/hedgehog/enum/ExposureType.enum';
import { useFormik } from 'formik';
import { IOrganizationQuery } from '../../../../services/hedgehog/interfaces/IOrganizationQuery';
import { statusOptions } from './data/StatusOptions';
import { HamburgerButton } from '../../../../components/HamburgerButton';

type Props = { onQueryChange: (props: IOrganizationQuery) => void };

export const FilterOrganizations: React.FC<Props> = ({ onQueryChange }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  const formik = useFormik({
    initialValues: {
      exposureType: ExposureTypeQuery.ALL,
      is_active: true,
      name: '',
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const updateQuery = () => {
    const queryParams: IOrganizationQuery = {};

    if (formik.values.exposureType !== ExposureTypeQuery.ALL) {
      queryParams['exposureType'] = formik.values
        .exposureType as unknown as ExposureType;
    }

    if (formik.values.is_active !== undefined) {
      queryParams['is_active'] = formik.values.is_active;
    }

    if (formik.values.name) {
      queryParams['name'] = formik.values.name;
    }

    onQueryChange(queryParams);
  };

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newTimeout = setTimeout(() => {
      updateQuery();
    }, 500);

    setDebounceTimeout(newTimeout);

    return () => clearTimeout(newTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return (
    <div
      className={`flex-2 md:flex-1 ml-auto ${
        isMenuOpen ? 'mb-[280px] lg:mb-[250px]' : 'mb-0'
      }`}
    >
      <HamburgerButton onClick={toggleMenu} isOpen={isMenuOpen} />
      <form
        onSubmit={formik.handleSubmit}
        className={`${
          isMenuOpen ? 'flex' : 'hidden'
        } flex-col items-start gap-2 justify-start absolute top-full left-0 top-[60px] border border-gray-300 rounded-md bg-white border-dashed z-10 p-4 w-full`}
      >
        <div className="w-full">
          <label
            htmlFor="name"
            data-cy="name-label"
            className="block mb-2 lg:mb-0 mr-2 text-sm font-medium text-gray-900"
          >
            Name
          </label>
          <input
            id="name"
            data-cy="name-input"
            type="text"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            className="block mb-2 lg:mb-0 w-full lg:w-22 p-2 bg-gray-50 border mr-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
          ></input>
        </div>
        <div className="w-full">
          <label
            htmlFor="status"
            data-cy="status-label"
            className="block mb-2 lg:mb-0 mr-2 text-sm font-medium text-gray-900"
          >
            Status:
          </label>
          <select
            id="status"
            data-cy="status-select"
            name="is_active"
            onChange={formik.handleChange}
            value={String(formik.values.is_active)}
            className="block mb-2 lg:mb-0 w-full lg:w-22 p-2.5 bg-gray-50 border mr-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
          >
            {statusOptions.map((option) => (
              <option key={option.text} value={String(option.value)}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full">
          <label
            htmlFor="exposureType"
            data-cy="exposure-type-label"
            className="block mb-2 whitespace-nowrap lg:mb-0 mr-2 text-sm font-medium text-gray-900"
          >
            Exposure Type:
          </label>
          <select
            id="exposureType"
            data-cy="exposure-type-select"
            name="exposureType"
            onChange={formik.handleChange}
            value={formik.values.exposureType}
            className="block mb-2 lg:mb-0 w-full lg:w-22 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
          >
            {Object.values(ExposureTypeQuery).map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </form>
    </div>
  );
};
