import { AreaChart, Area, Tooltip, ResponsiveContainer, XAxis } from 'recharts';
import { IIRSTrade } from '../services/hedgehog/interfaces/IIRSTrade';
import { IIRSMTMValuationEntry } from '../services/hedgehog/interfaces/IIRSMTMValuationEntry';
import { defaultColors } from './charts/default-colors';

interface IIRSGraphProps {
  trade: IIRSTrade;
  calculateDataValues: (entries: IIRSMTMValuationEntry[]) => any[];
  calculateGradientOffset: (graphData: { cleanFairValue: number }[]) => number;
}

export const IRSTradeGraphRow = ({
  trade,
  calculateDataValues,
  calculateGradientOffset,
}: IIRSGraphProps) => {
  const graphData = calculateDataValues(trade.valuationEntries);
  const off = calculateGradientOffset(graphData);

  return (
    <div className="w-full h-10">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={graphData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis hide dataKey="month" />
          <Tooltip
            wrapperStyle={{ zIndex: 1000 }}
            position={{ x: 0, y: 50 }}
            formatter={(value) =>
              trade.currency + ' ' + value.toLocaleString('en-US')
            }
          ></Tooltip>
          <defs>
            <linearGradient
              id={'splitColor' + trade.reference}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset={off}
                stopColor={defaultColors.cyan500.opaque}
                stroke={defaultColors.cyan500.opaque}
                stopOpacity={1}
              />
              <stop
                offset={off}
                stopColor={defaultColors.purple500.opaque}
                stroke={defaultColors.purple500.opaque}
                stopOpacity={1}
              />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            name="Clean Fair Value"
            dataKey="cleanFairValue"
            stroke={`url(#splitColor${trade.reference})`}
            fill={`url(#splitColor${trade.reference})`}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
