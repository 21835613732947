import { createContext, PropsWithChildren, useCallback, useMemo } from 'react';
import {
  addExistingMemberToOrganization,
  notifyReports,
} from '../services/hedgehog/hedgehog.api';
import { IAddExistingMemberParameters } from '../services/hedgehog/types/add-existing-member-parameters';

interface IApiCallHandlerContext {
  handleAddExistingMemberToOrganization: (
    values: IAddExistingMemberParameters,
  ) => Promise<void>;
  handleNotifyReports: (organizationId: number) => Promise<void>;
}

export const ApiCallHandlerContext = createContext(
  {} as IApiCallHandlerContext,
);

export const ApiCallHandlerProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const handleAddExistingMemberToOrganization = useCallback(
    async (values: IAddExistingMemberParameters) => {
      await addExistingMemberToOrganization(values);
    },
    [],
  );

  const handleNotifyReports = useCallback(async (organizationId: number) => {
    await notifyReports(organizationId);
  }, []);

  const contextValue = useMemo(
    () => ({ handleAddExistingMemberToOrganization, handleNotifyReports }),
    [handleAddExistingMemberToOrganization, handleNotifyReports],
  );

  return (
    <ApiCallHandlerContext.Provider value={contextValue}>
      {children}
    </ApiCallHandlerContext.Provider>
  );
};
