import { IReportInputs } from '../../OutputReportTypes';

export const MtmValuationInputs: IReportInputs[] = [
  {
    type: 'HB - Detailed Instrument Report',
  },
  {
    type: 'HB - IRS Position Report',
  },
  {
    type: 'HB - CVA Report',
    optional: true,
  },
];
