type Props = {
  label: string;
  id: string;
  name: string;
  type: string;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  defaultValue?: string;
  disabled?: boolean;
  children: React.ReactNode;
};
export const InputSelect = ({
  label,
  id,
  name,
  handleChange,
  defaultValue,
  disabled = false,
  children,
}: Props) => {
  return (
    <div className=" w-full mb-2">
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-900 "
      >
        {label}
      </label>
      <select
        name={name}
        disabled={disabled}
        defaultValue={defaultValue}
        id={id}
        onChange={handleChange}
        className="block w-full p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
      >
        {children}
      </select>
    </div>
  );
};
