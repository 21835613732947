import { Outlet } from 'react-router-dom';
import { UserDashboardProvider } from '../../../context/UserDashboardProvider';
import UserAuthGuard from '../../../components/guards/UserAuthGuard';
import { DashboardHeaderBar } from '../../../components/navbar/DashboardHeaderBar';
import { addExistingMemberToOrganization } from '../utils/addExistingMemberToOrganization';
import { Navbar } from '../../../components/navbar/Navbar';
import { UserSidebar } from '../../../components/sidebar/UserSidebar';

export interface IHeaderLink {
  label: string;
  href: string;
  style?: { variant: 'solid' | 'outline'; color: 'cyan' | 'white' | 'gray' };
  icon?: React.FC<React.ComponentPropsWithoutRef<'svg'>>;
}
export interface INavLink {
  label: string;
  href: string;
  icon: JSX.Element;
  extra?: number;
}

export const UserDashboardLayout = () => {
  return (
    <UserAuthGuard>
      <UserDashboardProvider>
        <div className="h-screen">
          <div className="flex h-screen overflow-hidden">
            <UserSidebar />
            <div
              className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-gray-100"
              data-cy="user-dashboard-layout"
            >
              <div className="block md:hidden">
                <Navbar />
              </div>
              <DashboardHeaderBar
                addExistingMemberToOrganization={
                  addExistingMemberToOrganization
                }
              />

              <main>
                <div className="mx-auto p-4 md:p-6">
                  <Outlet />
                </div>
              </main>
            </div>
          </div>
        </div>
      </UserDashboardProvider>
    </UserAuthGuard>
  );
};
