import * as ExcelJS from 'exceljs';
import { fxAnalysisReportHeaders } from './hooks/useFxAnalysisReport';
import { DEFAULT_PERCENTAGE_FORMAT } from '../../tables/common/utils';

const styleHeaderRow = (headerRow: ExcelJS.Row): void => {
  headerRow.height = 30;
  headerRow.eachCell((cell: ExcelJS.Cell) => {
    cell.font = {
      bold: false,
      color: { argb: 'ff252525' },
      name: 'Helvetica',
      size: 10,
    };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF1FD6FF' },
    };
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
    cell.border = {
      top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
      left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
      bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
      right: { style: 'thin', color: { argb: 'FFFFFFFF' } },
    };
  });
};

const styleDataRow = (addedRow: ExcelJS.Row) => {
  addedRow.height = 15;
  addedRow.eachCell((cell, colNumber) => {
    cell.font = { name: 'Helvetica', size: 10 };
    if (colNumber === 12) {
      cell.numFmt = DEFAULT_PERCENTAGE_FORMAT;
    } else if ([1, 2, 3, 4, 5, 8, 10, 11].includes(colNumber)) {
      cell.numFmt = '#,##0.00';
    }
  });
};

export const downloadReport = async (sheet: any[][]) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Fx Analysis Report');

  worksheet.columns = fxAnalysisReportHeaders.map((header) => ({
    width: Math.max(10, header.length + 1),
  }));

  sheet.forEach((row, i) => {
    const addedRow = worksheet.addRow(row);
    if (i === 0) {
      styleHeaderRow(addedRow);
    } else {
      styleDataRow(addedRow);
    }
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const href = window.URL.createObjectURL(blob);
  const anchorElement = document.createElement('a');
  anchorElement.href = href;
  anchorElement.download = 'Fx Analysis Report.xlsx';
  document.body.appendChild(anchorElement);
  anchorElement.click();
  document.body.removeChild(anchorElement);
  window.URL.revokeObjectURL(href);
};
