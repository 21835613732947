import { CellProperties } from 'handsontable/settings';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_PERCENTAGE_FORMAT,
  NUMERIC_FORMAT_PATTERN,
} from '../common/utils';
import moment from 'moment';
import { CellValue } from 'exceljs';

export const irsMtmValuationCellsConfig = (sheet: CellValue[][]) => {
  const mergedCells = [
    {
      row: 0,
      col: 0,
      rowspan: 1,
      colspan: sheet[0].length - 2,
    },
    {
      row: 0,
      col: sheet[0].length - 2,
      rowspan: 2,
      colspan: 2,
    },
    {
      row: 1,
      col: 2,
      rowspan: 1,
      colspan: sheet[0].length - 4,
    },
    {
      row: 1,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    { row: sheet.length - 2, col: 0, rowspan: 2, colspan: sheet[0].length },
  ];
  return mergedCells;
};

let totalRow: number | undefined = undefined;
export const customRenderer = (
  TD: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: string,
  cellProperties: CellProperties,
  sheet: any[][],
) => {
  if (row <= 2) return TD;
  if (value === 'Total') {
    totalRow = row;
  }

  const dateCols = sheet[0].length === 18 ? [8, 9, 10] : [2, 3, 4, 10, 11];
  const percentageCols = sheet[0].length === 18 ? [6, 7] : [5, 9];
  const numericCols = sheet[0].length === 18 ? [4, 12, 13] : [7, 8];
  const isDateCell = dateCols.includes(col) && (!totalRow || row < totalRow);
  const isNumericCell = numericCols.includes(col) || col >= 14;
  const isPercentageCell = percentageCols.includes(col);

  if (isDateCell) {
    TD.textContent = moment.utc(value).format(DEFAULT_DATE_FORMAT);
  }

  if (isNumericCell) {
    cellProperties.type = 'numeric';
    cellProperties.numericFormat = {
      pattern: NUMERIC_FORMAT_PATTERN,
    };
  }

  if (isPercentageCell) {
    cellProperties.type = 'numeric';
    cellProperties.numericFormat = {
      pattern: DEFAULT_PERCENTAGE_FORMAT,
    };
  }

  if (col === 20 && row === 0) {
    cellProperties.type = 'text';
    cellProperties.className = 'htLeft';
    cellProperties.wordWrap = true;
  }

  return TD;
};
