import { Form, Formik } from 'formik';
import { Button } from '../../Button';
import { Modal } from '../Modal';
import { Toast } from '../../../services/toastify/Toastify';
import { UNEXPECTED_ERROR_MESSAGE } from '../../../error/errorMessages.constants';
import { ComponentProps, Fragment } from 'react';
import { FormField } from '../../form-field/FormField';
import { MonthNames } from '../../../services/hedgehog/month-names.enum';
import { v4 as uuidv4 } from 'uuid';
import {
  ReportingPeriodsActiveOption,
  ReportingPeriodsReviewedOption,
} from '../../common/reporting-periods/enum/reporting-periods.enum';
import { useUser } from '../../../hooks/useUser';
import { UserRole } from '../../../services/hedgehog/enum/UserRole.enum';
import { reportingPeriodsFilterValidationSchema } from './reporting-periods-filter.schema';
import { IReportingPeriodsQuery } from '../../common/reporting-periods/interface/IReportingPeriodsQuery';

const currentYear = new Date().getFullYear();
const lastTenYears = Array.from({ length: 10 }, (_, index) =>
  (currentYear - index).toString(),
);

const validMonths: MonthNames[] = Object.values(MonthNames);
const isActiveFormField: ComponentProps<typeof FormField> = {
  id: 'isFilterActive',
  label: 'You want to filter the reporting periods?',
  name: 'isFilterActive',
  type: 'radio',
  containerClassName: '[&>*:nth-child(2)]:ml-0 mb-0',
  placeholder: 'Is Filter Active',
  options: Object.values(ReportingPeriodsActiveOption),
};

const formFields: ComponentProps<typeof FormField>[] = [
  {
    label: 'Year',
    id: 'year',
    name: 'year',
    type: 'select',
    placeholder: 'Year',
    options: lastTenYears,
  },
  {
    label: 'From',
    id: 'fromMonth',
    name: 'fromMonth',
    type: 'select',
    placeholder: 'From month',
    options: validMonths,
  },
  {
    label: 'To',
    id: 'toMonth',
    name: 'toMonth',
    type: 'select',
    placeholder: 'To Month',
    options: validMonths,
  },
  {
    id: 'reviewStatus',
    label: 'Review Status',
    name: 'reviewStatus',
    type: 'radio',
    placeholder: 'Review Status',
    containerClassName: '[&>*:nth-child(2)]:ml-0',
    options: Object.values(ReportingPeriodsReviewedOption),
  },
];

export const ReportingPeriodsFilterModal = ({
  close,
  initialValues,
  onSubmit,
}: {
  close: () => void;
  initialValues: IReportingPeriodsQuery;
  onSubmit: (values: IReportingPeriodsQuery) => any;
}) => {
  const { user } = useUser();
  const handleSubmit = async (values: typeof initialValues) => {
    if (user?.role !== UserRole.Admin) {
      values.reviewStatus = ReportingPeriodsReviewedOption.All;
    }
    try {
      onSubmit?.({ ...values });
      close();
    } catch (e: unknown) {
      if (e instanceof Error) Toast.error(e.message);
      else Toast.error(UNEXPECTED_ERROR_MESSAGE);
    }
  };

  return (
    <Modal close={close}>
      <div className="flex flex-col max-h-full w-full overflow-y-auto items-center py-2">
        <div className="flex flex-col justify-center items-center relative w-11/12 sm:w-96 z-0 overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
          <Formik
            initialValues={initialValues}
            validationSchema={reportingPeriodsFilterValidationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ values }) => (
              <Form className="flex flex-col justify-center items-center w-full p-4 gap-2 bg-white">
                <h2 className="font-medium text-gray-900 text-lg">
                  Reporting Periods Filters
                </h2>

                <div className="flex flex-wrap justify-center items-center w-full gap-2 my-2">
                  <div className="flex justify-center items-center w-full">
                    <FormField {...isActiveFormField} />
                  </div>

                  {values.isFilterActive === ReportingPeriodsActiveOption.Yes &&
                    formFields.map((field) => (
                      <Fragment key={uuidv4()}>
                        <div
                          className={
                            field.id === 'fromMonth' || field.id === 'toMonth'
                              ? 'w-[48%]'
                              : 'w-full'
                          }
                        >
                          {(field.id !== 'reviewStatus' ||
                            user?.role === UserRole.Admin) && (
                            <FormField {...field} />
                          )}
                        </div>
                      </Fragment>
                    ))}
                </div>

                <div className="flex flex-row items-center gap-4 justify-end w-full">
                  <button
                    data-cy="cancel-filter-config"
                    type="button"
                    className="text-sm font-semibold underline underline-offset-4"
                    onClick={close}
                  >
                    Cancel
                  </button>
                  <Button
                    data-cy="save-filter-config"
                    variant="solid"
                    color="cyan"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};
