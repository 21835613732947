import * as yup from 'yup';
import { IStaticField } from '../../OutputReportTypes';
import { CreateOrUpdateHedgeEffectivenessReport } from './enum/CreateOrUpdateHedgeEffectivenessReport.enum';

export const MtmValuationStaticData: {
  fields: IStaticField[];
  validationSchema: any;
} = {
  fields: [
    {
      label: 'Name',
      name: 'name',
      type: 'string',
      defaultValue: 'IRS MTM Valuation Report',
    },
    {
      label: 'Swap index',
      name: 'swapIndex',
      type: 'string',
    },
    {
      label: 'Create or update hedge effectiveness report?',
      name: 'createOrUpdateEffectiveness',
      type: 'radio',
      options: [
        CreateOrUpdateHedgeEffectivenessReport.CreateOrUpdate,
        CreateOrUpdateHedgeEffectivenessReport.Skip,
      ],
      defaultValue: CreateOrUpdateHedgeEffectivenessReport.Skip,
      comment:
        'NOTE: Remember to migrate old effectivenes report first. You can do this from the',
      link: {
        text: 'organization menu',
        url: '/organization/menu/:organizationId',
      },
    },
  ],

  validationSchema: yup.object().shape({
    name: yup.string().required('Name is required'),
    timeValue: yup.number().optional(),
    instrinsicValue: yup.number().optional(),
    swapIndex: yup.string().required('Swap index is required'),
  }),
};
