import {
  validateHeadersBySchema,
  validateTotalHeadersBySchema,
} from '../../helpers/headerValidator';
import { ProcessableInputReport } from '../../ProcessableInputReport';
import {
  hedgehogMtmValuationHeaderSchema,
  hedgehogMtmValuationTotalHeaderSchema,
} from './mtmValuation.schema';
import { hedgehogMtmValuationTotalHeader } from './mtmValuationTotalHeader';
import { IrsMtmJournalRequiredInputsType } from './mtmJournalInputs';
import {
  irsMtmValuationReportOutputHeaders,
  irsMtmValuationOutputHeaders,
} from './mtmValuationReportOutputHeaders';
import { InputReportType } from '../../inputReportType';

export class MtmValuationReportValidator {
  colHeaders: string[] = irsMtmValuationReportOutputHeaders;
  totalColHeaders: string[] = hedgehogMtmValuationTotalHeader;

  constructor(private processableInput: ProcessableInputReport) {}

  validate() {
    if (
      this.processableInput.sheetName !==
      InputReportType.HEDGEHOG_IRS_MTM_VALUATION_REPORT
    ) {
      throw new Error(
        `Input report type ${IrsMtmJournalRequiredInputsType.HEDGEHOG_MTM_VALUATION_REPORT} is wrong type for this output`,
      );
    }

    const totalRowValue = this.processableInput.findCellValue(
      irsMtmValuationOutputHeaders.total,
    );

    if (!totalRowValue) {
      throw new Error(
        `Row "${irsMtmValuationOutputHeaders.total}" is missing in ${this.processableInput.sheetName}`,
      );
    }

    validateHeadersBySchema(
      this.colHeaders,
      hedgehogMtmValuationHeaderSchema,
      this.processableInput,
      totalRowValue.row,
    );

    validateTotalHeadersBySchema(
      this.totalColHeaders,
      hedgehogMtmValuationTotalHeaderSchema,
      this.processableInput,
      totalRowValue.row,
    );
  }
}
