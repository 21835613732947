import { Link } from 'react-router-dom';
import { PasswordRecoveryForm } from '../../components/password-recovery-form/PasswordRecoveryForm';
import { AuthPageLayout } from '../../components/AuthLayout';

export const PasswordRecovery = () => {
  return (
    <AuthPageLayout
      title="Password recovery"
      subtitle={
        <>
          Already registered?{' '}
          <Link to="/" className="text-secondary-blue-500">
            Sign in
          </Link>{' '}
          to your account.
        </>
      }
    >
      <PasswordRecoveryForm />
    </AuthPageLayout>
  );
};
