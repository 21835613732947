import { IInputReport } from './IInputReport';
import { IOrganization } from './IOrganization';
import { IOutputReport } from './IOutputReport';
import { IUser } from './IUser';

export enum IFXHedgeDocumentationPipeline {
  RAW_REPORT_PIPELINE = 'RawReportPipeline',
  FX_HEDGE_DOCUMENTATION_PIPELINE = 'FXHedgeDocumentationFormPipeline',
}

export interface IFxHedgeDocumentationForm {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  name: string;
  reference: string;
  origin: null;
  pipeline: IFXHedgeDocumentationPipeline;
  type: string;
  currencyPair: string;
  template: string;
  reportingDate: string;
  reviewDate: string;
  organization: IOrganization;
  createdBy: IUser;
  updatedBy: IUser;
  output: IOutputReport;
  inputs: IInputReport[];
}
