import Core from 'handsontable/core';
import { CellProperties } from 'handsontable/settings';
import moment from 'moment';
import { CellValue } from 'exceljs';

export const NUMERIC_FORMAT_PATTERN = '#,##0.00';
export const DEFAULT_DATE_FORMAT = 'DD MMM YY';
export const DEFAULT_PERCENTAGE_FORMAT = '0.00%';

export const formatDate = (date: string, dateFormat = DEFAULT_DATE_FORMAT) => {
  return moment(new Date(date)).format(dateFormat);
};

export const parseDateToDefaultFormatWithRenderer = (
  instance: Core,
  TD: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: any,
  cellProperties: CellProperties,
) => {
  const isValidDate = moment(value, 'DD-MM-YY', true).isValid();
  if (isValidDate) {
    TD.textContent = moment(value).format(DEFAULT_DATE_FORMAT);
  } else {
    cellProperties.type = 'text';
  }
  return TD;
};

export function insertEmptyRowAfter(
  sheet: CellValue[][],
  header: string,
): CellValue[][] {
  const updatedSheet = [...sheet];

  const totalIndexes = updatedSheet.reduce((indexes, row, index) => {
    if (row.some((cell) => cell === header)) {
      indexes.push(index);
    }
    return indexes;
  }, [] as number[]);

  for (let i = totalIndexes.length - 1; i >= 0; i--) {
    const index = totalIndexes[i];
    if (typeof index === 'number') {
      updatedSheet.splice(index + 1, 0, Array(updatedSheet[0].length).fill(''));
    }
  }

  return updatedSheet;
}

export function parseRichText(sheet: CellValue[][]): CellValue[][] {
  const updatedSheet = sheet.map((row) =>
    row.map((cell) => {
      if (typeof cell === 'object' && cell !== null && 'richText' in cell) {
        return cell.richText.map((item) => item.text).join('');
      }
      return cell;
    }),
  );
  return updatedSheet;
}

export function removeEmptyRows(sheet: CellValue[][]): CellValue[][] {
  const newSheet = sheet.filter(
    (row) =>
      !row.every((cell) => cell?.toString().trim() === '' || cell === null),
  );
  return newSheet;
}

export function getCellProperties(
  sheet: any[][],
  headerPatterns: RegExp[],
  row: number,
  col: number,
) {
  const cellProperties: Partial<CellProperties> = {};
  cellProperties.className += ' default-text-color';

  const cellValue = sheet[row][col] ? String(sheet[row][col]) : '';

  if (headerPatterns.some((pattern) => pattern.test(cellValue))) {
    cellProperties.className += ' header';
  }

  if ((row === 1 && col === 2) || (row === 1 && col === 1)) {
    cellProperties.className += ' title';
  }

  if (row === 1 && col === 0) {
    cellProperties.className += ' subtitle';
  }

  if (row >= sheet.length - 3) {
    cellProperties.className += ' footer';
  }

  return cellProperties;
}

export const swapSheetValues = (
  sheet: any[][],
  firstIndexValues: number[],
  secondIndexValues: number[],
) => {
  const auxValue = sheet[firstIndexValues[0]][firstIndexValues[1]];
  sheet[firstIndexValues[0]][firstIndexValues[1]] =
    sheet[secondIndexValues[0]][secondIndexValues[1]];
  sheet[secondIndexValues[0]][secondIndexValues[1]] = auxValue;
};
