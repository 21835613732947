import { ICellWithFormula } from '../types/cell-with-formula.type';

export const isCellWithFormula = (
  value: unknown,
): value is ICellWithFormula => {
  if (value !== null && typeof value === 'object') {
    return 'formula' in value;
  }
  return false;
};
