import * as Yup from 'yup';

export const addNewUserAsAdminValidationSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
  name: Yup.string().required('Name is required'),
  'last-name': Yup.string().required('Lastname is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must include at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must include at least one uppercase letter')
    .matches(/\d/, 'Password must include at least one digit')
    .matches(
      /[$&+,:;=?@#|'<>.^*()%!-]/,
      'Password must include at least one special character',
    ),
  'repeat-password': Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Repeat Password is required'),
});
