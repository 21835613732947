import { parseCvsFileBufferToSheet } from './helpers/parseCsvFileBufferToSheet';
import { InputReportType } from './inputReportType';
import { ProcessableInputReport } from './ProcessableInputReport';
import { validationClasses } from './validationClasses';
import { Buffer } from 'buffer';

export async function validateInput(
  file: File,
  inputType: InputReportType,
): Promise<void> {
  // This conditional should be removed after all validation classes are added
  if (validationClasses[inputType] === undefined) return;

  const buffer = Buffer.from(await file.arrayBuffer());
  const sheet = parseCvsFileBufferToSheet(buffer);

  const processableInputReport = new ProcessableInputReport({
    sheet,
    sheetName: inputType,
  });

  const ReportValidationClass = validationClasses[inputType].validationClass;
  const validationClass = new ReportValidationClass(processableInputReport);
  validationClass.validate();
  processableInputReport.dispose();
}
