import { useEffect, useState } from 'react';
import { IIRSTrade } from '../../services/hedgehog/interfaces/IIRSTrade';
import moment from 'moment';
import { IIRSMTMValuationEntry } from '../../services/hedgehog/interfaces/IIRSMTMValuationEntry';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { defaultColors } from './default-colors';
type Props = {
  trade: IIRSTrade;
  className?: string;
};

const calculateDataValues = (entries: IIRSMTMValuationEntry[]) => {
  const map = new Map<
    string,
    {
      cleanFairValue: number;
      pnlDrCr: number;
      pnlVolatilityWithoutHedgeAccounting: number;
      pnlActualMovement: number;
    }
  >();
  entries.forEach((entry) => {
    const amount = map.get(moment(entry.reportingDate).format('MMMM YYYY')) ?? {
      cleanFairValue: 0,
      pnlDrCr: 0,
      pnlVolatilityWithoutHedgeAccounting: 0,
      pnlActualMovement: 0,
    };
    amount.cleanFairValue += entry.cleanFairValue;
    amount.pnlDrCr += entry.profitAndLossesDrCr;
    amount.pnlVolatilityWithoutHedgeAccounting = entry.periodicChange;
    amount.pnlActualMovement = entry.profitAndLossesDrCrChange;
    map.set(moment(entry.reportingDate).format('MMMM YYYY'), amount);
  });
  return Array.from(map).map(([key, value]) => ({
    month: key,
    cleanFairValue: value.cleanFairValue,
    pnlDrCr: value.pnlDrCr,
    pnlVolatilityWithoutHedgeAccounting:
      value.pnlVolatilityWithoutHedgeAccounting,
    pnlActualMovement: value.pnlActualMovement,
  }));
};

export const IRSTradeGraph = ({ trade, className = 'w-full h-80' }: Props) => {
  const [dateRange, setDateRange] = useState<number>(5);
  const [graphData, setGraphData] = useState<
    { month: string; cleanFairValue: number }[] | null
  >(null);
  const handleDateRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRange = parseInt(e.target.value);
    setDateRange(selectedRange);
  };

  useEffect(() => {
    if (!trade) return;
    const filteredEntries = trade.valuationEntries.filter((entry) =>
      moment(entry.reportingDate).isSameOrAfter(
        moment().subtract(dateRange, 'month'),
      ),
    );
    setGraphData(calculateDataValues(filteredEntries));
  }, [dateRange, trade, trade.valuationEntries]);

  return (
    <div className={`flex flex-col gap-4 ${className}`}>
      <div className="w-full flex flex-wrap items-center justify-between ">
        <div className="w-full flex flex-row gap-2 items-center justify-center">
          <div className="w-full flex flex-row gap-8 items-center justify-start">
            <div>
              <span className="font-medium">Reference:</span>
              <p data-cy="irs-trade-graph-reference">{trade.reference}</p>
            </div>
            <div>
              <span className="font-medium">Counter Party:</span>
              <p>{trade.counterParty}</p>
            </div>
          </div>
          <div className="w-60 flex flex-col justify-right">
            <label className="font-medium" htmlFor="date-range">
              Select Date Range:
            </label>
            <select
              data-cy="irs-trade-graph"
              id="date-range"
              value={dateRange}
              onChange={handleDateRangeChange}
            >
              <option value={5}>6 Months</option>
              <option value={11}>1 Year</option>
              <option value={23}>2 Years</option>
            </select>
          </div>
        </div>
      </div>

      {!graphData && <div className="text-center">No data</div>}

      {graphData && (
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={graphData}>
            <YAxis
              tick={{ fontSize: 10 }}
              tickFormatter={(tick) => {
                return tick.toLocaleString();
              }}
              label={{
                value: 'AUD',
                position: 'insideLeft',
                fontSize: 12,
              }}
            />
            <XAxis dataKey="month" tick={{ fontSize: 10 }} />
            <Tooltip formatter={(value) => `${value.toLocaleString()}`} />
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            <Area
              type="monotone"
              dataKey="pnlVolatilityWithoutHedgeAccounting"
              name="P&L Movements if FVTPL"
              fill={defaultColors.cyan500.opaque}
              stroke={defaultColors.cyan500.opaque}
            />
            <Area
              type="monotone"
              dataKey="pnlActualMovement"
              name="P&L Movements of CFH"
              fill={defaultColors.purple500.opaque}
              stroke={defaultColors.purple500.opaque}
            />
            <Bar
              dataKey="cleanFairValue"
              name="FV of Portfolio on B/S"
              fill={defaultColors.blue400.opaque}
              barSize={16}
              stroke={defaultColors.blue400.opaque}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
