import ConfirmModal from '../components/modal/confirm-modal/ConfirmModal';
import { useModal } from './useModal';

export const useConfirm = () => {
  const { openModal, closeModal } = useModal();

  const confirmAction = async (
    action: () => Promise<void>,
    text = 'Are you sure?',
    title = 'Confirm',
  ) => {
    const onConfirm = async () => {
      await action();
      closeModal('confirm-action');
    };

    openModal(
      'confirm-action',
      <ConfirmModal
        onCancel={() => closeModal('confirm-action')}
        onConfirm={onConfirm}
        title={title}
        text={text}
      />,
    );
  };

  return { confirmAction };
};
