import { Link } from 'react-router-dom';
import { HedgehogLogo } from '../icons/HedgehogLogo';
import { NavLinks } from '../navbar/NavLinks';
import { navLink } from '../navbar/navLink.constant';

export const Footer = () => {
  return (
    <footer className="border-t border-gray-200">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-start justify-between gap-y-12 pb-6 pt-16 lg:flex-row lg:items-center lg:py-16">
          <div>
            <div className="flex flex-wrap items-center text-gray-900">
              <Link to="/" data-cy="footer-link">
                <HedgehogLogo
                  svgIconStyles="w-64 h-full p-4 flex-none fill-cyan-500"
                  logoTitleStyles="text-primary-dark-500 fill-current"
                  logoIconStyles="text-primary-cyan-500 fill-current"
                />
              </Link>
              <div className="ml-4 flex flex-col sm:block">
                <p className="text-base font-semibold" data-cy="footer-title">
                  Hedgehog
                </p>
                <p className="mt-1 text-sm" data-cy="footer-subtitle">
                  Hedge Effective Advisory's cloud-based platform for all your
                  hedge accounting compliance and reporting needs.
                </p>
              </div>
            </div>
            <nav className="sm:mt-11 flex flex-wrap gap-4 sm:gap-8 p-4">
              <NavLinks link={navLink} />
            </nav>
          </div>
        </div>
        <div className="flex flex-col items-center border-t border-gray-200 pb-12 pt-8 md:flex-row-reverse md:justify-between md:pt-6">
          <p className="mt-6 text-sm text-gray-500 md:mt-0">
            &copy; Copyright {new Date().getFullYear()}. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
