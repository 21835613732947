import { Link } from 'react-router-dom';
import { IOrganization } from '../../../../services/hedgehog/interfaces/IOrganization';
import { formatDate } from '../../../../components/tables/common/utils';

type Props = {
  organizations: IOrganization[];
  showUnarchiveActions: boolean;
  handleArchiveOrganization: (organizationId: number) => void;
  handleUnarchiveOrganization: (organizationId: number) => void;
  handleDeleteOrganization: (organizationId: number) => void;
};

const columnNames: string[] = [
  'logo',
  'name',
  'type',
  'created at',
  'members',
  'manager',
  'actions',
];
export const ClientsTable = ({
  organizations,
  showUnarchiveActions,
  handleArchiveOrganization,
  handleUnarchiveOrganization,
  handleDeleteOrganization,
}: Props) => {
  const getManagerEmail = (organization: IOrganization) => {
    return organization.people.find((member) => member.position === 'manager')
      ?.user.email;
  };

  return (
    <table className="w-full text-sm text-left text-gray-500">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
        <tr className="bg-white border-b hover:bg-gray-50">
          {columnNames.map((key) => (
            <th key={key} className="px-6 whitespace-nowrap py-4 space-x-2">
              {key}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {organizations.map((organization) => (
          <tr
            key={organization.id}
            className="bg-white whitespace-nowrap border-b hover:bg-gray-50"
          >
            <td className="py-2 px-2 md:px-0">
              <img
                data-cy={`logo-${organization.id}`}
                className="m-auto rounded object-scale-down h-[75px] w-[145px]"
                alt={`${organization.name}-logo`}
                src={organization.img}
              />
            </td>
            <td className="px-6 py-4 space-x-2">{organization.name}</td>
            <td className="px-6 py-4 space-x-2">{organization.exposureType}</td>
            <td className="px-6 py-4 space-x-2">
              {formatDate(organization.createdAt)}
            </td>
            <td className="px-6 py-4 space-x-2">
              {organization.people.length}
            </td>
            <td className="px-6 py-4 space-x-2">
              {getManagerEmail(organization)}
            </td>
            <td className="px-6 pr-6 py-4 gap-3 justify-start align-middle">
              {!organization.is_active ? (
                <div>
                  <button
                    className="font-medium text-blue-600 hover:underline"
                    data-cy={`unarchive-organization-${organization.id}`}
                    onClick={() => handleUnarchiveOrganization(organization.id)}
                  >
                    Unarchive
                  </button>
                  <button
                    className="font-medium text-red-600 hover:underline ml-4"
                    data-cy={`delete-organization-${organization.id}`}
                    onClick={() => handleDeleteOrganization(organization.id)}
                  >
                    Delete permanently
                  </button>
                </div>
              ) : (
                <div>
                  <Link
                    className="text-sm font-semibold text-blue-500/90"
                    to={`/organization/menu/${organization.id}`}
                    data-cy={`view-organization-${organization.id}`}
                    state={{ showBackButton: true }}
                  >
                    Manage
                  </Link>
                  <button
                    className="font-medium text-red-600 hover:underline ml-4"
                    data-cy={`archive-organization-${organization.id}`}
                    onClick={() => handleArchiveOrganization(organization.id)}
                  >
                    Archive
                  </button>
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
