type Props = {
  className?: string;
  children: React.ReactNode;
  defaultStyles?: string;
};

export const Page = ({
  className = '',
  children,
  defaultStyles = 'flex flex-col justify-center grow items-center w-full max-h-fit',
}: Props) => {
  return <main className={`${defaultStyles} ${className}`}>{children}</main>;
};
