/* eslint-disable no-sparse-arrays */
/* eslint-disable quotes */
export const fxHedgeDocumentationStandardSheetMock = [
  [, , 'The George Institute for Global Health'],
  [, , 'HEDGE DESIGNATION, DOCUMENTATION & APPROVAL FORM'],
  [],
  [, , 'Nature of risk hedged'],
  [, , 'xxxx', 'xxxx', 'xxxx', 'xxxx', 'xxxx'],
  [, , 'Policy Declaration'],
  [
    ,
    ,
    'Policy Declaration',
    'Policy Declaration',
    'Policy Declaration',
    'Policy Declaration',
    'Policy Declaration',
  ],
  [, , 'Risk Management Strategy'],
  [
    ,
    ,
    'Risk Management Strategy',
    'Risk Management Strategy',
    'Risk Management Strategy',
    'Risk Management Strategy',
    'Risk Management Strategy',
  ],
  [, , 'Risk Management Objective'],
  [
    ,
    ,
    'Risk Management Objective',
    'Risk Management Objective',
    'Risk Management Objective',
    'Risk Management Objective',
    'Risk Management Objective',
  ],
  [],
  [, , 'Hedge Type', 'Hedge Type', 'Hedge Type', 'Yes', 'No'],
  [, , 'Cash Flow Hedge', 'Cash Flow Hedge', 'Cash Flow Hedge', 'x'],
  [, , 'Fair Value Hedge', 'Fair Value Hedge', 'Fair Value Hedge', null, 'x'],
  [
    ,
    ,
    'Net Investment Hedge',
    'Net Investment Hedge',
    'Net Investment Hedge',
    null,
    'x',
  ],
  [, , 'Date of designation of hedging relationship'],
  [
    ,
    ,
    new Date('2023-11-08T21:00:00-03:00'),
    new Date('2023-11-08T21:00:00-03:00'),
    new Date('2023-11-08T21:00:00-03:00'),
    new Date('2023-11-08T21:00:00-03:00'),
    new Date('2023-11-08T21:00:00-03:00'),
  ],
  [, , 'Details of the Hedging Instrument', , ,],
  [
    ,
    ,
    'Nature of hedging instrument',
    'Nature of hedging instrument',
    'Nature of hedging instrument',
    'Nature of hedging instrument',
    'Nature of hedging instrument',
  ],
  [
    ,
    ,
    'Deal Reference number (attach copy of confirmation)',
    'SDBBKCM33333GSS3RG',
    'SDBBKCM33333GSS3RG',
    'SDBBKCM33333GSS3RG',
    'SDBBKCM33333GSS3RG',
  ],
  [
    ,
    ,
    'Contractual parties of the hedging instrument',
    'GS & The George Institute for Global Health',
    'GS & The George Institute for Global Health',
    'GS & The George Institute for Global Health',
    'GS & The George Institute for Global Health',
  ],
  [
    ,
    ,
    'Start Date',
    new Date('2023-11-08T21:00:00-03:00'),
    new Date('2023-11-08T21:00:00-03:00'),
    new Date('2023-11-08T21:00:00-03:00'),
    new Date('2023-11-08T21:00:00-03:00'),
  ],
  [
    ,
    ,
    'Maturity Date',
    new Date('2024-01-09T21:00:00-03:00'),
    new Date('2024-01-09T21:00:00-03:00'),
    new Date('2024-01-09T21:00:00-03:00'),
    new Date('2024-01-09T21:00:00-03:00'),
  ],
  [, , 'Currency', 'NZD', 'NZD', 'NZD', 'NZD'],
  [
    ,
    ,
    'Notional amount',
    'NZD 600000',
    'NZD 600000',
    'NZD 600000',
    'NZD 600000',
  ],
  [
    ,
    ,
    'Cash Flows to be paid',
    'NZD 600000',
    'NZD 600000',
    'NZD 600000',
    'NZD 600000',
  ],
  [
    ,
    ,
    'Cash Flows to be received',
    'AUD 553712.41',
    'AUD 553712.41',
    'AUD 553712.41',
    'AUD 553712.41',
  ],
  [
    ,
    ,
    'Amount of principal designated as hedging instrument',
    0.97,
    0.97,
    0.97,
    0.97,
  ],
  [, , 'Details of the Hedged Item', , ,],
  [
    ,
    ,
    'Nature of hedged item',
    'Nature of Hedged Item',
    'Nature of Hedged Item',
    'Nature of Hedged Item',
    'Nature of Hedged Item',
  ],
  [
    ,
    ,
    'Impact to profit or loss',
    'Impact to profit or loss',
    'Impact to profit or loss',
    'Impact to profit or loss',
    'Impact to profit or loss',
  ],
  [
    ,
    ,
    'Contractual parties of the hedging item',
    'Contractual parties of the hedging item',
    'Contractual parties of the hedging item',
    'Contractual parties of the hedging item',
    'Contractual parties of the hedging item',
  ],
  [
    ,
    ,
    'Start Date',
    new Date('2023-11-08T21:00:00-03:00'),
    new Date('2023-11-08T21:00:00-03:00'),
    new Date('2023-11-08T21:00:00-03:00'),
    new Date('2023-11-08T21:00:00-03:00'),
  ],
  [
    ,
    ,
    'Maturity Date',
    new Date('2024-01-09T21:00:00-03:00'),
    new Date('2024-01-09T21:00:00-03:00'),
    new Date('2024-01-09T21:00:00-03:00'),
    new Date('2024-01-09T21:00:00-03:00'),
  ],
  [, , 'Currency', 'NZD', 'NZD', 'NZD', 'NZD'],
  [
    ,
    ,
    'Notional amount',
    'NZD 600000',
    'NZD 600000',
    'NZD 600000',
    'NZD 600000',
  ],
  [, , 'Amount designated as a hedged item', 0.97, 0.97, 0.97, 0.97],
  [, , 'Fair value at inception', 'Nil', 'Nil', 'Nil', 'Nil'],
  [, , 'Effectiveness Testing'],
  [, , 'Prospective Test at inception'],
  [
    ,
    ,
    'Can hedge effectiveness be reliably measured',
    'Yes',
    'Yes',
    'Yes',
    'Yes',
  ],
  [
    ,
    ,
    'Expectation of hedge effectiveness',
    'Highly Effective',
    'Highly Effective',
    'Highly Effective',
    'Highly Effective',
  ],
  [
    ,
    ,
    'Type of test',
    'Matched Terms Test',
    'Matched Terms Test',
    'Matched Terms Test',
    'Matched Terms Test',
  ],
  [, , 'Matched Terms Prospective Testing at inception'],
  [, , 'Notional/Principle Amount', 'Yes', 'Yes', 'Yes', 'Yes'],
  [, , 'Currency', 'Yes', 'Yes', 'Yes', 'Yes'],
  [, , 'Inception Date', 'Yes', 'Yes', 'Yes', 'Yes'],
  [, , 'Maturity Date', 'Yes', 'Yes', 'Yes', 'Yes'],
  [, , 'Principle and coupon payments dates', 'Yes', 'Yes', 'Yes', 'Yes'],
  [, , 'Underlying risk(s) match', 'Yes', 'Yes', 'Yes', 'Yes'],
  [
    ,
    ,
    'Credit quality of counterparty is investment grade',
    'N/A',
    'N/A',
    'N/A',
    'N/A',
  ],
  [, , 'Benchmark interest rate basis', 'N/A', 'N/A', 'N/A', 'N/A'],
  [, , 'Prospective Effectiveness Testing at inception'],
  [
    ,
    ,
    'Date of Test',
    new Date('2023-11-08T21:00:00-03:00'),
    new Date('2023-11-08T21:00:00-03:00'),
    new Date('2023-11-08T21:00:00-03:00'),
    new Date('2023-11-08T21:00:00-03:00'),
  ],
  [
    ,
    ,
    'Results of Test',
    'Highly Effective',
    'Highly Effective',
    'Highly Effective',
    'Highly Effective',
  ],
  [, , 'Prospective Effectiveness Testing'],
  [
    ,
    ,
    'Can hedge effectiveness be reliably measured',
    'Yes',
    'Yes',
    'Yes',
    'Yes',
  ],
  [
    ,
    ,
    'Expectation of hedge effectiveness',
    'Highly Effective',
    'Highly Effective',
    'Highly Effective',
    'Highly Effective',
  ],
  [
    ,
    ,
    'Type of test',
    'Cumulative Dollar Offset',
    'Cumulative Dollar Offset',
    'Cumulative Dollar Offset',
    'Cumulative Dollar Offset',
  ],
  [
    ,
    ,
    'Hedge effectiveness testing',
    'Hedge effectiveness testing',
    'Hedge effectiveness testing',
    'Hedge effectiveness testing',
  ],
  [, , 'Existence of an economic relationship'],
  [, , 'An economic', 'An economic', 'An economic', 'An economic'],
  [, , 'Effect of changes in credit risk'],
  [, , 'Credit risk', 'Credit risk', 'Credit risk', 'Credit risk'],
  [, , 'Hedge ratio applied'],
  [
    ,
    ,
    'The same quantity of hedged',
    'The same quantity of hedged',
    'The same quantity of hedged',
    'The same quantity of hedged',
  ],
  [, , 'How Ineffectiveness will be quantified'],
  [
    ,
    ,
    'Cumulative ineffectiveness',
    'Cumulative ineffectiveness',
    'Cumulative ineffectiveness',
    'Cumulative ineffectiveness',
  ],
  [, , 'Expected ineffectiveness'],
  [
    ,
    ,
    'Potential sources of ineffectiveness',
    'Potential sources of ineffectiveness',
    'Potential sources of ineffectiveness',
    'Potential sources of ineffectiveness',
  ],
  [, , 'Testing Frequency'],
  [
    ,
    ,
    'The prospective testing will',
    'The prospective testing will',
    'The prospective testing will',
    'The prospective testing will',
  ],
  [
    ,
    ,
    'Hedge Documentation prepared by',
    'Jane Doe - CEO - Hedgehog',
    'Jane Doe - CEO - Hedgehog',
    'Jane Doe - CEO - Hedgehog',
    'Jane Doe - CEO - Hedgehog',
  ],
  [
    ,
    ,
    'Hedge Documentation reviewed by',
    'Jane Doe - CEO - Hedgehog',
    'Jane Doe - CEO - Hedgehog',
    'Jane Doe - CEO - Hedgehog',
    'Jane Doe - CEO - Hedgehog',
  ],
  [
    ,
    ,
    'Date',
    new Date('2023-11-08T21:00:00-03:00'),
    new Date('2023-11-08T21:00:00-03:00'),
    new Date('2023-11-08T21:00:00-03:00'),
    new Date('2023-11-08T21:00:00-03:00'),
  ],
];

/* eslint-disable quotes */
export const fxHedgeDocumentationSyntheticSheetMock = [
  [, , 'John Doe Organization'],
  [, , 'HEDGE DESIGNATION, DOCUMENTATION & APPROVAL FORM'],
  [],
  [, , 'Nature of risk hedged'],
  [
    ,
    ,
    'Cash flow hedge of two hedged items; (i) AUD/USD for anticipated purchases and (ii) a floating interest rate hedge for a forecasted borrowing transaction. The forward rate is preferred.',
    'Cash flow hedge of two hedged items; (i) AUD/USD for anticipated purchases and (ii) a floating interest rate hedge for a forecasted borrowing transaction. The forward rate is preferred.',
    'Cash flow hedge of two hedged items; (i) AUD/USD for anticipated purchases and (ii) a floating interest rate hedge for a forecasted borrowing transaction. The forward rate is preferred.',
    'Cash flow hedge of two hedged items; (i) AUD/USD for anticipated purchases and (ii) a floating interest rate hedge for a forecasted borrowing transaction. The forward rate is preferred.',
    'Cash flow hedge of two hedged items; (i) AUD/USD for anticipated purchases and (ii) a floating interest rate hedge for a forecasted borrowing transaction. The forward rate is preferred.',
  ],
  [, , 'Policy Declaration'],
  [, , 'XXX', 'XXX', 'XXX', 'XXX', 'XXX'],
  [, , 'Risk Management Strategy'],
  [, , 'XXX', 'XXX', 'XXX', 'XXX', 'XXX'],
  [, , 'Risk Management Objective'],
  [, , 'XXX', 'XXX', 'XXX', 'XXX', 'XXX'],
  [, , 'Hedge Type', 'Hedge Type', 'Hedge Type', 'Yes', 'No'],
  [, , 'Cash Flow Hedge', 'Cash Flow Hedge', 'Cash Flow Hedge', 'x'],
  [, , 'Fair Value Hedge', 'Fair Value Hedge', 'Fair Value Hedge', , 'x'],
  [
    ,
    ,
    'Net Investment Hedge',
    'Net Investment Hedge',
    'Net Investment Hedge',
    ,
    'x',
  ],
  [, , 'Date of designation of hedging relationship'],
  [
    ,
    ,
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
  ],
  [, , 'Details of the Hedging Instrument', , , ,],
  [, , 'Nature of hedging instrument', 'XXX', 'XXX', 'XXX', 'XXX'],
  [
    ,
    ,
    'Deal Reference number (attach copy of confirmation)',
    '0025C40228077956ZZZXX',
    '0025C40228077956ZZZXX',
    '0025C40228077956ZZZXX',
    '0025C40228077956ZZZXX',
  ],
  [
    ,
    ,
    'Contractual parties of the hedging instrument',
    'John Doe & John Doe Organization',
    'John Doe & John Doe Organization',
    'John Doe & John Doe Organization',
    'John Doe & John Doe Organization',
  ],
  [
    ,
    ,
    'Start Date',
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
  ],
  [
    ,
    ,
    'Maturity Date',
    new Date('2024-08-14T21:00:00-03:00'),
    new Date('2024-08-14T21:00:00-03:00'),
    new Date('2024-08-14T21:00:00-03:00'),
    new Date('2024-08-14T21:00:00-03:00'),
  ],
  [, , 'Currency', 'TWD', 'TWD', 'TWD', 'TWD'],
  [
    ,
    ,
    'Notional amount',
    'TWD 3000000',
    'TWD 3000000',
    'TWD 3000000',
    'TWD 3000000',
  ],
  [
    ,
    ,
    'Cash Flows to be paid',
    'USD 82777.57',
    'USD 82777.57',
    'USD 82777.57',
    'USD 82777.57',
  ],
  [
    ,
    ,
    'Cash Flows to be received',
    'TWD 3000000',
    'TWD 3000000',
    'TWD 3000000',
    'TWD 3000000',
  ],
  [, , 'Amount of principal designated as hedging instrument', 1, 1, 1, 1],
  [, , 'Details of the Hedged Item'],
  [
    ,
    ,
    'Nature of hedged item',
    'John Doe Organization incurs external operational costs (cash outflows) in TWD which it hedges to minimize exposure. The forecasted amounts are updated for accuracy on a regular basis, at a minimum monthly.',
    'John Doe Organization incurs external operational costs (cash outflows) in TWD which it hedges to minimize exposure. The forecasted amounts are updated for accuracy on a regular basis, at a minimum monthly.',
    'John Doe Organization incurs external operational costs (cash outflows) in TWD which it hedges to minimize exposure. The forecasted amounts are updated for accuracy on a regular basis, at a minimum monthly.',
    'John Doe Organization incurs external operational costs (cash outflows) in TWD which it hedges to minimize exposure. The forecasted amounts are updated for accuracy on a regular basis, at a minimum monthly.',
  ],
  [, , 'Impact to profit or loss', 'XXX', 'XXX', 'XXX', 'XXX'],
  [, , 'Contractual parties of the hedging item', 'xxx', 'xxx', 'xxx', 'xxx'],
  [
    ,
    ,
    'Start Date',
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
  ],
  [
    ,
    ,
    'Maturity Date',
    new Date('2024-08-14T21:00:00-03:00'),
    new Date('2024-08-14T21:00:00-03:00'),
    new Date('2024-08-14T21:00:00-03:00'),
    new Date('2024-08-14T21:00:00-03:00'),
  ],
  [, , 'Currency hedge item (i)', 'USD', 'USD', 'USD', 'USD'],
  [
    ,
    ,
    'Notional amount (i)',
    'USD 82777.57',
    'USD 82777.57',
    'USD 82777.57',
    'USD 82777.57',
  ],
  [, , 'Currency hedge item (ii)', 'TWD', 'TWD', 'TWD', 'TWD'],
  [
    ,
    ,
    'Notional amount (ii)',
    'TWD 3000000',
    'TWD 3000000',
    'TWD 3000000',
    'TWD 3000000',
  ],
  [, , 'Amount designated as a hedged item', 1, 1, 1, 1],
  [, , 'Fair value at inception', 'Nil', 'Nil', 'Nil', 'Nil'],
  [, , 'Assessment of hedge effectiveness'],
  [, , 'Effectiveness testing at inception and ongoing'],
  [
    ,
    ,
    'Date of test',
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
  ],
  [
    ,
    ,
    'Results of test',
    'Highly Effective',
    'Highly Effective',
    'Highly Effective',
    'Highly Effective',
  ],
  [, , 'Details of qualitative and quantitative testing'],
  [
    ,
    ,
    'Can hedge effectiveness be reliably measured',
    'Yes',
    'Yes',
    'Yes',
    'Yes',
  ],
  [
    ,
    ,
    'Type of test',
    'Qualitative and quantitative testing',
    'Qualitative and quantitative testing',
    'Qualitative and quantitative testing',
    'Qualitative and quantitative testing',
  ],
  [
    ,
    ,
    'To achieve hedge effectiveness we need to split the exposures. Leg (i) is USD/AUD, and Leg (ii) is AUD/TWD. Both legs are subject to TWD/AUD exchange rate movements.',
    'In order to assess the hedge effectiveness we can perform qualitative and quantitative testing by comparing the fair value of the hedging instrument for the same period.',
    'In order to assess the hedge effectiveness we can perform qualitative and quantitative testing by comparing the fair value of the hedging instrument for the same period.',
    'In order to assess the hedge effectiveness we can perform qualitative and quantitative testing by comparing the fair value of the hedging instrument for the same period.',
    'In order to assess the hedge effectiveness we can perform qualitative and quantitative testing by comparing the fair value of the hedging instrument for the same period.',
  ],
  [
    ,
    ,
    'Matched terms test',
    'Hedging Instrument',
    'Hedged Item (i)',
    'Hedged Item (ii)',
    'Yes/No',
  ],
  [, , 'Notional amounts', 'TWD 3000000', 82777.57, 3000000, 'Yes'],
  [, , , 'TWD 3000000', 82777.57, 3000000, 'Yes'],
  [, , 'Currency', 'TWD/USD', 'USD', 'TWD', 'Yes'],
  [
    ,
    ,
    'Inception date',
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
    'Yes',
  ],
  [
    ,
    ,
    'Maturity date',
    new Date('2024-08-14T21:00:00-03:00'),
    new Date('2024-08-14T21:00:00-03:00'),
    new Date('2024-08-14T21:00:00-03:00'),
    'Yes',
  ],
  [, , 'Underlying risk(s)', 'Leg (i) USD/AUD', 'USD/AUD', 'AUD/TWD', 'Yes'],
  [, , , 'Leg (i) USD/AUD', 'USD/AUD', 'AUD/TWD', 'Yes'],
  [, , 'Existence of an economic relationship'],
  [
    ,
    ,
    'An economic relationship exists. The changes in the value of the hedged item and the hedging instrument generally move in the opposite direction and offset each other.',
    'An economic relationship exists. The changes in the value of the hedged item and the hedging instrument generally move in the opposite direction and offset each other.',
    'An economic relationship exists. The changes in the value of the hedged item and the hedging instrument generally move in the opposite direction and offset each other.',
    'An economic relationship exists. The changes in the value of the hedged item and the hedging instrument generally move in the opposite direction and offset each other.',
    'An economic relationship exists. The changes in the value of the hedged item and the hedging instrument generally move in the opposite direction and offset each other.',
  ],
  [, , 'Effect of changes in credit risk'],
  [
    ,
    ,
    "Credit risk arises from the credit rating of the company and the counterparty to the forward contracts. The company monitors the counterparty's credit risk for any significant changes. The credit risk associated with the company and the counterparty is considered minimal and will be re-assessed in cases where there is a significant change in any party's circumstances. The credit risk of the counterparty is not expected to change significantly over the life of the contract.",
    "Credit risk arises from the credit rating of the company and the counterparty to the forward contracts. The company monitors the counterparty's credit risk for any significant changes. The credit risk associated with the company and the counterparty is considered minimal and will be re-assessed in cases where there is a significant change in any party's circumstances. The credit risk of the counterparty is not expected to change significantly over the life of the contract.",
    "Credit risk arises from the credit rating of the company and the counterparty to the forward contracts. The company monitors the counterparty's credit risk for any significant changes. The credit risk associated with the company and the counterparty is considered minimal and will be re-assessed in cases where there is a significant change in any party's circumstances. The credit risk of the counterparty is not expected to change significantly over the life of the contract.",
    "Credit risk arises from the credit rating of the company and the counterparty to the forward contracts. The company monitors the counterparty's credit risk for any significant changes. The credit risk associated with the company and the counterparty is considered minimal and will be re-assessed in cases where there is a significant change in any party's circumstances. The credit risk of the counterparty is not expected to change significantly over the life of the contract.",
  ],
  [, , 'Hedge ratio applied'],
  [
    ,
    ,
    'The same quantity of hedged item and hedging instrument is used for hedge accounting purposes as it is for risk management purposes.',
    'The same quantity of hedged item and hedging instrument is used for hedge accounting purposes as it is for risk management purposes.',
    'The same quantity of hedged item and hedging instrument is used for hedge accounting purposes as it is for risk management purposes.',
    'The same quantity of hedged item and hedging instrument is used for hedge accounting purposes as it is for risk management purposes.',
  ],
  [, , 'How Ineffectiveness will be quantified'],
  [
    ,
    ,
    'Cumulative ineffectiveness within the relationship since the previous reporting date, calculated as above.',
    'Cumulative ineffectiveness within the relationship since the previous reporting date, calculated as above.',
    'Cumulative ineffectiveness within the relationship since the previous reporting date, calculated as above.',
    'Cumulative ineffectiveness within the relationship since the previous reporting date, calculated as above.',
  ],
  [, , 'Expected ineffectiveness'],
  [
    ,
    ,
    'Potential sources of ineffectiveness',
    'Potential sources of ineffectiveness',
    'Potential sources of ineffectiveness',
    'Potential sources of ineffectiveness',
  ],
  [, , 'Testing Frequency'],
  [
    ,
    ,
    'The prospective testing will be performed on a semi-annual basis. An annual test to ensure that the hedge accounting criteria are still being met will be carried out.',
    'The prospective testing will be performed on a semi-annual basis. An annual test to ensure that the hedge accounting criteria are still being met will be carried out.',
    'The prospective testing will be performed on a semi-annual basis. An annual test to ensure that the hedge accounting criteria are still being met will be carried out.',
    'The prospective testing will be performed on a semi-annual basis. An annual test to ensure that the hedge accounting criteria are still being met will be carried out.',
  ],
  [
    ,
    ,
    'Hedge Documentation prepared by',
    'John Doe - Managing Director - Hedge Advisory',
    'John Doe - Managing Director - Hedge Advisory',
    'John Doe - Managing Director - Hedge Advisory',
    'John Doe - Managing Director - Hedge Advisory',
  ],
  [
    ,
    ,
    'Hedge Documentation reviewed by',
    'Jane Doe - CFO - Who Gives A Crap',
    'Jane Doe - CFO - Who Gives A Crap',
    'Jane Doe - CFO - Who Gives A Crap',
    'Jane Doe - CFO - Who Gives A Crap',
  ],
  [
    ,
    ,
    'Date',
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
    new Date('2024-07-14T21:00:00-03:00'),
  ],
];
