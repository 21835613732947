import * as Sentry from '@sentry/react';
import 'handsontable/dist/handsontable.full.min.css';
import { registerAllModules } from 'handsontable/registry';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import { SessionRefresher } from './components/SessionRefresher';
import { ModalProvider } from './context/ModalProvider';

import { Chart as ChartJS, registerables } from 'chart.js';
import { ErrorFallback } from './components/ErrorFallback';
import { router } from './router';
import AuthTracker from './components/AuthTracker';
import { AxiosInterceptor } from './services/axios/AxiosInterceptors';
import { ApiCallHandlerProvider } from './context/ApiCallHandlerProvider';

ChartJS.register(...registerables);

const queryClient = new QueryClient();

registerAllModules();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AxiosInterceptor />
      <Sentry.ErrorBoundary fallback={<ErrorFallback />} showDialog>
        <ApiCallHandlerProvider>
          <ModalProvider>
            <SessionRefresher>
              <div className="min-h-screen max-h-fit w-full flex flex-col">
                <AuthTracker />
                <RouterProvider router={router} />
              </div>
            </SessionRefresher>
          </ModalProvider>
        </ApiCallHandlerProvider>
      </Sentry.ErrorBoundary>
    </QueryClientProvider>
  );
}
export default App;
