import * as Joi from 'joi';
import { irsMtmValuationInputHeaderTypes } from '../cvaReport/irsMtmValuationReportInputHeaders';

export const detailedInstrumentSchemas: Record<string, Joi.Schema> = {
  [irsMtmValuationInputHeaderTypes.reference[0]]: Joi.alternatives()
    .try(Joi.string(), Joi.number())
    .required()
    .messages({
      'alternatives.types':
        'Reference must be a valid string or number and cannot be null or empty',
      'any.required': 'Reference must be provided',
    }),
  [irsMtmValuationInputHeaderTypes.accruedInterest[0]]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Accrued Interest must be a valid number and cannot be null or empty',
      'any.required': 'Accrued Interest must be provided',
    }),
  [irsMtmValuationInputHeaderTypes.effectiveDate[0]]: Joi.date()
    .required()
    .messages({
      'date.base':
        'Effective Date must be a valid date and cannot be null or empty',
      'any.required': 'Effective Date must be provided',
    }),
  [irsMtmValuationInputHeaderTypes.maturityDate[0]]: Joi.date()
    .required()
    .messages({
      'date.base':
        'Maturity Date must be a valid date and cannot be null or empty',
      'any.required': 'Maturity Date must be provided',
    }),
  [irsMtmValuationInputHeaderTypes.baseCurrency[0]]: Joi.string()
    .regex(/^[A-Z]{3}$/)
    .required()
    .messages({
      'string.base':
        'Base Currency must be a valid string and cannot be null or empty',
      'string.pattern.base':
        'Base Currency must be a three-letter uppercase string',
      'any.required': 'Base Currency must be provided',
    }),
  [irsMtmValuationInputHeaderTypes.counterparty[0]]: Joi.string()
    .required()
    .messages({
      'string.base':
        'Counterparty must be a valid string and cannot be null or empty',
      'any.required': 'Counterparty must be provided',
    }),
  [irsMtmValuationInputHeaderTypes.dealDate[0]]: Joi.date()
    .required()
    .messages({
      'date.base': 'Deal Date must be a valid date and cannot be null or empty',
      'any.required': 'Deal Date must be provided',
    }),
  [irsMtmValuationInputHeaderTypes.value[0]]: Joi.number().required().messages({
    'number.base': 'Value must be a valid number and cannot be null or empty',
    'any.required': 'Value must be provided',
  }),
};
