import { ComponentProps, useEffect, useState } from 'react';
import { Modal } from '../Modal';
import { organizationFormValidationSchema } from './organization-form.schema';
import { Formik, Form } from 'formik';
import { FormField } from '../../form-field/FormField';
import { UNEXPECTED_ERROR_MESSAGE } from '../../../error/errorMessages.constants';
import { Toast } from '../../../services/toastify/Toastify';
import { Button } from '../../Button';
import { signup } from '../../../services/hedgehog/user.api';
import {
  createOrganization,
  getUserByEmail,
  uploadImages,
} from '../../../services/hedgehog/hedgehog.api';
import useLoading from '../../../hooks/useLoading';
import { InputFile } from '../../input-file/InputFile';
import { useModal } from '../../../hooks/useModal';
import { ProfileConfigModal } from '../profile-config-modal/ProfileConfigModal';
import { IOrganizationProfile } from '../../../services/hedgehog/interfaces/IOrganizationProfile';
import { CognitoExceptions } from '../../../services/cognito/cognito';
import { ExposureType } from '../../../services/hedgehog/enum/ExposureType.enum';
import { OrganizationLogo } from '../../OrganizationLogo';
import { SimpleLoadingModal } from '../SimpleLoadingModal';
const initialValues = {
  'organization-name': '',
  'exposure-type': ExposureType.BOTH,
  'manager-email': '',
  'manager-name': '',
  'manager-last-name': '',
  password: '',
  'password-repeat': '',
};
const organizationFields: ComponentProps<typeof FormField>[] = [
  {
    label: 'Organization name',
    id: 'organization-name',
    name: 'organization-name',
    type: 'text',
    placeholder: 'Organization name',
  },
  {
    label: 'Exposure type',
    id: 'exposure-type',
    name: 'exposure-type',
    placeholder: 'Exposure Type',
    type: 'select',
    options: Object.values(ExposureType),
  },
];

const managerFields: { [key: string]: ComponentProps<typeof FormField> } = {
  email: {
    label: 'Manager email',
    id: 'manager-email',
    name: 'manager-email',
    type: 'email',
    placeholder: 'Manager email',
  },
  name: {
    label: 'Name',
    id: 'manager-name',
    name: 'manager-name',
    type: 'text',
    placeholder: 'Manager name',
  },
  lastName: {
    label: 'Lastname',
    id: 'manager-last-name',
    name: 'manager-last-name',
    type: 'text',
    placeholder: 'Manager last name',
  },
  password: {
    label: 'Password',
    id: 'password',
    name: 'password',
    type: 'password',
    placeholder: 'Password',
  },
  repeatPassword: {
    label: 'Repeat Password',
    id: 'repeat-password',
    name: 'repeat-password',
    type: 'password',
    placeholder: 'Repeat password',
  },
};

const logoInputFile = {
  name: 'logo',
  label: 'Organization Logo',
};
type Props = {
  close: () => void;
  onSubmit?: () => void;
};

export const AddOrganizationModal = ({ close, onSubmit }: Props) => {
  const [logoLocation, setLogoLocation] = useState<string | null>(null);
  const [profile, setProfile] = useState<IOrganizationProfile | null>(null);
  const [isCreatingOrganization, waitForOrganization] = useLoading();
  const [formValues, setFormValues] =
    useState<typeof initialValues>(initialValues);

  const { openModal, closeModal } = useModal();

  const handleLoadProfile = (e: React.FormEvent) => {
    e.preventDefault();

    if (!formValues['exposure-type']) {
      return Toast.error('Select exposure type first');
    }

    openModal(
      'profile-config-modal',
      <ProfileConfigModal
        close={() => closeModal('profile-config-modal')}
        defaultValues={profile ?? undefined}
        exposureType={formValues['exposure-type']}
        onSubmit={(profile) => {
          setProfile(profile);
          closeModal('profile-config-modal');
        }}
      />,
    );
  };

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      if (!logoLocation) {
        return Toast.error('No file selected');
      }
      if (!profile) {
        return Toast.error('Load profile first');
      }

      let manager;
      try {
        manager = await signup(
          values['manager-name'],
          values['manager-last-name'],
          values['manager-email'],
          values.password,
        );
      } catch (e: unknown) {
        if (
          e instanceof Error &&
          e.name === CognitoExceptions.UsernameExistsException
        )
          manager = await getUserByEmail(values['manager-email']);
        else throw e;
      }

      const newOrganization = {
        name: values['organization-name'],
        exposureType: values['exposure-type'],
        img: logoLocation,
        organizationProfile: profile,
      };

      await createOrganization(newOrganization, manager);
      Toast.success('Organization created successfully');
      onSubmit?.();
      close();
    } catch (e: unknown) {
      if (e instanceof Error) Toast.error(e.message);
      else Toast.error(UNEXPECTED_ERROR_MESSAGE);
      console.log(e);
    }
  };

  const handleFileChange = async (file: File) => {
    const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    if (!validImageTypes.includes(file.type)) {
      setLogoLocation(null);
      throw new Error(
        'Invalid file type. Only JPEG, PNG, and JPG are allowed.',
      );
    }
    const { location } = await uploadImages(file);
    setLogoLocation(location);
  };

  const exposureType = formValues['exposure-type'];

  useEffect(() => {
    setProfile(null);
  }, [exposureType]);

  const logoMessage =
    'The logo should be well-cropped, with a preferably white background to seamlessly integrate with report backgrounds. Ensure the size is below 4MB, and accepted formats are: png, jpeg and jpg.';

  return (
    <>
      <Modal>
        <div className="max-h-full overflow-y-auto">
          <div className="flex flex-col justify-center items-center relative sm:w-96 z-0 overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
            <Formik
              initialValues={initialValues}
              validationSchema={organizationFormValidationSchema}
              onSubmit={(values) => waitForOrganization(handleSubmit(values))}
              validate={(values) => setFormValues(values)}
            >
              <Form className="flex flex-col justify-center items-center w-full p-4 gap-2 bg-white">
                {organizationFields.map((field) => (
                  <FormField key={field.id} {...field} />
                ))}
                <div className="w-full mb-4">
                  {logoLocation && (
                    <div className="w-full flex flex-row  justify-center items-center mb-4">
                      <OrganizationLogo
                        src={logoLocation}
                        logoSize="medium"
                        logoStyles=""
                      />
                    </div>
                  )}
                  <InputFile
                    name={logoInputFile.name}
                    handleLoad={(file) => handleFileChange(file)}
                    label={logoInputFile.label}
                    aria-describedby={logoInputFile.name}
                    id={logoInputFile.name}
                  ></InputFile>
                  <span className="text-xs text-left text-gray-500">
                    {logoMessage}
                  </span>
                </div>
                <FormField {...managerFields.email}></FormField>
                <div className="w-full flex flex-row gap-4">
                  <FormField {...managerFields.name}></FormField>
                  <FormField {...managerFields.lastName}></FormField>
                </div>
                <FormField {...managerFields.password}></FormField>
                <FormField {...managerFields.repeatPassword}></FormField>
                <div className="flex flex-row items-center justify-end gap-4 w-full">
                  <div className="flex flex-row gap-4">
                    <Button variant="underline" color="black" onClick={close}>
                      Cancel
                    </Button>
                    {profile && (
                      <Button
                        variant="solid"
                        color="cyan"
                        data-cy="submit-organization-button"
                        type="submit"
                      >
                        Create
                      </Button>
                    )}
                    <Button
                      variant="solid"
                      color="cyan"
                      data-cy="load-profile-button"
                      onClick={handleLoadProfile}
                    >
                      {profile ? 'Edit profile' : 'Load profile'}
                    </Button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </Modal>
      {isCreatingOrganization && <SimpleLoadingModal />}
    </>
  );
};
