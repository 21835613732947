import { ComponentProps } from 'react';
import { Modal } from '../Modal';
import { Formik, Form } from 'formik';
import { FormField } from '../../form-field/FormField';
import { UNEXPECTED_ERROR_MESSAGE } from '../../../error/errorMessages.constants';
import { Toast } from '../../../services/toastify/Toastify';
import { Button } from '../../Button';
import { signup } from '../../../services/hedgehog/user.api';
import useLoading from '../../../hooks/useLoading';
import { addMemberFormValidationSchema } from './member-form.schema';
import { addOrganizationMember } from '../../../services/hedgehog/hedgehog.api';
import { IOrganization } from '../../../services/hedgehog/interfaces/IOrganization';
import { SimpleLoadingModal } from '../SimpleLoadingModal';
import { Position } from '../../../services/hedgehog/interfaces/IPeople';
const initialValues = {
  'member-email': '',
  'member-name': '',
  'member-last-name': '',
  role: Position.member,
  password: '',
  'password-repeat': '',
};
const formFields: ComponentProps<typeof FormField>[] = [
  {
    label: 'Member email',
    id: 'member-email',
    name: 'member-email',
    type: 'email',
    placeholder: 'Member email',
  },
  {
    label: 'Name',
    id: 'member-name',
    name: 'member-name',
    type: 'text',
    placeholder: 'Member name',
  },
  {
    label: 'Last name',
    id: 'member-last-name',
    name: 'member-last-name',
    type: 'text',
    placeholder: 'Member last name',
  },
  {
    label: 'Role',
    id: 'role',
    name: 'role',
    type: 'select',
    placeholder: 'Select role',
    options: [Position.manager, Position.member],
  },
  {
    label: 'Password',
    id: 'password',
    name: 'password',
    type: 'password',
    placeholder: 'Password',
  },
  {
    label: 'Repeat Password',
    id: 'repeat-password',
    name: 'repeat-password',
    type: 'password',
    placeholder: 'Repeat Password',
  },
];

type Props = {
  organization: IOrganization;
  close: () => void;
  onSubmit?: () => void;
};

export const AddMemberModal = ({ close, onSubmit, organization }: Props) => {
  const [isAddingMember, waitFor] = useLoading();

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      const member = await signup(
        values['member-name'],
        values['member-last-name'],
        values['member-email'],
        values.password,
      );
      await addOrganizationMember(member, values.role, organization.id);
      Toast.success('Member created successfully');
      onSubmit?.();
      close();
    } catch (e: unknown) {
      if (e instanceof Error) Toast.error(e.message);
      else Toast.error(UNEXPECTED_ERROR_MESSAGE);
      console.log(e);
    }
  };

  return (
    <>
      <Modal>
        <div className="max-h-full w-full sm:w-auto overflow-y-auto items-center py-2">
          <div className="flex flex-col relative w-full sm:w-96 justify-center items-center bg-white border border-gray-200 shadow-md rounded-lg">
            <Formik
              initialValues={initialValues}
              validationSchema={addMemberFormValidationSchema}
              onSubmit={(values) => waitFor(handleSubmit(values))}
            >
              <Form className="flex flex-col justify-center items-center w-full p-4 gap-2 bg-white">
                <div>
                  <h3 className="text-center font-semibold text-xl text-black">
                    Invite to Organization
                  </h3>
                  <span className="text-xs text-gray-500 text-center">
                    Create an account for someone in your{' '}
                    <strong className="text-gray-600">organization</strong>.
                  </span>
                </div>

                {formFields.map((field) => (
                  <FormField key={field.id} {...field} />
                ))}

                <div className="flex flex-row items-center gap-4 justify-end w-full">
                  <button
                    type="button"
                    className="text-sm font-semibold underline underline-offset-4"
                    onClick={close}
                  >
                    Close
                  </button>
                  <Button
                    data-cy="submit-member-button"
                    variant="solid"
                    color="cyan"
                    type="submit"
                  >
                    Create
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </Modal>
      {isAddingMember && <SimpleLoadingModal />}
    </>
  );
};
