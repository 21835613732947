import HotTable from '@handsontable/react';
import React, { useEffect, useState } from 'react';
import { useLoadWorkbook } from '../../../hooks/useLoadWorkbook';
import { useLoadBasicSheet } from '../../../hooks/useLoadBasicSheet';
import { fxMaturedDealsReportColumnConfig } from './fxMaturedDealsReportColumnConfig';
import { fxMaturedDealsReportMergeCells } from './fxMaturedDealsReportMergeCells';
import {
  getCellProperties,
  insertEmptyRowAfter,
  parseRichText,
} from '../common/utils';
import { fxMaturedDealsReportNewColumnConfig } from './fxMaturedDealsReportNewColumnConfig';

interface IFxMaturedDealsReportProps {
  report: Blob;
}

export const FX_MATURED_DEALS_REPORT_SHEET_NAME = 'FX Matured Deals Report';

const headers = [
  /Deal\s*Date/,
  /Maturity\s*Date/,
  /Deal\s*Type/,
  /Direction/,
  /Volume/,
  /Exchange\s* Rate/,
  /Counterparty/,
  /Spot Rate/,
  /Gain\/Loss/,
  /Deal Number/,
  /Reference/,
  /Comment/,
  /Hedging/,
];

export const ViewFxMaturedDealsReportTable = ({
  report,
}: IFxMaturedDealsReportProps) => {
  const { workbook } = useLoadWorkbook(
    report,
    'Error loading Hedge Effectiveness Report',
  );

  const [selectedSheet] = useState<string>(FX_MATURED_DEALS_REPORT_SHEET_NAME);
  const { sheet: originalSheet } = useLoadBasicSheet(workbook, selectedSheet);
  const [sheet, setSheet] = useState<any[][]>([]);

  useEffect(() => {
    if (originalSheet) {
      const parsedSheet = parseRichText(originalSheet);
      const updatedSheet = insertEmptyRowAfter(parsedSheet, 'TOTAL');
      const cleanedSheet = updatedSheet.filter(
        (row) => !row.every((cell) => cell === ' '),
      );
      setSheet(handleOldFooters(cleanedSheet));
    }
  }, [originalSheet]);

  const handleOldFooters = (sheet: any[][]) => {
    const oldFooterPattern = /This report is confidential/;
    const newSheet = sheet.map((row) => [...row]);

    if (oldFooterPattern.test(newSheet[newSheet.length - 1][0])) {
      const combinedFooter = newSheet.slice(-3).flat().join('\n');
      const finalSheet = [...newSheet.slice(0, -3)];
      finalSheet.push([combinedFooter]);
      finalSheet.push([combinedFooter]);
      return finalSheet;
    }

    return newSheet;
  };

  return (
    <div className="flex justify-center">
      {sheet.length > 0 && (
        <HotTable
          readOnly={true}
          key={'fx-matured-deals-report'}
          data={sheet}
          rowHeaders={false}
          colHeaders={false}
          width={sheet[3].length === 12 ? 1495 : 1640}
          height="auto"
          licenseKey="non-commercial-and-evaluation"
          rowHeights={35}
          manualColumnResize={true}
          manualRowResize={true}
          wordWrap={true}
          allowEmpty={false}
          columns={
            sheet[3].length === 12
              ? [...fxMaturedDealsReportNewColumnConfig]
              : [...fxMaturedDealsReportColumnConfig]
          }
          mergeCells={fxMaturedDealsReportMergeCells(sheet)}
          cells={(row, col) => getCellProperties(sheet, headers, row, col)}
        />
      )}
    </div>
  );
};
