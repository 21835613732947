import { CellProperties, ColumnSettings } from 'handsontable/settings';
import {
  NUMERIC_FORMAT_PATTERN,
  parseDateToDefaultFormatWithRenderer,
} from '../common/utils';
import { CellValue } from 'exceljs';
import { FxHedgeEffectivenessReportSheetNames } from './ViewFxHedgeEffectivenessReportTable';

export const fxHedgeEffectivenessReportColumnConfig = (
  sheetName: FxHedgeEffectivenessReportSheetNames,
) => {
  const actualAndHypoColumnConfig: ColumnSettings[] = [
    {
      width: 175,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
      wordWrap: true,
    },
    {
      width: 100,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 100,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 100,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
      renderer: parseDateToDefaultFormatWithRenderer,
    },
    {
      width: 75,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 75,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 100,
      type: 'numeric',
      numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 100,
      type: 'numeric',
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    { width: 75, readOnly: true, readOnlyCellClassName: 'text-black' },
    {
      width: 150,
      type: 'numeric',
      numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 100,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
      renderer: parseDateToDefaultFormatWithRenderer,
    },
    { width: 50, readOnly: true, readOnlyCellClassName: 'text-black' },
    {
      width: 50,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 150,
      type: 'numeric',
      numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 100,
      type: 'numeric',
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 150,
      type: 'numeric',
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 125,
      type: 'numeric',
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
  ];

  const journalConfig: ColumnSettings[] = [
    {
      width: 200,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
      wordWrap: true,
    },
    {
      width: 200,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 100,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 100,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 100,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 125,
      type: 'text',
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 150,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 200,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 175,
      type: 'numeric',
      numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
      readOnly: true,
      readOnlyCellClassName: 'text-black',
      wordWrap: true,
    },
    {
      width: 175,
      type: 'numeric',
      numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
  ];

  const testConfig: ColumnSettings[] = [
    {
      width: 175,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
      renderer: parseDateToDefaultFormatWithRenderer,
      wordWrap: true,
    },
    {
      width: 100,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 100,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 100,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
      renderer: parseDateToDefaultFormatWithRenderer,
    },
    {
      width: 75,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 75,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 25,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 25,
      type: 'numeric',
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    { width: 25, readOnly: true, readOnlyCellClassName: 'text-black' },
    {
      width: 25,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 25,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    { width: 25, readOnly: true, readOnlyCellClassName: 'text-black' },
    {
      width: 25,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 50,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 75,
      type: 'numeric',
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 150,
      readOnly: true,
      readOnlyCellClassName: 'text-black',
    },
    {
      width: 125,
      type: 'numeric',
      numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
      readOnly: true,
      readOnlyCellClassName: 'text-black',
      wordWrap: true,
    },
  ];
  switch (sheetName) {
    case FxHedgeEffectivenessReportSheetNames.ACTUAL:
      return actualAndHypoColumnConfig;

    case FxHedgeEffectivenessReportSheetNames.HYPO:
      return actualAndHypoColumnConfig;

    case FxHedgeEffectivenessReportSheetNames.JOURNAL:
      return journalConfig;

    case FxHedgeEffectivenessReportSheetNames.TEST:
      return testConfig;
    default:
      break;
  }
};

export const fxHedgeEffectivenessReportMergeCells = (
  sheetName: FxHedgeEffectivenessReportSheetNames,
  sheet: CellValue[][],
) => {
  const actualSubFooter = /Hypothetical Derivative Method/;
  const firstSubTotal = /Total fair value of/;
  const secondSubTotal = /Represents \(gains\) or losses in the CFHR of:/;

  const hypoAndActual = [
    {
      row: 0,
      col: 0,
      rowspan: 1,
      colspan: 15,
    },
    {
      row: 1,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 1,
      col: 2,
      rowspan: 1,
      colspan: sheet[0].length - 4,
    },
    {
      row: 0,
      col: 15,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 1,
      col: 15,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 2,
      col: 0,
      rowspan: 1,
      colspan: sheet[0].length,
    },
    {
      row: sheet.length - 2,
      col: 0,
      rowspan: 2,
      colspan: sheet[0].length,
    },
    {
      row: sheet.length - 8,
      col: 0,
      rowspan: 6,
      colspan: sheet[0].length,
    },
  ];

  sheet.forEach((row, rowIndex) => {
    const cellValue = row[0];
    if (typeof cellValue === 'string' && actualSubFooter.test(cellValue)) {
      hypoAndActual.push({
        row: rowIndex,
        col: 0,
        rowspan: 1,
        colspan: row.length,
      });
    }

    const potentialSubTotal = row[8];
    if (
      typeof potentialSubTotal === 'string' &&
      firstSubTotal.test(potentialSubTotal)
    ) {
      hypoAndActual.push({
        row: rowIndex,
        col: 8,
        rowspan: 1,
        colspan: 5,
      });
    }
    if (
      typeof potentialSubTotal === 'string' &&
      secondSubTotal.test(potentialSubTotal)
    ) {
      hypoAndActual.push({
        row: rowIndex,
        col: 8,
        rowspan: 1,
        colspan: 5,
      });
    }
  });

  const journalConfig = [
    {
      row: 1,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 1,
      col: 2,
      rowspan: 1,
      colspan: 6,
    },
    {
      row: 0,
      col: 0,
      rowspan: 1,
      colspan: 8,
    },
    {
      row: 1,
      col: 8,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 0,
      col: 8,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 2,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 3,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 4,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 2,
      col: 2,
      rowspan: 1,
      colspan: 3,
    },
    {
      row: 3,
      col: 2,
      rowspan: 1,
      colspan: 3,
    },
    {
      row: 4,
      col: 2,
      rowspan: 1,
      colspan: 3,
    },
    {
      row: 2,
      col: 5,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 3,
      col: 5,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 4,
      col: 5,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: sheet.length - 4,
      col: 0,
      rowspan: 1,
      colspan: sheet[0].length - 2,
    },
    {
      row: sheet.length - 3,
      col: 0,
      rowspan: 1,
      colspan: sheet[0].length,
    },
    {
      row: sheet.length - 2,
      col: 0,
      rowspan: 2,
      colspan: sheet[0].length,
    },
  ];

  const testConfig = [
    {
      row: 0,
      col: 0,
      rowspan: 1,
      colspan: sheet[0].length - 2,
    },
    {
      row: 0,
      col: 15,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 1,
      col: 15,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 1,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 1,
      col: 2,
      rowspan: 1,
      colspan: 13,
    },
    {
      row: 2,
      col: 0,
      rowspan: 1,
      colspan: 17,
    },
    {
      row: 6,
      col: 0,
      rowspan: 1,
      colspan: 17,
    },
    {
      row: 7,
      col: 0,
      rowspan: 1,
      colspan: 17,
    },
    {
      row: 8,
      col: 0,
      rowspan: 6,
      colspan: 17,
    },
    {
      row: 3,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 4,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 3,
      col: 2,
      rowspan: 1,
      colspan: 14,
    },
    {
      row: 4,
      col: 2,
      rowspan: 1,
      colspan: 14,
    },
    {
      row: 5,
      col: 0,
      rowspan: 1,
      colspan: 16,
    },
    {
      row: sheet.length - 2,
      col: 0,
      rowspan: 2,
      colspan: sheet[0].length,
    },
  ];

  switch (sheetName) {
    case FxHedgeEffectivenessReportSheetNames.ACTUAL:
      return hypoAndActual;

    case FxHedgeEffectivenessReportSheetNames.HYPO:
      return hypoAndActual;

    case FxHedgeEffectivenessReportSheetNames.JOURNAL:
      return journalConfig;

    case FxHedgeEffectivenessReportSheetNames.TEST:
      return testConfig;
    default:
      break;
  }
};

export const fxHedgeEffectivenessReportCustomRenderer = (
  TD: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: string,
  cellProperties: CellProperties,
) => {
  if (col === 15 && row === 0) {
    cellProperties.type = 'text';
    cellProperties.className = 'htLeft';
    cellProperties.wordWrap = true;
  }

  return TD;
};
