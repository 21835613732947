import { useUserDashboardContext } from '../../../../hooks/useDashboardContext';
import { ReportingPeriods } from '../../../../components/common/reporting-periods/ReportingPeriods';
import { Outlet } from 'react-router-dom';
import { FilterProvider } from '../../../../context/FilterProvider';

export const UserReportingPeriods = () => {
  const { currentOrganization } = useUserDashboardContext();

  const organizationId = currentOrganization && currentOrganization.id;

  const route = 'user';

  return (
    <FilterProvider>
      <div className="flex flex-col gap-4">
        <ReportingPeriods route={route} organizationId={organizationId} />
        <Outlet />
      </div>
    </FilterProvider>
  );
};
