import { Link } from 'react-router-dom';
import { SignupForm } from '../../components/signup-form/SignupForm';
import { AuthPageLayout } from '../../components/AuthLayout';
import { useRedirectHomeIfAuthenticated } from '../../hooks/useRedirectHomeIfAuthenticated';

export const Signup = () => {
  useRedirectHomeIfAuthenticated();

  return (
    <AuthPageLayout
      title="Sign up for an account"
      subtitle={
        <>
          Already registered?{' '}
          <Link to="/" className="text-secondary-blue-500">
            Sign in
          </Link>{' '}
          to your account.
        </>
      }
    >
      <SignupForm />
    </AuthPageLayout>
  );
};
