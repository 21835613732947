import { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { ApiQueryKeys } from '../../services/hedgehog/api-query-keys.enum';
import { getFxTrades } from '../../services/hedgehog/hedgehog.api';
import { SimpleLoading } from '../loading/SimpleLoading';
import moment from 'moment';
import { Button } from '../Button';
import { OutputReportTypes } from '../../services/hedgehog/types/OutputReportTypes';
import { convertTradesToChart, PieChartData } from './utils/fxBarChartUtils';
import {
  DEFAULT_FROM_DATE,
  DEFAULT_TO_DATE,
} from '../../constants/dateConstants';

export const FXBarChart = ({ organizationId }: { organizationId: number }) => {
  const [query, setQuery] = useState<{ from: Date; to: Date }>({
    from: DEFAULT_FROM_DATE,
    to: DEFAULT_TO_DATE,
  });
  const { data, isLoading, isError } = useQuery(
    [
      ApiQueryKeys.fx_matured_deals_chart_data,
      query.from,
      query.to,
      organizationId,
    ],
    () =>
      getFxTrades(organizationId, {
        entriesFrom: query.from,
        entriesTo: query.to,
        option: OutputReportTypes.FX_MATURED_DEALS_REPORT,
      }),
  );

  const [chartData, setChartData] = useState<PieChartData | null>(null);
  const [isDataEmpty, setIsDataEmpty] = useState(false);

  useEffect(() => {
    if (data && !isLoading && !isError) {
      setIsDataEmpty(false);
      setChartData(convertTradesToChart(data));
    }
  }, [data, isLoading, isError]);

  useEffect(() => {
    if (chartData?.labels && chartData.labels.length === 0) {
      setIsDataEmpty(true);
    } else {
      setIsDataEmpty(false);
    }
  }, [chartData?.labels, data, isLoading, isError]);

  const handleNextMonth = () => {
    const nextMonth = moment(query.to).add(1, 'month');
    setQuery({
      from: moment(nextMonth).startOf('month').toDate(),
      to: moment(nextMonth).endOf('month').toDate(),
    });
  };

  const handlePreviousMonth = () => {
    const previousMonth = moment(query.from).subtract(1, 'month');
    setQuery({
      from: moment(previousMonth).startOf('month').toDate(),
      to: moment(previousMonth).endOf('month').toDate(),
    });
  };

  const isCurrentMonth = moment().isSame(moment(query.to), 'month');

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <h4 className="text-base font-bold text-center text-primary-dark-500">
        FX - Gain And Loss By Currency Pair
      </h4>
      <div className="flex flex-col justify-between items-center w-[90%] h-full p-2">
        {isError && (
          <div className="flex flex-row justify-center items-center w-80 h-80">
            <p className="text-lg text-red-600 font-semibold text-center">
              Error loading data
            </p>
          </div>
        )}
        {isDataEmpty && !isLoading && (
          <div className="flex flex-col justify-center items-center w-80 h-80">
            <p className=" text-lg font-semibold text-center">
              No data for this month
            </p>
          </div>
        )}

        {isLoading && (
          <SimpleLoading
            loadingColor="text-primary-cyan-500"
            loadingSize="h-10 w-10"
            externalStyles="flex flex-row justify-center items-center w-80 h-80 p-2"
          />
        )}

        {!isLoading && !isDataEmpty && chartData && (
          <Chart type="bar" data={chartData}></Chart>
        )}
      </div>
      <div className="flex flex-row justify-between gap-4">
        <Button
          data-cy="fx-bar-chart-previous-month"
          type="button"
          onClick={handlePreviousMonth}
          variant="solid"
          color="cyan"
        >
          Prev Month
        </Button>
        <Button
          type="button"
          variant="outline"
          className="text-center"
          disabled
        >
          {moment(query.from).format('MMM YY')}
        </Button>
        <Button
          data-cy="fx-bar-chart-next-month"
          variant={isCurrentMonth ? 'outline' : 'solid'}
          color={isCurrentMonth ? undefined : 'cyan'}
          disabled={isCurrentMonth}
          type="button"
          onClick={handleNextMonth}
        >
          Next Month
        </Button>
      </div>
    </div>
  );
};
