import HotTable from '@handsontable/react';
import { useLoadWorkbook } from '../../../hooks/useLoadWorkbook';

import { useLoadBasicSheet } from '../../../hooks/useLoadBasicSheet';
import {
  customRender,
  fxHedgeDocumentationFormColumnConfig,
  fxHedgeDocumentationFormMergeCells,
} from './fxHedgeDocumentationFormConfig';
import { IFxHedgeDocumentationForm } from '../../../services/hedgehog/interfaces/IFxHedgeDocumentationForm';
import { useEffect, useState } from 'react';
import { getFxHedgeDocsCellProperties } from './utils';
import { formatDate } from '../common/utils';
import { FxHedgeDocumentationFormTemplateOptions } from '../../../services/hedgehog/types/reports/fx-mtm-journal-report/fx-mtm-journal-options';

interface IViewFxHedgeDocumentationFormTableProps {
  report: Blob;
  reportInfo: IFxHedgeDocumentationForm;
}

export const FX_HEDGE_DOCUMENTATION_STANDARD_SHEET_NAME =
  'FX Hedge Doc Standard Form';
export const FX_HEDGE_DOCUMENTATION_SYNTHETIC_SHEET_NAME =
  'FX Hedge Doc Synthetic Form';

export const ViewFxHedgeDocumentationFormTable = ({
  report,
  reportInfo,
}: IViewFxHedgeDocumentationFormTableProps) => {
  const sheetName =
    reportInfo.template ===
    FxHedgeDocumentationFormTemplateOptions.STANDARD_TEMPLATE
      ? FX_HEDGE_DOCUMENTATION_STANDARD_SHEET_NAME
      : FX_HEDGE_DOCUMENTATION_SYNTHETIC_SHEET_NAME;

  const { workbook } = useLoadWorkbook(
    report,
    'Error loading FX Hedge Documentation Form',
    sheetName,
  );
  const { sheet: originalSheet } = useLoadBasicSheet(workbook, sheetName);
  const [sheet, setSheet] = useState<any[][]>([]);

  useEffect(() => {
    if (!originalSheet) return;

    const parsedSheet = originalSheet.map((row) => {
      const trimmedRow = row.slice(1, 6);
      return trimmedRow.map((cell) =>
        cell instanceof Date ? formatDate(cell.toISOString()) : cell,
      );
    });

    setSheet(parsedSheet);
  }, [originalSheet]);

  return (
    <div className="flex justify-center border">
      {sheet.length > 0 && (
        <HotTable
          readOnly={true}
          data={sheet}
          rowHeaders={false}
          colHeaders={false}
          width="1415px"
          height="auto"
          licenseKey="non-commercial-and-evaluation"
          rowHeights={60}
          manualColumnResize={true}
          wordWrap={true}
          allowEmpty={true}
          columns={fxHedgeDocumentationFormColumnConfig}
          afterRenderer={customRender}
          mergeCells={fxHedgeDocumentationFormMergeCells(reportInfo.template)}
          cells={(row, col) =>
            getFxHedgeDocsCellProperties(sheet, row, col, reportInfo.template)
          }
        />
      )}
    </div>
  );
};
