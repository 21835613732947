export enum ExposureType {
  IRS_ONLY = 'IRS Only',
  FX_ONLY = 'FX Only',
  BOTH = 'IRS + FX',
}

export enum ExposureTypeQuery {
  IRS_ONLY = 'IRS Only',
  FX_ONLY = 'FX Only',
  BOTH = 'IRS + FX',
  ALL = 'ALL',
}
