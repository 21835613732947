import { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { ApiQueryKeys } from '../../services/hedgehog/api-query-keys.enum';
import { getOrganizationIrsTrades } from '../../services/hedgehog/hedgehog.api';
import { SimpleLoading } from '../loading/SimpleLoading';
import { IIRSTrade } from '../../services/hedgehog/interfaces/IIRSTrade';
import { ChartData } from 'chart.js';
import moment from 'moment';
import { Button } from '../Button';
import { defaultColors } from './default-colors';
import {
  DEFAULT_FROM_DATE,
  DEFAULT_TO_DATE,
} from '../../constants/dateConstants';

type PieChartData = ChartData<'pie', number[], string>;
const convertData = (trades: IIRSTrade[]): PieChartData => {
  const labels = new Map<
    string,
    { count: number; totalValue: number; currency: string }
  >();

  trades.forEach((trade) => {
    let totalCleanFairValue = 0;
    trade.valuationEntries.forEach((entry) => {
      totalCleanFairValue += entry.cleanFairValue;
    });

    const counterParty = trade.counterParty;
    if (labels.has(counterParty)) {
      const current = labels.get(counterParty)!;
      labels.set(counterParty, {
        count: current.count + 1,
        totalValue: current.totalValue + totalCleanFairValue,
        currency: trade.currency,
      });
    } else {
      labels.set(counterParty, {
        count: 1,
        totalValue: totalCleanFairValue,
        currency: trade.currency,
      });
    }
  });

  const labelsWithValues = Array.from(labels.entries()).map(
    ([label, { count, totalValue, currency }]) =>
      `${label} (${totalValue.toLocaleString()} ${currency}) ${
        count === 1 ? count + ' Trade' : count + ' Trades'
      }`,
  );

  const dataValues = Array.from(labels.values()).map(
    ({ totalValue }) => totalValue,
  );

  return {
    labels: labelsWithValues,
    datasets: [
      {
        label: 'Total Clean Fair Value',
        data: dataValues,
        borderWidth: 1,
        backgroundColor: Object.values(defaultColors).map(
          ({ transparent }) => transparent,
        ),
        borderColor: Object.values(defaultColors).map(({ opaque }) => opaque),
      },
    ],
  };
};

export const IRSPieChart = ({ organizationId }: { organizationId: number }) => {
  const [query, setQuery] = useState<{ from: Date; to: Date }>({
    from: DEFAULT_FROM_DATE,
    to: DEFAULT_TO_DATE,
  });

  const { data, isLoading, isError } = useQuery(
    [ApiQueryKeys.irs_pie_chart_data, query],
    () =>
      getOrganizationIrsTrades(organizationId, {
        entriesFrom: query.from,
        entriesTo: query.to,
      }),
  );
  const [pieChartData, setPieChartData] = useState<PieChartData | null>(null);
  const [isDataEmpty, setIsDataEmpty] = useState(false);

  useEffect(() => {
    if (data && !isLoading && !isError) {
      setPieChartData(convertData(data.trades));
    }
  }, [data, isLoading, isError]);

  useEffect(() => {
    if (pieChartData?.labels && pieChartData.labels.length === 0) {
      setIsDataEmpty(true);
    } else {
      setIsDataEmpty(false);
    }
  }, [pieChartData?.labels, data, isLoading, isError]);

  const handleNextMonth = () => {
    const nextMonth = moment(query.to).add(1, 'month');
    setQuery({
      from: moment(nextMonth).startOf('month').toDate(),
      to: moment(nextMonth).endOf('month').toDate(),
    });
  };

  const handlePreviousMonth = () => {
    const previousMonth = moment(query.from).subtract(1, 'month');
    setQuery({
      from: moment(previousMonth).startOf('month').toDate(),
      to: moment(previousMonth).endOf('month').toDate(),
    });
  };

  const isCurrentMonth = moment().isSame(moment(query.to), 'month');

  return (
    <div className="flex flex-col items-center w-full h-full">
      <h4 className="text-base font-bold text-center text-primary-dark-500">
        Total Clean Fair Values per Counterparty
      </h4>
      <div className="flex flex-row justify-between items-center w-[90%] h-full p-2">
        {isError && (
          <div className="flex flex-row justify-center items-center">
            <p className=" text-lg text-red-600 font-semibold text-center">
              Error loading data
            </p>
          </div>
        )}
        {isLoading && !pieChartData && (
          <SimpleLoading
            loadingColor="text-primary-cyan-500"
            loadingSize="h-10 w-10"
            externalStyles="flex flex-row justify-center items-center w-full h-full p-2"
          />
        )}

        {pieChartData?.datasets.every(
          (dataset) => dataset.data.length === 0,
        ) && (
          <div className="flex flex-row justify-center items-center w-full h-full">
            <p className=" text-lg font-semibold text-center">
              No data for this month
            </p>
          </div>
        )}

        {!isLoading && !isDataEmpty && pieChartData && (
          <Chart type="pie" data={pieChartData}></Chart>
        )}
      </div>
      <div className="flex flex-row justify-between gap-4 mt-auto">
        <Button
          data-cy="irs-pie-chart-previous-month"
          type="button"
          variant="solid"
          color="cyan"
          onClick={handlePreviousMonth}
        >
          Prev Month
        </Button>
        <Button
          type="button"
          variant="outline"
          className="text-center"
          disabled
        >
          {moment(query.from).format('MMM YY')}
        </Button>
        <Button
          data-cy="irs-pie-chart-next-month"
          variant={isCurrentMonth ? 'outline' : 'solid'}
          color={isCurrentMonth ? undefined : 'cyan'}
          disabled={isCurrentMonth}
          type="button"
          onClick={handleNextMonth}
        >
          Next Month
        </Button>
      </div>
    </div>
  );
};
