import { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { ApiQueryKeys } from '../../services/hedgehog/api-query-keys.enum';
import { getFxTrades } from '../../services/hedgehog/hedgehog.api';
import { SimpleLoading } from '../loading/SimpleLoading';
import { ChartData } from 'chart.js';
import moment from 'moment';
import { Button } from '../Button';
import { OutputReportTypes } from '../../services/hedgehog/types/OutputReportTypes';
import { IFxTrade } from '../../services/hedgehog/interfaces/IFXTrade';
import { defaultColors } from './default-colors';
import {
  DEFAULT_FROM_DATE,
  DEFAULT_TO_DATE,
} from '../../constants/dateConstants';

type PieChartData = ChartData<'pie', number[], string>;

const convertData = (trades: IFxTrade[]): PieChartData => {
  const labels = new Map<string, number>();
  const tradesCount = new Map<string, number>();

  trades.forEach((trade) => {
    const count = tradesCount.get(trade.counterparty) || 0;
    tradesCount.set(trade.counterparty, count + 1);

    let totalCleanFairValue = 0;
    trade.mtmValuationEntry.forEach((entry) => {
      totalCleanFairValue += entry.fairValue;
    });

    const currentValue = labels.get(trade.counterparty) || 0;
    labels.set(trade.counterparty, currentValue + totalCleanFairValue);
  });

  const dataValues = Array.from(labels.values());
  const labeledKeys = Array.from(labels.keys()).map(
    (key) => `${key} (${tradesCount.get(key) || 0} Trades)`,
  );

  return {
    labels: labeledKeys,
    datasets: [
      {
        label: 'Total Clean Fair Value',
        data: dataValues,
        borderWidth: 1,
        backgroundColor: Object.values(defaultColors).map(
          ({ transparent }) => transparent,
        ),
        borderColor: Object.values(defaultColors).map(({ opaque }) => opaque),
      },
    ],
  };
};

export const FXPieChart = ({ organizationId }: { organizationId: number }) => {
  const [query, setQuery] = useState<{ from: Date; to: Date }>({
    from: DEFAULT_FROM_DATE,
    to: DEFAULT_TO_DATE,
  });

  const { data, isLoading, isError } = useQuery(
    [
      ApiQueryKeys.fx_pie_mtm_valuation_chart_data,
      query.from,
      query.to,
      organizationId,
    ],
    () =>
      getFxTrades(organizationId, {
        entriesFrom: query.from,
        entriesTo: query.to,
        option: OutputReportTypes.FX_MTM_VALUATION,
      }),
  );

  const [pieChartData, setPieChartData] = useState<PieChartData | null>(null);
  const [isDataEmpty, setIsDataEmpty] = useState(false);

  useEffect(() => {
    if (data && !isLoading && !isError) {
      setIsDataEmpty(false);
      setPieChartData(convertData(data));
    }
  }, [data, isLoading, isError]);

  useEffect(() => {
    if (pieChartData?.labels && pieChartData.labels.length === 0) {
      setIsDataEmpty(true);
    } else {
      setIsDataEmpty(false);
    }
  }, [pieChartData?.labels, setIsDataEmpty]);

  const handleNextMonth = () => {
    const nextMonth = moment(query.to).add(1, 'month');
    setQuery({
      from: moment(nextMonth).startOf('month').toDate(),
      to: moment(nextMonth).endOf('month').toDate(),
    });
  };

  const handlePreviousMonth = () => {
    const previousMonth = moment(query.from).subtract(1, 'month');
    setQuery({
      from: moment(previousMonth).startOf('month').toDate(),
      to: moment(previousMonth).endOf('month').toDate(),
    });
  };

  const isCurrentMonth = moment().isSame(moment(query.to), 'month');

  return (
    <div className="flex flex-col items-center w-full h-full">
      <h4 className="text-base font-bold text-center text-primary-dark-500">
        FX - Fair Value per Counterparty
      </h4>
      <div className="flex flex-row justify-between items-center w-[90%] h-full p-2">
        {isError && (
          <div className="flex flex-row justify-center items-center">
            <p className=" text-lg text-red-600 font-semibold text-center">
              Error loading data
            </p>
          </div>
        )}
        {isDataEmpty && !isLoading && (
          <div className="flex flex-row justify-center items-center w-full h-full">
            <p className=" text-lg font-semibold text-center">
              No data for this month
            </p>
          </div>
        )}

        {isLoading && (
          <SimpleLoading
            loadingColor="text-primary-cyan-500"
            loadingSize="h-10 w-10"
            externalStyles="flex flex-row justify-center items-center w-full h-full p-2"
          />
        )}

        {!isLoading && !isDataEmpty && pieChartData && (
          <Chart type="pie" data={pieChartData}></Chart>
        )}
      </div>
      <div className="flex flex-row justify-between gap-4">
        <Button
          data-cy="fx-pie-chart-previous-month"
          type="button"
          variant="solid"
          color="cyan"
          onClick={handlePreviousMonth}
        >
          Prev Month
        </Button>
        <Button
          type="button"
          variant="outline"
          className="text-center"
          disabled
        >
          {moment(query.from).format('MMM YY')}
        </Button>
        <Button
          data-cy="fx-pie-chart-next-month"
          variant={isCurrentMonth ? 'outline' : 'solid'}
          color={isCurrentMonth ? undefined : 'cyan'}
          disabled={isCurrentMonth}
          type="button"
          onClick={handleNextMonth}
        >
          Next Month
        </Button>
      </div>
    </div>
  );
};
