export const headers = [
  /^Hedge Type$/,
  /^Date of designation of hedging relationship$/,
  /^Details of the Hedging Instrument$/,
  /^Details of the Hedged Item$/,
  /^Effectiveness Testing$/,
  /^Prospective Test at inception$/,
  /^Matched Terms Prospective Testing at inception$/,
  /^Prospective Effectiveness Testing at inception$/,
  /^Prospective Effectiveness Testing $/,
  /^Existence of an economic relationship$/,
  /^Effect of changes in credit risk$/,
  /^Hedge ratio applied$/,
  /^How Ineffectiveness will be quantified$/,
  /^Expected ineffectiveness$/,
  /^Testing Frequency$/,
  /^Assessment of hedge effectiveness$/,
  /^Effectiveness testing at inception and ongoing$/,
  /^Details of qualitative and quantitative testing$/,
];

export const subHeaders = [
  /^Cash Flow Hedge$/,
  /^Fair Value Hedge$/,
  /^Net Investment Hedge$/,
  /^Nature of hedging instrument$/,
  /^Deal Reference number \(attach copy of confirmation\)$/,
  /^Contractual parties of the hedging instrument$/,
  /^Start Date$/,
  /^Maturity Date {1,3}$/,
  /^Currency {1,3}$/,
  /^Maturity Date$/,
  /^Notional amount$/,
  /^Cash Flows to be paid$/,
  /^Cash Flows to be received$/,
  /^Amount of principal designated as hedging instrument$/,
  /^Nature of hedged item$/,
  /^Impact to profit or loss$/,
  /^Contractual parties of the hedging item$/,
  /^Start Date $/,
  /^Notional amount $/,
  /^Notional amounts {1,3}$/,
  /^Amount designated as a hedged item$/,
  /^Fair value at inception$/,
  /^Can hedge effectiveness be reliably measured$/,
  /^Expectation of hedge effectiveness$/,
  /^Type of test {1,3}$/,
  /^Notional\/Principle Amount$/,
  /^Currency$/,
  /^Inception Date{1,3}$/,
  /^Inception date {1,3}$/,
  /^Principle and coupon payments dates$/,
  /^Underlying risk\(s\) match$/,
  /^Credit quality of counterparty is investment grade$/,
  /^Benchmark interest rate basis$/,
  /^Date of Test$/,
  /^Date of test$/,
  /^Results of Test$/,
  /^Can hedge effectiveness be reliably measured $/,
  /^Expectation of hedge effectiveness $/,
  /^Hedge Documentation prepared by$/,
  /^Hedge Documentation reviewed by$/,
  /^Date$/,
  /^Type of test$/,
  /^Assessment of hedge effectiveness$/,
  /^Effectiveness testing at inception and ongoing$/,
  /^Details of qualitative and quantitative testing$/,
  /^Results of test$/,
  /^Matched terms test$/,
  /^Underlying risk\(s\)$/,
  /^Currency hedge item \(i\)$/,
  /^Currency hedge item \(ii\)$/,
  /^Notional amount \(i\)$/,
  /^Notional amount \(ii\)$/,
];
