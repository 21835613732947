/* eslint-disable no-sparse-arrays */
export const fxHedgeEffectivenessTestSheetMock = [
  [, , , , , , , , , , , , , , , , 'Hedgehog Software', 'Hedgehog Software'],
  [],
  [
    ,
    'The Jon',
    'The Jon',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    ,
    null,
  ],
  [],
  [
    ,
    'The Lower of Test',
    'The Lower of Test',
    'The Lower of Test',
    'The Lower of Test',
    'The Lower of Test',
    'The Lower of Test',
    'The Lower of Test',
    'The Lower of Test',
    'The Lower of Test',
    'The Lower of Test',
    'The Lower of Test',
    'The Lower of Test',
    'The Lower of Test',
    'The Lower of Test',
    'The Lower of Test',
    'The Lower of Test',
    'The Lower of Test',
  ],
  [
    ,
    new Date('2024-06-29T21:00:00-03:00'),
    ,
    'Total fair value of actual hedging instruments',
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    100,
  ],
  [
    ,
    new Date('2024-06-29T21:00:00-03:00'),
    ,
    'Total fair value of hypotheticals',
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    200,
  ],
  [, , , , , , , , , , , , , , , , , 300],
  [
    ,
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
  ],
  [
    ,
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
  ],
  [
    ,
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
  ],
  [
    ,
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
  ],
  [
    ,
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
  ],
  [
    ,
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
  ],
  [
    ,
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
    'The hypothetical approach used in this report is to',
  ],
  [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    ,
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
  ],
  [
    ,
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
    'The valuation date of this report is 30 Jun 24. ',
  ],
];
