import { useOrganization } from '../../../../hooks/useOrganization';
import { useParams } from 'react-router-dom';
import { getAllReportByOrganization } from '../../../../services/hedgehog/hedgehog.api';
import useLoading from '../../../../hooks/useLoading';
import { Modal } from '../../../../components/modal/Modal';
import { SimpleLoading } from '../../../../components/loading/SimpleLoading';

import { ExposureType } from '../../../../services/hedgehog/enum/ExposureType.enum';
import { useQuery } from 'react-query';
import { ApiQueryKeys } from '../../../../services/hedgehog/api-query-keys.enum';
import { IReportByMonth } from '../../../../services/hedgehog/interfaces/IReportByMonth';
import { IRSPieChart } from '../../../../components/charts/IRSPieChart';
import { FXPieChart } from '../../../../components/charts/FXPieChart';
import { FXBarChart } from '../../../../components/charts/FXBarChart';
import { IRSComposedGraph } from '../../../../components/charts/IRSComposedGraph';
import { GridCountCards } from '../../../../components/cards/GridCountCard';

export const OrganizationMenu = () => {
  const { id } = useParams();

  const organizationId = Number(id);

  const { organization } = useOrganization(organizationId);

  const [isLoading] = useLoading();

  const { data: reports, isLoading: isLoadingReports } =
    useQuery<IReportByMonth>(
      [ApiQueryKeys.organization_all_reports, organizationId],
      () => getAllReportByOrganization(organizationId),
      { refetchOnWindowFocus: true },
    );

  return (
    <div className="flex flex-col">
      <div className="flex flex-col-reverse w-full h-full md:flex-row-reverse gap-4">
        <div className="flex flex-col w-full rounded-lg">
          <>
            <div className="flex pb-2 md:pb-4">
              <p className="text-base font-bold text-left text-primary-dark-500">
                {`${organization?.name}`}
              </p>
              <span className="px-1"> - </span>
              <p className="text-base text-left text-zinc-500">Overview</p>
            </div>
            <div className="pb-4 md:pb-6">
              {organization && (
                <GridCountCards
                  isLoadingReports={isLoadingReports}
                  reports={reports}
                  organization={organization}
                />
              )}
            </div>
          </>
        </div>

        {isLoading && (
          <Modal className="z-[150]">
            <div className="max-h-full overflow-y-auto">
              <div className="flex flex-wrap justify-center items-center relative w-full overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
                <SimpleLoading
                  loadingColor="text-primary-cyan-500"
                  loadingSize="h-20 w-20"
                  externalStyles="flex flex-wrap items-center justify-center p-6"
                />
              </div>
            </div>
          </Modal>
        )}
      </div>
      <div className="flex flex-col justify-center h-full w-full bg-white border border-gray-200 shadow-md rounded-lg p-4">
        <div className="flex flex-col justify-center items-center w-full">
          <div className="pb-1">
            <h3 className="text-2xl font-bold">
              Dashboard - {organization?.exposureType}
            </h3>
          </div>
        </div>
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 h-full">
          {organization?.exposureType === ExposureType.IRS_ONLY && (
            <>
              <IRSPieChart organizationId={organizationId} />
              <IRSComposedGraph organizationId={organizationId} />
            </>
          )}
          {organization?.exposureType === ExposureType.FX_ONLY && (
            <>
              <FXPieChart organizationId={organizationId} />
              <FXBarChart organizationId={organizationId} />
            </>
          )}

          {organization?.exposureType === ExposureType.BOTH && (
            <>
              <IRSPieChart organizationId={organizationId} />
              <IRSComposedGraph organizationId={organizationId} />
              <FXPieChart organizationId={organizationId} />
              <FXBarChart organizationId={organizationId} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
