import { useMutation } from 'react-query';
import { useApiCallHandler } from '../../../../hooks/useApiCallHandler';
import { Toast } from '../../../../services/toastify/Toastify';

export const useNotifyReports = (organizationId: number, close: () => void) => {
  const { handleNotifyReports } = useApiCallHandler();

  return useMutation((id: number) => handleNotifyReports(id), {
    mutationKey: ['notify-reports', organizationId],
    onSuccess: () => {
      close();
      Toast.success('The reports have been notified.');
    },
    onError: () => {
      Toast.error('An error occurred while sending the emails.');
    },
  });
};
