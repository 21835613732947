import moment from 'moment';

export const customParseDate = (
  dateString: string,
  dateFormat: string | undefined,
) => {
  const momentDate = moment(dateString, dateFormat, true);
  if (momentDate.isValid()) {
    return {
      year: momentDate.year(),
      month: momentDate.month() + 1,
      day: momentDate.date(),
    };
  }
};
