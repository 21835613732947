import React from 'react';
import { Modal } from '../Modal';
import { useFetchReportsToNotify } from './hooks/useFetchReportsToNotify';
import { SimpleLoadingModal } from '../SimpleLoadingModal';
import { Button } from '../../Button';
import { ReportItem } from './ReportItem';
import { theReportsAreEmpty } from './utils/theReportsAreEmpty';
import { useNotifyReports } from './hooks/useNotifyReports';
import { SimpleLoading } from '../../loading/SimpleLoading';

type ReportKeys =
  | 'outputs'
  | 'irsMtmJournals'
  | 'hedgeEffectivenessReports'
  | 'hedgeDocumentationForms'
  | 'fxHedgeDocumentationForms';

type Props = {
  close: () => void;
  organizationId: number;
};

export const NotifyReportsToEmailModal: React.FC<Props> = ({
  close,
  organizationId,
}) => {
  const { data, isLoading } = useFetchReportsToNotify(organizationId);
  const { mutate: onClick, isLoading: isLoadingMutation } = useNotifyReports(
    organizationId,
    close,
  );

  if (isLoading) {
    return <SimpleLoadingModal />;
  }

  return (
    <Modal closeOnOutClick close={close}>
      <div className="bg-white border border-gray-200 shadow-md w-fit md:w-4/12 px-6 h-fit rounded-lg">
        <div className="flex flex-col items-center justify-center my-2">
          <h3 className="text-base font-semibold">Notify Reports</h3>
          {!data || theReportsAreEmpty(data) ? (
            <span className="text-sm py-1">
              There are no reports to notify.
            </span>
          ) : (
            <ul className="list-disc md:columns-2 list-inside flex-col md:flex md:flex-row md:flex-wrap justify-center items-center min-w-fit md:w-[180px] gap-2 border-t border-b mt-2 py-1 max-h-32 overflow-y-auto">
              {(Object.keys(data) as ReportKeys[]).flatMap(
                (key) =>
                  data[key].map((report) => (
                    <ReportItem key={report.id} report={report} />
                  )) || [],
              )}
            </ul>
          )}
          <div className="w-full flex flex-row justify-center mt-3 gap-3">
            <Button
              type="submit"
              data-cy="confirm-modal-confirm-button"
              disabled={theReportsAreEmpty(data) || isLoadingMutation}
              variant="solid"
              className="disabled:bg-gray-500"
              onClick={() => {
                onClick(organizationId);
              }}
            >
              Confirm
              {isLoadingMutation && <SimpleLoading loadingMargin="ml-2" />}
            </Button>
            <Button
              data-cy="notify-clients-cancel-button"
              variant="outline"
              onClick={close}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
