export const fxMtmJournalWorkingHeaders = [
  /Deal Date/,
  /Maturity Date/,
  /Buy Ccy/,
  /Buy Amount/,
  /Sell Ccy/,
  /Sell Amount/,
  /Deal Type/,
  /Exchange Rate/,
  /Fair value/,
  /Counterparty/,
  /Deal Number/,
  /Spot Rateat Inception/,
  /Reference/,
  /Comment/,
  /Hedging/,
  /Profit or Loss - Dr\/(Cr)/,
  /OCI - Dr\/(Cr)/,
  /Inventory - Dr\/(Cr)/,
];

export const fxMtmJournalHeaders = [
  /GL Accounts/,
  /Classification/,
  /Description/,
  /Reference/,
  /DR$/,
  /CR$/,
];
