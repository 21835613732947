import { fxHedgeEffectivenessInputsReportHeadersSchema } from './fxHedgeEffectivenessInputsReportHeadersSchema';
import { validateHeadersBySchema } from '../../helpers/headerValidator';
import { ProcessableInputReport } from '../../ProcessableInputReport';
import {
  FxDetailedInstrumentReportColHeaders,
  FxHedgeEffectivenessReportRequiredInputsType,
} from './fx-hedge-effectiveness-report.enums';
import { InputReportType } from '../../inputReportType';

export class FxDetailedInstrumentReportValidator {
  colHeaders: FxDetailedInstrumentReportColHeaders[] = Object.values(
    FxDetailedInstrumentReportColHeaders,
  );

  constructor(private processableInput: ProcessableInputReport) {}

  validate() {
    if (
      this.processableInput.sheetName !==
      InputReportType.HB_FX_DETAILED_INSTRUMENT_REPORT
    ) {
      throw new Error(
        `Input report type ${FxHedgeEffectivenessReportRequiredInputsType.HB_FX_DETAILED_INSTRUMENT_REPORT} is wrong type for this output`,
      );
    }

    let rowLimit: number;
    const lastRowWithBlankSpace = this.processableInput.findCellValue(' ');
    if (!lastRowWithBlankSpace) {
      rowLimit = this.processableInput.getSheetDimensions().height;
    } else {
      rowLimit = lastRowWithBlankSpace.row;
    }

    validateHeadersBySchema(
      this.colHeaders,
      fxHedgeEffectivenessInputsReportHeadersSchema,
      this.processableInput,
      rowLimit,
    );
  }
}
