import { useEffect, useState } from 'react';
import { Button } from '../../Button';
import { v4 as uuidv4 } from 'uuid';
import { FxMtmJournalWorkingReportTable } from './FxMtmJournalWorkingReportTable';
import { FxMtmJournalStandardReportTable } from './FxMtmJournalStandardReportTable';
import { useSearchParams } from 'react-router-dom';

export enum FxMtmJournalReportSheetNames {
  FX_MTM_JOURNAL_WORKING = 'FX MTM Journal Working',
  FX_MTM_JOURNAL = 'FX MTM Journal',
}

export const ViewFxMtmJournalReportTable = ({ report }: { report: Blob }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSheet =
    searchParams.get('sheet') === 'fx_mtm_journal'
      ? FxMtmJournalReportSheetNames.FX_MTM_JOURNAL
      : FxMtmJournalReportSheetNames.FX_MTM_JOURNAL_WORKING;

  const [selectedSheet, setSelectedSheet] =
    useState<FxMtmJournalReportSheetNames>(initialSheet);

  useEffect(() => {
    const sheetParamName =
      selectedSheet === FxMtmJournalReportSheetNames.FX_MTM_JOURNAL
        ? 'fx_mtm_journal'
        : 'fx_mtm_journal_working';
    const showBackButton = window.history.length > 1;

    setSearchParams(
      { sheet: sheetParamName },
      { state: { showBackButton }, replace: true },
    );
  }, [selectedSheet, setSearchParams]);

  return (
    <>
      <div className="flex justify-center gap-2 p-2 text-lg font-semibold text-left text-gray-900">
        {Object.values(FxMtmJournalReportSheetNames).map((sheetName) => (
          <Button
            key={uuidv4()}
            onClick={() => setSelectedSheet(sheetName)}
            data-cy={`${sheetName}-button`}
            disabled={selectedSheet === sheetName}
            variant={selectedSheet === sheetName ? 'solid' : 'outline'}
          >
            {sheetName}
          </Button>
        ))}
      </div>

      {selectedSheet === FxMtmJournalReportSheetNames.FX_MTM_JOURNAL_WORKING ? (
        <FxMtmJournalWorkingReportTable report={report} />
      ) : (
        <FxMtmJournalStandardReportTable report={report} />
      )}
    </>
  );
};
