import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { IReportingPeriods } from '../../services/hedgehog/interfaces/IReportingPeriods';
import moment from 'moment';

interface IFolderCardProps extends IReportingPeriods {
  route: string;
  option: string;
}
const folderStyle =
  'min-h-fit max-h-fit min-w-fit p-6 border shadow-md border-gray-300 rounded-lg  [transition:0.10s_ease-out]';

export const FolderCard = ({
  year,
  month,
  reports,
  route,
  option,
}: IFolderCardProps) => {
  const { year: selectedYear, month: selectedMonth } = useParams();
  const backgroundColor =
    selectedMonth === month && selectedYear && +selectedYear === year
      ? 'bg-primary-cyan-500'
      : 'bg-white';

  return (
    <Link
      to={`/${route}/reporting-periods/reports-by-month/${year}/${month}/${option}`}
      state={{ showBackButton: true }}
      className={folderStyle + ' ' + backgroundColor}
      data-cy={`${month}-${year}`}
    >
      <h5 className="mb-2 text-2xl font-semibold  text-gray-900">
        {moment(month, 'MMMM').format('MMM')} {year}
      </h5>

      <div className="flex flex-row justify-between items-center gap-2">
        <p className="font-medium text-sm text-gray-600">Reports:</p>
        <p className="text-xl">{reports.length}</p>
      </div>
    </Link>
  );
};
