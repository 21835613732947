import { useEffect, useState } from 'react';
import * as ExcelJS from 'exceljs';
import { parseReportBlobFileToWorkbook } from '../services/excel/exceljs-service';
import { Toast } from '../services/toastify/Toastify';

export const useLoadWorkbook = (
  report: Blob,
  message = 'Error loading report',
  reportName?: string,
) => {
  const [workbook, setWorkbook] = useState<ExcelJS.Workbook>();

  useEffect(() => {
    const loadWorkbook = async () => {
      try {
        const generatedWorkbook = await parseReportBlobFileToWorkbook(
          report,
          reportName,
        );
        setWorkbook(generatedWorkbook);
      } catch (error) {
        Toast.error(message);
      }
    };

    if (report) {
      loadWorkbook();
    }
  }, [message, report, reportName]);

  return { workbook };
};
