export const fxMtmValuationSheetMock: any[][] = [
  [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    'Hedgehog Software',
    null,
  ],
  [
    'Abstract entity',
    null,
    'FX MTM Valuation Report - 30 Nov 2023',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    'EUR/AUD Hedging',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    'Trade Reference',
    'Counterparty',
    'Deal Date',
    'Maturity Date',
    'Buy',
    'Amount',
    'Sell',
    'Amount',
    'Deal Type',
    'Exchange Rate',
    'MTM',
  ],
  [
    'EFX 1038 83408',
    'NAB',
    new Date('2023-04-03'),
    new Date('2023-04-03'),
    'EUR',
    133,
    'AUD',
    133,
    'Forward',
    6,
    133,
  ],
  [
    'EFX 1038 83371',
    'NAB',
    new Date('2023-04-03'),
    new Date('2023-04-03'),
    'EUR',
    133,
    'AUD',
    133,
    'Forward',
    6,
    133,
    133,
  ],
  ['TOTAL', null, null, null, 'EUR', 133, 'AUD', 133, null, 133, 133, null],
  [
    'Grand Total',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    200,
    null,
  ],
  [
    {
      richText: [
        {
          font: {},
          text: 'End',
        },
        {
          font: {},
          text: 'of',
        },
        {
          font: {},
          text: 'Footer',
        },
      ],
    },
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],

  ['EndofFooter', null, null, null, null, null, null, null, null, null, null],
];
