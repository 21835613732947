import HotTable from '@handsontable/react';
import { useLoadWorkbook } from '../../../hooks/useLoadWorkbook';
import { hedgeDocumentationFormColumnConfig } from './hedgeDocumentationFormColumnConfig';
import {
  customRender,
  handleMergeCellsConfig,
} from './hedgeDocumentationFormMergeConfig';
import { useLoadBasicSheet } from '../../../hooks/useLoadBasicSheet';
import { getHedgeDocumentationCellProperties } from './utils/getHedgeDocumentationCellProperties';
import { useEffect, useState } from 'react';
import { formatDate } from '../common/utils';
import { CellValue } from 'exceljs';

interface IViewHedgeDocumentationFormTableProps {
  report: Blob;
}

export const hedgeDocumentationFormSheetName = 'IRS Hedge Documentation Form';

const headers = [
  /Nature of risk hedged/,
  /Risk Management Strategy/,
  /Risk Management Objective/,
  /Hedge Type/,
  /Date of designation of hedging relationship/,
  /Details of the Hedging Instrument/,
  /Details of the Hedged Item/,
  /Assessment of Hedge Effectiveness/,
  /Effectiveness testing at inception and ongoing/,
  /Matched Terms/,
  /Existence of an economic relationship/,
  /Effect of changes in credit risk/,
  /Hedge ratio applied/,
  /How Ineffectiveness will be quantified/,
  /Expected ineffectiveness/,
  /Testing Frequency/,
  /Policy Declaration/,
];

export const ViewHedgeDocumentationFormTable = ({
  report,
}: IViewHedgeDocumentationFormTableProps) => {
  const { workbook } = useLoadWorkbook(
    report,
    'Error loading IRS Hedge Documentation Form',
  );
  const { sheet: originalSheet } = useLoadBasicSheet(
    workbook,
    hedgeDocumentationFormSheetName,
  );

  const [sheet, setSheet] = useState<CellValue[][]>([]);

  useEffect(() => {
    if (!originalSheet) return;
    const parsedSheet = originalSheet.map((row) => {
      const trimmedRow = row.slice(2, 7);
      return trimmedRow.map((cell) =>
        cell instanceof Date ? formatDate(cell.toISOString()) : cell,
      );
    });
    setSheet(parsedSheet);
  }, [originalSheet]);

  return (
    <div className="flex justify-center border">
      {sheet.length > 0 && (
        <HotTable
          readOnly
          columns={hedgeDocumentationFormColumnConfig}
          data={sheet}
          rowHeaders={false}
          colHeaders={false}
          width="1415px"
          height="auto"
          licenseKey="non-commercial-and-evaluation"
          rowHeights={45}
          wordWrap={true}
          allowEmpty={true}
          mergeCells={handleMergeCellsConfig(sheet)}
          afterRenderer={customRender}
          cells={(row, col) =>
            getHedgeDocumentationCellProperties(sheet, headers, row, col)
          }
        />
      )}
    </div>
  );
};
