import { useQuery } from 'react-query';
import { useUserDashboardContext } from '../../../../hooks/useDashboardContext';
import { ApiQueryKeys } from '../../../../services/hedgehog/api-query-keys.enum';
import { IPeople } from '../../../../services/hedgehog/interfaces/IPeople';
import { MembersTable } from './MembersTable';
import { getOrganizationMembers } from '../../../../services/hedgehog/hedgehog.api';
import { SimpleLoading } from '../../../../components/loading/SimpleLoading';

export const MemberDashboard = ({
  organizationId,
}: {
  organizationId: number;
}) => {
  const { isManager } = useUserDashboardContext();

  const { data: members, isLoading } = useQuery<IPeople[]>(
    [ApiQueryKeys.organization_members, organizationId],
    () => getOrganizationMembers(organizationId),
  );

  return (
    <div className="flex flex-col">
      <div className="relative z-0 overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
        <div
          className={`flex flex-wrap ${
            isManager ? 'justify-between sm:justify-between' : 'justify-center'
          } w-full p-4 gap-2 bg-white text-gray-900`}
        >
          <h3 className="text-2xl font-bold text-center">Members</h3>
          {isManager && <div className="hidden sm:flex" />}
        </div>
        {isLoading && (
          <SimpleLoading
            loadingColor="text-primary-cyan-500"
            loadingSize="h-10 w-10"
            externalStyles="flex flex-wrap items-center justify-center p-2"
          />
        )}
        {members && (
          <MembersTable members={members} organizationId={organizationId} />
        )}
      </div>
    </div>
  );
};
