import { PhoneFrame } from '../../mobile/PhoneFrame';
import { BackgroundIllustration } from '../BackgroundIllustration';
import hedgeEffectiveLogo from '../../../assets/logo/hedge-effective-logo.png';
import gowingsLogo from '../../../assets/logo/gowings-logo.png';
import accoladeLogo from '../../../assets/logo/accolade-logo.png';
import modernLogo from '../../../assets/logo/modern-logo.svg';
import novotechLogo from '../../../assets/logo/novotech-logo.png';
import pwrLogo from '../../../assets/logo/pwr-logo.png';
import shakeLogo from '../../../assets/logo/shake-logo.png';
import shiftLogo from '../../../assets/logo/shift-logo.png';
import { MobileDemo } from '../../mobile/MobileDemo';

const companiesLogos = [
  gowingsLogo,
  accoladeLogo,
  modernLogo,
  novotechLogo,
  pwrLogo,
  shakeLogo,
  shiftLogo,
];

export const About = () => {
  return (
    <section>
      <div className="overflow-hidden py-10 sm:py-20 lg:py-30 2xl:py-36 lg:pb-32 xl:pb-36">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
            <div className="relative w-full z-10 mx-auto max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6">
              <h1
                className="text-4xl font-medium tracking-tight text-gray-900"
                data-cy="about-title"
              >
                Hedgehog is an advisory-tech platform by
              </h1>
              <img
                src={hedgeEffectiveLogo}
                className="mt-8 mb-12"
                alt="hedge-effective-logo"
              ></img>
              <p
                className="mt-6 text-lg text-gray-600"
                data-cy="about-description"
              >
                Our proprietary software allows us to simplify hedge accounting
                reporting for our valued clients, offering a comprehensive
                solution for their financial strategies.
              </p>

              <div data-cy="about-button" className="text-right mt-6">
                <a
                  href="https://www.hedgeeffective.com/hedgehog-software"
                  className="inline-block bg-cyan-400 text-white font-semibold py-3 px-10 rounded-lg shadow hover:bg-cyan-600 transition-colors"
                >
                  Learn more
                </a>
              </div>
            </div>
            <div className="relative mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6">
              <BackgroundIllustration className="absolute left-1/2 top-4 h-[1024px] w-[1024px] -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0" />
              <div className="-mx-4 h-[40rem] px-9 [mask-image:linear-gradient(to_bottom,white_60%,transparent)] sm:mx-0 lg:absolute lg:-inset-x-10 lg:-bottom-20 lg:-top-10 lg:h-auto lg:px-0 lg:pt-10 xl:-bottom-32">
                <PhoneFrame className="mx-auto max-w-[366px]" priority>
                  <MobileDemo />
                </PhoneFrame>
              </div>
            </div>
            <div className="relative mt-4 lg:col-span-7 lg:mt-0 xl:col-span-6">
              <p className="text-center text-sm font-semibold text-gray-900 ">
                Companies that rely on us
              </p>
              <div
                data-cy="companies-logos"
                className="mx-auto mt-4 flex max-w-xl flex-wrap gap-2 justify-between"
              >
                {companiesLogos.map((company, index) => (
                  <img
                    key={index}
                    src={company}
                    alt="Logo of company that trust us."
                    className="max-h-12 min-w-[25px]"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
