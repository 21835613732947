import { IIRSTrade } from '../../services/hedgehog/interfaces/IIRSTrade';
import { SimpleLoading } from '../loading/SimpleLoading';
import { IRSTradeGraphRow } from '../IRSTradeGraphRow';
import {
  calculateDataValues,
  calculateGradientOffset,
} from '../../utils/irsTrades';
import moment from 'moment';

type IIRSTradesAdminTableProps = {
  irsTrades: IIRSTrade[] | undefined;
  onDelete: (id: number) => void;
  isUpdating: boolean;
};

const COLUMNS: string[] = [
  'reference',
  'Counter Party',
  'Deal Date',
  'Maturity Date',
  'FV graph',
  'Actions',
];

export const IRSTradesAdminTable = ({
  irsTrades,
  onDelete,
  isUpdating,
}: IIRSTradesAdminTableProps) => {
  return (
    <table className="w-full text-sm text-left text-gray-500 rounded-lg">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
        <tr className="bg-white border-b hover:bg-gray-50 rounded-lg">
          {COLUMNS.map((key) => (
            <th key={key} className="px-4 whitespace-nowrap py-4 space-x-2">
              {key}
            </th>
          ))}
        </tr>
      </thead>
      {isUpdating ? (
        <tbody>
          <tr>
            <td colSpan={COLUMNS.length}>
              <div className="min-h-80 text-center flex justify-center items-center mt-4">
                <SimpleLoading loadingColor="text-primary-cyan-500" />
              </div>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {irsTrades &&
            irsTrades.map((trade) => (
              <tr
                key={trade.id}
                data-cy={`irs-trade-row-${trade.id}`}
                className="bg-white whitespace-nowrap border-b hover:bg-primary-cyan-100 ease-in transition duration-100 cursor-pointer"
              >
                <td className="px-4 py-4 space-x-2">{trade.reference}</td>
                <td className="px-4 py-4 space-x-2">{trade.counterParty}</td>
                <td className="px-4 py-4 space-x-2">
                  {moment(trade.dealDate).format('DD MMM YY')}
                </td>
                <td className="px-4 py-4 space-x-2">
                  {moment(trade.maturityDate).format('DD MMM YY')}
                </td>
                <td className="px-4 min-w-[200px] py-4">
                  <IRSTradeGraphRow
                    trade={trade}
                    calculateDataValues={calculateDataValues}
                    calculateGradientOffset={calculateGradientOffset}
                  />
                </td>
                <td className="px-4 py-4">
                  <button
                    onClick={() => onDelete && onDelete(trade.id)}
                    data-cy={`irs-trade-delete-${trade.id}`}
                    className="text-red-500 hover:text-red-700 hover:underline cursor-pointer"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      )}
    </table>
  );
};
