import { Link } from 'react-router-dom';
import { HedgehogLogo } from '../icons/HedgehogLogo';
import { AdminSideBarMenuOptions } from './admin/AdminSideBarMenuOptions';

export interface INavLink {
  label: string;
  href: string;
  icon: JSX.Element;
  extra?: number;
}

export const AdminSidebar = () => {
  return (
    <div className="hidden md:flex flex-col justify-start items-center w-[306px] max-h-[1106px] relative gap-4 p-6 rounded bg-white border-r">
      <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 w-60 relative gap-2">
        <Link className="flex gap-2 lg:justify-center" to="/">
          <HedgehogLogo
            svgIconStyles="text-black flex-grow-0 flex-shrink-0 w-60 h-[55px] object-contain"
            logoTitleStyles="text-primary-dark fill-current"
            logoIconStyles="text-primary-cyan-500 fill-current"
          />
        </Link>
      </div>
      <AdminSideBarMenuOptions />
    </div>
  );
};
