import React, { useEffect } from 'react';
import { AuthGuard } from './AuthGuard';
import { useUser } from '../../hooks/useUser';
import { UserRole } from '../../services/hedgehog/enum/UserRole.enum';
import { useNavigate } from 'react-router-dom';

export const UserAuthGuard = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (user && user?.role !== UserRole.User) {
      navigate('/');
    }
  }, [navigate, user]);
  return <AuthGuard>{children}</AuthGuard>;
};

export default UserAuthGuard;
