import { BackgroundIllustration } from './landing/BackgroundIllustration';

export const AuthPageLayout = ({
  title,
  subtitle,
  children,
}: {
  title: string;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <main className="flex min-h-full overflow-hidden py-6 sm:py-16 2xl:py-30 ">
      <div className="mx-auto flex w-full max-w-2xl flex-col px-4 sm:px-6">
        <div className="relative mt-10 sm:mt-4 lg:col-span-5 lg:row-span-2  lg:mt-2 xl:col-span-6">
          <BackgroundIllustration className="absolute left-1/2 -top-10 h-[1280px] w-[1280px]  pointer-events-none -translate-x-1/2 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)]" />

          <h1 className="text-center text-2xl font-medium tracking-tight text-gray-900 z-50">
            {title}
          </h1>
          {subtitle && (
            <p className="mt-3 text-center text-lg text-gray-600 z-50">
              {subtitle}
            </p>
          )}
        </div>
        <div className="mt-10 flex-auto z-50 bg-white px-6 min-[320px]:px-12 py-10 shadow-2xl shadow-gray-900/10 sm:mx-0 sm:flex-none sm:rounded-3xl sm:p-16">
          {children}
        </div>
      </div>
    </main>
  );
};
