export enum FxMtmValuationReportOutputColHeaders {
  DealDate = 'Deal Date',
  MaturityDate = 'Maturity Date',
  BuyCcy = 'Buy Ccy',
  BuyAmount = 'Buy Amount',
  SellCcy = 'Sell Ccy',
  SellAmount = 'Sell Amount',
  DealType = 'Deal Type',
  ExchangeRate = 'Exchange Rate',
  FairValue = 'Fair value',
  Counterparty = 'Counterparty',
  DealNumber = 'Deal Number',
  SpotRateatInception = 'Spot Rateat Inception',
  Reference = 'Reference',
  Comment = 'Comment',
}

export enum FxMtmValuationReportInputsRowHeader {
  TOTAL = 'TOTAL',
}

export enum FxMtmValuationReportOutputRowHeader {
  GRAND_TOTAL = 'Grand Total',
}

export enum FxMtmValuationReportHedgingRowHeader {
  HEDGING = 'Hedging',
}

export const fxMtmValuationHeaderMapping = (
  header:
    | FxMtmValuationReportOutputColHeaders
    | FxMtmValuationReportInputsRowHeader,
) => {
  if (header === FxMtmValuationReportOutputColHeaders.FairValue) {
    return 'Valuation';
  }
  return header;
};
