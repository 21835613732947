import { useQueryClient } from 'react-query';
import { useConfirm } from '../../../../../hooks/useConfirm';
import { Toast } from '../../../../../services/toastify/Toastify';

type Props = {
  id: number;
  queryKey: string;
  confirmationMessage: string;
  fn: (parameter: number) => Promise<void>;
};

export const useMakeAction = () => {
  const { confirmAction } = useConfirm();
  const queryClient = useQueryClient();

  const makeAction = ({ id, queryKey, confirmationMessage, fn }: Props) => {
    const action = async () => {
      try {
        await fn(id);
        Toast.success('Action performed successfully');
        queryClient.refetchQueries(queryKey);
      } catch (e: unknown) {
        if (e instanceof Error) {
          Toast.error(e.message);
        }
      }
    };

    confirmAction(action, confirmationMessage);
  };

  return { makeAction };
};
