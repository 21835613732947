import HotTable from '@handsontable/react';
import { useEffect, useState } from 'react';
import { useLoadBasicSheet } from '../../../hooks/useLoadBasicSheet';
import { useLoadWorkbook } from '../../../hooks/useLoadWorkbook';
import { getCellProperties } from '../common/utils';
import {
  fxHedgeEffectivenessReportColumnConfig,
  fxHedgeEffectivenessReportCustomRenderer,
  fxHedgeEffectivenessReportMergeCells,
} from './fxHedgeEffectivenessConfig';
import { FxHedgeEffectivenessReportSheetNames } from './ViewFxHedgeEffectivenessReportTable';

interface IProps {
  report: Blob;
}

export const FxHedgeEffectivenessJournalTable = ({ report }: IProps) => {
  const { workbook } = useLoadWorkbook(
    report,
    'Error loading FX Hedge Effectiveness Journal Report',
  );

  const { sheet: originalSheet } = useLoadBasicSheet(
    workbook,
    FxHedgeEffectivenessReportSheetNames.JOURNAL,
  );

  const headerPatterns = [
    /GL Accounts/,
    /Classification/,
    /Entry Description/,
    /Reference/,
    /DR/,
    /CR/,
  ];

  const [sheet, setSheet] = useState<any[][]>([]);

  useEffect(() => {
    if (originalSheet) {
      const updatedSheet = [
        ...originalSheet.slice(0, 6),
        [null],
        ...originalSheet.slice(6),
      ];

      setSheet(updatedSheet);
    }
  }, [originalSheet]);

  return (
    <div className="flex justify-center">
      {sheet.length > 0 && (
        <HotTable
          readOnly={true}
          key={'fx-hedge-effectiveness-journal-report'}
          data={sheet}
          rowHeaders={false}
          colHeaders={false}
          width="1550px"
          height="auto"
          licenseKey="non-commercial-and-evaluation"
          rowHeights={35}
          manualColumnResize={true}
          manualRowResize={true}
          wordWrap={true}
          allowEmpty={false}
          cells={(col, row) =>
            getCellProperties(sheet, headerPatterns, col, row)
          }
          columns={fxHedgeEffectivenessReportColumnConfig(
            FxHedgeEffectivenessReportSheetNames.JOURNAL,
          )}
          mergeCells={fxHedgeEffectivenessReportMergeCells(
            FxHedgeEffectivenessReportSheetNames.JOURNAL,
            sheet,
          )}
          afterRenderer={fxHedgeEffectivenessReportCustomRenderer}
        />
      )}
    </div>
  );
};
