import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const enableSentry =
  process.env.REACT_APP_MODE === 'production' ||
  process.env.REACT_APP_MODE === 'staging';

if (enableSentry) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    environment: process.env.REACT_APP_MODE,
    tracesSampleRate: +(process.env.REACT_APP_SENTRY_TRACE_RATE || 0.1),
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
