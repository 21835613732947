import moment from 'moment';
import { useEffect, useState } from 'react';
import { parseReportBlobFileToWorkbook } from '../../../../services/excel/exceljs-service';
import { IFxTrade } from '../../../../services/hedgehog/interfaces/IFXTrade';
import { formatDate } from '../../../tables/common/utils';
import { useFetchFxTrades } from '../useQueries/useFetchFxTrades';
import { useFetchMtmValuationReportFile } from '../useQueries/useFetchMtmValuationReportFile';
import { useFetchMtmValuationReports } from '../useQueries/useFetchMtmValuationReports';
import { useGetProfitOrLoss } from '../useQueries/useGetProfitOrLoss';

export const fxAnalysisReportHeaders = [
  'Date',
  'Fair Value on B/S',
  'FVTPL Movements G/(L)',
  'Profit or Loss - Dr/(Cr)',
  'CFH P&L Movements G/(L)',
  '',
  'Date	',
  'Buy Ccy	',
  'Buy Amount	',
  'Sell Ccy	',
  'Sell Amount	',
  'PnL Vol. Reduction %	',
  'PnL Reduction $',
];

export const useFxAnalysisReport = (organizationId: number) => {
  const [sheet, setSheet] = useState<any[][]>([[]]);

  const {
    data: trades,
    isLoading: isLoadingTrades,
    isError: isErrorTrades,
  } = useFetchFxTrades(organizationId);
  const {
    data: profitAndLosses,
    isLoading: isLoadingPL,
    isError: isErrorPL,
  } = useGetProfitOrLoss(organizationId);
  const {
    data: mtmValuationReports,
    isLoading: isLoadingMtmValuation,
    isError: isErrorMtmValuationReports,
  } = useFetchMtmValuationReports(organizationId, trades);
  const {
    data: mtmValuationReportFile,
    isLoading: isLoadingMtmValuationFile,
    isError: isErrorMtmValuationFile,
  } = useFetchMtmValuationReportFile(mtmValuationReports);

  useEffect(() => {
    const calculateSheet = async () => {
      if (trades && mtmValuationReportFile && profitAndLosses) {
        const newSheet = await getSheetData(
          trades,
          mtmValuationReportFile,
          profitAndLosses,
        );

        setSheet(newSheet);
      }
    };

    calculateSheet();
  }, [trades, mtmValuationReportFile, profitAndLosses]);

  return {
    sheet,
    isLoading:
      isLoadingTrades ||
      isLoadingPL ||
      isLoadingMtmValuation ||
      isLoadingMtmValuationFile,
    isError:
      isErrorTrades ||
      isErrorPL ||
      isErrorMtmValuationReports ||
      isErrorMtmValuationFile,
  };
};

const getSheetData = async (
  trades: IFxTrade[],
  mtmValuationFile: Blob,
  profitAndLosses: number[],
): Promise<any[][]> => {
  const sheetWithfairValues = calculateFairValues(trades);
  const sheetWithftvlMovements = calculateFvtplMovements(sheetWithfairValues);
  const sheetWithMtmValuationData = await calculateMtmValuationData(
    sheetWithftvlMovements,
    mtmValuationFile,
  );
  const sheetWithProfitOrLoss = addProfitOrLoss(
    sheetWithMtmValuationData,
    profitAndLosses,
  );
  const sheetWithCfhMovements = calculateCfhMovements(sheetWithProfitOrLoss);
  const sheetWithPnLPercentage = calculatePnLPercentage(sheetWithCfhMovements);
  const sheetWithPnLValue = calculatePnLValue(sheetWithPnLPercentage);
  const sheetWithDuplicatedDates = addExtraDateColumn(sheetWithPnLValue);
  return addHeadersToSheet(sheetWithDuplicatedDates);
};

const addExtraDateColumn = (sheet: any[][]): any[][] => {
  return sheet.map((row, i) => {
    const newRow = [...row];
    newRow[6] = row[0];
    return newRow;
  });
};

const calculatePnLValue = (sheet: any[][]): any[][] => {
  return sheet.map((row, i) => {
    row.length = 12;
    if (i === 0) return [...row];
    const pnlPercentage = Math.abs(row[11]);
    const fvtplValue = Math.abs(row[2]);
    const pnlValue = pnlPercentage * fvtplValue;
    return [...row.slice(0, 12), pnlValue, ...row.slice(12)];
  });
};

const calculatePnLPercentage = (sheet: any[][]): any[][] => {
  return sheet.map((row, i) => {
    row.length = 12;
    if (i === 0) return [...row];
    const cfhValue = Math.abs(row[4]);
    const fvtplValue = Math.abs(row[2]);
    const pnlPercentage = cfhValue / fvtplValue - 1;
    return [...row.slice(0, 11), pnlPercentage, ...row.slice(11)];
  });
};

const addProfitOrLoss = (sheet: any[][], profitAndLosses: number[]) => {
  return sheet.map((row, i) => [
    ...row.slice(0, 3),
    profitAndLosses[i],
    ...row.slice(3),
  ]);
};

const calculateCfhMovements = (sheet: any[][]) => {
  return sheet.map((row, i, arr) => {
    if (i === 0) return row;

    const previousRow = [...arr[i - 1]];
    if (isPreviousMonth(row[0], previousRow[0])) {
      return [...row.slice(0, 4), row[3] - previousRow[3], ...row.slice(4)];
    }
    return row;
  });
};

const addHeadersToSheet = (sheet: any[][]) => {
  const newSheet = sheet.map((row) => [...row]);
  newSheet.splice(0, 0, fxAnalysisReportHeaders);
  return newSheet;
};

const calculateMtmValuationData = async (
  sheet: any[][],
  mtmValuation: Blob,
): Promise<any[][]> => {
  const workbook = await parseReportBlobFileToWorkbook(mtmValuation);
  const sheetValues = workbook?.getWorksheet(1)?.getSheetValues() as any[][];

  let totalRow: number | null = null;
  let totalCol: number | null = null;

  sheetValues.forEach((row, rowIndex) => {
    if (totalRow !== null) return;
    row.forEach((cell, colIndex) => {
      if (cell === 'TOTAL') {
        totalRow = rowIndex;
        totalCol = colIndex;
      }
    });
  });

  if (totalRow === null || totalCol === null) {
    return sheet;
  }

  const valuesToAdd = [
    sheetValues[totalRow][totalCol + 2],
    sheetValues[totalRow][totalCol + 3],
    sheetValues[totalRow][totalCol + 4],
    sheetValues[totalRow][totalCol + 5],
  ];

  if (sheet.length > 0) {
    const lastRow = sheet[sheet.length - 1];
    lastRow[5] = valuesToAdd[0];
    lastRow[6] = valuesToAdd[1];
    lastRow[7] = valuesToAdd[2];
    lastRow[8] = valuesToAdd[3];
  } else {
    sheet.push([null, null, null, ...valuesToAdd]);
  }

  return sheet;
};

const calculateFairValues = (trades: IFxTrade[]): any[][] => {
  const result: any = {};

  trades.forEach((trade) => {
    trade.mtmValuationEntry.forEach((entry) => {
      const tradeDate = new Date(entry.reportingDate);
      const date = formatDate(tradeDate.toISOString());

      if (!result[date]) {
        result[date] = [date, 0, null];
      }

      result[date][1] += entry.fairValue;
    });
  });

  return (Object.values(result) as any[][]).sort(sortByMonthYear);
};

const calculateFvtplMovements = (sheet: any[][]): any[][] => {
  return sheet.map((currentRow, i, arr) => {
    if (i === 0) return currentRow;

    const previousRow = arr[i - 1];
    if (isPreviousMonth(currentRow[0], previousRow[0])) {
      return [currentRow[0], currentRow[1], currentRow[1] - previousRow[1]];
    }

    return currentRow;
  });
};

const isPreviousMonth = (firstMonth: string, secondMonth: string) => {
  return (
    moment(firstMonth, 'MMM-YYYY-DD').diff(
      moment(secondMonth, 'MMM-YYYY-DD'),
      'months',
    ) === 1
  );
};

const sortByMonthYear = (firstDate: string[], secondDate: string[]): number => {
  const dateA = moment(firstDate[0], 'DD-MMM-YY');
  const dateB = moment(secondDate[0], 'DD-MMM-YY');
  return dateA.isSame(dateB) ? 0 : dateA.isBefore(dateB) ? -1 : 1;
};
