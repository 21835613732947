import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './useUser';

export const useRedirectHomeIfAuthenticated = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    if (!user?.id) return;

    if (user.role === 'admin') {
      navigate('/admin/home');
    } else {
      navigate('/user/home');
    }
  }, [navigate, user]);
};
