import { Button } from '../../Button';
import { Modal } from '../Modal';
import { InputFile } from '../../input-file/InputFile';
import { useState } from 'react';
import { validateInput } from '../../../services/validation/validateInput';
import { InputReportType } from '../../../services/validation/inputReportType';

export const MigrateReportModal = ({
  close,
  onSubmit,
}: {
  close: () => void;
  onSubmit: (values: any) => any;
}) => {
  const [file, setFile] = useState<File>();

  const handleFileChange = async (file: File) => {
    await validateInput(file, InputReportType.MIGRATION_REPORT);
    setFile(file);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!file) return;
    onSubmit(file);
  };

  return (
    <Modal close={close}>
      <div className="max-h-full overflow-y-auto">
        <div className="flex flex-col justify-center items-center relative w-11/12 sm:w-96  z-0 overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
          <form className="flex flex-col justify-center items-center w-full p-4 gap-2 bg-white">
            <h2 className="font-medium text-gray-900">Migrate Report</h2>

            <div className="w-full my-2">
              <InputFile
                name="migration-file"
                id="migration-file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                label="Hedge Effectiveness Report"
                handleLoad={(file: File) => handleFileChange(file)}
              ></InputFile>
            </div>

            <div className="flex flex-row items-center gap-4 justify-end w-full my-2">
              <Button variant="underline" color="black" onClick={close}>
                Cancel
              </Button>
              <Button
                variant="solid"
                color="cyan"
                data-cy="migrate-report-button"
                onClick={handleSubmit}
              >
                Send
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
