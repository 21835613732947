import { OutputReportTypes } from './OutputReportTypes';

export const OutputGetByIdRoutes = {
  'Output Report': 'output-report',
  [OutputReportTypes.IRS_CURRENT_NON_CURRENT_REPORT]: 'output-report',
  [OutputReportTypes.IRS_MTM_VALUATION_REPORT]: 'output-report',
  [OutputReportTypes.FX_MTM_VALUATION]: 'output-report',
  [OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM]: 'hedge-documentation-form',
  [OutputReportTypes.IRS_MTM_JOURNAL]: 'irs-mtm-journal',
  [OutputReportTypes.IRS_HEDGE_EFFECTIVENESS_REPORT]:
    'hedge-effectiveness-report',
  [OutputReportTypes.FX_HEDGE_DOCUMENTATION_FORM]:
    'fx-hedge-documentation-form',
} as const;

// bad work, should be util type "ReadOnly"
export const OutputGetFileByIdRoutes = {
  'Output Report': 'output-report',
  'Input Report': 'input-report',
  [OutputReportTypes.IRS_MTM_VALUATION_REPORT]: 'output-report',
  [OutputReportTypes.IRS_CURRENT_NON_CURRENT_REPORT]: 'output-report',
  [OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM]: 'hedge-documentation-form',
  [OutputReportTypes.IRS_MTM_JOURNAL]: 'irs-mtm-journal',
  [OutputReportTypes.FX_MTM_VALUATION]: 'output-report',
  [OutputReportTypes.IRS_HEDGE_EFFECTIVENESS_REPORT]:
    'hedge-effectiveness-report',
  [OutputReportTypes.FX_HEDGE_DOCUMENTATION_FORM]:
    'fx-hedge-documentation-form',
} as const;
