import { Link } from 'react-router-dom';

const alertClasses = {
  danger: 'text-red-800 rounded-lg bg-red-50 shadow-lg',
  warning: 'text-yellow-800 rounded-lg bg-yellow-50 shadow-lg',
  info: 'text-blue-800 rounded-lg bg-blue-50 shadow-lg',
  success: 'text-green-800 rounded-lg bg-green-50 shadow-lg',
};
type AlertType = keyof typeof alertClasses;

interface IAlertProps {
  alertMessage: string;
  alertType: AlertType;
  alertStyles?: string;
  alertRoute?: {
    name: string;
    route: string;
  };
}

export const SimpleAlert = ({
  alertMessage,
  alertType,
  alertStyles,
  alertRoute,
}: IAlertProps) => {
  return (
    <div
      className={`text-center p-4 mb-4 text-lg ${alertStyles} ${alertClasses[alertType]}`}
      role="alert"
      data-cy="simple-alert"
    >
      <p>
        <span className="font-medium">{alertMessage}</span>
      </p>
      {alertRoute && (
        <span className="text-sm">
          {'Go back to '}
          <Link to={alertRoute.route}>
            <strong>{alertRoute.name}</strong>
          </Link>
        </span>
      )}
    </div>
  );
};
