import { ComponentProps } from 'react';
import { UserRole } from '../../../../services/hedgehog/enum/UserRole.enum';
import { FormField } from '../../../../components/form-field/FormField';
import useLoading from '../../../../hooks/useLoading';
import { Toast } from '../../../../services/toastify/Toastify';
import {
  getUserByEmail,
  updateUser,
} from '../../../../services/hedgehog/hedgehog.api';
import { Modal } from '../../../../components/modal/Modal';
import { Form, Formik } from 'formik';
import { Button } from '../../../../components/Button';
import { SimpleLoadingModal } from '../../../../components/modal/SimpleLoadingModal';
import { addExistingUserAsAdminValidationSchema } from './validations/addExistingUserAsAdminValidation';

const initialValues = {
  email: '',
  role: UserRole.Admin,
};

const formFields: ComponentProps<typeof FormField>[] = [
  {
    label: 'Email',
    id: 'email',
    name: 'email',
    type: 'email',
    placeholder: 'Email',
  },
];

type IAddExistingAdminModalProps = {
  close: () => void;
  onSubmit?: () => void;
};

export const AddExistingAdminModal = ({
  close,
  onSubmit,
}: IAddExistingAdminModalProps) => {
  const [isAddingAdmin, waitFor] = useLoading();

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      console.log(values);
      const foundUser = await getUserByEmail(values['email']);
      await updateUser(foundUser.id, { role: UserRole.Admin });
      Toast.success('Admin added successfully');
      onSubmit?.();
      close();
    } catch (e: unknown) {
      if (e instanceof Error) Toast.error(e.message);
    }
  };

  return (
    <>
      <Modal>
        <div className="max-h-full w-full sm:w-auto overflow-y-auto items-center py-2">
          <div className="flex flex-col relative w-full sm:w-96 justify-center items-center bg-white border border-gray-200 shadow-md rounded-lg">
            <Formik
              initialValues={initialValues}
              validationSchema={addExistingUserAsAdminValidationSchema}
              onSubmit={(values) => waitFor(handleSubmit(values))}
            >
              <Form className="flex flex-col justify-center rounded-lg items-center w-full p-4 gap-2 bg-white">
                <div>
                  <h3 className="text-center font-semibold text-xl my-2 text-black">
                    Add existing user as administrator
                  </h3>
                </div>

                {formFields.map((field) => (
                  <FormField key={field.id} {...field} />
                ))}

                <div className="flex flex-row items-center gap-4 justify-end w-full">
                  <button
                    type="button"
                    className="text-sm font-semibold underline underline-offset-4"
                    onClick={close}
                  >
                    Close
                  </button>
                  <Button
                    data-cy="add-existing-admin-button"
                    variant="solid"
                    color="cyan"
                    type="submit"
                  >
                    Add
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </Modal>
      {isAddingAdmin && <SimpleLoadingModal />}
    </>
  );
};
