import { CellProperties, ColumnSettings } from 'handsontable/settings';
import { columnConfigWithNumberAndFormat } from '../../tables/common/columnConfigs';
import { DEFAULT_PERCENTAGE_FORMAT } from '../../tables/common/utils';

export const fxAnalysisReportColumnConfig: ColumnSettings[] = [
  { width: 80 },
  {
    width: 100,
    ...columnConfigWithNumberAndFormat,
  },
  { width: 100, ...columnConfigWithNumberAndFormat },
  { width: 100, ...columnConfigWithNumberAndFormat },
  { width: 100, ...columnConfigWithNumberAndFormat },
  { width: 25 },
  { width: 80 },
  { width: 100 },
  { width: 100, ...columnConfigWithNumberAndFormat },
  { width: 100 },
  { width: 100, ...columnConfigWithNumberAndFormat },
  {
    width: 100,
    type: 'numeric',
    numericFormat: {
      pattern: DEFAULT_PERCENTAGE_FORMAT,
    },
  },
  { width: 100, ...columnConfigWithNumberAndFormat },
];

export function getFxAnalysisReportCells(col: number) {
  const cellProperties: Partial<CellProperties> = {};
  cellProperties.className += ' default-text-color';

  if (col === 0) {
    cellProperties.className += ' header';
  }

  return cellProperties;
}

export const getFxAnalysisReportMergeCellsConfig = (sheet: any[][]) => {
  const mergeCellsConfiguration = [
    {
      row: 1,
      col: 5,
      rowspan: sheet.length - 1,
      colspan: 1,
    },
  ];

  return mergeCellsConfiguration;
};
