import { ProcessableInputReport } from './ProcessableInputReport';
import { FxDetailedInstrumentReportValidator } from './reports/fxDetailedIntrument/FxDetailedInstrumentReportValidator';
import { FxForwardsHeldReportValidator } from './reports/fxForwardsHeld/FxForwardsHeldReportValidator';
import { FxMaturedDealsReportValidator } from './reports/fxMaturedDealsReport/FxMaturedDealsReportValidator';
import { IrsCurrentNonCurrentReportValidator } from './reports/irsCurrentNonCurrent/IrsCurrentNonCurrentReportValidator';
import { MigrationReportValidator } from './reports/migration/MigrationReportValidator';
import { MtmValuationReportValidator } from './reports/hedgehogMtmValuation/mtmValuationReportValidator';
import { InputReportType } from './inputReportType';
import { HedgehogCurrentNonCurrentValidator } from './reports/hedgehogCurrentNonCurrent/HedgehogCurrentNonCurrentValidator';
import { CvaReportValidator } from './reports/cvaReport/CvaReportValidator';
import { DetailedInstrumentReportValidator } from './reports/detailedInstrument/DetailedInstrumentReportValidator';
import { IrsPositionReportValidator } from './reports/position/IrsPositionReportValidator';

interface IReportValidationClass {
  validationClass: new (processableInput: ProcessableInputReport) => {
    validate: () => void;
  };
}

export const validationClasses: Record<
  InputReportType,
  IReportValidationClass
> = {
  [InputReportType.MIGRATION_REPORT]: {
    validationClass: MigrationReportValidator,
  },
  [InputReportType.IRS_CURRENT_NON_CURRENT_REPORT]: {
    validationClass: IrsCurrentNonCurrentReportValidator,
  },
  [InputReportType.HB_FX_MATURED_DEALS_REPORT]: {
    validationClass: FxMaturedDealsReportValidator,
  },
  [InputReportType.HB_FX_DETAILED_INSTRUMENT_REPORT]: {
    validationClass: FxDetailedInstrumentReportValidator,
  },
  [InputReportType.HB_FX_FORWARDS_HELD_REPORT]: {
    validationClass: FxForwardsHeldReportValidator,
  },
  [InputReportType.HEDGEHOG_IRS_MTM_VALUATION_REPORT]: {
    validationClass: MtmValuationReportValidator,
  },
  [InputReportType.HEDGEHOG_IRS_CURRENT_NON_CURRENT_REPORT]: {
    validationClass: HedgehogCurrentNonCurrentValidator,
  },
  [InputReportType.MTM_HB_CVA_REPORT]: {
    validationClass: CvaReportValidator,
  },
  [InputReportType.MTM_HB_DETAILED_INSTRUMENT_REPORT]: {
    validationClass: DetailedInstrumentReportValidator,
  },
  [InputReportType.MTM_HB_IRS_POSITION_REPORT]: {
    validationClass: IrsPositionReportValidator,
  },
};
