import * as yup from 'yup';
import { IStaticField } from '../../OutputReportTypes';

export const FxHedgeEffectivenessReportStaticData: {
  fields: IStaticField[];
  validationSchema: any;
} = {
  fields: [
    {
      label: 'Name',
      name: 'name',
      type: 'string',
      defaultValue: 'FX Hedge Effectiveness Report',
    },
    { label: 'Reporting Date', name: 'reportingDate', type: 'date' },
    {
      label: 'Valuation Currency',
      name: 'valuationCurrency',
      type: 'string',
      defaultValue: 'AUD',
    },
  ],

  validationSchema: yup.object().shape({
    name: yup.string().required('Name is required'),
    reportingDate: yup.date().required('Reporting Date is required'),
    valuationCurrency: yup
      .string()
      .min(3)
      .max(3)
      .trim()
      .required('Reporting Date is required'),
  }),
};
