import { useParams } from 'react-router-dom';
import { useConfirm } from '../../../../hooks/useConfirm';
import { IRSTradesAdminTable } from '../../../../components/admin-trades/IRSTradesAdminTable';
import { useDeleteTrade } from '../../../../hooks/useDeleteTrade';
import { useGetPaginatedMigrationIrsTrades } from '../../../../hooks/useGetPaginatedMigrationIrsTrades';
import { PaginationButtons } from '../dashboard-home/PaginationButtons';

export const OrganizationTrades = () => {
  const { id } = useParams();
  const organizationId = Number(id);
  const NUMBER_OF_TRADES_PER_PAGE = 6;
  const { confirmAction } = useConfirm();
  const {
    isLoading: isLoadingTrades,
    isFetching: isFetchingTrades,
    data: tradesResponse,
    currentPage,
    nextPage,
    prevPage,
  } = useGetPaginatedMigrationIrsTrades(
    organizationId,
    NUMBER_OF_TRADES_PER_PAGE,
  );
  const { mutate: deleteTrade, isLoading: isLoadingDeletion } =
    useDeleteTrade(organizationId);

  const onTradeDelete = (id: number) => {
    confirmAction(
      async () => deleteTrade(id),
      'Are you sure you want to delete this trade?',
    );
  };

  return (
    <div className="w-full">
      <div className="min-h-[520px] overflow-y-auto">
        <div className="col-span-6 w-fit md:w-full h-[490px] border border-gray-200 shadow-md rounded-lg mb-4">
          <IRSTradesAdminTable
            isUpdating={
              isLoadingDeletion || isLoadingTrades || isFetchingTrades
            }
            irsTrades={tradesResponse?.trades}
            onDelete={onTradeDelete}
          />
        </div>
      </div>
      <PaginationButtons
        isLoading={isLoadingTrades}
        currentPage={currentPage}
        limit={NUMBER_OF_TRADES_PER_PAGE}
        amountOfItems={tradesResponse?.count ?? 0}
        nextPage={nextPage}
        prevPage={prevPage}
      />
    </div>
  );
};
