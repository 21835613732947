import { v4 as uuidv4 } from 'uuid';
import { ProfileConfigStaticDataName } from '../modal/profile-config-modal/profileStaticData';
import { IProfileConfigSegment } from '../modal/profile-config-modal/interface/IProfileSegment';
import { FormField } from './FormField';
import { FormFxReportCurrencyRulesStaticData } from './FormFxReportCurrencyRulesStaticData';

export const FormFieldSpecific = ({
  segment,
}: {
  segment: IProfileConfigSegment;
}) => {
  if (segment.name === ProfileConfigStaticDataName.FxCurrencyRules) {
    return (
      <>
        <FormFxReportCurrencyRulesStaticData />
      </>
    );
  } else {
    return (
      <>
        {segment.fields.map((field) => (
          <div key={uuidv4()}>
            <FormField id={field.name} placeholder={''} {...field} />
          </div>
        ))}
      </>
    );
  }
};
