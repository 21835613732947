interface IHamburgerButtonProps {
  onClick: () => void;
  isOpen: boolean;
}

export const HamburgerButton = ({ onClick, isOpen }: IHamburgerButtonProps) => {
  return (
    <button
      data-cy="hamburger-button"
      onClick={onClick}
      className="p-2 ml-auto flex rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      <svg
        className={`w-6 h-6 text-gray-700 transition-transform duration-300 ${
          isOpen ? 'rotate-90' : ''
        }`}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        {isOpen ? (
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 6l12 12M6 18l12-12"
          ></path>
        ) : (
          <>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16"
            ></path>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 12h16"
            ></path>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 18h16"
            ></path>
          </>
        )}
      </svg>
    </button>
  );
};
