import { RawCellContent } from 'hyperformula';
import { HyperFormulaService } from './HyperformulaService';

export type Cell = {
  col: number;
  row: number;
  value: any;
};

export class ProcessableReport {
  sheets: { [key: string]: { name: string; id: number } } = {};

  hyperFormulaService = new HyperFormulaService();

  private getDefaultSheet() {
    if (Object.keys(this.sheets).length === 0) return null;
    return this.sheets[Object.keys(this.sheets)[0]];
  }

  private getSheetIdOrThrow(sheetId?: number): number {
    const id = sheetId || this.getDefaultSheet()?.id;
    if (id === undefined) throw new Error('Sheet not found');
    return id;
  }

  buildFromSheets(sheets: { [key: string]: any[][] }) {
    Object.keys(sheets).forEach((sheetName) => {
      const sheetId = this.addSheet(sheetName, sheets[sheetName]);
      this.sheets[sheetName] = { name: sheetName, id: sheetId };
    });
  }

  addSheet(sheetName: string, content: RawCellContent[][]): number {
    if (this.hyperFormulaService.workbook.getSheetNames().includes(sheetName)) {
      const sheetId = this.hyperFormulaService.workbook.getSheetId(sheetName);
      this.hyperFormulaService.workbook.removeSheet(sheetId!);
    }
    this.hyperFormulaService.workbook.addSheet(sheetName);
    const sheetId = this.hyperFormulaService.workbook.getSheetId(sheetName);
    if (sheetId === undefined) throw new Error('Sheet not found');
    this.hyperFormulaService.workbook.setSheetContent(sheetId, content);
    this.hyperFormulaService.workbook.renameSheet(
      sheetId,
      `${sheetName}${sheetId}`,
    );
    return sheetId;
  }

  removeSheet(sheetName: string, sheetId?: number) {
    const id = this.getSheetIdOrThrow(sheetId);
    this.hyperFormulaService.workbook.removeSheet(id);
    delete this.sheets[sheetName];
  }

  getRangeValues(
    startCell: Pick<Cell, 'col' | 'row'>,
    endCell: Pick<Cell, 'col' | 'row'>,
    {
      sheetId,
      condition,
    }: { sheetId?: number; condition?: (cell: Cell) => boolean } = {},
  ): Cell[] {
    const id = this.getSheetIdOrThrow(sheetId);
    const values = [];
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const value = HyperFormulaService.workbook.getCellValue({
          sheet: id,
          row,
          col,
        });
        if (condition) {
          if (condition({ col, row, value })) {
            values.push({ col, row, value });
          }
        } else {
          values.push({ col, row, value });
        }
      }
    }
    return values;
  }

  getSheetDimensions(sheetId?: number) {
    const id = this.getSheetIdOrThrow(sheetId);
    return HyperFormulaService.workbook.getSheetDimensions(id);
  }

  findCellValue(
    value: any,
    sheetId?: number,
    caseInsensitive = false,
  ): Cell | null {
    const id = this.getSheetIdOrThrow(sheetId);
    const dimensions = this.hyperFormulaService.workbook.getSheetDimensions(id);
    for (let row = 0; row < dimensions.height; row++) {
      for (let col = 0; col < dimensions.width; col++) {
        const cellValue = this.hyperFormulaService.workbook.getCellValue({
          sheet: id,
          row,
          col,
        });
        // eslint-disable-next-line eqeqeq
        if (
          caseInsensitive &&
          typeof cellValue === 'string' &&
          typeof value === 'string'
        ) {
          if (cellValue.toLowerCase() === value.toLowerCase()) {
            return { row, col, value: cellValue };
          }
        } else {
          if (cellValue === value) {
            return { row, col, value: cellValue };
          }
        }
      }
    }
    return null;
  }

  findCellValueByCondition(
    valueOrCondition: (cell: Cell) => boolean,
    sheetId?: number,
  ): Cell | null {
    const id = this.getSheetIdOrThrow(sheetId);
    const dimensions = this.hyperFormulaService.workbook.getSheetDimensions(id);
    for (let row = 0; row < dimensions.height; row++) {
      for (let col = 0; col < dimensions.width; col++) {
        const cellValue = this.hyperFormulaService.workbook.getCellValue({
          sheet: id,
          row,
          col,
        });
        if (valueOrCondition({ col, row, value: cellValue })) {
          return { col, row, value: cellValue };
        }
      }
    }
    return null;
  }

  findCellValueOrThrowError(value: any, sheetId?: number): Cell {
    const id = this.getSheetIdOrThrow(sheetId);
    const dimensions = this.hyperFormulaService.workbook.getSheetDimensions(id);
    for (let row = 0; row < dimensions.height; row++) {
      for (let col = 0; col < dimensions.width; col++) {
        const cellValue = this.hyperFormulaService.workbook.getCellValue({
          sheet: id,
          row,
          col,
        });
        if (cellValue === value) {
          return { row, col, value: cellValue };
        }
      }
    }
    throw new Error(
      `The value "${value}" does not exist in: ${this.getDefaultSheet()?.name}`,
    );
  }

  findCellValues(
    valueOrCondition: any | ((cell: Cell) => boolean),
    sheetId?: number,
  ): Cell[] {
    const id = this.getSheetIdOrThrow(sheetId);
    const dimensions = this.hyperFormulaService.workbook.getSheetDimensions(id);
    const matchingCells: Cell[] = [];
    for (let row = 0; row < dimensions.height; row++) {
      for (let col = 0; col < dimensions.width; col++) {
        const cellValue = this.hyperFormulaService.workbook.getCellValue({
          sheet: id,
          row,
          col,
        });
        if (typeof valueOrCondition === 'function') {
          if (valueOrCondition(cellValue)) {
            matchingCells.push({ col, row, value: cellValue });
          }
        } else if (cellValue === valueOrCondition) {
          matchingCells.push({ col, row, value: cellValue });
        }
      }
    }
    return matchingCells;
  }

  getCellValue({ col, row }: Partial<Cell>, sheetId?: number) {
    const id = this.getSheetIdOrThrow(sheetId);
    if (col === undefined) throw new Error('Col not found');
    if (row === undefined) throw new Error('Row not found');
    return this.hyperFormulaService.workbook.getCellValue({
      col,
      row,
      sheet: id,
    });
  }

  dispose() {
    Object.keys(this.sheets).forEach((sheet) => {
      const sheetId = this.sheets[sheet].id;
      if (sheetId !== undefined) {
        this.hyperFormulaService.workbook.removeSheet(sheetId);
      }
    });
  }
}
