import { ProcessableInputReport } from '../ProcessableInputReport';
import { Cell } from '../ProcessableReport';
import * as Joi from 'joi';

export const validateHeadersBySchema = (
  headers: string[],
  schemaRecord: Record<string, Joi.Schema>,
  processableInput: ProcessableInputReport,
  rowLimit: number,
) => {
  headers.forEach((header) => {
    const schema = schemaRecord[header];

    const cellValue = processableInput.findCellValue(header);
    if (!cellValue) {
      throw new Error(
        `Column "${header}" is missing in ${processableInput.sheetName}`,
      );
    }

    processableInput.getRangeValues(
      { col: cellValue.col, row: cellValue.row + 1 },
      {
        col: cellValue.col,
        row: rowLimit - 1,
      },
      {
        condition: (cell: Cell) => {
          const validationResult = schema.validate(cell.value);
          if (validationResult.error) {
            throw new Error(
              `Validation error: An incorrect value was found under the header "${header}" and the value is "${cell.value}": ${validationResult.error.message}`,
            );
          }
          return true;
        },
      },
    );
  });
};

export const validateEitherHeadersBySchema = (
  headers: string[][],
  schemaRecord: Record<string, Joi.Schema>,
  processableInput: ProcessableInputReport,
  rowLimit: number,
) => {
  headers.forEach((headerOptions) => {
    const headerFound = headerOptions.find((header) =>
      processableInput.findCellValue(header, undefined, true),
    );

    if (!headerFound) {
      throw new Error(
        `None of the possible headers "${headerOptions.join(
          '", "',
        )}" are present in ${processableInput.sheetName}`,
      );
    }

    const schema = schemaRecord[headerOptions[0]];
    const cellValue = processableInput.findCellValue(
      headerFound,
      undefined,
      true,
    );

    if (!cellValue) {
      throw new Error(
        `Column "${headerFound}" is missing in ${processableInput.sheetName}`,
      );
    }

    processableInput.getRangeValues(
      { col: cellValue.col, row: cellValue.row + 1 },
      {
        col: cellValue.col,
        row: rowLimit - 1,
      },
      {
        condition: (cell: Cell) => {
          const validationResult = schema.validate(cell.value);
          if (validationResult.error) {
            throw new Error(
              `Validation error: An incorrect value was found under the header "${headerFound}" and the value is "${cell.value}": ${validationResult.error.message}`,
            );
          }
          return true;
        },
      },
    );
  });
};

export const validateTotalHeadersBySchema = (
  headers: string[],
  schemaRecord: Record<string, Joi.Schema>,
  processableInput: ProcessableInputReport,
  rowPosition: number,
) => {
  headers.forEach((header) => {
    const schema = schemaRecord[header];

    const cellValue = processableInput.findCellValue(header);
    if (!cellValue) {
      throw new Error(
        `Column "${header}" is missing in ${processableInput.sheetName}`,
      );
    }

    const value = processableInput.getCellValue({
      col: cellValue.col,
      row: rowPosition,
    });

    const validationResult = schema.validate(value);

    if (validationResult.error) {
      throw new Error(
        `An incorrect value was found in the 'Total' row ${rowPosition} under the header "${header}" and the value is "${value}": ${validationResult.error.message}`,
      );
    }
  });
};
