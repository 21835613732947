import { ProcessableInputReport } from '../../ProcessableInputReport';
import { Cell } from '../../ProcessableReport';
import {
  headerMapping,
  IrsCurrentNonCurrentColHeaders,
  IrsCurrentNonCurrentRowHeaders,
} from './irsCurrentNonCurrentHeaders';
import { IrsCurrentNonCurrentInputs } from './irsCurrentNonCurrentInputs';

export class IrsCurrentNonCurrentReportValidator {
  colHeaders: IrsCurrentNonCurrentColHeaders[] = Object.values(
    IrsCurrentNonCurrentColHeaders,
  );
  rowHeaders: IrsCurrentNonCurrentRowHeaders[] = Object.values(
    IrsCurrentNonCurrentRowHeaders,
  );
  constructor(private processableInput: ProcessableInputReport) {}

  validate() {
    if (
      this.processableInput.sheetName !==
      IrsCurrentNonCurrentInputs.IRSCurrentNonCurrent
    ) {
      throw new Error(
        `Input report type ${IrsCurrentNonCurrentInputs.IRSCurrentNonCurrent} is wrong type for this output`,
      );
    }
    let firstColumnValue: Cell | null = null;
    let lastColumnValue: Cell | null = null;
    let totalRowValue: Cell | null = null;

    this.colHeaders.forEach((header) => {
      const cellValue = this.processableInput.findCellValue(
        headerMapping(header),
      );
      if (!cellValue) {
        throw new Error(`Column ${headerMapping(header)} is missing`);
      }
      if (firstColumnValue === null) {
        firstColumnValue = cellValue;
      }
      lastColumnValue = cellValue;
    });

    this.rowHeaders.forEach((header) => {
      const cellValue = this.processableInput.findCellValue(
        headerMapping(header),
      );
      if (!cellValue) {
        throw new Error(`Row ${headerMapping(header)} is missing`);
      }
      if (header === IrsCurrentNonCurrentRowHeaders.Total) {
        totalRowValue = cellValue;
      }
    });

    if (
      firstColumnValue === null ||
      lastColumnValue === null ||
      totalRowValue === null
    ) {
      throw new Error('Unable to determine the necessary range for validation');
    }

    const values = this.processableInput.getRangeValues(
      firstColumnValue,
      {
        col: (lastColumnValue as Cell).col,
        row: (totalRowValue as Cell).row - 1,
      },
      { condition: (cell: Cell) => cell.value === null },
    );

    if (values.length > 0) {
      throw new Error('There is at least one empty cell in the report');
    }
  }
}
