import { Link } from 'react-router-dom';

const baseStyles = {
  solid:
    'inline-flex justify-center rounded-lg py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors',
  outline:
    'inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors',
  underline: 'text-sm font-semibold ',
};

const variantStyles = {
  solid: {
    cyan: 'relative overflow-hidden bg-primary-cyan-500 text-primary-dark-500 before:absolute before:inset-0 active:before:bg-transparent hover:before:bg-white/10 active:bg-primary-cyan-600 active:text-white/80 before:transition-colors',
    purple:
      'relative overflow-hidden bg-secondary-purple-500 text-white before:absolute before:inset-0 active:before:bg-transparent hover:before:bg-white/10 active:bg-secondary-purple-600 active:text-white/80 before:transition-colors',

    white:
      'bg-white text-cyan-900 hover:bg-white/90 active:bg-white/90 active:text-cyan-900/70',
    gray: 'bg-gray-800 text-white hover:bg-gray-900 active:bg-gray-800 active:text-white/80',
  },
  outline: {
    gray: 'border-gray-300 text-gray-700 enabled:hover:border-gray-400 enabled:active:bg-gray-100 enabled:active:text-gray-700/80',
    cyan: 'relative overflow-hidden bg-white text-primary-dark-500 before:absolute before:inset-0 active:before:bg-transparent hover:before:bg-white/10 active:bg-primary-cyan-600 active:text-white/80 before:transition-colors border border-primary-cyan-500 hover:border-white/10 hover:bg-primary-cyan-500',
  },
  underline: {
    black:
      'underline underline-offset-4 hover:text-black/90 active:text-black/80',
  },
};

type VariantKey = keyof typeof variantStyles;
type ColorKey<Variant extends VariantKey> =
  keyof (typeof variantStyles)[Variant];

type ButtonProps<
  Variant extends VariantKey,
  Color extends ColorKey<Variant>,
> = {
  variant?: Variant;
  color?: Color;
} & (
  | Omit<React.ComponentPropsWithoutRef<typeof Link>, 'color'>
  | (Omit<React.ComponentPropsWithoutRef<'button'>, 'color'> & {
      to?: undefined;
    })
);

export const Button = <
  Color extends ColorKey<Variant>,
  Variant extends VariantKey = 'solid',
>({
  variant,
  color,
  className,
  ...props
}: ButtonProps<Variant, Color>) => {
  variant = variant ?? ('solid' as Variant);
  color = color ?? ('gray' as Color);

  className =
    `${baseStyles[variant]} ${variantStyles[variant][color]} ${className}`.trim();

  return typeof props.to === 'undefined' ? (
    <button className={className} {...props} />
  ) : (
    <Link className={className} {...props} />
  );
};
