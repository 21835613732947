import { createBrowserRouter } from 'react-router-dom';
import { PublicLayout } from './components/PublicLayout';
import { AdminLayout } from './pages/dashboard/admin/AdminLayout';
import { AdminHomeDashboard } from './pages/dashboard/admin/dashboard-home/AdminHomeDashboard';
import { OrganizationMenu } from './pages/dashboard/admin/organization-menu/OrganizationMenu';
import { OrganizationMonthlyReport } from './pages/dashboard/admin/organization-monthly-report/OrganizationMonthlyReport';
import { OrganizationDashboardProfile } from './pages/dashboard/admin/organization-profile/OrganizationDashboardProfile';
import { OrganizationReportingPeriods } from './pages/dashboard/admin/organization-reporting-periods/OrganizationReportingPeriods';
import { OrganizationProfileViewLayout } from './pages/dashboard/admin/OrganizationProfileViewLayout';
import { UserDashboard } from './pages/dashboard/admin/users/UserDashboard';
import { UserDashboardHome } from './pages/dashboard/user/dashboard-home/UserDashboardHome';
import { UserMonthlyReport } from './pages/dashboard/user/monthly-report/UserMonthlyReport';
import { UserDashboardProfile } from './pages/dashboard/user/profile/UserDashboardProfile';
import { UserReportingPeriods } from './pages/dashboard/user/reporting-periods/UserReportingPeriods';
import { UserDashboardLayout } from './pages/dashboard/user/UserDashboardLayout';
import { ViewOutputReport } from './pages/dashboard/view/output/ViewOutputReport';
import { Login } from './pages/login/Login';
import { PasswordRecovery } from './pages/password-recovery/PasswordRecovery';
import { Signup } from './pages/signup/Signup';

import { BubbleError } from './components/BubbleError';
import { NotFound } from './components/NotFound';
import { FilterProvider } from './context/FilterProvider';
import { OrganizationTrades } from './pages/dashboard/admin/organization-trades/OrganizationTrades';
import { UserOrganizationTrades } from './pages/dashboard/user/trades-dashboard/UserOrganizationTrades';
import { About } from './components/landing/about/About';
import { Faqs } from './components/landing/faqs/Faqs';
import { AdminsPanel } from './pages/dashboard/admin/admins-panel/AdminsPanel';

const viewRoutes = {
  path: 'view',
  children: [
    {
      path: 'output-report/:id',
      element: <ViewOutputReport />,
    },
    {
      path: 'hedge-effectiveness-report/:id',
      element: <ViewOutputReport />,
    },
    {
      path: 'hedge-documentation-form/:id',
      element: <ViewOutputReport />,
    },
    {
      path: 'irs-mtm-journal/:id',
      element: <ViewOutputReport />,
    },
    {
      path: 'fx-hedge-documentation-form/:id',
      element: <ViewOutputReport />,
    },
  ],
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <PublicLayout />,
    errorElement: <BubbleError />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
      {
        path: '/faqs',
        element: <Faqs />,
      },
      {
        path: '/about',
        element: <About />,
      },
      {
        path: '/signup',
        element: <Signup />,
      },
      {
        path: '/password-recovery',
        element: <PasswordRecovery />,
      },
    ],
  },
  {
    path: '/admin',
    element: <AdminLayout />,
    children: [
      {
        path: 'home',
        element: <AdminHomeDashboard />,
      },
      {
        path: 'users',
        element: <UserDashboard />,
      },
      {
        path: 'manage-admins',
        element: <AdminsPanel />,
      },
      { ...viewRoutes },
    ],
  },
  {
    path: '/user',
    element: <UserDashboardLayout />,
    children: [
      {
        path: 'home',
        element: <UserDashboardHome />,
      },
      {
        path: 'reporting-periods',
        element: <UserReportingPeriods />,
        children: [
          {
            path: 'reports-by-month/:year/:month/Reviewed',
            element: <UserMonthlyReport />,
          },
        ],
      },
      {
        path: 'profile',
        element: <UserDashboardProfile />,
      },
      {
        path: 'trades',
        element: <UserOrganizationTrades />,
      },
      { ...viewRoutes },
    ],
  },
  {
    path: '/organization',
    element: <OrganizationProfileViewLayout />,
    children: [
      {
        path: ':id/reporting-periods/',
        element: <OrganizationReportingPeriods />,
        children: [
          {
            path: 'reports-by-month/:year/:month/:status',
            element: (
              <FilterProvider>
                <OrganizationMonthlyReport />
              </FilterProvider>
            ),
          },
        ],
      },
      {
        path: 'menu/:id',
        element: <OrganizationMenu />,
      },
      {
        path: 'profile/:id',
        element: <OrganizationDashboardProfile />,
      },
      { path: ':id/trades', element: <OrganizationTrades /> },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);
