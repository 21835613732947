import { useLocation, useParams } from 'react-router-dom';
import { ApiQueryKeys } from '../../../../services/hedgehog/api-query-keys.enum';
import { useQuery } from 'react-query';
import {
  getReportById,
  getReportFileById,
} from '../../../../services/hedgehog/hedgehog.api';
import { SimpleLoading } from '../../../../components/loading/SimpleLoading';
import { SimpleAlert } from '../../../../components/alerts/SimpleAlert';
import { MtmValuationTable } from '../../../../components/tables/mtm-valuation-table/MtmValuationTable';
import { UNEXPECTED_ERROR_MESSAGE } from '../../../../error/errorMessages.constants';
import { OutputReportTypes } from '../../../../services/hedgehog/types/OutputReportTypes';
import { CurrentNonCurrentTable } from '../../../../components/tables/current-non-current/CurrentNonCurrentTable';
import { ViewIrsMtmJournalTable } from '../../../../components/tables/irs-mtm-journal/ViewIrsMtmJournalTable';
import { ViewHedgeDocumentationFormTable } from '../../../../components/tables/hedge-documentation-form/ViewHedgeDocumentationFormTable';
import { ViewTHedgeEffectivenessReportTable } from '../../../../components/tables/hedge-effectiveness-report/ViewHedgeEffectivenessReportTable';
import { GoBackButton } from '../../../../components/GoBackButton';
import { ViewFxMtmValuationReportTable } from '../../../../components/tables/fx-mtm-valuation/ViewFxMtmValuationReportTable';
import { ViewFxMaturedDealsReportTable } from '../../../../components/tables/fx-matured-deals-report/ViewFxMaturedDealsReportTable';
import { ViewFxHedgeEffectivenessReportTable } from '../../../../components/tables/fx-hedge-effectiveness/ViewFxHedgeEffectivenessReportTable';
import { ViewFxMtmJournalReportTable } from '../../../../components/tables/fx-mtm-journal/ViewFxMtmJournalReportTable';
import { ViewFxHedgeDocumentationFormTable } from '../../../../components/tables/fx-hedge-documentation-form/ViewFxHedgeDocumentationForm';
import { IReport } from '../../../../services/hedgehog/interfaces/IReportByMonth';
import './tableStyles.css';

export const ViewOutputReport = () => {
  const { id } = useParams();

  const location = useLocation();
  const match = location.pathname.match(/\/view\/([^/]+)\/(\d+)/);

  const localization = match ? match[1] : '';

  const {
    data: reportInfo,
    isLoading: isLoadingReportInfo,
    error: errorReportInfo,
  } = useQuery<IReport>([ApiQueryKeys.output_report_by_id, id], () =>
    getReportById(localization, Number(id)),
  );

  const {
    data: reportFile,
    isLoading: isLoadingReportFile,
    error: errorReportFile,
  } = useQuery<Blob>([ApiQueryKeys.output_report_file_by_id, id], () =>
    getReportFileById(localization, Number(id)),
  );

  const isLoading = isLoadingReportInfo || isLoadingReportFile;
  const error = errorReportInfo || errorReportFile;

  if (isLoading)
    return (
      <SimpleLoading
        loadingColor="text-primary-cyan-500"
        loadingSize="h-10 w-10"
      />
    );

  if (error) {
    if (error instanceof Error) {
      return <SimpleAlert alertMessage={error.message} alertType="danger" />;
    } else {
      return (
        <SimpleAlert
          alertMessage={UNEXPECTED_ERROR_MESSAGE}
          alertType="danger"
        />
      );
    }
  }

  const reportComponentMap: Record<
    OutputReportTypes,
    ((props: any) => JSX.Element) | null
  > = {
    [OutputReportTypes.IRS_CURRENT_NON_CURRENT_REPORT]: CurrentNonCurrentTable,
    [OutputReportTypes.IRS_MTM_VALUATION_REPORT]: MtmValuationTable,
    [OutputReportTypes.FX_MTM_VALUATION]: ViewFxMtmValuationReportTable,
    [OutputReportTypes.RAW_REPORT]: null,
    [OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM]:
      ViewHedgeDocumentationFormTable,
    [OutputReportTypes.IRS_HEDGE_EFFECTIVENESS_REPORT]:
      ViewTHedgeEffectivenessReportTable,
    [OutputReportTypes.IRS_MTM_JOURNAL]: ViewIrsMtmJournalTable,
    [OutputReportTypes.FX_MATURED_DEALS_REPORT]: ViewFxMaturedDealsReportTable,
    [OutputReportTypes.FX_HEDGE_EFFECTIVENESS_REPORT]:
      ViewFxHedgeEffectivenessReportTable,
    [OutputReportTypes.FX_MTM_JOURNAL_REPORT]: ViewFxMtmJournalReportTable,
    [OutputReportTypes.FX_HEDGE_DOCUMENTATION_FORM]:
      ViewFxHedgeDocumentationFormTable,
  };

  const reportType = reportInfo?.type as OutputReportTypes;

  const TableComponent = reportType && reportComponentMap[reportType];
  return (
    <div>
      <div className="flex flex-col h-full w-full">
        <div className="relative z-0 overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
          <div className="flex flex-wrap h-full w-full p-4 gap-2 bg-white">
            <div className="flex w-full gap-2 bg-white items-center">
              <div className="flex items-center justify-start w-[5%]">
                <GoBackButton buttonStyles="hover:bg-gray-300 rounded-md p-2 transition ease-in duration-150 text-gray-600 hover:text-gray-900" />
              </div>
              <h3
                className="text-2xl font-bold flex-grow text-center"
                data-cy="output-report-type"
              >
                {reportInfo?.type}
              </h3>

              <div className="w-[5%]"></div>
            </div>
          </div>
          <div className="border-t border-gray-100" />
          <div className="h-full w-full p-4 gap-2 bg-white">
            {TableComponent ? (
              <TableComponent report={reportFile} reportInfo={reportInfo} />
            ) : (
              <SimpleAlert
                alertMessage="Not exist table for this type of report"
                alertType="warning"
                alertStyles="w-full"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
