import StartToastifyInstance, { Options } from 'toastify-js';
export class Toast {
  static success(message: string) {
    StartToastifyInstance({
      text: message,
      duration: 3000,
      close: true,
    }).showToast();
  }

  static error(message: string) {
    StartToastifyInstance({
      text: message,
      duration: 3000,
      close: true,
      style: {
        background: 'red',
      },
    }).showToast();
  }
  static custom(options: Options) {
    StartToastifyInstance(options).showToast();
  }
}
