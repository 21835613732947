import { RawCellContent } from 'hyperformula';
import { ProcessableReport } from './ProcessableReport';

interface IProcessableInputReport {
  sheet: RawCellContent[][];
  sheetName: string;
}

export class ProcessableInputReport extends ProcessableReport {
  sheetName = '';
  constructor({ sheet, sheetName }: IProcessableInputReport) {
    super();
    this.buildFromSheets({ [sheetName]: sheet });
    this.sheetName = sheetName;
  }
}
