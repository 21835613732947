export enum InputReportType {
  MIGRATION_REPORT = 'Migration Report',
  IRS_CURRENT_NON_CURRENT_REPORT = 'IRS_Current_Non-Current',
  HB_FX_MATURED_DEALS_REPORT = 'HB - FX Matured Deals Report',
  HB_FX_DETAILED_INSTRUMENT_REPORT = 'HB - FX Detailed Instrument Report',
  HB_FX_FORWARDS_HELD_REPORT = 'HB - FX Forwards Held Report',
  HEDGEHOG_IRS_MTM_VALUATION_REPORT = 'Hedgehog - IRS MTM Valuation Report',
  HEDGEHOG_IRS_CURRENT_NON_CURRENT_REPORT = 'Hedgehog - IRS Current/Non-Current Report',
  MTM_HB_CVA_REPORT = 'HB - CVA Report',
  MTM_HB_DETAILED_INSTRUMENT_REPORT = 'HB - Detailed Instrument Report',
  MTM_HB_IRS_POSITION_REPORT = 'HB - IRS Position Report',
}
