import { ProcessableInputReport } from '../../ProcessableInputReport';
import {
  MigrationReportHeaders,
  MigrationReportOptionalHeaders,
} from './migrationReportHeaders';

export class MigrationReportValidator {
  private requiredHeaders = Object.values(MigrationReportHeaders);

  private requiredHeaderData = [
    MigrationReportHeaders.TradeReference,
    MigrationReportHeaders.HedgeType,
    MigrationReportHeaders.DesignationDate,
    MigrationReportHeaders.RiskClass,
    MigrationReportHeaders.AnalysisFrequency,
    MigrationReportHeaders.AnalysisPeriod,
    MigrationReportHeaders.Tenor,
    MigrationReportHeaders.BetaRange,
    MigrationReportHeaders.ReportingCcy,
    MigrationReportHeaders.TestType,
    MigrationReportHeaders.FairValueChanges,
    MigrationReportHeaders.LastReportDate,
  ];

  constructor(private processableInput: ProcessableInputReport) {}

  validate() {
    this.validateHeadersPresence();
    this.validateHeaderOccurrence(MigrationReportHeaders.CleanFairValue, 2);
    this.validateHeaderOccurrence(MigrationReportHeaders.ReportingDate, 1);
    this.validateHeaderOccurrence(MigrationReportHeaders.PeriodicChg, 2);
    this.validateHeaderOccurrence(MigrationReportHeaders.CumlChg, 2);
  }

  private validateHeaderPresence(header: string) {
    try {
      this.processableInput.findCellValueOrThrowError(header);
    } catch (e) {
      throw new Error(`Missing ${header} header`);
    }
  }

  private validateHeadersPresence() {
    this.requiredHeaders.forEach((header) => {
      switch (header) {
        case MigrationReportHeaders.TradeReference:
          this.tradeReferenceCheck();
          break;
        case MigrationReportHeaders.ReportingDate:
          this.validateReportingDate();
          break;
        case MigrationReportHeaders.HighlyEffective:
          this.validateHighlyEffective();
          break;
        default:
          this.validateHeaderPresence(header);
          this.validateDataHeaderPresence(header);
      }
    });
  }

  private validateDataHeaderPresence(header: MigrationReportHeaders) {
    const headerCell = this.processableInput.findCellValueOrThrowError(header);
    if (this.requiredHeaderData.includes(header)) {
      const cellData = this.processableInput.getCellValue({
        ...headerCell,
        col: headerCell.col + 1,
      });
      if (!cellData) {
        throw new Error(`Missing data in ${header} header`);
      }
    }
  }

  private validateHeaderOccurrence(header: string, expectedCount: number) {
    if (this.processableInput.findCellValues(header).length !== expectedCount) {
      throw new Error(`Missing ${header} header`);
    }
  }

  private validateReportingDate(): string {
    const pattern1 = /Reporting Date: (\d{2}-[a-zA-Z]+-\d{4})/;
    const pattern2 = /Hedge Effectiveness Report: \((\d{2}\/\d{2}\/\d{4})\)/;

    const reportingDateCell = this.processableInput.findCellValues(
      (value: any) => pattern1.test(value) || pattern2.test(value),
    );

    if (reportingDateCell.length > 0) {
      const match1: RegExpMatchArray | null =
        reportingDateCell[0].value.match(pattern1);
      const match2: RegExpMatchArray | null =
        reportingDateCell[0].value.match(pattern2);

      if (match1?.[1]) {
        return match1[1];
      } else if (match2?.[1]) {
        return match2[1];
      }
    }
    throw new Error('Missing Reporting Date');
  }

  private tradeReferenceCheck() {
    let referenceHeader = this.processableInput.findCellValue(
      MigrationReportHeaders.TradeReference,
    );
    if (!referenceHeader) {
      referenceHeader = this.processableInput.findCellValue(
        MigrationReportOptionalHeaders.Reference,
        this.processableInput.sheets['IR Swap Position Report'].id,
      );
      if (!referenceHeader) {
        throw new Error('Missing Trade Reference');
      }
      const reference = this.processableInput.getCellValue(
        {
          ...referenceHeader,
          row: referenceHeader.row + 1,
        },
        this.processableInput.sheets['IR Swap Position Report'].id,
      ) as string;
      if (!reference) {
        throw new Error('Missing Trade Reference');
      }
    }
  }

  private validateHighlyEffective() {
    const headerCell = this.processableInput.findCellValueByCondition(
      (cell) =>
        cell.value === MigrationReportHeaders.HighlyEffective ||
        cell.value === MigrationReportOptionalHeaders.Efctv,
    );
    if (!headerCell) {
      throw new Error('Missing Highly Effective header');
    }
    const cellValue = this.processableInput.getCellValue({
      ...headerCell,
      row: headerCell.row + 1,
    }) as string;
    if (!cellValue) {
      throw new Error('Missing data in Highly Effective header');
    }
  }
}
