import { useQueryClient } from 'react-query';
import { formatDate } from '../../../../components/tables/common/utils';
import { useConfirm } from '../../../../hooks/useConfirm';
import { ApiQueryKeys } from '../../../../services/hedgehog/api-query-keys.enum';
import { IUser } from '../../../../services/hedgehog/interfaces/IUser';
import { deleteUser } from '../../../../services/hedgehog/user.api';
import { Toast } from '../../../../services/toastify/Toastify';

type Props = {
  users: IUser[];
};

const columnNames: string[] = [
  'id',
  'full name',
  'email',
  'organization',
  'created at',
  'actions',
];
export const UserTable = ({ users }: Props) => {
  const { confirmAction } = useConfirm();
  const queryClient = useQueryClient();

  const handleDeleteUser = (user: IUser) => {
    const action = async () => {
      await deleteUser(user);
      Toast.success('User deleted successfully');
      queryClient.refetchQueries(ApiQueryKeys.users);
    };
    confirmAction(
      action,
      `The user will be deleted from the platform. Are you sure you want to delete: ${user.name} ${user.lastName}?`,
      'Delete user',
    );
  };

  return (
    <table className="w-full text-sm text-left text-gray-500">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
        <tr className="bg-white border-b hover:bg-gray-50">
          {columnNames.map((key) => (
            <th key={key} className="px-6 py-4 space-x-2">
              {key}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <tr
            key={user.id}
            className="bg-white border-b hover:bg-gray-50 text-gray-700"
          >
            <td className="px-6 py-4 space-x-2">{user.id}</td>
            <td className="px-6 py-4">
              <div>
                <span>{`${user.name} ${user.lastName}`}</span>
              </div>
              <div className="text-gray-500 capitalize">
                <span className="text-sm">{user.role}</span>
              </div>
            </td>
            <td className="px-6 py-4 space-x-2">{user.email}</td>
            <td className="px-6 py-4 space-x-2">
              {user.asPeople.length > 0
                ? user.asPeople
                    .map((person) => person.organization.name)
                    .join(' - ')
                : ''}
            </td>
            <td className="px-6 py-4 space-x-2">
              {formatDate(user.createdAt)}
            </td>
            <td className="px-6 py-4 space-x-2">
              <button
                data-cy={`delete-${user.name}-${user.id}`}
                className="text-blue-500 hover:text-blue-700"
                onClick={() => handleDeleteUser(user)}
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
