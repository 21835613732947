import { HotTable } from '@handsontable/react';
import { useModal } from '../../../hooks/useModal';
import { Button } from '../../Button';
import { SimpleLoading } from '../../loading/SimpleLoading';
import { Modal } from '../Modal';
import {
  fxAnalysisReportColumnConfig,
  getFxAnalysisReportCells,
  getFxAnalysisReportMergeCellsConfig,
} from './fxAnalysisReportConfig';
import { useFxAnalysisReport } from './hooks/useFxAnalysisReport';
import { downloadReport } from './helpers';
import { useEffect } from 'react';

interface IFxAnalysisReportModal {
  organizationId: number;
}

export const FX_ANALYSIS_REPORT_NAME = 'FX Analysis Report';

export const FxAnalysisReportModal = ({
  organizationId,
}: IFxAnalysisReportModal) => {
  const { sheet, isLoading, isError } = useFxAnalysisReport(organizationId);
  const { closeModal } = useModal();

  useEffect(() => {
    if (isError) {
      closeModal('fx-analysis-report');
    }
  }, [isError, closeModal]);

  return (
    <>
      <Modal closeOnOutClick close={() => closeModal('fx-analysis-report')}>
        {isLoading && <SimpleLoading />}
        {sheet.length > 1 && (
          <div
            data-cy="fx-analysis-report"
            className="bg-white max-w-[95%] p-6 border border-gray-200 shadow-md rounded-lg"
          >
            <Button
              data-cy="download-report-button"
              onClick={() => downloadReport(sheet)}
              className="mb-4"
            >
              Download Report
            </Button>
            <HotTable
              readOnly={true}
              preventOverflow
              data={sheet}
              wordWrap={true}
              columns={fxAnalysisReportColumnConfig}
              mergeCells={getFxAnalysisReportMergeCellsConfig(sheet)}
              cells={(col) => getFxAnalysisReportCells(col)}
              licenseKey="non-commercial-and-evaluation"
            ></HotTable>
          </div>
        )}
      </Modal>
    </>
  );
};
