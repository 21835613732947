import * as Joi from 'joi';
import { irsMtmValuationInputHeaderTypes } from '../cvaReport/irsMtmValuationReportInputHeaders';

export const irsPositionSchemas: Record<string, Joi.Schema> = {
  [irsMtmValuationInputHeaderTypes.fixedRate[0]]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Fixed Rate must be a valid number and cannot be null or empty',
      'any.required': 'Fixed Rate must be provided',
    }),
  [irsMtmValuationInputHeaderTypes.notionalAmount[0]]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Notional Amount must be a valid number and cannot be null or empty',
      'any.required': 'Notional Amount must be provided',
    }),
  [irsMtmValuationInputHeaderTypes.floatingRate[0]]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Floating Rate must be a valid number and cannot be null or empty',
      'any.required': 'Floating Rate must be provided',
    }),
  [irsMtmValuationInputHeaderTypes.nextResetDate[0]]: Joi.date()
    .required()
    .messages({
      'date.base':
        'Next Reset Date must be a valid date and cannot be null or empty',
      'any.required': 'Next Reset Date must be provided',
    }),
  [irsMtmValuationInputHeaderTypes.lastResetDate[0]]: Joi.date()
    .required()
    .messages({
      'date.base':
        'Last Reset Date must be a valid date and cannot be null or empty',
      'any.required': 'Last Reset Date must be provided',
    }),
  [irsMtmValuationInputHeaderTypes.counterparty[0]]: Joi.string()
    .required()
    .messages({
      'string.base':
        'Counterparty must be a valid string and cannot be null or empty',
      'any.required': 'Counterparty must be provided',
    }),
  [irsMtmValuationInputHeaderTypes.valuation[0]]: Joi.number()
    .required()
    .messages({
      'number.base':
        'Valuation must be a valid number and cannot be null or empty',
      'any.required': 'Valuation must be provided',
    }),
};
