import { useNavigate } from 'react-router-dom';
import { logout } from '../services/hedgehog/user.api';
import { useUser } from './useUser';

export const useLogout = (redirectLink = '/') => {
  const navigate = useNavigate();

  const { setUserInfo } = useUser();

  const handleLogout = () => {
    logout();
    setUserInfo(undefined);
    navigate(redirectLink);
  };
  return {
    handleLogout,
  };
};
