import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../Button';
import { useUser } from '../../hooks/useUser';
import { logout } from '../../services/hedgehog/user.api';
import { UserSideBarMenuOptions } from '../sidebar/UserSideBarMenuOptions';
import { UserRole } from '../../services/hedgehog/enum/UserRole.enum';
import { AdminSideBarMenuOptions } from '../sidebar/admin/AdminSideBarMenuOptions';

interface INavMobileMenuProps {
  link?: string[][];
  menuRef: React.RefObject<HTMLDivElement>;
  userRole?: UserRole;
  onClickOutside: () => void;
}

export const NavMobileMenu = ({
  link,
  menuRef,
  userRole,
  onClickOutside,
}: INavMobileMenuProps) => {
  const { user, setUserInfo } = useUser();

  const handleLogout = () => {
    logout();
    setUserInfo(undefined);
    navigate('/');
  };

  const navigate = useNavigate();

  const { pathname } = useLocation();
  const isHomeRoute =
    pathname.includes('/user') || pathname.includes('/organization');

  const handleNavigate = (href: string) => {
    onClickOutside();
    navigate(href);
  };

  return (
    <>
      <div className="absolute inset-0 z-10">
        <div
          ref={menuRef}
          className="absolute inset-x-0 top-0 z-10 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-20 shadow-2xl shadow-gray-900/20"
        >
          <div className="space-y-2 mt-6">
            {isHomeRoute && (
              <>
                {userRole === UserRole.Admin ? (
                  <div className="flex flex-col justify-start items-center gap-4">
                    <AdminSideBarMenuOptions onClickOutside={onClickOutside} />
                  </div>
                ) : (
                  <UserSideBarMenuOptions onClickOutside={onClickOutside} />
                )}
              </>
            )}
            {link?.map(([label, href]) => (
              <button
                data-cy={`${label}-link`}
                key={label}
                onClick={() => handleNavigate(href)}
                className="w-full block py-2 px-2 text-left text-base tracking-tight text-gray-700 transition-colors hover:text-gray-900 rounded-lg hover:bg-gray-200 ease-in duration-200"
              >
                <span className="relative z-10">{label}</span>
              </button>
            ))}

            <div className="mt-8 flex flex-col gap-4">
              {user && (
                <>
                  <Button
                    onClick={handleLogout}
                    variant="solid"
                    data-cy="mobile-logout-button"
                  >
                    Log out
                  </Button>
                </>
              )}
              {!user && (
                <Button
                  onClick={() => handleNavigate('/')}
                  variant="solid"
                  data-cy="mobile-login-button"
                  color="cyan"
                  className="hover:bg-primary-cyan-600 text-white"
                >
                  Log In
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-300/60 fixed inset-0 z-0 backdrop-blur" />
    </>
  );
};
