import { CellProperties } from 'handsontable/settings';
import { headers, subHeaders } from './FxHedgeDocumentationHeaders';
import { FxHedgeDocumentationFormTemplateOptions as FxHedgeDocFormTemplateOptions } from '../../../services/hedgehog/types/reports/fx-mtm-journal-report/fx-mtm-journal-options';

export const getFxHedgeDocsCellProperties = (
  sheet: any[][],
  row: number,
  col: number,
  template: string,
): Partial<CellProperties> => {
  const cellProperties: Partial<CellProperties> = {};
  const cellValue = sheet[row][col] ? String(sheet[row][col]) : '';
  const isHeaderRow = [2, 4, 6, 8].includes(row) && col === 0;
  const isTitleRow = [0, 1].includes(row) && col === 0;
  const isCheckmark = cellValue === 'x';
  const isSynthetic =
    template === FxHedgeDocFormTemplateOptions.SYNTHETIC_LEGS_TEMPLATE;

  const shouldApplyNoOutline =
    (isSynthetic && col === 0 && [28, 46, 49, 52, 53, 54].includes(row)) ||
    (!isSynthetic && col === 0 && row === 58);

  cellProperties.className +=
    ' default-text-color default-hedge-documentation-cell border';

  if (headers.some((header) => header.test(cellValue)) || isHeaderRow) {
    cellProperties.className += ' hedge-documentation-header';
  }

  if (isCheckmark) {
    cellProperties.className += ' checkmark';
  }

  if (isTitleRow) {
    cellProperties.className += ' title';
  }

  if (
    shouldApplyNoOutline ||
    subHeaders.some((subHeader) => subHeader.test(cellValue))
  ) {
    cellProperties.className += ' no-outline';
  }

  return cellProperties;
};
