import { useEffect } from 'react';
import { useUser } from '../hooks/useUser';
import { router } from '../router';

const AuthTracker = () => {
  const { setUserInfo } = useUser();

  useEffect(() => {
    const cognitoRegex = /CognitoIdentityServiceProvider[^]*?refreshToken/;
    const checkAuthToken = () => {
      const hasCognitoToken = Object.keys(localStorage).some((key) =>
        cognitoRegex.test(key),
      );

      if (!hasCognitoToken) {
        setUserInfo(undefined);
        router.navigate('/');
      }
    };

    window.addEventListener('storage', checkAuthToken);
    return () => {
      window.removeEventListener('storage', checkAuthToken);
    };
  }, []);

  return null;
};

export default AuthTracker;
