import {
  addOrganizationMember,
  getUserByEmail,
} from '../../../services/hedgehog/hedgehog.api';
import { IAddExistingMemberParameters } from '../../../services/hedgehog/types/add-existing-member-parameters';

export const addExistingMemberToOrganization = async (
  values: IAddExistingMemberParameters,
) => {
  const member = await getUserByEmail(values.email);
  await addOrganizationMember(member, values.role, values.organizationId);
};
