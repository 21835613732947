import { UNEXPECTED_ERROR_MESSAGE } from '../error/errorMessages.constants';
import { getReportFileById } from '../services/hedgehog/hedgehog.api';
import { Toast } from '../services/toastify/Toastify';

export const handleDownloadFile = async (
  name: string,
  organizationName: string,
  id: number,
  route: string,
) => {
  try {
    const response = await getReportFileById(route, id);
    const href = window.URL.createObjectURL(response);

    const anchorElement = document.createElement('a');

    anchorElement.href = href;
    anchorElement.download = `${name} - ${organizationName}`;

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  } catch (error) {
    if (error instanceof Error) Toast.error(error.message);
    else Toast.error(UNEXPECTED_ERROR_MESSAGE);
  }
};
