import { useState } from 'react';
import { IReportByMonth } from '../../../services/hedgehog/interfaces/IReportByMonth';

interface UseMonthlyReportPaginationProps {
  reports: IReportByMonth | undefined;
  showDocFormsTable: boolean;
  itemsPerPage: number;
}

export const useMonthlyReportPagination = ({
  reports,
  showDocFormsTable,
  itemsPerPage,
}: UseMonthlyReportPaginationProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const paginatedIrsForms =
    showDocFormsTable && reports
      ? reports.irsHedgeDocumentationForms.slice(
          indexOfFirstItem,
          indexOfLastItem,
        )
      : [];

  const paginatedFxForms =
    showDocFormsTable && reports
      ? reports.fxHedgeDocumentationForms.slice(
          indexOfFirstItem,
          indexOfLastItem,
        )
      : [];

  const paginatedReports =
    !showDocFormsTable && reports
      ? reports.allReports.slice(indexOfFirstItem, indexOfLastItem)
      : [];

  const totalItems = showDocFormsTable
    ? (reports?.irsHedgeDocumentationForms.length || 0) +
      (reports?.fxHedgeDocumentationForms.length || 0)
    : reports?.allReports.length || 0;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return {
    paginatedIrsForms,
    paginatedFxForms,
    paginatedReports,
    setCurrentPage,
    currentPage,
    totalItems,
    totalPages,
    nextPage,
    prevPage,
  };
};
