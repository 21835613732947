import { HedgehogLogo } from '../icons/HedgehogLogo';

import { Link } from 'react-router-dom';
import { UserSideBarMenuOptions } from './UserSideBarMenuOptions';

export interface INavLink {
  label: string;
  href: string;
  icon: JSX.Element;
  extra?: number;
}

export const UserSidebar = () => {
  return (
    <div className="w-0 flex flex-col justify-start items-center md:w-[306px] h-[1106px] relative gap-6 p-0 md:p-6 rounded bg-white border-r">
      <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 w-60 relative gap-2">
        <Link className="flex gap-2 lg:justify-center" to="/">
          <HedgehogLogo
            svgIconStyles="text-black flex-grow-0 flex-shrink-0 w-0 md:w-60 h-[55px] object-contain"
            logoTitleStyles="text-primary-dark fill-current"
            logoIconStyles="text-primary-cyan-500 fill-current"
          />
        </Link>
      </div>

      <UserSideBarMenuOptions />
    </div>
  );
};
