import { CellValue } from 'exceljs';

export const fxMaturedDealsReportMergeCells = (sheet: CellValue[][]) => {
  const currencyPairPattern = /^[A-Z]{3}\/[A-Z]{3} Hedging$/;
  const mergeCellsConfig = [
    {
      row: 1,
      col: 0,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 1,
      col: 2,
      rowspan: 1,
      colspan: sheet[3].length === 12 ? 9 : 10,
    },
    {
      row: 0,
      col: 11,
      rowspan: 1,
      colspan: 2,
    },
    {
      row: 0,
      col: 0,
      rowspan: 1,
      colspan: sheet[3].length === 12 ? 10 : 11,
    },
    {
      row: sheet.length - 2,
      col: 0,
      rowspan: 2,
      colspan: sheet[0].length,
    },
  ];

  sheet.forEach((row, rowIndex) => {
    const cellValue = row[0];
    if (typeof cellValue === 'string' && currencyPairPattern.test(cellValue)) {
      mergeCellsConfig.push({
        row: rowIndex,
        col: 0,
        rowspan: 1,
        colspan: sheet[3].length === 12 ? row.length - 1 : row.length,
      });
    }
  });

  sheet.forEach((row, rowIndex) => {
    row.forEach((cellValue) => {
      if (cellValue === 'TOTAL') {
        const newRowIndex = rowIndex + 1;
        mergeCellsConfig.push({
          row: newRowIndex,
          col: 0,
          rowspan: 1,
          colspan: sheet[0].length,
        });
      }
    });
  });

  return mergeCellsConfig;
};
