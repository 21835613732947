import { Formik, Form } from 'formik';
import { Modal } from '../Modal';
import useLoading from '../../../hooks/useLoading';
import { SimpleLoading } from '../../loading/SimpleLoading';
import { IOrganization } from '../../../services/hedgehog/interfaces/IOrganization';
import { Button } from '../../Button';
import { FormField } from '../../form-field/FormField';
import { v4 as uuidv4 } from 'uuid';
import { ComponentProps, useEffect, useState } from 'react';
import { useModal } from '../../../hooks/useModal';
import { ProfileConfigModal } from '../profile-config-modal/ProfileConfigModal';
import { IOrganizationProfile } from '../../../services/hedgehog/interfaces/IOrganizationProfile';
import { Toast } from '../../../services/toastify/Toastify';
import { OrganizationLogo } from '../../OrganizationLogo';
import { UNEXPECTED_ERROR_MESSAGE } from '../../../error/errorMessages.constants';
import { updateOrganizationLogo } from '../../../services/hedgehog/hedgehog.api';
import { InputFile } from '../../input-file/InputFile';
import { useQuery } from 'react-query';
import { ApiQueryKeys } from '../../../services/hedgehog/api-query-keys.enum';
import { IOrganizationUpdate } from './interface/IOrganizationUpdate';
import { ExposureType } from '../../../services/hedgehog/enum/ExposureType.enum';
import { updateOrganizationValidationSchema } from './updateOrganization.validation.schema';

const organizationUpdateSegment: ComponentProps<typeof FormField>[] = [
  {
    id: 'organization-name',
    name: 'name',
    type: 'string',
    label: 'Organization Name',
    placeholder: 'Organization name',
  },
  {
    id: 'exposure-type',
    name: 'exposureType',
    label: 'Exposure Type',
    placeholder: 'Exposure Type',
    type: 'select',
    options: Object.values(ExposureType),
  },
];

const logoInputFile = {
  name: 'logo',
  label: 'Update organization logo',
};

export const UpdateOrganizationModal = ({
  close,
  onSubmit,
  organization,
}: {
  close: () => void;
  onSubmit: (
    organizationUpdate: IOrganizationUpdate,
    organizationProfile: IOrganizationProfile,
  ) => any;
  organization: IOrganization;
}) => {
  const [isGeneratingReport, waitFor] = useLoading();
  const [profile, setProfile] = useState<IOrganizationProfile | undefined>(
    organization.organizationProfile,
  );
  const [isProfileRequired, setIsProfileRequired] = useState<boolean>(false);
  const [fileError, setFileError] = useState<boolean>(false);
  const [exposureType, setExposureType] = useState<ExposureType>(
    organization.exposureType,
  );

  const { openModal, closeModal } = useModal();

  const handleSubmit = async (organizationUpdate: IOrganizationUpdate) => {
    if (!profile && isProfileRequired) {
      return Toast.error('Load profile first');
    }

    if (profile) {
      onSubmit(organizationUpdate, profile);
    }
  };
  const handleLoadProfile = (e: React.FormEvent) => {
    e.preventDefault();

    openModal(
      'profile-config-modal-update',
      <ProfileConfigModal
        close={() => closeModal('profile-config-modal-update')}
        defaultValues={profile ?? undefined}
        exposureType={exposureType}
        onSubmit={(profile) => {
          setProfile(profile);
          closeModal('profile-config-modal-update');
        }}
      />,
    );
  };

  const handleInitialValues = (): IOrganizationUpdate => {
    const exposureType = organization.exposureType || ExposureType.BOTH;
    return {
      name: organization.name,
      exposureType: exposureType,
    };
  };

  const { refetch } = useQuery([
    ApiQueryKeys.organization_logo_by_id,
    organization.id,
  ]);

  const handleFileChange = async (file: File) => {
    if (!file) {
      setFileError(true);
      return;
    }

    const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    if (!validImageTypes.includes(file.type)) {
      setFileError(true);

      return Toast.error(
        'Invalid file type. Only JPEG, PNG, and JPG are allowed.',
      );
    }

    try {
      await updateOrganizationLogo(organization.id, file);
      setFileError(false);
      refetch();
    } catch (e) {
      if (e instanceof Error) Toast.error(e.message);
      else Toast.error(UNEXPECTED_ERROR_MESSAGE);
      setFileError(true);
    }
  };

  useEffect(() => {
    if (organization.exposureType !== exposureType) {
      setProfile(undefined);
      setIsProfileRequired(true);
    } else {
      setIsProfileRequired(false);
      setProfile(organization.organizationProfile);
    }
  }, [
    exposureType,
    organization.organizationProfile,
    organization.exposureType,
  ]);

  return (
    <>
      {isGeneratingReport && (
        <Modal className="z-[150]">
          <div className="max-h-full overflow-y-auto">
            <div className="flex flex-wrap justify-center items-center relative w-full overflow-x-auto bg-white border border-gray-200 shadow-md rounded-lg">
              <SimpleLoading
                loadingColor="text-primary-cyan-500"
                loadingSize="h-20 w-20"
                externalStyles="flex flex-wrap items-center justify-center p-6"
              />
            </div>
          </div>
        </Modal>
      )}
      <Modal close={close}>
        <div className="max-h-full overflow-y-auto">
          <div className="flex flex-col justify-center items-center relative z-0 overflow-x-auto bg-gray-50 border border-gray-200 shadow-md rounded-lg">
            <Formik
              initialValues={handleInitialValues()}
              validationSchema={updateOrganizationValidationSchema}
              onSubmit={(values) => waitFor(handleSubmit(values))}
              validate={(values) => setExposureType(values.exposureType)}
            >
              <Form className="flex flex-col justify-center items-center md:w-[38rem] h-full p-4 gap-2 ">
                <div>
                  <h2 className="font-bold text-gray-900 text-center text-lg">
                    Update Organization Info
                  </h2>
                </div>
                <div className="flex flex-col md:flex-row w-full gap-4">
                  <div className="flex flex-col gap-2 w-full md:w-3/5 ">
                    <div className="mt-1">
                      {organizationUpdateSegment.map((field) => (
                        <div key={uuidv4()}>
                          <FormField {...field} />
                        </div>
                      ))}
                      <div>
                        <InputFile
                          className={`${fileError && 'border-red-500'}`}
                          name={logoInputFile.name}
                          label={logoInputFile.label}
                          aria-describedby={logoInputFile.name}
                          id={logoInputFile.name}
                          handleLoad={(file) => handleFileChange(file)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center w-full">
                    <div>
                      <h3 className="font-semibold text-center text-sm text-gray-900 pb-0 mb-1">
                        Organization Logo
                      </h3>
                    </div>
                    <div className="flex flex-wrap justify-center max-w-xs border-2 border-gray-200 p-2 shadow-md bg-white rounded-md">
                      <OrganizationLogo
                        organizationId={organization.id}
                        logoSize="none"
                        logoStyles=" "
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <Button
                    type="button"
                    variant="underline"
                    color="black"
                    onClick={close}
                  >
                    Cancel
                  </Button>
                  {!isProfileRequired && (
                    <Button
                      variant="solid"
                      color="cyan"
                      data-cy="save-organization-button"
                      type="submit"
                    >
                      Save
                    </Button>
                  )}

                  {isProfileRequired && (
                    <div className="flex flex-row gap-2">
                      <Button
                        variant="solid"
                        color="cyan"
                        data-cy="save-organization-button"
                        type="submit"
                      >
                        Save
                      </Button>
                      <Button
                        variant="solid"
                        color="cyan"
                        data-cy="load-profile-button"
                        onClick={handleLoadProfile}
                      >
                        {profile ? 'Edit profile' : 'Load profile'}
                      </Button>
                    </div>
                  )}
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  );
};
