import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { MonthlyReport } from '../../../../components/common/monthly-report/MonthlyReport';
import { ApiQueryKeys } from '../../../../services/hedgehog/api-query-keys.enum';
import { getReportByMonthAndYear } from '../../../../services/hedgehog/hedgehog.api';
import { IReportByMonth } from '../../../../services/hedgehog/interfaces/IReportByMonth';
import { SimpleAlert } from '../../../../components/alerts/SimpleAlert';
import { ADMIN_DASHBOARD_ROUTE } from '../../../../components/navbar/Navbar';
import { useFilters } from '../../../../hooks/useFilters';

export const OrganizationMonthlyReport = () => {
  const { year, month, id, status } = useParams();
  const organizationId = Number(id);

  const { filters } = useFilters();
  const {
    data: reports,
    isLoading,
    error,
  } = useQuery<IReportByMonth>(
    [
      ApiQueryKeys.organization_all_reports_by_month_and_year,
      month,
      year,
      organizationId,
      status,
      [...filters],
    ],
    () =>
      getReportByMonthAndYear(organizationId, {
        month,
        year,
        reportStatus: status,
        types: [...filters],
      }),
  );

  if (!month || !year) {
    return (
      <SimpleAlert
        alertMessage="Month and year are required."
        alertType="danger"
        alertRoute={{ name: 'Admin Home', route: ADMIN_DASHBOARD_ROUTE }}
      />
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <MonthlyReport
        error={error}
        isLoading={isLoading}
        reports={reports}
        month={month}
        year={year}
        status={status}
        routeType="admin"
      />
    </div>
  );
};
