import { Link } from 'react-router-dom';
import { LoginForm } from '../../components/login-form/LoginForm';
import { AuthPageLayout } from '../../components/AuthLayout';
import { useRedirectHomeIfAuthenticated } from '../../hooks/useRedirectHomeIfAuthenticated';

export const Login = () => {
  useRedirectHomeIfAuthenticated();

  return (
    <div className="my-14">
      <AuthPageLayout
        title="Log in to account"
        subtitle={
          <>
            Forgot your password?{' '}
            <Link to="/password-recovery" className="text-secondary-blue-500">
              Click here
            </Link>{' '}
            to initiate the recovery process.
          </>
        }
      >
        <LoginForm />
      </AuthPageLayout>
    </div>
  );
};
