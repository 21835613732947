import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { IPeople, Position } from '../services/hedgehog/interfaces/IPeople';
import { useUser } from '../hooks/useUser';
import { IOrganization } from '../services/hedgehog/interfaces/IOrganization';
import { SimpleLoading } from '../components/loading/SimpleLoading';
import { router } from '../router';

type UserDashboardContextValue = {
  asPeople: IPeople[];
  isManager: boolean;
  currentOrganization: IOrganization;
  changeOrganization: (organizationId: number) => void;
};

const UserDashboardContext = React.createContext(
  {} as UserDashboardContextValue,
);

const UserDashboardProvider = ({ children }: { children: React.ReactNode }) => {
  const [asPeople, setAsPeople] = useState<IPeople[]>([]);
  const [isManager, setIsManager] = useState(false);
  const [currentOrganization, setCurrentOrganization] = useState<IOrganization>(
    {} as IOrganization,
  );

  const changeOrganization = useCallback(
    (organizationId: number) => {
      const person = asPeople.find(
        (person) => person.organization.id === organizationId,
      );
      if (!person) return;
      setCurrentOrganization(person.organization);
    },
    [asPeople],
  );

  const { user, isLoading } = useUser();

  const checkIsManager = useCallback(
    (organization: IOrganization) => {
      if (!user) return false;
      return asPeople.some(
        (userPerson) =>
          userPerson.organization.id === organization.id &&
          userPerson.position === Position.manager,
      );
    },
    [asPeople, user],
  );

  const UserDashboardProviderValue = useMemo(
    () => ({
      isManager,
      asPeople,
      currentOrganization,
      changeOrganization,
    }),
    [isManager, asPeople, currentOrganization, changeOrganization],
  );

  useEffect(() => {
    if (!user || isLoading) return;

    if (!user.asPeople[0]) {
      router.navigate('/about');
      return;
    }

    const organization = user.asPeople[0].organization;
    setAsPeople(user.asPeople);
    setCurrentOrganization(organization);
    setIsManager(checkIsManager(organization));
  }, [user, isLoading, checkIsManager]);

  useEffect(() => {
    if (currentOrganization) {
      setIsManager(checkIsManager(currentOrganization));
    }
  }, [checkIsManager, currentOrganization]);

  if (!isLoading && user?.id && asPeople.length && currentOrganization?.id) {
    return (
      <UserDashboardContext.Provider value={UserDashboardProviderValue}>
        {children}
      </UserDashboardContext.Provider>
    );
  } else {
    return (
      <div className="h-screen flex flex-row justify-center items-center">
        <SimpleLoading
          loadingColor="text-primary-cyan-500"
          loadingSize="h-20 w-20"
        />
      </div>
    );
  }
};
export { UserDashboardContext, UserDashboardProvider };
