import { ColumnSettings } from 'handsontable/settings';
import { NUMERIC_FORMAT_PATTERN } from './utils';

export const columnConfigWithNumberAndFormat: ColumnSettings = {
  type: 'numeric',
  numericFormat: {
    pattern: NUMERIC_FORMAT_PATTERN,
  },
};

export const columnConfigTypeText: ColumnSettings = {
  type: 'text',
};

export const columnConfigTypeNumber: ColumnSettings = {
  type: 'numeric',
};
