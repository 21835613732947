import { Link } from 'react-router-dom';
import { BackgroundIllustration } from '../BackgroundIllustration';

const faqs = [
  [
    {
      question: 'How much is the HEDGEHOG subscription?',
      answer:
        'Our mission is to provide hedge accounting capabilities to all businesses, regardless of size. We access the size of your hedge book and business and provide a bespoke pricing solution that delivers a RoI that can\'t be matched across our peers."',
    },
    {
      question: 'Do I need to be a Subject Matter Expert to use HEDGEHOG?',
      answer:
        'No. This is a bureau-service offering, meaning that everything is done for you. We are different to SaaS offerings that require training and knowledge of the subject matter.',
    },
  ],
  [
    {
      question: 'When will the month-end Reports be available?',
      answer:
        'Reports will be available by close of business on business day three of the month.  They could be available earlier than this, but it will be by close of business on business day three of the month at the very latest.',
    },
    {
      question: 'We have a new team member - how do they get access?',
      answer: (
        <>
          Please send an email to{' '}
          <a
            href="mailto:IT@hedgeeffective.com"
            className="text-gray-600 underline"
          >
            IT@hedgeeffective.com
          </a>{' '}
          copying in your new team member, and request access to your portfolio.
          The new user will be emailed instructions.
        </>
      ),
    },
  ],
  [
    {
      question: 'How do I reset my password?',
      answer: (
        <>
          You can reset your password using your username and the link at the
          bottom of the login page or{' '}
          <Link to={'password-recovery'} className="text-gray-600 underline">
            here
          </Link>
          .
        </>
      ),
    },
  ],
];

export const Faqs = () => {
  return (
    <section
      id="faqs"
      aria-labelledby="faqs-title"
      className="py-20 sm:py-32 overflow-hidden"
    >
      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <BackgroundIllustration className="absolute left-1/2 z-0 -top-10 h-[1280px] w-[1280px]  pointer-events-none -translate-x-1/2 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)]" />
        <div className="mx-auto max-w-2xl lg:mx-0 relative">
          <h2
            id="faqs-title"
            className="text-3xl font-medium tracking-tight text-black"
          >
            Frequently asked questions
          </h2>
          <p className="mt-2 text-lg text-gray-700">
            If you have anything else you want to ask,{' '}
            <a
              href="mailto:info@hedgeeffective.com"
              className="text-gray-700 underline"
            >
              reach out to us
            </a>
            .
          </p>
        </div>
        <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 sm:mt-20 lg:max-w-none lg:grid-cols-3 relative">
          {faqs.map((column, columnIndex) => (
            <li key={`column-${columnIndex + 1}`}>
              <ul className="space-y-10">
                {column.map((faq, faqIndex) => (
                  <li key={`faq-${faqIndex + 1}`}>
                    <h3
                      className="text-lg font-semibold leading-6 text-black"
                      data-cy={`faq-title-column-${columnIndex + 1}-faq-${
                        faqIndex + 1
                      }`}
                    >
                      {faq.question}
                    </h3>
                    <p
                      className="mt-4 text-sm text-gray-700"
                      data-cy={`faq-subtitle-column-${columnIndex + 1}-faq-${
                        faqIndex + 1
                      }`}
                    >
                      {faq.answer}
                    </p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
