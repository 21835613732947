import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { ApiQueryKeys } from '../../../../services/hedgehog/api-query-keys.enum';
import { useUserDashboardContext } from '../../../../hooks/useDashboardContext';
import { getReportByMonthAndYear } from '../../../../services/hedgehog/hedgehog.api';

import { MonthlyReport } from '../../../../components/common/monthly-report/MonthlyReport';
import { IReportByMonth } from '../../../../services/hedgehog/interfaces/IReportByMonth';
import { ReportingPeriodsReviewedOption } from '../../../../components/common/reporting-periods/enum/reporting-periods.enum';
import { useFilters } from '../../../../hooks/useFilters';

export const UserMonthlyReport = () => {
  const { currentOrganization } = useUserDashboardContext();

  const organizationId = currentOrganization && currentOrganization.id;

  const { year, month } = useParams();

  const { filters } = useFilters();

  const {
    data: reports,
    isLoading,
    error,
  } = useQuery<IReportByMonth>(
    [
      ApiQueryKeys.organization_all_reports_by_month_and_year,
      month,
      year,
      organizationId,
      ReportingPeriodsReviewedOption.Reviewed,
      [...filters],
    ],
    () =>
      getReportByMonthAndYear(Number(organizationId), {
        month,
        year,
        types: [...filters],
      }),
  );

  if (!month || !year) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <MonthlyReport
        error={error}
        isLoading={isLoading}
        reports={reports}
        month={month}
        year={year}
        routeType="user"
      />
    </div>
  );
};
