import HotTable from '@handsontable/react';
import React, { useEffect, useState } from 'react';
import { useLoadWorkbook } from '../../../hooks/useLoadWorkbook';
import { useLoadBasicSheet } from '../../../hooks/useLoadBasicSheet';
import { fxMtmValuationReportColumnConfig } from './fxMtmValuationReportColumnConfig';
import {
  getCellProperties,
  insertEmptyRowAfter,
  parseRichText,
  removeEmptyRows,
} from '../common/utils';
import { fxMtmValuationReportMergeCells } from './fxMtmValuationReportMergeCells';
import { fxMtmValuationReportColumnNewConfig } from './fxMtmValuationReportColumnNewConfig';

interface IFxMtmValuationReportProps {
  report: Blob;
}

export const FX_MTM_VALUATION_SHEET_NAME = 'FX MTM Valuation Report';

const headers = [
  /Deal\s*Date/,
  /Maturity\s*Date/,
  /Buy Ccy/,
  /Buy Amount/,
  /Sell Ccy/,
  /Sell Amount/,
  /Deal Type/,
  /Exchange\s*Rate/,
  /Fair value/,
  /Counterparty/,
  /Deal Number/,
  /Spot Rateat Inception/,
  /Reference/,
  /Comment/,
  /Hedging/,
  /Buy/,
  /Sell/,
  /Amount/,
  /^MTM/,
];

export const ViewFxMtmValuationReportTable = ({
  report,
}: IFxMtmValuationReportProps) => {
  const { workbook } = useLoadWorkbook(
    report,
    'Error loading Hedge Effectiveness Report',
  );

  const [selectedSheet] = useState<string>(FX_MTM_VALUATION_SHEET_NAME);

  const { sheet: originalSheet } = useLoadBasicSheet(workbook, selectedSheet);
  const [sheet, setSheet] = useState<any[][]>([]);

  useEffect(() => {
    if (originalSheet) {
      const parsedSheet = parseRichText(originalSheet);
      const cleanedSheet = removeEmptyRows(parsedSheet);
      setSheet(insertEmptyRowAfter(cleanedSheet, 'Grand Total'));
    }
  }, [originalSheet]);

  return (
    <div className="flex justify-center">
      {sheet.length > 0 && (
        <HotTable
          readOnly={true}
          key={'fx-mtm-valuation-report'}
          data={sheet}
          rowHeaders={false}
          colHeaders={false}
          width={sheet.length === 22 ? '1750px' : '1400px'}
          height="auto"
          licenseKey="non-commercial-and-evaluation"
          rowHeights={35}
          manualColumnResize={true}
          manualRowResize={true}
          wordWrap={true}
          allowEmpty={false}
          columns={
            sheet.length === 22
              ? fxMtmValuationReportColumnConfig
              : fxMtmValuationReportColumnNewConfig
          }
          mergeCells={fxMtmValuationReportMergeCells(sheet)}
          cells={(row, col) => getCellProperties(sheet, headers, row, col)}
        />
      )}
    </div>
  );
};
