import { CellValue, RawCellContent } from 'hyperformula';
import * as XLSX from 'xlsx';

export interface IReportSheet {
  sheetName: string;
  sheet: CellValue[][];
}

export function parseCvsFileBufferToSheet(fileBuffer: Buffer) {
  const workbook = XLSX.read(fileBuffer, { cellDates: true, type: 'buffer' });
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];
  const cells = XLSX.utils.sheet_to_json(sheet, {
    header: 1,
  });

  return cells as RawCellContent[][];
}
