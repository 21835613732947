import { ErrorMessage, Field, FieldArray, useFormikContext } from 'formik';

import { v4 as uuidv4 } from 'uuid';
import { IOrganizationProfile } from '../../services/hedgehog/interfaces/IOrganizationProfile';
import { Button } from '../Button';
import {
  EMPTY_EXPOSURE_CURRENCY,
  FxHedgeDocumentationFormTemplateOptions,
  FxMtmJournalReportBaseCurrency,
  FxMtmJournalReportMonth,
  FxMtmJournalReportQuoteCurrency,
  FxMtmJournalReportRule,
  fxReportCurrencyRules,
  fxReportCurrencyRulesStaticData,
} from '../../services/hedgehog/types/reports/fx-mtm-journal-report/fx-mtm-journal-options';
import { useModal } from '../../hooks/useModal';
import { FxHedgeDocFormModal } from '../modal/fx-hedge-doc-form-modal/FxHedgeDocFormModal';
import {
  FxReportCurrencyRulesOptions,
  IFxReportCurrencyRule,
} from '../../services/hedgehog/interfaces/IFxMtmJournalStaticData';
import { useEffect } from 'react';
type Props = {
  label: string;
  name: string;
  exposureCurrencyOptions: string[];
  disabled?: boolean;
  type: string;
};

export const FormFieldFxReportCurrencyRule = (props: Props) => {
  const fields: Array<keyof FxReportCurrencyRulesOptions> = [
    'baseCurrency',
    'quoteCurrency',
    'exposureCurrency',
    'month',
    'rule',
    'fxHedgeDocumentationFormTemplateOption',
  ];

  return (
    <>
      {fields.map((fieldName) => (
        <td className="py-0.5 px-0.5 sm:py-1 sm:px-1.5" key={uuidv4()}>
          <Field
            as="select"
            className="block p-1.5 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
            data-cy={`${props.name}.${fieldName}`}
            {...props}
            name={`${props.name}.${fieldName}`}
          >
            {fieldName === 'exposureCurrency' &&
              props.exposureCurrencyOptions.map((option) => (
                <option key={uuidv4()} value={option}>
                  {option}
                </option>
              ))}
            {fieldName !== 'exposureCurrency' &&
              fxReportCurrencyRules[fieldName] &&
              fxReportCurrencyRules[fieldName].map((option) => (
                <option key={uuidv4()} value={option}>
                  {option}
                </option>
              ))}
          </Field>
        </td>
      ))}
    </>
  );
};

export const FormFxReportCurrencyRulesStaticData = () => {
  const { values, setFieldValue } = useFormikContext<IOrganizationProfile>();

  const basicCurrencyRule: IFxReportCurrencyRule = {
    baseCurrency: FxMtmJournalReportBaseCurrency.AUD,
    quoteCurrency: FxMtmJournalReportQuoteCurrency.AUD,
    exposureCurrency: FxMtmJournalReportQuoteCurrency.AUD,
    month: FxMtmJournalReportMonth.SameMonth,
    rule: FxMtmJournalReportRule.PNL,
    fxHedgeDocumentationFormTemplateOption:
      FxHedgeDocumentationFormTemplateOptions.No,
    fxHedgeDocumentationFormTemplateInfo: null,
  };

  const { openModal, closeModal } = useModal();

  const handleLoadFxHedgeDocFormInfo = (index: number) => {
    const fxHedgeDocumentationFormInfo =
      values.fxCurrencyRules[index].fxHedgeDocumentationFormTemplateInfo;

    const fxHedgeDocumentationFormTemplateOption =
      values.fxCurrencyRules[index].fxHedgeDocumentationFormTemplateOption;

    openModal(
      'fx-hedge-doc-form-config-modal',
      <FxHedgeDocFormModal
        close={() => closeModal('fx-hedge-doc-form-config-modal')}
        defaultValues={fxHedgeDocumentationFormInfo ?? undefined}
        templateOption={fxHedgeDocumentationFormTemplateOption}
        onSubmit={(fxHedgeDocFormInfo) => {
          setFieldValue(
            `fxCurrencyRules[${index}].fxHedgeDocumentationFormTemplateInfo`,
            fxHedgeDocFormInfo,
          );
          closeModal('fx-hedge-doc-form-config-modal');
        }}
      />,
    );
  };

  useEffect(() => {
    values.fxCurrencyRules.forEach((fxCurrencyRule, index) => {
      if (
        fxCurrencyRule.fxHedgeDocumentationFormTemplateOption ===
        FxHedgeDocumentationFormTemplateOptions.No
      ) {
        setFieldValue(
          `fxCurrencyRules[${index}].fxHedgeDocumentationFormTemplateInfo`,
          null,
        );
      }
    });
  }, [values.fxCurrencyRules, setFieldValue]);

  const fields: Array<keyof FxReportCurrencyRulesOptions> = [
    'baseCurrency',
    'quoteCurrency',
    'exposureCurrency',
    'month',
    'rule',
    'fxHedgeDocumentationFormTemplateOption',
  ];

  return (
    <div>
      <FieldArray name={fxReportCurrencyRulesStaticData}>
        {({ push, remove }: { push: any; remove: any }) => (
          <table className="w-full">
            <thead>
              <tr className="text-center text-xs font-normal">
                <th>Buy Currency</th>
                <th>Sell Currency</th>
                <th>Exposure Currency</th>
                <th>Month</th>
                <th>Rules</th>
                <th>Create Hedge Doc Form</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="mb-10">
              {values.fxCurrencyRules.map((value, index) => (
                <tr key={uuidv4()} className="text-left w-full ">
                  <FormFieldFxReportCurrencyRule
                    name={`${fxReportCurrencyRulesStaticData}[${index}]`}
                    exposureCurrencyOptions={[
                      EMPTY_EXPOSURE_CURRENCY,
                      values.fxCurrencyRules[index].baseCurrency,
                      values.fxCurrencyRules[index].quoteCurrency,
                    ]}
                    label={`Rule ${index + 1}`}
                    type="string"
                  />

                  <td className="flex flex-  justify-center items-center gap-1 p-1">
                    {value.fxHedgeDocumentationFormTemplateOption !==
                      FxHedgeDocumentationFormTemplateOptions.No && (
                      <Button
                        data-cy={`load-hedge-doc-form-info-button-${index}`}
                        className={`text-white ${
                          !value.fxHedgeDocumentationFormTemplateInfo
                            ? 'bg-yellow-400 hover:bg-yellow-700 text-yellow-900'
                            : 'bg-green-400 hover:bg-green-700 text-green-900'
                        } font-bold text-xs py-0.5 px-1`}
                        type="button"
                        variant={
                          !value.fxHedgeDocumentationFormTemplateInfo
                            ? 'outline'
                            : 'solid'
                        }
                        onClick={() => handleLoadFxHedgeDocFormInfo(index)}
                      >
                        {!value.fxHedgeDocumentationFormTemplateInfo
                          ? 'Load Template Info'
                          : 'Edit Template Info'}
                      </Button>
                    )}

                    <Button
                      type="button"
                      className="bg-red-400 hover:bg-red-700 text-white font-bold text-xs py-0.5 px-1"
                      onClick={() => remove(index)}
                      data-cy={`remove-currency-rule-${index}`}
                    >
                      Remove rule
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot className="m-2">
              <tr>
                <td colSpan={6} className="text-center py-1.5 px-1.5">
                  {values.fxCurrencyRules.map((_, index) => (
                    <p key={uuidv4()}>
                      {fields.map((fieldName) => (
                        <ErrorMessage
                          data-cy="form-field-error"
                          name={`${fxReportCurrencyRulesStaticData}[${index}].${fieldName}`}
                          component="span"
                          className="text-red-500 text-sm mt-1"
                          key={uuidv4()}
                        />
                      ))}
                    </p>
                  ))}

                  {values.fxCurrencyRules.length === 0 && (
                    <ErrorMessage
                      data-cy="form-field-error"
                      name={`${fxReportCurrencyRulesStaticData}`}
                      component="span"
                      className="text-red-500 text-sm mt-1"
                    />
                  )}
                </td>
              </tr>

              <tr>
                <td colSpan={6} className="text-center py-1.5 px-1.5">
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => push(basicCurrencyRule)}
                    data-cy="add-currency-rule"
                  >
                    Add Rule
                  </Button>
                </td>
              </tr>
            </tfoot>
          </table>
        )}
      </FieldArray>
    </div>
  );
};
