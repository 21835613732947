import { Link } from 'react-router-dom';
import { useUserDashboardContext } from '../../hooks/useDashboardContext';
import { OrganizationLogo } from '../OrganizationLogo';
import { HedgehogLogo } from '../icons/HedgehogLogo';
import { IAddExistingMemberParameters } from '../../services/hedgehog/types/add-existing-member-parameters';
import React from 'react';

type Props = {
  addExistingMemberToOrganization: (
    values: IAddExistingMemberParameters,
  ) => Promise<void>;
};

export const DashboardHeaderBar: React.FC<Props> = ({
  addExistingMemberToOrganization,
}) => {
  const { currentOrganization } = useUserDashboardContext();

  return (
    <header className="hidden sticky top-0 z-[90] bg-white md:flex w-full border-b">
      <div className="flex flex-wrap w-full py-4 px-4 md:px-6">
        <div className="flex flex-wrap w-full justify-between gap-3">
          <div className="visible md:invisible flex justify-center items-center">
            <Link className="pl-3" to="/">
              <HedgehogLogo
                svgIconStyles="text-black flex-grow-0 flex-shrink-0 w-40 h-[55px] object-contain"
                logoTitleStyles="text-primary-dark fill-current"
                logoIconStyles="text-primary-cyan-500 fill-current"
              />
            </Link>
          </div>
          <OrganizationLogo
            logoSize="none"
            logoStyles="min-w-6 max-w-12 min-h-6 max-h-12 shadow-primary-dark-500"
            organizationId={currentOrganization.id}
          />
        </div>
      </div>
    </header>
  );
};
