export const fxMtmJournalWorkingSheetMock: any[][] = [
  [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    'Hedgehog Software',
    'Hedgehog Software',
    'Hedgehog Software',
  ],
  [],
  [
    'Novatech',
    null,
    'FX MTM Journal Working - 30 Nov 2023',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    'AUD/USD Hedging',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    'AUD',
    'AUD',
    'AUD',
  ],
  [
    'Deal Date',
    'Maturity Date',
    'Buy Ccy',
    'Buy Amount',
    'Sell Ccy',
    'Sell Amount',
    'Deal Type',
    'Exchange Rate',
    'Fair value',
    'Counterparty',
    'Deal Number',
    'Spot Rate at Inception',
    'Reference',
    'Comment',
    null,
    'Profit or Loss - Dr/(Cr)',
    'OCI - Dr/(Cr)',
    'Inventory - Dr/(Cr)',
  ],
  [
    'Thu Sep 28 2023',
    'Sun Sep 28 2025',
    'AUD',
    100,
    'USD',
    200,
    'Swap',
    300,
    400,
    null,
    'FWD232729893',
    null,
    null,
    null,
    500,
    null,
  ],
  [
    '-',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    'Grand Total',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    600,
    null,
    null,
    null,
    null,
    null,
    null,
    0,
    0,
    0,
  ],
  [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    'The valuation date of this report is 30 Nov 23.',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    'The valuation date of this report is 30 Nov 23.',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
];

export const fxMtmJournalSheetMock: any[][] = [
  [null, null, null, null, 'Hedgehog Software', null],
  [],
  ['Ltd. Sydney 1452', 'Ltd. Sydney 1452', null, null, null, null],
  [null, null, 'Post as a Reserving Journal', null],
  [
    'GL Accounts',
    'Classification',
    'Description',
    'Reference',
    'AUD DR',
    'AUD CR',
  ],
  [
    'Inventory',
    'Balance Sheet - Current Assets',
    'Carry Adj to Inventory',
    'AUD/USD Hedging',
    0,
    0,
  ],
  [
    'Fair value of FX Forwards',
    'Balance Sheet - Current Assets',
    'MTM of FX Forwards',
    'AUD/USD Hedging',
    100,
    0,
  ],
  [
    'Fair value of FX Forwards',
    'Balance Sheet - Liabilities',
    'MTM of FX Forwards',
    'AUD/USD Hedging',
    0,
    200,
  ],
  [
    'Cash Flow Hedge Reserve',
    'Other Comprehensive Income',
    'MTM of FX Forwards',
    'AUD/USD Hedging',
    0,
    300,
  ],
  [
    'Unrealised FX gains or losses',
    'Profit or Loss',
    'Reclassification Adj',
    'AUD/USD Hedging',
    0,
    0,
  ],
  ['-', null, null, null, 400, 500],
  [null, null, null, null, null, null],
  [
    'The valuation date of this report is 30 Nov 23.',
    null,
    null,
    null,
    null,
    null,
  ],
  [
    'The valuation date of this report is 30 Nov 23.',
    null,
    null,
    null,
    null,
    null,
  ],
  [null, null, null, null, null, null],
];
