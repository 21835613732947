import { ProcessableInputReport } from '../ProcessableInputReport';

export function validateActualAndHypoReferences(
  processableInput: ProcessableInputReport,
) {
  const regex = / H$/;
  const hypoCellValues: string[] = [];
  const actualCellValues: string[] = [];

  const headerCell = processableInput.findCellValue('Reference');
  if (!headerCell) {
    // eslint-disable-next-line prettier/prettier, quotes
    throw new Error("No 'Reference' header found in the sheet.");
  }

  const totalRows = processableInput.getSheetDimensions().height;

  for (let row = headerCell.row + 1; row < totalRows; row++) {
    const cellValue = processableInput.getCellValue({
      col: headerCell.col,
      row,
    });

    const cellValueString = cellValue?.toString();
    if (!cellValueString?.trim()) continue;

    if (regex.test(cellValueString)) {
      hypoCellValues.push(cellValueString.replace(/ H$/, ''));
    } else {
      actualCellValues.push(cellValueString);
    }
  }

  for (const actualValue of actualCellValues) {
    const correspondingHypoValue = hypoCellValues.find(
      (hypoValue) => hypoValue === actualValue,
    );

    if (!correspondingHypoValue) {
      throw new Error(
        `Not found hypo reference for actual reference: ${actualValue}`,
      );
    }
  }

  for (const hypoValue of hypoCellValues) {
    const correspondingActualValue = actualCellValues.find(
      (actualValue) => actualValue === hypoValue,
    );

    if (!correspondingActualValue) {
      throw new Error(
        `Not found hypo reference for actual reference: ${hypoValue}`,
      );
    }
  }
}
