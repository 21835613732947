import moment from 'moment';

export const DEFAULT_FROM_DATE = moment(new Date())
  .startOf('month')
  .subtract(1, 'month')
  .toDate();

export const DEFAULT_TO_DATE = moment(new Date())
  .endOf('month')
  .subtract(1, 'month')
  .toDate();
