import * as Joi from 'joi';
import {
  CURRENCY_VALIDATION_REGEX,
  REFERENCE_VALIDATION_REGEX,
} from '../../helpers/validationRegex';
import { IrsCurrentNonCurrentColHeaders } from '../irsCurrentNonCurrent/irsCurrentNonCurrentHeaders';

export const hedgehogCurrentNonCurrentHeadersSchema: Record<
  IrsCurrentNonCurrentColHeaders,
  Joi.Schema
> = {
  [IrsCurrentNonCurrentColHeaders.MaturityDate]: Joi.date().required(),
  [IrsCurrentNonCurrentColHeaders.Currency]: Joi.string()
    .regex(CURRENCY_VALIDATION_REGEX)
    .required(),
  [IrsCurrentNonCurrentColHeaders.NotionalAmount]: Joi.number().required(),
  [IrsCurrentNonCurrentColHeaders.FixedRate]: Joi.number().required(),
  [IrsCurrentNonCurrentColHeaders.SwapType]: Joi.string().required(),
  [IrsCurrentNonCurrentColHeaders.FloatingRate]: Joi.number().required(),
  [IrsCurrentNonCurrentColHeaders.Frequency]: Joi.string().required(),
  [IrsCurrentNonCurrentColHeaders.Valuation]: Joi.number().required(),
  [IrsCurrentNonCurrentColHeaders.Counterparty]: Joi.string().required(),
  [IrsCurrentNonCurrentColHeaders.Reference]: Joi.alternatives()
    .try(
      Joi.string()
        .regex(REFERENCE_VALIDATION_REGEX)
        .message(
          'Trade reference can be a number, string and has special characters',
        ),
      Joi.number(),
    )
    .required(),
  [IrsCurrentNonCurrentColHeaders.CurrentAsset]: Joi.number().required(),
  [IrsCurrentNonCurrentColHeaders.NonCurrentAsset]: Joi.number().required(),
  [IrsCurrentNonCurrentColHeaders.CurrentLiability]: Joi.number().required(),
  [IrsCurrentNonCurrentColHeaders.NonCurrentLiability]: Joi.number().required(),
  [IrsCurrentNonCurrentColHeaders.CurrentNet]: Joi.number().required(),
  [IrsCurrentNonCurrentColHeaders.NonCurrentNet]: Joi.number().required(),
};

export const hedgehogCurrentNonCurrentTotalHeadersSchema: Partial<
  Record<IrsCurrentNonCurrentColHeaders, Joi.Schema>
> = {
  [IrsCurrentNonCurrentColHeaders.NotionalAmount]: Joi.number().required(),
  [IrsCurrentNonCurrentColHeaders.CurrentAsset]: Joi.number().required(),
  [IrsCurrentNonCurrentColHeaders.NonCurrentAsset]: Joi.number().required(),
  [IrsCurrentNonCurrentColHeaders.CurrentLiability]: Joi.number().required(),
  [IrsCurrentNonCurrentColHeaders.NonCurrentLiability]: Joi.number().required(),
  [IrsCurrentNonCurrentColHeaders.CurrentNet]: Joi.number().required(),
  [IrsCurrentNonCurrentColHeaders.NonCurrentNet]: Joi.number().required(),
};
