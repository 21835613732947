import { MouseEventHandler } from 'react';

import { useQuery } from 'react-query';
import { ApiQueryKeys } from '../../services/hedgehog/api-query-keys.enum';
import {
  getAllReportByOrganization,
  migrateHedgeEffectivenessReport,
  updateOrganizationProfile,
} from '../../services/hedgehog/hedgehog.api';
import { useLocation, useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';

import { v4 as uuidv4 } from 'uuid';
import { OverviewIcon } from '../icons/OverviewIcon';

import { DocumentIcon } from '../icons/DocumentIcon';
import { MembersIcon } from '../icons/MembersIcon';
import { IOrganization } from '../../services/hedgehog/interfaces/IOrganization';
import useLoading from '../../hooks/useLoading';
import { IReportByMonth } from '../../services/hedgehog/interfaces/IReportByMonth';
import { IOrganizationProfile } from '../../services/hedgehog/interfaces/IOrganizationProfile';
import { ExposureType } from '../../services/hedgehog/enum/ExposureType.enum';
import { ProfileConfigModal } from '../modal/profile-config-modal/ProfileConfigModal';
import { Toast } from '../../services/toastify/Toastify';
import { useOrganization } from '../../hooks/useOrganization';
import { UNEXPECTED_ERROR_MESSAGE } from '../../error/errorMessages.constants';
import { MigrateReportModal } from '../modal/migrate-report-modal/MigrateReportModal';
import { DataIcon } from '../icons/DataIcon';
import { MigrateIcon } from '../icons/MigrateIcon';
import LineChartIcon from '../icons/LineChartIcon';
import { validateInput } from '../../services/validation/validateInput';
import { InputReportType } from '../../services/validation/inputReportType';
import { FxAnalysisReportModal } from '../modal/fx-analysis-report-modal/FxAnalysisReportModal';

export interface OrganizationNavigationItem {
  label: string;
  path: string | null;
  onClick: MouseEventHandler<HTMLButtonElement>;
  icon: JSX.Element;
  extra?: number;
}
export interface AdminSidebarProps {
  adminOrganization: IOrganization;
  onclickOutside?: () => void;
}
export const AdminSidebarNavigationList = ({
  adminOrganization,
  onclickOutside,
}: AdminSidebarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const isCurrentRoute = (path: string | null) => location.pathname === path;
  const { refetch } = useOrganization(adminOrganization.id);

  const [, waitFor] = useLoading();

  const { refetch: refetchReports } = useQuery<IReportByMonth>(
    [ApiQueryKeys.organization_all_reports, adminOrganization?.id],
    () => getAllReportByOrganization(adminOrganization?.id),
    { refetchOnWindowFocus: true },
  );

  const handleUpdateStaticData = () => {
    const updateProfile = async (profile: IOrganizationProfile) => {
      try {
        await updateOrganizationProfile(adminOrganization.id, profile);
        refetch();
        Toast.success('Profile updated successfully');
        closeModal('update-profile');
      } catch (error) {
        if (error instanceof Error) Toast.error(error.message);
        else Toast.error(UNEXPECTED_ERROR_MESSAGE);
        console.log(error);
      }
    };

    openModal(
      'update-profile',
      <ProfileConfigModal
        close={() => closeModal('update-profile')}
        onSubmit={(profile) => waitFor(updateProfile(profile))}
        exposureType={adminOrganization?.exposureType ?? ExposureType.BOTH}
        defaultValues={adminOrganization?.organizationProfile ?? undefined}
      />,
    );
  };

  const handleFxAnalysisReportModal = () => {
    openModal(
      'fx-analysis-report',
      <FxAnalysisReportModal organizationId={adminOrganization.id} />,
    );
  };

  const handleMigrateReportModal = () => {
    const migrate = async (organizationId: number, file: File) => {
      try {
        await validateInput(file, InputReportType.MIGRATION_REPORT);
        await migrateHedgeEffectivenessReport(organizationId, file);
        refetch();
        refetchReports();
        Toast.success('Report migrated successfully');
        closeModal('migrate-report');
      } catch (error) {
        Toast.error(
          error instanceof Error ? error.message : UNEXPECTED_ERROR_MESSAGE,
        );
      }
    };
    openModal(
      'migrate-report',
      <MigrateReportModal
        close={() => closeModal('migrate-report')}
        onSubmit={(file) => waitFor(migrate(adminOrganization.id, file))}
      />,
    );
  };

  const organizationMenu: OrganizationNavigationItem[] = [
    {
      label: 'Overview',
      onClick: () => navigate(`/organization/menu/${adminOrganization.id}`),
      icon: <OverviewIcon />,
      path: `/organization/menu/${adminOrganization.id}`,
    },
    {
      label: 'Reporting Periods',
      onClick: () =>
        navigate(`/organization/${adminOrganization.id}/reporting-periods`),
      icon: <DocumentIcon />,
      path: `/organization/${adminOrganization.id}/reporting-periods`,
    },
    {
      label: 'Profile',
      onClick: () => navigate(`/organization/profile/${adminOrganization.id}`),
      icon: <MembersIcon />,
      path: `/organization/profile/${adminOrganization.id}`,
    },
    {
      label: 'Static Data',
      onClick: handleUpdateStaticData,
      icon: <DataIcon />,
      path: null,
    },

    {
      label: 'Migrate Historic Data',
      onClick: handleMigrateReportModal,
      icon: <MigrateIcon />,
      path: null,
    },
  ];

  if (
    adminOrganization.exposureType === ExposureType.BOTH ||
    adminOrganization.exposureType === ExposureType.IRS_ONLY
  ) {
    organizationMenu.splice(3, 0, {
      label: 'IRS Migration Trades',
      onClick: () => navigate(`/organization/${adminOrganization.id}/trades`),
      icon: <LineChartIcon />,
      path: `/organization/${adminOrganization.id}/trades`,
    });
  } else {
    organizationMenu.pop();
  }

  if (
    adminOrganization.exposureType === ExposureType.BOTH ||
    adminOrganization.exposureType === ExposureType.FX_ONLY
  ) {
    organizationMenu.splice(3, 0, {
      label: 'Fx Analysis Report',
      onClick: handleFxAnalysisReportModal,
      icon: <DataIcon />,
      path: null,
    });
  }

  return (
    <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-4">
      <p className="self-stretch flex-grow-0 flex-shrink-0 w-[258px] text-base font-semibold text-left text-slate-500">
        Menu
      </p>
      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-6 ">
        {organizationMenu.map(({ onClick, label: name, icon, path, extra }) => (
          <button
            data-cy={`organization-menu-${name}`}
            className={`flex cursor-pointer justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2 px-3 py-2
          ${isCurrentRoute(path) && 'bg-gray-200'}`}
            onClick={(event) => {
              onClick(event);
              onclickOutside?.();
            }}
            key={uuidv4()}
          >
            {icon}
            <pre>{name}</pre>
            {extra && (
              <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-2 px-2.5 rounded bg-primary-dark-500">
                <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-left text-white">
                  {extra}
                </p>
              </div>
            )}
          </button>
        ))}
      </div>
    </div>
  );
};
