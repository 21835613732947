import { IOrganization } from '../../services/hedgehog/interfaces/IOrganization';
import { IReportByMonth } from '../../services/hedgehog/interfaces/IReportByMonth';
import { OutputReportTypes } from '../../services/hedgehog/types/OutputReportTypes';
import { BasicCountCard } from './BasicCountCard';
import { SimpleLoading } from '../loading/SimpleLoading';

export const GridCountCards = ({
  reports,
  isLoadingReports,
  organization,
}: {
  reports?: IReportByMonth;
  isLoadingReports: boolean;
  organization: IOrganization;
}) => {
  const handleOrganizationCardCount = (
    reports: IReportByMonth,
    organization: IOrganization,
  ): [string, number][] => {
    const typeCounts: Record<string, number> = {};

    if (reports) {
      typeCounts['Members'] = organization.people.length;

      reports.allReports.forEach((report) => {
        typeCounts[report.type] = (typeCounts[report.type] || 0) + 1;
      });

      if (reports.irsHedgeDocumentationForms.length > 0) {
        typeCounts[OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM] =
          reports.irsHedgeDocumentationForms.length;
      }

      if (reports.fxHedgeDocumentationForms.length > 0) {
        typeCounts[OutputReportTypes.FX_HEDGE_DOCUMENTATION_FORM] =
          reports.fxHedgeDocumentationForms?.length;
      }

      const totalReports =
        reports.allReports.length +
        reports.fxHedgeDocumentationForms.length +
        reports.irsHedgeDocumentationForms.length;

      typeCounts['Total of Reports'] = totalReports;
    }
    return Object.entries(typeCounts);
  };

  return (
    <>
      {isLoadingReports && (
        <SimpleLoading
          loadingColor="text-primary-cyan-500"
          loadingSize="h-10 w-10"
          externalStyles="flex flex-wrap items-center justify-center p-2"
        />
      )}
      <div className="grid gap-4 grid-cols-1 md:grid-cols-4 xl:grid-cols-12 h-full ">
        {reports &&
          handleOrganizationCardCount(reports, organization).map(
            ([type, count]) => (
              <BasicCountCard
                key={`${type}-${count}`}
                count={count}
                type={type}
                cardClassName="bg-white rounded-[10px] bg-white border border-zinc-200 col-span-2"
              />
            ),
          )}
      </div>
    </>
  );
};
