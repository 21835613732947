import { ApiQueryKeys } from '../services/hedgehog/api-query-keys.enum';
import { getOrganizationIrsTrades } from '../services/hedgehog/hedgehog.api';
import { IGetOrganizationIrsTradesQuery } from '../services/hedgehog/interfaces/IGetOrganizationIrsTradesQuery';
import {
  IIRSTradeAndCount,
  IRSTradeCreationMethod,
} from '../services/hedgehog/interfaces/IIRSTrade';
import { usePaginatedQuery } from './usePaginatedQuery';

export const useGetPaginatedMigrationIrsTrades = (
  organizationId: number,
  limit: number,
) => {
  return usePaginatedQuery<IIRSTradeAndCount, IGetOrganizationIrsTradesQuery>(
    [ApiQueryKeys.irs_trades_table, organizationId],
    { limit, offset: 0 },
    (query) => {
      query.creationMethod = IRSTradeCreationMethod.MIGRATION;
      return getOrganizationIrsTrades(organizationId, query);
    },
  );
};
