import { useFilters } from '../../../hooks/useFilters';
import { OutputReportTypes } from '../../../services/hedgehog/types/OutputReportTypes';
import { SimpleLoading } from '../../loading/SimpleLoading';
import { FilterCategory } from './FilterCategory';

const irsTypes: { name: string; type: OutputReportTypes }[] = [
  {
    name: 'Current Non-Current',
    type: OutputReportTypes.IRS_CURRENT_NON_CURRENT_REPORT,
  },
  {
    name: 'MTM Valuation',
    type: OutputReportTypes.IRS_MTM_VALUATION_REPORT,
  },
  {
    name: 'MTM Journal',
    type: OutputReportTypes.IRS_MTM_JOURNAL,
  },
  {
    name: 'Hedge Effectiveness',
    type: OutputReportTypes.IRS_HEDGE_EFFECTIVENESS_REPORT,
  },
  {
    name: 'Hedge Documentation Form',
    type: OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM,
  },
];

const fxTypes: { name: string; type: OutputReportTypes }[] = [
  {
    name: 'MTM Valuation',
    type: OutputReportTypes.FX_MTM_VALUATION,
  },
  {
    name: 'Matured Deals',
    type: OutputReportTypes.FX_MATURED_DEALS_REPORT,
  },
  {
    name: 'Hedge Effectiveness',
    type: OutputReportTypes.FX_HEDGE_EFFECTIVENESS_REPORT,
  },
  {
    name: 'Hedge Documentation Form',
    type: OutputReportTypes.FX_HEDGE_DOCUMENTATION_FORM,
  },
  {
    name: 'MTM Journal',
    type: OutputReportTypes.FX_MTM_JOURNAL_REPORT,
  },
];

const otherTypes: { name: string; type: OutputReportTypes }[] = [
  {
    name: 'Raw',
    type: OutputReportTypes.RAW_REPORT,
  },
];

const irsValues = irsTypes.map((type) => type.type);
const fxValues = fxTypes.map((type) => type.type);

export const FilterSection = () => {
  const { filters, updateFilters } = useFilters();

  const handleFilter = (type: OutputReportTypes) => {
    if (!filters) return;

    if (filters.has(type)) {
      const newFilters = new Set(filters);
      newFilters.delete(type);
      updateFilters(newFilters);
      return;
    }
    const newFilters = new Set(filters);
    newFilters.add(type);
    updateFilters(newFilters);
  };

  const areAllChecked = (types: OutputReportTypes[]) =>
    types.every((type) => filters.has(type));

  const handleAllFilter = (types: OutputReportTypes[]) => {
    if (!filters) return;
    if (areAllChecked(types)) {
      const newFilters = new Set(filters);
      types.forEach((type) => newFilters.delete(type));
      updateFilters(newFilters);
    } else {
      const newFilters = new Set(filters);
      types.forEach((type) => newFilters.add(type));
      updateFilters(newFilters);
    }
  };

  if (!filters) return <SimpleLoading />;

  return (
    <div className="flex flex-col xl:w-60 h-fit gap-4 p-4 xl:p-4 bg-white border border-gray200 shadow-md rounded-lg">
      <h4 className="text-gray-900 font-bold text-center xl:text-left">
        Filters
      </h4>
      <div className="flex flex-col min-[430px]:flex-row justify-around gap-2 xl:block overflow-y-auto">
        <FilterCategory
          title="IRS"
          handleFilter={handleFilter}
          handleAllFilter={handleAllFilter}
          filterList={irsTypes.map((irsType) => ({
            name: irsType.name,
            value: irsType.type,
            checked: filters.has(irsType.type),
          }))}
        />
        <FilterCategory
          title="FX"
          handleFilter={handleFilter}
          handleAllFilter={handleAllFilter}
          filterList={fxTypes.map((fxType) => ({
            name: fxType.name,
            value: fxType.type,
            checked: filters.has(fxType.type),
          }))}
        />
        <FilterCategory
          title="Others"
          handleFilter={handleFilter}
          filterList={otherTypes.map((otherType) => ({
            name: otherType.name,
            value: otherType.type,
            checked: filters.has(otherType.type),
          }))}
        />
      </div>
    </div>
  );
};
