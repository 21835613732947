import { useMutation, useQueryClient } from 'react-query';
import { ApiQueryKeys } from '../services/hedgehog/api-query-keys.enum';
import { Toast } from '../services/toastify/Toastify';
import { deleteIrsTrade } from '../services/hedgehog/hedgehog.api';

export const useDeleteTrade = (organizationId: number) => {
  const queryClient = useQueryClient();

  return useMutation((id: number) => deleteIrsTrade(id), {
    mutationKey: [ApiQueryKeys.irs_trades_table, organizationId],
    onSuccess: () => {
      Toast.success('Report deleted successfully');
      queryClient.invalidateQueries([
        ApiQueryKeys.irs_trades_table,
        organizationId,
      ]);
    },
    onError: () => {
      Toast.error('An error occurred while deleting the report');
    },
  });
};
