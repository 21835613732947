import { useQuery } from 'react-query';
import { IFxTrade } from '../../../../services/hedgehog/interfaces/IFXTrade';
import { ApiQueryKeys } from '../../../../services/hedgehog/api-query-keys.enum';
import { getFxTrades } from '../../../../services/hedgehog/hedgehog.api';
import { OutputReportTypes } from '../../../../services/hedgehog/types/OutputReportTypes';
import { Toast } from '../../../../services/toastify/Toastify';

export const useFetchFxTrades = (organizationId: number) => {
  return useQuery<IFxTrade[]>(
    [ApiQueryKeys.fx_pie_mtm_valuation_chart_data, organizationId],
    {
      queryFn: async () => {
        const trades = await getFxTrades(organizationId, {
          option: OutputReportTypes.FX_MTM_VALUATION,
        });

        if (!trades || trades.length === 0) {
          throw new Error('Fx trades data is not available');
        }

        return trades;
      },
      onError: (error: any) => {
        Toast.error(error.message);
      },
    },
  );
};
