export const PlusIcon = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M8.5 3.33331V12.6666"
        stroke="#252525"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M3.83334 8H13.1667"
        stroke="#252525"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};
