import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserPoolData,
  ISignUpResult,
} from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION ?? 'region',
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? 'key',
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? 'secret',
});

const userPoolConfig: ICognitoUserPoolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? 'local_7cSMGZ6h',
  ClientId:
    process.env.REACT_APP_COGNITO_APP_CLIENT_ID ?? 'f57xus4aqxlted28t5jahujbc',
  endpoint: process.env.REACT_APP_COGNITO_ENDPOINT ?? 'http://localhost:9229',
};

const userPool = new CognitoUserPool(userPoolConfig);
const loginWithCredentials = (
  email: string,
  password: string,
): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    const authenticationData = {
      Username: email,
      Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result: CognitoUserSession) {
        resolve(result);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
};

const refreshUserToken = async (): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser === null) return reject(new Error('no refresh token'));
    cognitoUser.getSession(function (
      err: Error,
      session: CognitoUserSession | null,
    ) {
      if (err) {
        reject(err);
        return;
      }
      resolve(session as CognitoUserSession);
    });
  });
};

const signOut = async () => {
  return new Promise((resolve) => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) return resolve(null);
    cognitoUser.signOut();
    resolve(null);
  });
};

const passwordRecovery = async (email: string) => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  return new Promise<void>((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        resolve(data);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
};

const confirmNewPassword = async (
  newPassword: string,
  code: string,
  email: string,
): Promise<void> => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  });
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess() {
        resolve();
      },
      onFailure(err) {
        reject(err);
      },
    });
  });
};

const registerUser = async (
  name: string,
  lastName: string,
  email: string,
  password: string,
): Promise<ISignUpResult | undefined> => {
  const attributeList: CognitoUserAttribute[] = [];
  const dataName = {
    Name: 'name',
    Value: name,
  };
  const dataEmail = {
    Name: 'email',
    Value: email,
  };
  const dataFamilyName = {
    Name: 'family_name',
    Value: lastName,
  };

  const attributeEmail = new CognitoUserAttribute(dataEmail);
  const attributeName = new CognitoUserAttribute(dataName);
  const attributeFamilyName = new CognitoUserAttribute(dataFamilyName);

  attributeList.push(attributeName);
  attributeList.push(attributeEmail);
  attributeList.push(attributeFamilyName);

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, attributeList, [], (err, result) => {
      if (err) {
        return reject(err);
      }
      return resolve(result);
    });
  });
};

export const registerUserWithoutEmailConfirmation = async (
  name: string,
  lastName: string,
  email: string,
  password: string,
): Promise<AWS.CognitoIdentityServiceProvider.AdminCreateUserResponse> => {
  const cognito = new AWS.CognitoIdentityServiceProvider({
    region: process.env.REACT_APP_AWS_REGION ?? 'region',
    endpoint: process.env.REACT_APP_COGNITO_ENDPOINT ?? 'http://localhost:9229',
  });

  const createUserParams: AWS.CognitoIdentityServiceProvider.AdminCreateUserRequest =
    {
      UserPoolId: userPoolConfig.UserPoolId,
      Username: email,
      UserAttributes: [
        {
          Name: 'name',
          Value: name,
        },
        {
          Name: 'email',
          Value: email,
        },
        {
          Name: 'family_name',
          Value: lastName,
        },
        {
          Name: 'email_verified',
          Value: 'true',
        },
      ],
      TemporaryPassword: password,
      MessageAction: 'SUPPRESS',
    };

  const user: AWS.CognitoIdentityServiceProvider.AdminCreateUserResponse =
    await new Promise((resolve, reject) => {
      cognito.adminCreateUser(createUserParams, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

  const setPasswordParams: AWS.CognitoIdentityServiceProvider.AdminSetUserPasswordRequest =
    {
      UserPoolId: userPoolConfig.UserPoolId,
      Username: email,
      Password: password,
      Permanent: true,
    };

  await new Promise((resolve, reject) => {
    cognito.adminSetUserPassword(setPasswordParams, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });

  return user;
};

export enum CognitoExceptions {
  UsernameExistsException = 'UsernameExistsException',
}

export {
  registerUser,
  loginWithCredentials,
  refreshUserToken,
  signOut,
  passwordRecovery,
  confirmNewPassword,
};
