export const maturedDealsSheetMock = [
  [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    'Hedgehog Software',
    null,
  ],
  [
    'Ltd. Sydney 1452',
    null,
    'FX Matured Deals Report - 30 Nov 2023',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    'AUD/NZD Hedging',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    'Deal Date',
    'Maturity Date',
    'Deal Type',
    'Direction',
    'Volume (AUD)',
    'Volume (NZD)',
    'Exchange Rate',
    'Counterparty',
    'Spot Rate at Maturity',
    'Gain/Loss (AUD)',
    'Deal Number',
    'Reference',
    'Comment',
  ],
  [
    new Date('2023-04-04'),
    new Date('2023-11-09'),
    'Forward',
    'AUD Buy / NZD Sell',
    100,
    -100,
    200,
    'GS',
    300,
    400,
    500,
    'SDBB7N73333KJSSZZF',
    null,
  ],
  [
    'TOTAL',
    null,
    null,
    null,
    600,
    -700,
    800,
    null,
    null,
    900,
    null,
    null,
    null,
  ],
  [
    'Footer.',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    'Footer.',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
];
