/* eslint-disable quotes */
/* eslint-disable no-sparse-arrays */
export const fxHedgeEffectivenessActualsSheetMock = [
  [, , , , , , , , , , , , , , , 'Hedgehog Software', 'Hedgehog Software'],
  [],
  [
    ,
    'The Jon',
    'The Jon',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    'FX Effectiveness Test Report - 30 Jun 24',
    ,
    null,
  ],
  [],
  [
    ,
    'The Hedging Instruments',
    'The Hedging Instruments',
    'The Hedging Instruments',
    'The Hedging Instruments',
    'The Hedging Instruments',
    'The Hedging Instruments',
    'The Hedging Instruments',
    'The Hedging Instruments',
    'The Hedging Instruments',
    'The Hedging Instruments',
    'The Hedging Instruments',
    'The Hedging Instruments',
    'The Hedging Instruments',
    'The Hedging Instruments',
    'The Hedging Instruments',
    'The Hedging Instruments',
    'The Hedging Instruments',
  ],
  [
    ,
    'Reference',
    'Comment',
    'Deal Number',
    'Deal Date',
    'Base CCY',
    'Buy CCY',
    'Buy Amount',
    'Contract Rate',
    'Sell CCY',
    'Sell Amount',
    'Maturity Date',
    "C' party",
    'Spot Rate at Inception',
    'MtM Value',
    'DF',
    'Market Forward Rate',
    'Spot Rate at Valuation',
  ],
  [
    ,
    'SYDR',
    ,
    100,
    new Date('2024-01-28T21:00:00-03:00'),
    'AUD',
    'CNY',
    100,
    100,
    'AUD',
    100,
    new Date('2024-07-17T21:00:00-03:00'),
    'CBA',
    ,
    200,
    300,
    400,
    500,
  ],
  [
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    'Total fair value of FX Forwards in CFHR',
    'Total fair value of FX Forwards in CFHR',
    'Total fair value of FX Forwards in CFHR',
    'Total fair value of FX Forwards in CFHR',
    'Total fair value of FX Forwards in CFHR',
    600,
  ],
  [
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    'Represents (gains) or losses in the CFHR of:',
    'Represents (gains) or losses in the CFHR of:',
    'Represents (gains) or losses in the CFHR of:',
    'Represents (gains) or losses in the CFHR of:',
    'Represents (gains) or losses in the CFHR of:',
    200,
  ],
  [
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
    'Hypothetical Derivative Method (Dollar Offset)',
  ],
  [
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
  ],
  [
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
  ],
  [
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
  ],
  [
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
  ],
  [
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
  ],
  [
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
    'The hypothetical approach',
  ],
  [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  [
    ,
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
  ],
  [
    ,
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
    'The valuation date of this report is 30 Jun 24.',
  ],
];
