import * as Yup from 'yup';
export const confirmationValidationSchema = Yup.object().shape({
  'new-password': Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must include at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must include at least one uppercase letter')
    .matches(/\d/, 'Password must include at least one digit')
    .matches(
      /[$&+,:;=?@#|'<>.^*()%!-]/,
      'Password must include at least one special character',
    ),
  'confirm-new-password': Yup.string()
    .oneOf([Yup.ref('new-password')], 'Passwords must match')
    .required('Confirm Password is required'),
  code: Yup.string().required('Code is required'),
});
